import { SearchToken } from 'models'

import axiosClient from './axiosClient'

const accountApi = {
  getTokenList(address: string): Promise<{ result: SearchToken[] | null }> {
    return axiosClient.get('/api', {
      params: {
        module: 'account',
        action: 'tokenlist',
        address
      }
    })
  }
}

export default accountApi
