import { Button, Stack, Typography } from '@mui/material'
import { StatusFilter } from 'components/filter'
import { BRAND_COLOR, BRAND_COLOR_LIGHT, DARK_BLUE, DARK_GREEN } from 'constants/index'
import React, { ReactNode } from 'react'
import { useIsDarkMode } from 'state/user/hooks'

export function ToggleButton({
  children,
  onClick,
  size,
  active = false,
  disabled = false
}: {
  active?: boolean
  disabled?: boolean
  onClick: () => void
  size: number
  children: ReactNode
}) {
  const darkMode = useIsDarkMode()

  return (
    <Button
      disableElevation
      disabled={disabled && !active}
      variant={active ? 'contained' : 'outlined'}
      onClick={onClick}
      sx={{
        width: `calc(${Math.min(size, 25)}% - 16px)`,
        mx: '8px',
        my: 1,
        py: 0,
        borderRadius: '90px !important',
        borderColor: `${darkMode ? BRAND_COLOR : DARK_GREEN} !important`,
        fontSize: { xs: '1rem', md: '1.125rem' },
        fontWeight: 600,

        '&.MuiButton-outlined': {
          bgcolor: 'transparent',
          color: 'primary.main'
        },

        '&.Mui-disabled': {
          color: darkMode ? 'rgba(119, 254, 255, 0.5) !important' : 'rgba(28, 65, 79, 0.5) !important',
          borderColor: darkMode ? 'rgba(119, 254, 255, 0.5) !important' : 'rgba(28, 65, 79, 0.5) !important'
        }
      }}
    >
      {children}
    </Button>
  )
}

export function ToggleButtonGroup({
  heading,
  disabledHeading,
  data,
  selected,
  onSelect,
  onSelectNone,
  onModeChange,
  activeMode = '',
  activeNone = false,
  disableMax = false,
  flexEnd = false
}: {
  heading?: string | string[]
  disabledHeading?: string
  data: string[]
  selected: number
  onSelect: (value: number) => void
  onSelectNone?: () => void
  onModeChange?: (newMode: string) => void
  activeMode?: string
  activeNone?: boolean
  disableMax?: boolean
  flexEnd?: boolean
}) {
  const darkMode = useIsDarkMode()
  const size = 100 / Math.min(!onSelectNone ? data.length : data.length + 1, 6)

  const handleSelectNone = () => {
    if (!activeNone && onSelectNone) {
      onSelectNone()
    }
  }

  const handleModeChange = (event: any, newMode: string | null) => {
    if (!onModeChange || newMode === null) return
    onModeChange(newMode)
  }

  return (
    <Stack spacing={1}>
      {!!heading && (
        <Typography
          component="span"
          fontSize={{ xs: '1.125rem', md: '1.25rem' }}
          color={darkMode ? BRAND_COLOR_LIGHT : DARK_BLUE}
        >
          {typeof heading === 'string' ? (
            heading
          ) : (
            <StatusFilter data={heading} value={activeMode} onChange={handleModeChange} disabledKey={disabledHeading} />
          )}
        </Typography>
      )}

      <Stack direction="row" flexWrap="wrap" mx="-8px !important" justifyContent={flexEnd ? 'flex-end' : 'flex-start'}>
        {onSelectNone && (
          <ToggleButton size={size} active={activeNone} onClick={handleSelectNone}>
            None
          </ToggleButton>
        )}

        {data.map((name, index) => (
          <ToggleButton
            key={index}
            size={size}
            active={!activeNone && selected === index}
            disabled={disableMax && name === 'Max'}
            onClick={() => {
              activeNone && onSelectNone && onSelectNone()
              onSelect(index)
            }}
          >
            {name}
          </ToggleButton>
        ))}
      </Stack>
    </Stack>
  )
}
