import { Button, IconButton, Stack, Typography } from '@mui/material'
import Popup from 'components/Popup/Popup'
import { BRAND_COLOR, DARK_BLUE, DARK_GREEN, TOKENS } from 'constants/index'
import React from 'react'
import { useEffect, useState } from 'react'
import Countdown, { CountdownRenderProps } from 'react-countdown'
import { useIsDarkMode } from 'state/user/hooks'
import { CheckCircle, UncheckCircle } from 'svgComponents'

import FaucetItem from './FaucetItem'

const getStyles = (isDarkTheme: boolean) => ({
  title: {
    color: isDarkTheme ? 'primary.light' : DARK_BLUE,
    fontSize: { xs: '1.5rem', sm: '2rem' }
  },

  accept: {
    my: { xs: 3, sm: 5 },
    py: 2,
    px: { xs: 1, sm: 2 },
    alignItems: 'center',
    border: 0.5,
    borderColor: 'primary.main',
    borderRadius: 2
  },

  description: {
    fontSize: { xs: '0.75rem', sm: '0.875rem' },
    fontWeight: 'normal',

    span: {
      fontWeight: 500,
      color: isDarkTheme ? 'primary.light' : DARK_GREEN
    }
  },

  check: {
    '& > svg': {
      width: { xs: 24, sm: 32 },
      transition: 'all 200ms ease-in-out'
    },

    '& > svg:first-of-type': {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)'
    },

    '& > svg.show': {
      visibility: 'visible',
      opacity: 1
    },

    '& > svg.hide': {
      visibility: 'hidden',
      opacity: 0
    }
  },

  button: {
    py: 2,
    fontSize: { xs: '1rem', sm: '1.5rem' },
    fontWeight: { xs: 600, sm: 500 }
  }
})

export interface StepWizardProps {
  open: boolean
  isInProgress: boolean
  lockTime?: number
  onClose?: () => void
  onFaucet?: () => void
}

function FaucetWizard({ open, onClose, onFaucet, lockTime = 0, isInProgress }: StepWizardProps) {
  const [isChecked, setIsChecked] = useState(false)

  useEffect(() => {
    setIsChecked(false)
  }, [open])

  const renderer = ({ hours, minutes, seconds, completed }: CountdownRenderProps) => {
    if (completed) return <span>FUND YOUR WALLET</span>

    return (
      <span style={{ textTransform: 'none', fontSize: '1.25rem' }}>
        Request funds after {hours}:{minutes}:{seconds}
      </span>
    )
  }
  const darkMode = useIsDarkMode()
  const styles = getStyles(darkMode)

  return (
    <Popup open={open} scroll="body" onClose={onClose}>
      <Typography component="h6" align="center" sx={styles.title}>
        The Poetic Faucet
      </Typography>

      <Stack direction="row" spacing={{ xs: 2, sm: 4 }} sx={styles.accept}>
        <IconButton onClick={() => setIsChecked(x => !x)} sx={styles.check}>
          <CheckCircle color={darkMode ? BRAND_COLOR : DARK_GREEN} className={isChecked ? 'show' : 'hide'} />
          <UncheckCircle color={darkMode ? BRAND_COLOR : DARK_GREEN} className={isChecked ? 'hide' : 'show'} />
        </IconButton>

        <Typography sx={styles.description}>
          By funding your wallet, you acknowledge that the assets you receive are for testing and{' '}
          <span>are not real assets</span>. They are not transferable to the Cardano Mainnet. Funding your wallet might
          take a while, depending on network congestion.
        </Typography>
      </Stack>

      <Stack spacing={1} mb={{ xs: 3, sm: 5 }} alignItems="center" textAlign="center">
        <Typography component="span" fontSize={{ xs: '1rem', sm: '1.25rem' }}>
          You are about to receive
        </Typography>

        <Typography
          fontSize={{ xs: '0.75rem', sm: '0.875rem' }}
        >{`*if you don't use the tokens anymore, you can send it back to us`}</Typography>
      </Stack>

      <Stack spacing={3} mb={5}>
        <FaucetItem text="150 USDT" token={TOKENS.USDT} />
        <FaucetItem text="150 USDC" token={TOKENS.USDC} />
        <FaucetItem text="1,000 Byron" token={TOKENS.BYRON} />
        <FaucetItem text="2,000 Shelley" token={TOKENS.SHELLEY} />
        <FaucetItem text="3,000 Goguen" token={TOKENS.GOGUEN} />
        <FaucetItem text="500 Basho" token={TOKENS.BASHO} />
        <FaucetItem text="200 Voltaire" token={TOKENS.VOLTAIRE} />
      </Stack>

      <Button
        fullWidth
        variant="outlined"
        disabled={!isChecked || lockTime > Date.now() || isInProgress}
        sx={styles.button}
        onClick={onFaucet}
      >
        {lockTime > Date.now() ? <Countdown date={lockTime} renderer={renderer} /> : 'FUND YOUR WALLET'}
      </Button>
    </Popup>
  )
}

export default FaucetWizard
