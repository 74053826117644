import { Token } from '@adaswap/sdk'
import { Button, Dialog, DialogContent, DialogTitle, Divider, Stack, Typography } from '@mui/material'
import Column from 'components/Column'
import CurrencyLogo from 'components/CurrencyLogo'
import { RowBetween } from 'components/Row'
import { RowFixed } from 'components/Row'
import { BG_DARK_BLUE, BRAND_COLOR_LIGHT, DARK_GREEN, LIGHT_GREEN } from 'constants/index'
import { useActiveWeb3React } from 'hooks'
import useTheme from 'hooks/useTheme'
import React, { useState } from 'react'
import { useMemo } from 'react'
import { Text } from 'rebass'
import { useIsDarkMode, useRemoveUserAddedToken, useUserAddedTokens } from 'state/user/hooks'
import styled from 'styled-components'
import { ExternalLinkIcon, TrashIcon, TYPE } from 'theme'
import { getEtherscanLink } from 'utils'

import { useDefaultTokens } from '../../hooks/Tokens'
import ImportRow from './ImportRow'
import { TokenListProps } from './TokenList'

const getStyles = (isDarkTheme: boolean) => ({
  root: {
    border: isDarkTheme ? `1px solid ${BRAND_COLOR_LIGHT}` : `1px solid ${LIGHT_GREEN}`,
    boxShadow: '0 12px 60px -10px rgba(0, 0, 0, 0.7)',
    borderRadius: 4,
    margin: '16px',
    p: 2,
    zIndex: 1301,
    mt: { xs: 14, md: 2 }
  },

  btn: {
    minWidth: 110,
    py: 0.85,
    ...(!isDarkTheme && {
      backgroundColor: 'primary.light',
      borderColor: 'primary.main',
      color: 'primary.main',
      '&:hover': {
        color: 'primary.light'
      }
    })
  },

  divider: {
    borderColor: isDarkTheme ? 'rgba(124, 229, 207, 0.5)' : DARK_GREEN,
    borderWidth: '0.5px',
    margin: '16px 0',
    width: 'calc(100% - 1.5rem)'
  }
})

const Title = styled(TYPE.primary2)`
  font-size: 1.5rem;
  padding: 0.5rem 0;

  ${({ theme }) => theme.mediaWidth.upToMedium`
       font-size: 1.25rem;
  `}
`
const Wrapper = styled(Column)`
  overflow-y: scroll;
  margin-right: -1.5rem;
`
const RowItem = styled(RowBetween)`
  width: calc(100% - 1.5rem);
  padding: 10px 20px;
  border: 1px solid transparent;

  &:hover {
    border-radius: 10px;
    border-color: ${({ theme }) => theme.primary2};
    cursor: pointer;
  }
`
const ConfirmDelete = ({
  open,
  onClose,
  onDelete,
  darkMode,
  styles
}: {
  open: boolean
  onClose: () => void
  onDelete: () => void
  darkMode: boolean
  styles: any
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        '.MuiDialog-paper': { ...styles.root, bgcolor: darkMode ? BG_DARK_BLUE : LIGHT_GREEN }
      }}
    >
      <DialogTitle>
        <Typography fontSize="1.125rem">Are you sure want to delete this token</Typography>
      </DialogTitle>

      <DialogContent sx={{ mt: 2 }}>
        <Stack direction="row" spacing={3} sx={{ justifyContent: 'center', alignItems: 'center' }}>
          <Button variant="outlined" sx={styles.btn} onClick={onClose}>
            Cancel
          </Button>
          <Button variant="outlined" sx={styles.btn} onClick={onDelete}>
            Yes
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  )
}

export default function Manage({
  searchToken,
  searchTokenIsAddedOrDefaultToken,
  showImportView,
  filteredSortedTokens,
  onCurrencySelect,
  setImportToken
}: TokenListProps) {
  const darkMode = useIsDarkMode()
  const styles = getStyles(darkMode)

  const { chainId } = useActiveWeb3React()
  const [addressDelete, setAddressDelete] = useState('')

  const userAddedTokens: Token[] = useUserAddedTokens()
  const removeToken = useRemoveUserAddedToken()

  const handleRemoveToken = () => {
    chainId && removeToken(chainId, addressDelete)
    setAddressDelete('')
  }

  const handleOpenDeleteModal = (event: React.MouseEvent<Element, MouseEvent>, address: string) => {
    event.stopPropagation()
    setAddressDelete(address)
  }

  const defaultTokens = useDefaultTokens()
  const defaultTokenList = Object.values(defaultTokens)
  const filteredSortedAddressTokens = useMemo(() => {
    return filteredSortedTokens.map(item => item.address)
  }, [filteredSortedTokens])
  const theme = useTheme()

  const tokenDefaultList = useMemo(() => {
    const filterSearchResult = defaultTokenList.filter(item => filteredSortedAddressTokens.includes(item.address))
    if (chainId && filterSearchResult.length > 0) {
      return (
        chainId &&
        filterSearchResult.map(token => (
          <RowItem key={token.address} onClick={() => onCurrencySelect(token)}>
            <RowFixed>
              <CurrencyLogo currency={token} size={'40px'} />
              <Column style={{ paddingLeft: '1rem' }}>
                <Text title={token.name} fontWeight={600} fontSize={'1.25rem'} color={theme.text1}>
                  {token.symbol}
                </Text>
                <TYPE.darkGray ml="0px" fontSize={'1rem'} fontWeight={300}>
                  {token.name}
                </TYPE.darkGray>
              </Column>
            </RowFixed>
          </RowItem>
        ))
      )
    }
    return <TYPE.primary2>No options</TYPE.primary2>
  }, [defaultTokenList, chainId, filteredSortedAddressTokens])

  const tokenUserList = useMemo(() => {
    const filterSearchResult = userAddedTokens.filter(item => filteredSortedAddressTokens.includes(item.address))
    if (chainId && filterSearchResult.length > 0) {
      return filterSearchResult.map(token => (
        <RowItem key={token.address} onClick={() => onCurrencySelect(token)}>
          <RowFixed>
            <CurrencyLogo currency={token} size={'40px'} />
            <Column style={{ paddingLeft: '1rem' }}>
              <Text title={token.name} fontWeight={600} fontSize={'1.25rem'} color={theme.text1}>
                {token.symbol}
              </Text>
              <TYPE.darkGray ml="0px" fontSize={'1rem'} fontWeight={300}>
                {token.name}
              </TYPE.darkGray>
            </Column>
          </RowFixed>
          <RowFixed>
            <TrashIcon onClick={e => handleOpenDeleteModal(e, token.address)} />
            <ExternalLinkIcon href={getEtherscanLink(chainId, token.address, 'address')} />
          </RowFixed>
        </RowItem>
      ))
    }
    return <TYPE.primary2>No options</TYPE.primary2>
  }, [userAddedTokens, chainId, removeToken, filteredSortedAddressTokens])

  return (
    <React.Fragment>
      {/* <PaddedColumn>
        <RowBetween>
          <ArrowLeft
            style={{ cursor: 'pointer', color: theme.primary2 }}
            onClick={() => setModalView(CurrencyModalView.search)}
          />
          <Text fontWeight={500} fontSize={20} color={theme.primary2}>
            Manage
          </Text>
          <CloseIcon style={{ color: theme.primary2 }} onClick={onDismiss} />
        </RowBetween>
      </PaddedColumn>
      <PaddedColumn style={{ paddingBottom: 0 }}>
        <ToggleWrapper>
          <ToggleOption onClick={() => setShowLists(!showLists)} active={showLists}>
            Lists
          </ToggleOption>
          <ToggleOption onClick={() => setShowLists(!showLists)} active={!showLists}>
            Tokens
          </ToggleOption>
        </ToggleWrapper>
      </PaddedColumn>
      {showLists ? (
        <ManageLists setModalView={setModalView} setImportList={setImportList} setListUrl={setListUrl} />
      ) : ( 
      <ManageTokens setModalView={setModalView} setImportToken={setImportToken} /> 
       )} 
      <PaddedColumn>
        <RowBetween>
          <ArrowLeft
            style={{ cursor: 'pointer', color: theme.primary2 }}
            onClick={() => setModalView(CurrencyModalView.search)}
          />
          <Text fontWeight={500} fontSize={20} color={theme.primary2}>
            Manage
          </Text>
          <CloseIcon style={{ color: theme.primary2 }} onClick={onDismiss} />
        </RowBetween>
      </PaddedColumn>
      <PaddedColumn style={{ paddingBottom: 0 }}>
        <ToggleWrapper>
          <ToggleOption onClick={() => setShowLists(!showLists)} active={showLists}>
            Lists
          </ToggleOption>
          <ToggleOption onClick={() => setShowLists(!showLists)} active={!showLists}>
            Tokens
          </ToggleOption>
        </ToggleWrapper>
      </PaddedColumn>
      {showLists ? (
        <ManageLists setModalView={setModalView} setImportList={setImportList} setListUrl={setListUrl} />
      ) : (  */}

      <Wrapper>
        {/* <RowBetween>
          <TYPE.primary2 fontWeight={600}>Custom Token</TYPE.primary2>
          {userAddedTokens.length > 0 && (
            <ButtonText onClick={handleRemoveAll}>
              <TYPE.primary2>Clear all</TYPE.primary2>
            </ButtonText>
          )}
        </RowBetween> */}

        {searchToken && !searchTokenIsAddedOrDefaultToken && !defaultTokenList.includes(searchToken) && (
          <ImportRow
            style={{
              padding: 0,
              paddingRight: '1.5rem',
              marginTop: '1rem',
              marginBottom: '1rem'
            }}
            token={searchToken}
            showImportView={showImportView}
            setImportToken={setImportToken}
          />
        )}

        <Title>Popular</Title>
        {tokenDefaultList}
        <Divider sx={styles.divider} />
        <Title>Custom token</Title>
        {tokenUserList}
        <ConfirmDelete
          open={addressDelete ? true : false}
          onClose={() => setAddressDelete('')}
          onDelete={handleRemoveToken}
          darkMode={darkMode}
          styles={styles}
        />
      </Wrapper>
    </React.Fragment>
  )
}
