interface Props {
  color?: string
  style?: any
}

export const StakeIcon = (props: Props) => (
  <svg width="35" height="36" viewBox="0 0 35 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M29.186 20.06a.625.625 0 0 0-.881 0l-8.75 8.75a.626.626 0 1 0 .88.88l8.75-8.75a.625.625 0 0 0 0-.88zM21.245 24.251a2.499 2.499 0 1 0 0-4.998 2.499 2.499 0 0 0 0 4.998zm0-3.75a1.25 1.25 0 1 1 0 2.499 1.25 1.25 0 0 1 0-2.499zM27.495 25.501a2.499 2.499 0 0 0-1.767 4.267 2.499 2.499 0 1 0 1.767-4.267zm0 3.75a1.25 1.25 0 1 1 0-2.5 1.25 1.25 0 0 1 0 2.5zM11.845 20.423a9.374 9.374 0 1 1 8.075-8.11.63.63 0 1 0 1.25.156A10.628 10.628 0 0 0 11.53.54a10.625 10.625 0 1 0 .476 21.122.63.63 0 1 0-.161-1.25v.012z"
      fill={props.color || '#7CE5CF'}
    />
    <path
      d="M24.37 14.251a10.62 10.62 0 0 0-7.512 3.112 10.62 10.62 0 0 0 0 15.025 10.62 10.62 0 0 0 15.024 0 10.62 10.62 0 0 0 0-15.024 10.62 10.62 0 0 0-7.512-3.113zm0 20a9.38 9.38 0 0 1-6.63-2.745 9.38 9.38 0 0 1 0-13.26 9.38 9.38 0 0 1 13.26 0 9.38 9.38 0 0 1 0 13.26 9.38 9.38 0 0 1-6.63 2.745zM10.182 6.001l-4.687 4.688a.625.625 0 0 0 0 .88l4.687 4.688a.625.625 0 0 0 .882 0l4.687-4.687a.625.625 0 0 0 0-.881L11.064 6a.625.625 0 0 0-.882 0zm.438 8.925-3.797-3.8 3.797-3.8 3.803 3.8-3.803 3.8z"
      fill={props.color || '#7CE5CF'}
    />
  </svg>
)
