import { Currency, Token } from '@adaswap/sdk'
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked'
import TaskAltIcon from '@mui/icons-material/TaskAlt'
import { Checkbox, Divider } from '@mui/material'
import { ButtonPrimary } from 'components/Button'
import Card from 'components/Card'
import Column, { AutoColumn } from 'components/Column'
import CurrencyLogo from 'components/CurrencyLogo'
import { AutoRow, RowFixed } from 'components/Row'
import { DARK_GREEN } from 'constants/index'
import { useActiveWeb3React } from 'hooks'
import useTheme from 'hooks/useTheme'
import { transparentize } from 'polished'
import { useState } from 'react'
import { AlertTriangle } from 'react-feather'
import { Text } from 'rebass'
import { useCombinedInactiveList } from 'state/lists/hooks'
import { useAddUserToken } from 'state/user/hooks'
import styled from 'styled-components'
import { TYPE } from 'theme'

import { PaddedColumn } from './styleds'

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  overflow: auto;
`

const WarningWrapper = styled(Card)<{ highWarning: boolean }>`
  background-color: ${({ theme, highWarning }) =>
    highWarning ? transparentize(0.8, theme.red1) : transparentize(0.8, theme.yellow2)};
  width: fit-content;
`

const AddressText = styled(TYPE.blue)`
  font-size: 12px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 10px;
`}
`

interface ImportProps {
  tokens: Token[]
  onBack?: () => void
  onDismiss?: () => void
  handleCurrencySelect?: (currency: Currency) => void
}

export function ImportToken({ tokens, onBack, onDismiss, handleCurrencySelect }: ImportProps) {
  const theme = useTheme()

  const { chainId } = useActiveWeb3React()

  const [confirmed, setConfirmed] = useState(false)

  const addToken = useAddUserToken()

  // use for showing import source on inactive tokens
  const inactiveTokenList = useCombinedInactiveList()

  // higher warning severity if either is not on a list
  const fromLists =
    (chainId && inactiveTokenList?.[chainId]?.[tokens[0]?.address]?.list) ||
    (chainId && inactiveTokenList?.[chainId]?.[tokens[1]?.address]?.list)

  return (
    <Wrapper>
      {/* <PaddedColumn gap="14px" style={{ width: '100%', flex: '1 1' }}>
        <RowBetween>
          {onBack ? <ArrowLeft style={{ cursor: 'pointer', color: theme.primary2 }} onClick={onBack} /> : <div></div>}
          <TYPE.primary2 fontSize="1.5rem">Import {tokens.length > 1 ? 'Tokens' : 'Token'}</TYPE.primary2>
          {onDismiss ? <CloseIcon onClick={onDismiss} /> : <div></div>}
        </RowBetween>
      </PaddedColumn> */}
      <PaddedColumn gap="md">
        <Card style={{ backgroundColor: 'transparent', border: `1px solid ${theme.bg8}` }}>
          <AutoColumn justify="center" style={{ textAlign: 'center', gap: '16px', marginBottom: '12px' }}>
            <AlertTriangle stroke={theme.bg8} size={32} />
          </AutoColumn>

          <AutoColumn style={{ gap: '16px', marginBottom: '12px' }}>
            <TYPE.body fontWeight={500} color={theme.bg8}>
              Anyone can create a ERC20 token on Milkomeda with any name, including creating fake versions of existing
              tokens and tokens that claim to represent projects that do not have a token.
            </TYPE.body>
            <TYPE.body fontWeight={500} color={theme.bg8}>
              If you purchase this token, you may not be able to sell it back.
            </TYPE.body>
          </AutoColumn>
        </Card>
        {tokens.map(token => {
          const list = chainId && inactiveTokenList?.[chainId]?.[token.address]?.list
          return (
            <Card backgroundColor="transparent" key={'import' + token.address} className=".token-warning-container">
              <AutoColumn gap="10px">
                <AutoRow align="center">
                  <RowFixed>
                    <CurrencyLogo currency={token} size={'40px'} />
                    <Column style={{ paddingLeft: '1rem' }}>
                      <Text title={token.name} fontWeight={600} fontSize={'1.25rem'} color={theme.text1}>
                        {token.symbol}
                      </Text>
                      <TYPE.darkGray ml="0px" fontSize={'1rem'} fontWeight={300}>
                        {token.name}
                      </TYPE.darkGray>
                    </Column>
                  </RowFixed>
                </AutoRow>
                {/* {chainId && (
                  <ExternalLink href={getEtherscanLink(chainId, token.address, 'address')}>
                    <AddressText>{token.address}</AddressText>
                  </ExternalLink>
                )} */}
                {/* {list !== undefined ? (
                  <RowFixed>
                    {list.logoURI && <ListLogo logoURI={list.logoURI} size="12px" />}
                    <TYPE.small ml="6px" color={theme.text3}>
                      via {list.name}
                    </TYPE.small>
                  </RowFixed>
                ) : (
                  <WarningWrapper borderRadius="4px" padding="4px" highWarning={true}>
                    <RowFixed>
                      <AlertTriangle stroke={theme.red1} size="10px" />
                      <TYPE.body color={theme.red1} ml="4px" fontSize="10px" fontWeight={500}>
                        Unknown Source
                      </TYPE.body>
                    </RowFixed>
                  </WarningWrapper>
                )} */}
              </AutoColumn>
            </Card>
          )
        })}
        <Divider
          style={{
            borderColor: theme.isDarkTheme ? 'rgba(124, 229, 207, 0.5)' : DARK_GREEN,
            borderWidth: '0.5px',
            margin: '10px 0'
          }}
        />
        <AutoRow
          style={{ cursor: 'pointer', paddingBottom: '2rem', justifyContent: 'space-between' }}
          onClick={() => setConfirmed(!confirmed)}
        >
          <RowFixed>
            <Checkbox
              checked={confirmed}
              onChange={() => setConfirmed(!confirmed)}
              icon={<RadioButtonUncheckedIcon style={{ color: theme.primary2 }} />}
              checkedIcon={<TaskAltIcon style={{ color: theme.primary2 }} />}
            />
            <TYPE.primary2 ml="10px" fontSize="16px" fontWeight={500}>
              I understand
            </TYPE.primary2>
          </RowFixed>
          <ButtonPrimary
            disabled={!confirmed}
            altDisabledStyle={true}
            width="auto"
            borderRadius="20px"
            padding=".5rem 3rem"
            onClick={() => {
              tokens.map(token => addToken(token))
              handleCurrencySelect && handleCurrencySelect(tokens[0])
            }}
            className=".token-dismiss-button"
          >
            Import
          </ButtonPrimary>
        </AutoRow>
      </PaddedColumn>
    </Wrapper>
  )
}
