import { LIGHT_BOX_SHADOW, LIGHT_GREEN } from 'constants/index'
import React from 'react'
import styled from 'styled-components'

export const BodyWrapper = styled.div`
  position: relative;
  max-width: 552px;
  width: 100%;
  background-color: ${({ theme }) => (theme.isDarkTheme ? 'rgba(0, 0, 0, 0.5)' : LIGHT_GREEN)};
  border-radius: 16px;
  padding: 1.5rem 1rem;
  border: ${({ theme }) => (theme.isDarkTheme ? `1px solid ${theme.primary2}` : 'none')};
  box-shadow: ${({ theme }) => (theme.isDarkTheme ? 'none' : LIGHT_BOX_SHADOW)};
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    padding: 1rem 0.5rem;
    max-width: calc(100vw - 2rem);
  `};
`

/**
 * The styled container element that wraps the content of most pages and the tabs.
 */
export default function AppBody({ children, hide = false }: { children: React.ReactNode; hide?: boolean }) {
  return (
    <BodyWrapper style={{ opacity: hide ? 0 : 1, visibility: hide ? 'hidden' : 'visible' }}>{children}</BodyWrapper>
  )
}
