import AppsRoundedIcon from '@mui/icons-material/AppsRounded'
import FormatListBulletedRoundedIcon from '@mui/icons-material/FormatListBulletedRounded'
import { styled, ToggleButton, ToggleButtonGroup } from '@mui/material'
import React from 'react'

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  border: 'none',
  opacity: 0.5,
  transition: 'all 200ms ease-in-out',
  backgroundColor: 'transparent !important',

  '&.Mui-selected': {
    opacity: 1
  },

  '& > svg': {
    color: theme.isDarkTheme ? theme.palette.primary.light : theme.palette.primary.main
  }
}))

export interface LayoutFilterProps {
  value: number
  onChange: (event: any, newLayout: number | null) => void
}

export function LayoutFilter({ value, onChange }: LayoutFilterProps) {
  const iconSize = { width: '32px', height: '32px' }

  return (
    <ToggleButtonGroup value={value} onChange={onChange} exclusive size="small">
      <StyledToggleButton value={2}>
        <AppsRoundedIcon sx={iconSize} />
      </StyledToggleButton>

      <StyledToggleButton value={1}>
        <FormatListBulletedRoundedIcon sx={iconSize} />
      </StyledToggleButton>
    </ToggleButtonGroup>
  )
}
