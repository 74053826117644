/* eslint-disable @typescript-eslint/no-unused-vars */
import { Components, Theme } from '@mui/material'

import { BG_DARK_BLUE } from '../../constants'
import { ComponentParams } from '../index'

const MuiTypography = ({ globalTheme, isDarkTheme }: ComponentParams): Components<Theme>['MuiTypography'] => ({
  styleOverrides: {
    root: {
      color: isDarkTheme ? globalTheme.palette.primary.main : BG_DARK_BLUE,
      fontWeight: 500
    }
  }
})

export default MuiTypography
