import * as React from 'react'

interface Props {
  color?: string
  className?: string
}

export const BugsIcon = (props: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
    <g clipPath="url(#a)">
      <path
        d="M24.573 12.808a.963.963 0 0 0-1.29-.458l-2.344 1.11-1.998-.693v-2h2.56a.967.967 0 0 0 .967-.967V7.005a.968.968 0 1 0-1.936 0v1.827H4.133V7.005a.967.967 0 1 0-1.936 0V9.8a.97.97 0 0 0 .968.968h2.56v2l-1.999.692-2.343-1.11a.97.97 0 0 0-1.29.458.97.97 0 0 0 .46 1.29l2.701 1.282a.987.987 0 0 0 .733.04l1.737-.601v.623-.002c.002.466.052.93.152 1.386l-2.15.686-.001-.001a.973.973 0 0 0-.625.617l-.853 2.562a.97.97 0 0 0 .918 1.274.969.969 0 0 0 .92-.661l.697-2.097 1.8-.573v-.001a6.546 6.546 0 0 0 11.501 0l1.8.573.698 2.096v.002a.969.969 0 0 0 1.837-.613l-.853-2.562a.973.973 0 0 0-.624-.617l-2.15-.687-.001.002c.1-.456.15-.92.151-1.386v-.623l1.738.602v.002c.101.034.209.05.316.053a.99.99 0 0 0 .416-.094l2.701-1.282a.97.97 0 0 0 .46-1.29zM7.66 15.44v-4.672h3.704v9.243a4.68 4.68 0 0 1-3.704-4.57zm5.64 4.571v-9.243h3.704v4.672a4.68 4.68 0 0 1-3.704 4.571zM7.19 2.361a.968.968 0 1 1 1.77-.786l.618 1.39v.001a4.384 4.384 0 0 1 5.508 0l.617-1.39v-.001a.97.97 0 0 1 1.77.786L16.44 4.692v-.001a4.44 4.44 0 0 1 .373 1.783.968.968 0 1 1-1.936 0 2.544 2.544 0 1 0-5.088 0 .969.969 0 0 1-1.936 0c0-.614.127-1.22.374-1.783L7.192 2.36z"
        fill={props.color || '#7CE5CF'}
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
)
