import { Box, Container, Typography } from '@mui/material'
import { useWeb3React } from '@web3-react/core'
import { DARK_GREEN, LOADING_GIF_LT } from 'constants/index'
import useMigratePool from 'hooks/useMigratePool'
import { useIsDarkMode } from 'state/user/hooks'

import LPItem from './LpItem'

function MigrateLpPage() {
  const { active } = useWeb3React()
  const darkMode = useIsDarkMode()
  const { ALPTokenWithOldPools, loading, error } = useMigratePool()

  return (
    <Container maxWidth="md">
      <Typography
        sx={{
          mt: { xs: 5, sm: 10 },
          mb: 4,
          color: darkMode ? '#fff' : DARK_GREEN,
          fontSize: { xs: '2rem' },
          textAlign: { xs: 'center', md: 'left' }
        }}
      >
        {active ? 'Migrate all your pools' : 'Please connect your wallet to migrate pools'}
      </Typography>
      {error ? (
        <Typography
          sx={{
            mt: { xs: 5, sm: 10 },
            mb: 4,
            color: darkMode ? '#fff' : DARK_GREEN,
            fontSize: { xs: '1rem' },
            textAlign: { xs: 'center', md: 'left' }
          }}
        >
          Error when get pool information.
        </Typography>
      ) : (
        <>
          {loading && (
            <Box textAlign="center" pt={3}>
              <img src={darkMode ? LOADING_GIF_LT : LOADING_GIF_LT} alt="" width={150} />
            </Box>
          )}
          {ALPTokenWithOldPools.map(token => (
            <LPItem alpToken={token} key={token.address} />
          ))}
        </>
      )}
    </Container>
  )
}

export default MigrateLpPage
