interface Props {
  color?: string
  style?: any
}

export const HistoryIcon = (props: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
    <path
      xmlns="http://www.w3.org/2000/svg"
      d="M10.56 8.16v3.617a1.68 1.68 0 0 0 1.68 1.663h2.88V12h-2.88a.24.24 0 0 1-.24-.223V8.16h-1.44z"
      fill={props.color || '#7CE5CF'}
    />
    <path
      xmlns="http://www.w3.org/2000/svg"
      d="M12 4.56a7.439 7.439 0 0 0-6 3.048V5.52H4.56v4.56h4.56V8.64H7.038a6.001 6.001 0 0 1 10.693 5.148A5.998 5.998 0 0 1 6 12v-.24H4.56V12A7.443 7.443 0 0 0 12 19.44 7.442 7.442 0 0 0 19.44 12 7.443 7.443 0 0 0 12 4.56z"
      fill={props.color || '#7CE5CF'}
    />
  </svg>
)
