import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import { Dialog, DialogProps } from '@mui/material'
import { BG_DARK_BLUE, LIGHT_GREEN } from 'constants/index'
import { useIsDarkMode } from 'state/user/hooks'

export interface PopupProps extends DialogProps {
  paperStyles?: any
  smallCloseIcon?: boolean
  onBack?: () => void
  hideCloseIcon?: boolean
}

function Popup(props: PopupProps) {
  const {
    open,
    onClose,
    onBack,
    children,
    paperStyles,
    smallCloseIcon = false,
    hideCloseIcon = false,
    ...other
  } = props

  const darkMode = useIsDarkMode()
  const styles = {
    root: {
      '.MuiDialog-paper': {
        padding: '72px 32px 64px',
        border: darkMode ? '1px solid #77feff' : `1px solid ${LIGHT_GREEN}`,
        borderRadius: 4,
        backgroundColor: darkMode ? BG_DARK_BLUE : LIGHT_GREEN,
        boxShadow: '0 12px 60px -10px rgba(0, 0, 0, 0.7)',
        ...paperStyles
      }
    },

    close: {
      position: 'absolute',
      top: 0,
      right: 0,
      transform: 'translate(-1rem, 1rem)',

      fontSize: smallCloseIcon ? { xs: '1.5rem', sm: '2rem' } : { xs: '2rem', sm: '2.5rem' },
      color: 'primary.main',
      transition: 'all 200ms ease-in-out',
      cursor: 'pointer',

      ':hover': {
        color: darkMode ? 'primary.light' : 'primary.main'
      }
    }
  }

  return (
    <Dialog fullWidth open={open} onClose={onClose} {...other} sx={styles.root}>
      {!onBack && !hideCloseIcon && <CloseRoundedIcon sx={styles.close} onClick={onClose as () => void} />}
      {onBack && (
        <ArrowBackIosRoundedIcon
          onClick={onBack}
          sx={{
            ...styles.close,
            left: 0,
            transform: 'translate(1.5rem, 1.5rem)',
            fontSize: { xs: '1.5rem', sm: '2rem' }
          }}
        />
      )}
      {children}
    </Dialog>
  )
}

export default Popup
