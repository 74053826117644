/* eslint-disable @typescript-eslint/no-unused-vars */
import { Components, Theme } from '@mui/material'

import { ComponentParams } from '../index'

const MuiSvgIcon = ({ globalTheme, isDarkTheme }: ComponentParams): Components<Theme>['MuiSvgIcon'] => ({
  defaultProps: {
    color: 'primary'
  }
})

export default MuiSvgIcon
