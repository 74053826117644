import { Stack, styled, Typography } from '@mui/material'
import { BG_DARK_GREEN, DARK_GREEN, DARKER_GREEN } from 'constants/index'
import React from 'react'
import { useIsDarkMode } from 'state/user/hooks'

export interface FaucetItemProps {
  text: string
  token: string
}

const Icon = styled('img')(({ theme }) => ({
  width: 40,

  [theme.breakpoints.down('sm')]: {
    width: 24
  }
}))

function FaucetItem({ text, token }: FaucetItemProps) {
  const darkMode = useIsDarkMode()
  return (
    <Stack
      direction="row"
      sx={{
        alignItems: 'center',
        justifyContent: 'space-between',
        p: '16px 20px',
        border: 1,
        borderColor: darkMode ? 'primary.light' : DARK_GREEN,
        borderRadius: 4,
        bgcolor: darkMode ? BG_DARK_GREEN : DARKER_GREEN
      }}
    >
      <Typography component="span" fontSize={{ xs: '1rem', sm: '1.25rem' }}>
        {text}
      </Typography>

      <Icon src={token} alt="" />
    </Stack>
  )
}

export default FaucetItem
