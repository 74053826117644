import { Currency, Token } from '@adaswap/sdk'
import { ButtonPrimary } from 'components/Button'
import { LIGHT_GREEN } from 'constants/index'
import useTheme from 'hooks/useTheme'
import React from 'react'
import styled from 'styled-components'

import { TYPE } from '../../theme'
import Column from '../Column'
import Row from '../Row'
import TokenList from './TokenList'

const ContentWrapper = styled(Column)`
  width: 100%;
  flex: 1 1;
  position: relative;
  color: ${({ theme }) => theme.white};
`

const Footer = styled.div`
  width: 100%;
  /* border-radius: 20px; */
  padding-top: 2.5rem;
  /* border-top-left-radius: 0;
  border-top-right-radius: 0; */
  /* background-color: ${({ theme }) => theme.bg1}; */
  /* border-top: 1px solid ${({ theme }) => theme.bg2}; */
`
const ManageBtn = styled(ButtonPrimary)`
  padding: 1.25rem 1rem;
`

interface CurrencySearchProps {
  onDismiss: () => void
  selectedCurrency?: Currency | null
  onCurrencySelect: (currency: Currency) => void
  otherSelectedCurrency?: Currency | null
  showManageView: () => void
  showImportView: () => void
  setImportToken: (token: Token) => void
  searchToken: Token | null | undefined
  searchTokenIsAddedOrDefaultToken: boolean
  filteredSortedTokens: Token[]
  filteredInactiveTokens: Token[]
  showETH: boolean
}

export function CurrencySearch({
  selectedCurrency,
  onCurrencySelect,
  otherSelectedCurrency,
  onDismiss,
  showManageView,
  showImportView,
  setImportToken,
  searchToken,
  searchTokenIsAddedOrDefaultToken,
  filteredSortedTokens,
  filteredInactiveTokens,
  showETH
}: CurrencySearchProps) {
  const theme = useTheme()

  return (
    <ContentWrapper>
      <TokenList
        searchToken={searchToken}
        searchTokenIsAddedOrDefaultToken={searchTokenIsAddedOrDefaultToken}
        showImportView={showImportView}
        setImportToken={setImportToken}
        filteredInactiveTokens={filteredInactiveTokens}
        filteredSortedTokens={filteredSortedTokens}
        onCurrencySelect={onCurrencySelect}
        onDismiss={onDismiss}
        otherSelectedCurrency={otherSelectedCurrency}
        selectedCurrency={selectedCurrency}
        showETH={showETH}
      />

      <Footer>
        <Row justify="center">
          <ManageBtn onClick={showManageView} color={theme.blue1} className="list-token-manage-button">
            <TYPE.main color={theme.isDarkTheme ? theme.primary2 : LIGHT_GREEN}>Manage Token</TYPE.main>
          </ManageBtn>
        </Row>
      </Footer>
    </ContentWrapper>
  )
}
