import * as React from 'react'

interface Props {
  color?: string
  style?: any
  className?: any
}

export const CheckCircle = ({ color, ...rest }: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} fill="none" {...rest}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.411 22.883a.83.83 0 0 0 .21-.153l11.313-11.313a.821.821 0 0 0 .001-1.16l-.581-.582a.822.822 0 0 0-1.16.001L13.04 19.83l-4.495-4.495a.819.819 0 0 0-1.16-.002l-.581.582a.82.82 0 0 0 .001 1.16l5.654 5.654a.82.82 0 0 0 .953.153zM0 16C0 7.164 7.161 0 16 0c8.836 0 16 7.161 16 16 0 8.836-7.161 16-16 16-8.836 0-16-7.161-16-16z"
      fill={color || '#7CE5CF'}
    />
  </svg>
)
