import { SelectChangeEvent, Stack } from '@mui/material'
import { useWeb3React } from '@web3-react/core'
import { LayoutFilter, LPFilter, SearchFilter, SortFilter } from 'components/filter'
import { PoolNavigation } from 'components/pool'
import { PoolQueryParams } from 'pages/Pool'
import { useIsDarkMode } from 'state/user/hooks'

const getStyles = (isDarkTheme: boolean) => ({
  root: {
    px: { xs: 0, sm: 2 },
    py: { xs: 4, sm: 2 },
    alignItems: 'center',
    justifyContent: 'space-between',
    border: {
      xs: 0,
      sm: isDarkTheme ? 'solid 1px rgba(124, 229, 207, 0.5)' : 'solid 1px rgba(28, 65, 79, 0.5)'
    },
    borderRadius: { xs: 0, sm: 2 }
  },

  left: {
    alignItems: 'center',
    justifyContent: { xs: 'space-between', md: 'flex-start' },
    minWidth: { xs: '100%', sm: 280, md: 0 }
  }
})

export interface PoolFilterProps {
  filters: PoolQueryParams
  onLayoutChange: (event: any, newLayout: number | null) => void
  onFilterChange: (event: SelectChangeEvent<string>) => void
  onSearch: (value: string) => void
  onToggleShowLP: () => void
  loading?: boolean
}

export function PoolFilter({
  filters,
  onLayoutChange,
  onFilterChange,
  onSearch,
  onToggleShowLP,
  loading = false
}: PoolFilterProps) {
  const { active } = useWeb3React()
  const darkMode = useIsDarkMode()
  const styles = getStyles(darkMode)

  return (
    <Stack spacing={3} direction={{ xs: 'column', lg: 'row' }} sx={styles.root}>
      <Stack direction="row" spacing={{ xs: 3, md: 5, lg: 7 }} sx={styles.left}>
        <LayoutFilter value={filters.view} onChange={onLayoutChange} />
        {active && !loading && <LPFilter showMyLP={filters.showMyLP} onToggle={onToggleShowLP} />}
        <PoolNavigation smallSize addLiquidityOnly />
      </Stack>

      <Stack direction="row" spacing={3} pl={1} minWidth={{ xs: '100%', sm: 0 }}>
        <SortFilter value={filters.filter} onChange={onFilterChange} />
        <SearchFilter onSearch={onSearch} placeholderText="Search Pools" />
      </Stack>
    </Stack>
  )
}
