import { FormControlLabel, styled, Switch, SwitchProps } from '@mui/material'
import { BG_DARK_BLUE, BRAND_COLOR, LIGHT_GREEN } from 'constants/index'
import { useIsDarkMode } from 'state/user/hooks'

export interface StackedFilterProps extends SwitchProps {
  isChecked?: boolean
  hideLabel?: boolean
  onToggle?: () => void
}

const StyledSwitch = styled(Switch)(({ theme }) => {
  const colorByTheme = theme.isDarkTheme ? BG_DARK_BLUE : LIGHT_GREEN

  return {
    padding: 6,
    width: 68,

    '.MuiSwitch-track': {
      borderRadius: '90px',
      backgroundColor: colorByTheme,
      border: `1px solid ${theme.palette.primary.main}`,
      opacity: '1 !important'
    },

    '.MuiSwitch-switchBase': {
      padding: 6
    },

    '.MuiSwitch-thumb': {
      width: 18,
      height: 18,
      margin: 4,
      border: `1px solid ${theme.isDarkTheme ? BRAND_COLOR : BG_DARK_BLUE}`,
      color: BG_DARK_BLUE,
      boxShadow: 'none'
    },

    '.Mui-checked': {
      transform: 'translateX(30px) !important'
    },

    '.Mui-checked > .MuiSwitch-thumb': {
      borderColor: colorByTheme,
      color: colorByTheme
    }
  }
})

const getSx = (isDarkTheme: boolean) => ({
  marginRight: 0,

  '.MuiFormControlLabel-label': {
    color: isDarkTheme ? 'primary.light' : 'primary.main',
    fontWeight: 'normal',
    fontSize: '1rem',
    ml: '6px'
  }
})

export function StackedFilter({ isChecked, onToggle, hideLabel = false }: StackedFilterProps) {
  const darkMode = useIsDarkMode()

  return (
    <FormControlLabel
      label={hideLabel ? null : 'Staked Only'}
      control={<StyledSwitch checked={isChecked} />}
      sx={getSx(darkMode)}
      onChange={onToggle}
    />
  )
}
