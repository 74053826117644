import * as React from 'react'

interface Props {
  color?: string
  className?: string
}

export const TransactionSubmittedIcon = (props: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={70} height={38} fill="none" {...props}>
    <g clipPath="url(#a)">
      <path
        d="M34.359 0c-4.445.11-7.885 1.687-10.029 4.18-2.143 2.494-2.939 5.691-2.85 8.787.03 1.194 1.146 2.254 2.34 2.221 1.193-.03 2.253-1.146 2.22-2.34-.062-2.238.496-4.256 1.734-5.7 1.232-1.433 3.186-2.493 6.63-2.588h.047c3.15.018 5.062 1.078 6.368 2.589 1.312 1.52 1.98 3.625 1.947 5.724-.023 1.208 1.072 2.327 2.28 2.327h3.04c1.206.018 2.313-1.075 2.313-2.28 0-1.205-1.107-2.298-2.313-2.28h-.902c-.342-2.325-1.27-4.602-2.898-6.484C42.126 1.654 38.69.01 34.4 0h-.047.006zm26.327 3.777a2.327 2.327 0 0 0-1.84 1.481 2.33 2.33 0 0 0 .462 2.319l4.753 5.343-4.753 5.32c-.795.897-.706 2.435.19 3.23.897.796 2.435.707 3.23-.19l6.083-6.816c.74-.825.74-2.215 0-3.04L62.728 4.56a2.327 2.327 0 0 0-2.042-.783zm-7.508 6.863c-1.194.063-2.224 1.206-2.161 2.4.062 1.193 1.205 2.223 2.398 2.16h.76c1.206.018 2.313-1.074 2.313-2.28 0-1.205-1.107-2.297-2.313-2.28h-.997zm6.083 0c-1.194.063-2.224 1.206-2.162 2.4.063 1.193 1.206 2.223 2.4 2.16h.76c1.205.018 2.312-1.074 2.312-2.28 0-1.205-1.107-2.297-2.313-2.28h-.997zM9.148 15.937a2.304 2.304 0 0 0-1.544.783l-6.083 6.793c-.739.828-.739 2.212 0 3.04l6.083 6.864c.784.914 2.337 1.02 3.24.225.902-.795.99-2.348.18-3.242L8.34 27.384c.318-.038.645-.024.974-.024 1.205.018 2.313-1.074 2.313-2.28 0-1.205-1.107-2.297-2.313-2.28h-.997l2.707-3.04c.624-.67.78-1.733.377-2.556-.403-.822-1.342-1.35-2.253-1.268zm35.806 6.816c-1.194.047-2.236 1.181-2.185 2.375.062 2.238-.52 4.257-1.758 5.7-1.229 1.43-3.18 2.515-6.605 2.613h-.071c-3.135-.024-5.065-1.105-6.368-2.613-1.313-1.52-1.957-3.625-1.924-5.724.012-1.202-1.078-2.303-2.28-2.303h-3.04c-1.205-.018-2.313 1.074-2.313 2.28 0 1.205 1.107 2.297 2.313 2.28h.902c.345 2.315 1.277 4.583 2.898 6.46 2.161 2.502 5.596 4.17 9.886 4.18h.047c4.445-.11 7.886-1.71 10.029-4.204 2.143-2.494 2.939-5.691 2.85-8.787-.012-1.218-1.158-2.307-2.375-2.257h-.006zm-30.558.047c-1.193.063-2.223 1.206-2.16 2.4.061 1.193 1.204 2.223 2.398 2.16h.76c1.205.018 2.313-1.074 2.313-2.28 0-1.205-1.108-2.297-2.313-2.28h-.998z"
        fill={props.color || '#7CE5CF'}
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h70v38.001H0z" />
      </clipPath>
    </defs>
  </svg>
)
