import * as React from 'react'

interface Props {
  color?: string
  width?: string | number
  height?: string | number
  style?: any
  onClick?: () => void
}

export const SettingIcon = (props: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={40} height={40} viewBox="0 0 40 40" fill="none" {...props}>
    <g clipPath="url(#a)">
      <path
        d="m16.887.491-1.368 4.764c-1.238.342-2.42.84-3.514 1.462L7.689 4.312 3.82 8.18l2.405 4.316a15.434 15.434 0 0 0-1.462 3.514L0 17.378v5.471l4.764 1.368c.342 1.239.84 2.42 1.462 3.515l-2.405 4.316 3.868 3.867 4.316-2.405a15.432 15.432 0 0 0 3.514 1.462l1.368 4.764h5.471l1.368-4.764c1.239-.342 2.42-.84 3.515-1.462l4.316 2.406 3.868-3.868-2.406-4.317a15.432 15.432 0 0 0 1.462-3.514l4.764-1.367v-5.472l-4.764-1.368a15.433 15.433 0 0 0-1.462-3.514l2.406-4.316-3.868-3.868-4.316 2.405a15.432 15.432 0 0 0-3.515-1.462L22.36.491h-5.472zm2.736 9.811c5.392 0 9.811 4.42 9.811 9.812 0 5.392-4.42 9.81-9.811 9.81-5.392 0-9.812-4.418-9.812-9.81 0-5.393 4.42-9.812 9.812-9.812zm0 4.529a5.248 5.248 0 0 0-5.283 5.283 5.248 5.248 0 0 0 5.283 5.283 5.248 5.248 0 0 0 5.283-5.283 5.248 5.248 0 0 0-5.283-5.283z"
        fill={props.color || '#7CE5CF'}
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h39.245v40H0z" />
      </clipPath>
    </defs>
  </svg>
)
