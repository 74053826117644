import { ThemeOptions } from '@mui/material'

import { ComponentParams } from '../index'
import MuiAutocomplete from './Autocomplete'
import MuiButton from './Button'
import MuiContainer from './Container'
import { MuiFilledInput, MuiInputBase, MuiInputLabel, MuiOutlinedInput } from './Input'
import MuiLink from './Link'
import MuiSvgIcon from './SvgIcon'
import MuiTooltip from './Tooltip'
import MuiTypography from './Typography'

const components = {
  MuiLink,
  MuiTypography,
  MuiContainer,
  MuiButton,

  MuiInputBase,
  MuiFilledInput,
  MuiInputLabel,
  MuiOutlinedInput,

  MuiTooltip,
  MuiAutocomplete,
  MuiSvgIcon
} as Record<string, (params: ComponentParams) => void>

const componentsOverride = ({ globalTheme, isDarkTheme }: ComponentParams): ThemeOptions['components'] => {
  const res = {} as any
  Object.entries(components).forEach(([key, component]: [string, (params: ComponentParams) => void]) => {
    res[key] = component({ globalTheme, isDarkTheme })
  })
  return res
}

export default componentsOverride
