import { Theme } from '@mui/material'
import {
  createTheme,
  responsiveFontSizes,
  StyledEngineProvider,
  ThemeProvider as MUIThemeProvider
} from '@mui/material/styles'
import { BRAND_COLOR, BRAND_COLOR_LIGHT, DARK_GREEN, LIGHT_GREEN, WARNING_COLOR } from 'constants/index'
import { ReactNode } from 'react'
import { useIsDarkMode } from 'state/user/hooks'

import componentsOverride from './overrides'

export interface ComponentParams {
  globalTheme: Theme
  isDarkTheme: boolean
}
const getGlobalThemeOptions = (isDarkTheme: boolean) => ({
  isDarkTheme,
  palette: {
    primary: {
      main: isDarkTheme ? BRAND_COLOR : DARK_GREEN,
      light: isDarkTheme ? BRAND_COLOR_LIGHT : LIGHT_GREEN
    },
    secondary: {
      main: WARNING_COLOR
    }
  },

  typography: {
    fontFamily: 'Poppins, sans-serif'
  }
})

const getThemeOptions = ({ globalTheme, isDarkTheme }: ComponentParams) => ({
  components: componentsOverride({ globalTheme, isDarkTheme })
})

const getTheme = (isDarkTheme: boolean) => {
  const globalThemeOptions = getGlobalThemeOptions(isDarkTheme)
  const globalTheme = createTheme(globalThemeOptions)
  const themeOptions = getThemeOptions({ globalTheme, isDarkTheme })
  const theme = createTheme(themeOptions, globalTheme)
  return responsiveFontSizes(theme)
}

export default function ThemeProvider({ children }: { children: ReactNode }) {
  const darkMode = useIsDarkMode()
  return (
    <StyledEngineProvider injectFirst>
      <MUIThemeProvider theme={getTheme(darkMode)}>{children}</MUIThemeProvider>
    </StyledEngineProvider>
  )
}
