import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded'
import useTheme from 'hooks/useTheme'
import { darken } from 'polished'
import { useDispatch } from 'react-redux'
import { Link as HistoryLink, NavLink } from 'react-router-dom'
import { AppDispatch } from 'state'
import { resetMintState } from 'state/mint/actions'
import styled from 'styled-components'
import { LiquidityIcon } from 'svgComponents'

import Row, { AutoRow, RowBetween } from '../Row'
// import QuestionHelper from '../QuestionHelper'
import Settings from '../Settings'

const Tabs = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: center;
  border-radius: 3rem;
  justify-content: space-evenly;
`

const activeClassName = 'ACTIVE'

const StyledNavLink = styled(NavLink).attrs({
  activeClassName
})`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: center;
  justify-content: center;
  height: 3rem;
  border-radius: 3rem;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  color: ${({ theme }) => theme.text3};
  font-size: 20px;

  &.${activeClassName} {
    border-radius: 12px;
    font-weight: 500;
    color: ${({ theme }) => theme.text1};
  }

  :hover,
  :focus {
    color: ${({ theme }) => darken(0.1, theme.text1)};
  }
`

const ActiveText = styled.div`
  font-weight: 500;
  font-size: 28px;
  color: ${({ theme }) => theme.primary2};

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    font-size: 20px;
  `};
`

const StyledArrowLeft = styled(ArrowBackIosRoundedIcon)`
  cursor: pointer;
  font-size: 28px;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    font-size: 20px;
  `};
`

const StyledRow = styled(AutoRow)`
  padding-top: 32px;
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    padding-top: 24px;
  `};
`

export function SwapPoolTabs({ active }: { active: 'swap' | 'pool' }) {
  return (
    <Tabs style={{ marginBottom: '20px', display: 'none' }}>
      <StyledNavLink id={`swap-nav-link`} to={'/swap'} isActive={() => active === 'swap'}>
        Swap
      </StyledNavLink>
      <StyledNavLink id={`pool-nav-link`} to={'/pool'} isActive={() => active === 'pool'}>
        Pool
      </StyledNavLink>
    </Tabs>
  )
}

export function FindPoolTabs() {
  return (
    <Tabs>
      <RowBetween style={{ padding: '1rem 1rem 0 1rem' }}>
        <HistoryLink to="/pool">
          <StyledArrowLeft />
        </HistoryLink>
        <ActiveText>Import Pool</ActiveText>
        <Settings />
      </RowBetween>
    </Tabs>
  )
}

export function AddRemoveTabs({
  adding,
  creating,
  onBack
}: {
  adding: boolean
  creating: boolean
  onBack?: () => void
}) {
  // reset states on back
  const dispatch = useDispatch<AppDispatch>()
  const theme = useTheme()
  return (
    <Tabs>
      <Row align="flex-start">
        {onBack && <StyledArrowLeft onClick={onBack} />}

        {!onBack && (
          <HistoryLink
            to="/pool"
            onClick={() => {
              adding && dispatch(resetMintState())
            }}
          >
            <StyledArrowLeft />
          </HistoryLink>
        )}

        <StyledRow gap="8px" justify="center">
          <LiquidityIcon color={theme.primary0} style={{ width: '16px' }} />
          <ActiveText>{creating ? 'Create a pair' : adding ? 'Add Liquidity' : 'Remove Liquidity'}</ActiveText>
        </StyledRow>
        <Settings />
      </Row>
    </Tabs>
  )
}
