import { Stack, styled, ToggleButton, ToggleButtonGroup, Typography, useMediaQuery, useTheme } from '@mui/material'
import { SearchFilter } from 'components/filter'
import { BRAND_COLOR, DARK_BLUE, DARK_GREEN, DARKER_MAIN, LIGHT_GREEN, MIDDLE_GREEN } from 'constants/index'
import { TransactionType } from 'models'
import { useIsDarkMode } from 'state/user/hooks'

import HistoryFilterDay, { HistoryFilterDayProps } from './HistoryFilterDay'

const getStyles = (isDarkTheme: boolean) => ({
  root: {
    px: { xs: 0, sm: 2 },
    py: 2,
    alignItems: { xs: 'flex-start', lg: 'flex-end' },
    justifyContent: 'space-between',
    border: { xs: 0, sm: `solid 1px ${isDarkTheme ? 'rgba(124, 229, 207, 0.5)' : DARK_GREEN} ` },
    borderRadius: { xs: 0, sm: 2 }
  },

  left: {
    alignItems: 'center',
    justifyContent: { xs: 'space-between', md: 'flex-start' },
    minWidth: { xs: '100%', sm: 280, md: 0 }
  },
  buttonGroup: {
    mt: 2,
    gap: '1.5rem'
  },
  transaction: { fontSize: '20px', pb: 1, fontWeight: 'normal' }
})

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  border: `solid 1px ${theme.isDarkTheme ? BRAND_COLOR : DARK_GREEN}!important`,
  borderRadius: '90px !important',

  minHeight: 31,
  fontSize: '1rem',
  gap: '6px',
  padding: '6px 22px',
  textTransform: 'none',
  transitionDuration: '100ms',
  color: theme.isDarkTheme ? BRAND_COLOR : DARK_GREEN,
  '&.Mui-selected': {
    backgroundColor: theme.isDarkTheme ? BRAND_COLOR : MIDDLE_GREEN,
    color: theme.isDarkTheme ? DARK_BLUE : LIGHT_GREEN,
    '&:hover': { backgroundColor: theme.isDarkTheme ? BRAND_COLOR : DARKER_MAIN }
  }
}))

export interface HistoryFilterProps extends HistoryFilterDayProps {
  onSearch: (value: string) => void
  onChangeTransactionType: (type: TransactionType) => void
  transactionType: TransactionType
}

export default function HistoryFilter({
  onSearch,
  onChangeTransactionType,
  transactionType,
  ...props
}: HistoryFilterProps) {
  const darkMode = useIsDarkMode()
  const styles = getStyles(darkMode)
  const theme = useTheme()
  const isUpMd = useMediaQuery(theme.breakpoints.up('md'))

  const handleChangeTransactionType = (event: React.MouseEvent<HTMLElement>, newType: TransactionType) => {
    if (newType && newType !== transactionType) onChangeTransactionType(newType)
  }

  return (
    <Stack spacing={4} direction={{ xs: 'column', lg: 'row' }} sx={styles.root}>
      <Stack>
        <Typography sx={styles.transaction}>Transaction type</Typography>
        <ToggleButtonGroup
          color="primary"
          value={transactionType}
          onChange={handleChangeTransactionType}
          exclusive
          sx={styles.buttonGroup}
        >
          <StyledToggleButton value={TransactionType.ALL}>All</StyledToggleButton>
          <StyledToggleButton value={TransactionType.SWAP}>Swap</StyledToggleButton>
          <StyledToggleButton value={TransactionType.ADD}>Add {isUpMd ? 'Liquidity' : ''}</StyledToggleButton>
          <StyledToggleButton value={TransactionType.REMOVE}>Remove {isUpMd ? 'Liquidity' : ''}</StyledToggleButton>
        </ToggleButtonGroup>
      </Stack>

      <Stack spacing={3} direction="row">
        <HistoryFilterDay {...props} />
        <SearchFilter onSearch={onSearch} placeholderText="Search" hasBorder={true} />
      </Stack>
    </Stack>
  )
}
