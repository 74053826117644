import { Currency, ETHER, TokenAmount } from '@adaswap/sdk'
import { BigNumber } from '@ethersproject/bignumber'
import { TransactionResponse } from '@ethersproject/providers'
import AddRoundedIcon from '@mui/icons-material/AddRounded'
import { Stack } from '@mui/material'
import PreviewBar, { PreviewBarType } from 'components/PreviewBar'
import { useIsTransactionUnsupported } from 'hooks/Trades'
import { useCallback, useContext, useEffect, useState } from 'react'
import ReactGA from 'react-ga'
import { RouteComponentProps, useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Card, Text } from 'rebass'
import { ThemeContext } from 'styled-components'

import { BlueCard, OutlineCard } from '../../components/Card'
import { AutoColumn, ColumnCenter } from '../../components/Column'
import CurrencyInputPanel from '../../components/CurrencyInputPanel'
import DoubleCurrencyLogo from '../../components/DoubleLogo'
import { AddRemoveTabs } from '../../components/NavigationTabs'
import { RowBetween } from '../../components/Row'
import TransactionConfirmationModal, { ConfirmationModalContent } from '../../components/TransactionConfirmationModal'
import { MIDDLE_GREEN, ROUTER_ADDRESS } from '../../constants'
import { PairState } from '../../data/Reserves'
import { useActiveWeb3React } from '../../hooks'
import { useCurrency } from '../../hooks/Tokens'
import { ApprovalState, useApproveCallback } from '../../hooks/useApproveCallback'
import useTransactionDeadline from '../../hooks/useTransactionDeadline'
import { useWalletModalToggle } from '../../state/application/hooks'
import { Field } from '../../state/mint/actions'
import { useDerivedMintInfo, useMintActionHandlers, useMintState } from '../../state/mint/hooks'
import { useTransactionAdder } from '../../state/transactions/hooks'
import { useIsDarkMode, useIsExpertMode, useUserSlippageTolerance } from '../../state/user/hooks'
import { TYPE } from '../../theme'
import { calculateGasMargin, calculateSlippageAmount, getRouterContract } from '../../utils'
import { currencyId } from '../../utils/currencyId'
import { maxAmountSpend } from '../../utils/maxAmountSpend'
import { wrappedCurrency } from '../../utils/wrappedCurrency'
import { BackgroundGrid, Dots, MainButton, Wrapper } from '../PoolUni/styleds'
import { ConfirmAddModalBottom } from './ConfirmAddModalBottom'
import { PoolPriceBar } from './PoolPriceBar'

export default function AddLiquidity({
  match: {
    params: { currencyIdA, currencyIdB }
  },
  history
}: RouteComponentProps<{ currencyIdA?: string; currencyIdB?: string }>) {
  const { account, chainId, library } = useActiveWeb3React()
  const theme = useContext(ThemeContext)

  const currencyA = useCurrency(currencyIdA)
  const currencyB = useCurrency(currencyIdB)

  // const oneCurrencyIsWETH = Boolean(
  //   chainId &&
  //     ((currencyA && currencyEquals(currencyA, WETH[chainId])) ||
  //       (currencyB && currencyEquals(currencyB, WETH[chainId])))
  // )

  const toggleWalletModal = useWalletModalToggle() // toggle wallet when disconnected

  const expertMode = useIsExpertMode()

  // mint state
  const { independentField, typedValue, otherTypedValue } = useMintState()
  const {
    dependentField,
    currencies,
    pair,
    pairState,
    currencyBalances,
    parsedAmounts,
    price,
    noLiquidity,
    liquidityMinted,
    poolTokenPercentage,
    error
  } = useDerivedMintInfo(currencyA ?? undefined, currencyB ?? undefined)

  const { onFieldAInput, onFieldBInput } = useMintActionHandlers(noLiquidity)

  const { state }: any = useLocation()

  const isValid = !error

  useEffect(() => {
    onFieldAInput('')
  }, [])

  // modal and loading
  const [showPreview, setShowPreview] = useState<boolean>(false)
  const [showConfirm, setShowConfirm] = useState<boolean>(false)
  const [attemptingTxn, setAttemptingTxn] = useState<boolean>(false) // clicked confirm

  // txn values
  const deadline = useTransactionDeadline() // custom from users settings
  const [allowedSlippage] = useUserSlippageTolerance() // custom from users
  const [txHash, setTxHash] = useState<string>('')

  // get formatted amounts
  const formattedAmounts = {
    [independentField]: typedValue,
    [dependentField]: noLiquidity ? otherTypedValue : parsedAmounts[dependentField]?.toSignificant(6) ?? ''
  }

  // get the max amounts user can add
  const maxAmounts: { [field in Field]?: TokenAmount } = [Field.CURRENCY_A, Field.CURRENCY_B].reduce(
    (accumulator, field) => {
      return {
        ...accumulator,
        [field]: maxAmountSpend(currencyBalances[field])
      }
    },
    {}
  )

  const atMaxAmounts: { [field in Field]?: TokenAmount } = [Field.CURRENCY_A, Field.CURRENCY_B].reduce(
    (accumulator, field) => {
      return {
        ...accumulator,
        [field]: maxAmounts[field]?.equalTo(parsedAmounts[field] ?? '0')
      }
    },
    {}
  )

  // check whether the user has approved the router on the tokens
  const [approvalA, approveACallback] = useApproveCallback(parsedAmounts[Field.CURRENCY_A], ROUTER_ADDRESS)
  const [approvalB, approveBCallback] = useApproveCallback(parsedAmounts[Field.CURRENCY_B], ROUTER_ADDRESS)

  const addTransaction = useTransactionAdder()

  async function onAdd() {
    if (!chainId || !library || !account) return
    const router = getRouterContract(chainId, library, account)

    const { [Field.CURRENCY_A]: parsedAmountA, [Field.CURRENCY_B]: parsedAmountB } = parsedAmounts
    if (!parsedAmountA || !parsedAmountB || !currencyA || !currencyB || !deadline) {
      return
    }

    const amountsMin = {
      [Field.CURRENCY_A]: calculateSlippageAmount(parsedAmountA, noLiquidity ? 0 : allowedSlippage)[0],
      [Field.CURRENCY_B]: calculateSlippageAmount(parsedAmountB, noLiquidity ? 0 : allowedSlippage)[0]
    }

    let estimate,
      method: (...args: any) => Promise<TransactionResponse>,
      args: Array<string | string[] | number>,
      value: BigNumber | null
    if (currencyA === ETHER || currencyB === ETHER) {
      const tokenBIsETH = currencyB === ETHER
      estimate = router.estimateGas.addLiquidityETH
      method = router.addLiquidityETH
      args = [
        wrappedCurrency(tokenBIsETH ? currencyA : currencyB, chainId)?.address ?? '', // token
        (tokenBIsETH ? parsedAmountA : parsedAmountB).raw.toString(), // token desired
        amountsMin[tokenBIsETH ? Field.CURRENCY_A : Field.CURRENCY_B].toString(), // token min
        amountsMin[tokenBIsETH ? Field.CURRENCY_B : Field.CURRENCY_A].toString(), // eth min
        account,
        deadline.toHexString()
      ]
      value = BigNumber.from((tokenBIsETH ? parsedAmountB : parsedAmountA).raw.toString())
    } else {
      estimate = router.estimateGas.addLiquidity
      method = router.addLiquidity
      args = [
        wrappedCurrency(currencyA, chainId)?.address ?? '',
        wrappedCurrency(currencyB, chainId)?.address ?? '',
        parsedAmountA.raw.toString(),
        parsedAmountB.raw.toString(),
        amountsMin[Field.CURRENCY_A].toString(),
        amountsMin[Field.CURRENCY_B].toString(),
        account,
        deadline.toHexString()
      ]
      value = null
    }

    setAttemptingTxn(true)
    await estimate(...args, value ? { value } : {})
      .then(estimatedGasLimit =>
        method(...args, {
          ...(value ? { value } : {}),
          gasLimit: calculateGasMargin(estimatedGasLimit)
        }).then(response => {
          setAttemptingTxn(false)

          addTransaction(response, {
            summary:
              'Add ' +
              parsedAmounts[Field.CURRENCY_A]?.toSignificant(3) +
              ' ' +
              currencies[Field.CURRENCY_A]?.symbol +
              ' and ' +
              parsedAmounts[Field.CURRENCY_B]?.toSignificant(3) +
              ' ' +
              currencies[Field.CURRENCY_B]?.symbol
          })

          setTxHash(response.hash)
          setShowPreview(false)

          ReactGA.event({
            category: 'Liquidity',
            action: 'Add',
            label: [currencies[Field.CURRENCY_A]?.symbol, currencies[Field.CURRENCY_B]?.symbol].join('/')
          })
        })
      )
      .catch(error => {
        setAttemptingTxn(false)
        // we only care if the error is something _other_ than the user rejected the tx
        if (error?.code === 4001) {
          toast.error('Transaction rejected.')
          return
        }

        if (error?.code === -32603) {
          setShowConfirm(false)
          toast.error(
            'Please choose a higher slippage tolerance, currently the gas price is below configured minimum gas price'
          )
        } else {
          toast.error(`Failed to add liquidity: ${error?.message}`)
        }

        console.error(error)
      })
  }

  const modalHeader = () => {
    return (
      <AutoColumn gap="16px">
        <OutlineCard>
          <AutoColumn justify="center" gap="16px">
            <DoubleCurrencyLogo
              currency0={currencies[Field.CURRENCY_A]}
              currency1={currencies[Field.CURRENCY_B]}
              size={28}
              gap={8}
            />

            {liquidityMinted && (
              <Text fontSize="24px" fontWeight={'bold'}>
                {liquidityMinted?.toSignificant(6)}
              </Text>
            )}

            <Text fontSize="20px" fontWeight={500}>
              {noLiquidity
                ? currencies[Field.CURRENCY_A]?.symbol + '/' + currencies[Field.CURRENCY_B]?.symbol
                : currencies[Field.CURRENCY_A]?.symbol + '/' + currencies[Field.CURRENCY_B]?.symbol + ' Pool Tokens'}
            </Text>
          </AutoColumn>
        </OutlineCard>

        <TYPE.primary2 fontSize={14} fontWeight={400} textAlign="center">
          {`Output is estimated. If the price changes by more than ${allowedSlippage /
            100}% your transaction will revert.`}
        </TYPE.primary2>
      </AutoColumn>
    )
  }

  const modalBottom = () => {
    return (
      <ConfirmAddModalBottom
        price={price}
        currencies={currencies}
        parsedAmounts={parsedAmounts}
        noLiquidity={noLiquidity}
        onAdd={onAdd}
        poolTokenPercentage={poolTokenPercentage}
      />
    )
  }

  const pendingText = `Supplying ${parsedAmounts[Field.CURRENCY_A]?.toSignificant(6)} ${
    currencies[Field.CURRENCY_A]?.symbol
  } and ${parsedAmounts[Field.CURRENCY_B]?.toSignificant(6)} ${currencies[Field.CURRENCY_B]?.symbol}`

  const handleCurrencyASelect = useCallback(
    (currencyA: Currency) => {
      const newCurrencyIdA = currencyId(currencyA)
      if (newCurrencyIdA === currencyIdB) {
        history.push(`/add/${currencyIdB}/${currencyIdA}`)
      } else {
        history.push(`/add/${newCurrencyIdA}/${currencyIdB}`)
      }
    },
    [currencyIdB, history, currencyIdA]
  )
  const handleCurrencyBSelect = useCallback(
    (currencyB: Currency) => {
      const newCurrencyIdB = currencyId(currencyB)
      if (currencyIdA === newCurrencyIdB) {
        if (currencyIdB) {
          history.push(`/add/${currencyIdB}/${newCurrencyIdB}`)
        } else {
          history.push(`/add/${newCurrencyIdB}`)
        }
      } else {
        history.push(`/add/${currencyIdA ? currencyIdA : 'mADA'}/${newCurrencyIdB}`)
      }
    },
    [currencyIdA, history, currencyIdB]
  )

  const handleDismissConfirmation = useCallback(() => {
    // prevent close popup when transaction is pending
    !attemptingTxn && setShowConfirm(false)

    // if there was a tx hash, we want to clear the input
    if (txHash) {
      onFieldAInput('')
      history.push('/add')
    }
    setTxHash('')
  }, [onFieldAInput, attemptingTxn, txHash, history])

  const handleSupplyClick = () => {
    if (expertMode) {
      onAdd()
      return
    }

    if (showPreview) {
      setShowConfirm(true)
    } else {
      setShowPreview(true)
    }
  }

  const isCreate = history.location.pathname.includes('/create')

  const addIsUnsupported = useIsTransactionUnsupported(currencies?.CURRENCY_A, currencies?.CURRENCY_B)
  const darkMode = useIsDarkMode()

  return (
    <Wrapper>
      <BackgroundGrid />
      <TransactionConfirmationModal
        isOpen={showConfirm}
        onDismiss={handleDismissConfirmation}
        attemptingTxn={attemptingTxn}
        hash={txHash}
        content={() =>
          showPreview ? (
            <ConfirmationModalContent
              title={noLiquidity ? 'You are creating a pool' : 'You will receive'}
              onDismiss={handleDismissConfirmation}
              topContent={modalHeader}
              bottomContent={modalBottom}
            />
          ) : null
        }
        pendingText={pendingText}
        currencyToAdd={pair?.liquidityToken}
        currencyAmountA={parsedAmounts[Field.CURRENCY_A]}
        currencyAmountB={parsedAmounts[Field.CURRENCY_B]}
      />

      <Stack spacing={{ xs: 4, sm: 6 }}>
        <AddRemoveTabs
          creating={isCreate}
          adding={true}
          onBack={showPreview ? () => setShowPreview(false) : undefined}
        />

        {showPreview && noLiquidity && (
          <ColumnCenter>
            <BlueCard>
              <AutoColumn gap="10px">
                <TYPE.link fontWeight={600} color={'#0f1227'} textAlign="center">
                  You are the first liquidity provider.
                </TYPE.link>
                <TYPE.link fontWeight={500} color={'#0f1227'} textAlign="center">
                  The ratio of tokens you add will set the price. Once you are ok with the rate click supply to review.
                </TYPE.link>
              </AutoColumn>
            </BlueCard>
          </ColumnCenter>
        )}

        {showPreview && (
          <PreviewBar
            currencyA={parsedAmounts[Field.CURRENCY_A]}
            currencyB={parsedAmounts[Field.CURRENCY_B]}
            type={PreviewBarType.AddLiquidity}
          />
        )}

        {!showPreview && (
          <AutoColumn gap="16px">
            <CurrencyInputPanel
              value={formattedAmounts[Field.CURRENCY_A]}
              onUserInput={onFieldAInput}
              onMax={() => {
                onFieldAInput(maxAmounts[Field.CURRENCY_A]?.toExact() ?? '')
              }}
              onCurrencySelect={handleCurrencyASelect}
              showMaxButton={!atMaxAmounts[Field.CURRENCY_A]}
              currency={currencies[Field.CURRENCY_A]}
              id="add-liquidity-input-tokena"
              showCommonBases
              disableCurrencySelect={state?.prevPath === 'pools'}
            />

            <ColumnCenter>
              <AddRoundedIcon sx={{ fontSize: { xs: 32, sm: 40 } }} />
            </ColumnCenter>

            <CurrencyInputPanel
              value={formattedAmounts[Field.CURRENCY_B]}
              onUserInput={onFieldBInput}
              onCurrencySelect={handleCurrencyBSelect}
              onMax={() => {
                onFieldBInput(maxAmounts[Field.CURRENCY_B]?.toExact() ?? '')
              }}
              showMaxButton={!atMaxAmounts[Field.CURRENCY_B]}
              currency={currencies[Field.CURRENCY_B]}
              id="add-liquidity-input-tokenb"
              showCommonBases
              disableCurrencySelect={state?.prevPath === 'pools'}
            />
          </AutoColumn>
        )}

        {price && currencies[Field.CURRENCY_A] && currencies[Field.CURRENCY_B] && pairState !== PairState.INVALID && (
          <Card>
            {showPreview && (
              <TYPE.subHeader fontWeight={400} fontSize={20} mb={16}>
                {noLiquidity ? 'Initial prices' : 'Prices'} and pool share
              </TYPE.subHeader>
            )}

            <OutlineCard
              style={{ borderWidth: showPreview || darkMode ? 1 : 0 }}
              padding={showPreview ? '1.5rem 1rem' : '1rem'}
              borderRadius={showPreview ? '0.5rem' : '1rem'}
              backgroundColor={showPreview ? 'transparent' : darkMode ? '#02111a' : MIDDLE_GREEN}
            >
              <PoolPriceBar
                currencies={currencies}
                poolTokenPercentage={poolTokenPercentage}
                noLiquidity={noLiquidity}
                price={price}
                showPreview={showPreview}
              />
            </OutlineCard>
          </Card>
        )}

        {addIsUnsupported ? (
          <MainButton disabled={true}>Unsupported Asset</MainButton>
        ) : !account ? (
          <MainButton onClick={toggleWalletModal}>Connect Wallet</MainButton>
        ) : (
          <AutoColumn gap={'20px'}>
            {(approvalA === ApprovalState.NOT_APPROVED ||
              approvalA === ApprovalState.PENDING ||
              approvalB === ApprovalState.NOT_APPROVED ||
              approvalB === ApprovalState.PENDING) &&
              isValid && (
                <RowBetween>
                  {approvalA !== ApprovalState.APPROVED && (
                    <MainButton
                      onClick={approveACallback}
                      disabled={approvalA === ApprovalState.PENDING}
                      width={approvalB !== ApprovalState.APPROVED ? '48%' : '100%'}
                    >
                      {approvalA === ApprovalState.PENDING ? (
                        <Dots>
                          {approvalB === ApprovalState.APPROVED
                            ? `Approving ${currencies[Field.CURRENCY_A]?.symbol}`
                            : 'Approving'}
                        </Dots>
                      ) : (
                        'Approve ' + currencies[Field.CURRENCY_A]?.symbol
                      )}
                    </MainButton>
                  )}

                  {approvalB !== ApprovalState.APPROVED && (
                    <MainButton
                      onClick={approveBCallback}
                      disabled={approvalB === ApprovalState.PENDING}
                      width={approvalA !== ApprovalState.APPROVED ? '48%' : '100%'}
                    >
                      {approvalB === ApprovalState.PENDING ? (
                        <Dots>
                          {approvalA === ApprovalState.APPROVED
                            ? `Approving ${currencies[Field.CURRENCY_B]?.symbol}`
                            : 'Approving'}
                        </Dots>
                      ) : (
                        'Approve ' + currencies[Field.CURRENCY_B]?.symbol
                      )}
                    </MainButton>
                  )}
                </RowBetween>
              )}

            <MainButton
              onClick={handleSupplyClick}
              disabled={!isValid || approvalA !== ApprovalState.APPROVED || approvalB !== ApprovalState.APPROVED}
              // error={!isValid && !!parsedAmounts[Field.CURRENCY_A] && !!parsedAmounts[Field.CURRENCY_B]}
            >
              {error ?? 'SUPPLY'}
            </MainButton>
          </AutoColumn>
        )}

        {showPreview && (
          <ColumnCenter>
            <TYPE.body fontWeight={400} textAlign="center" color="primary0">
              When you add liquidity, you will receive pool tokens representing your position. These tokens
              automatically earn fees proportional to your share of the pool, and can be redeemed at any time.
            </TYPE.body>
          </ColumnCenter>
        )}
      </Stack>

      {/* {!addIsUnsupported ? (
          pair && !noLiquidity && pairState !== PairState.INVALID ? (
            <AutoColumn style={{ minWidth: '20rem', width: '100%', maxWidth: '400px', marginTop: '1rem' }}>
              <MinimalPositionCard showUnwrapped={oneCurrencyIsWETH} pair={pair} />
            </AutoColumn>
          ) : null
        ) : (
          <UnsupportedCurrencyFooter
            show={addIsUnsupported}
            currencies={[currencies.CURRENCY_A, currencies.CURRENCY_B]}
          />
        )} */}
    </Wrapper>
  )
}
