/* eslint-disable @typescript-eslint/no-unused-vars */
import { Components, Theme } from '@mui/material'

import { ComponentParams } from '../index'

const MuiAutocomplete = ({ globalTheme, isDarkTheme }: ComponentParams): Components<Theme>['MuiAutocomplete'] => ({
  styleOverrides: {
    noOptions: {
      color: globalTheme.palette.primary.main
    }
  }
})

export default MuiAutocomplete
