export const BRAND_COLOR = '#7ce5cf'
export const BRAND_COLOR_LIGHT = '#77feff'
export const BG_DARK_BLUE = '#091d28'
export const BG_DARK_GREEN = '#1e363e'
export const WARNING_COLOR = '#eb5e55'

// light theme specific colors
export const DARK_GREEN = '#1c414f'
export const DARKER_GREEN = '#d8ece7'
export const MIDDLE_GREEN = '#36697D'
export const LIGHT_GREEN = '#eff9f7'
export const DARK_BLUE = '#0f1227'
export const DARKER_MAIN = '#142f39'
export const LT_DISABLED_TEXT_COLOR_LIGHT = 'rgba(239, 249, 247, 0.5)'
export const LT_DISABLED_TEXT_COLOR_DARK = 'rgba(28, 65, 79, 0.5)'
export const BLUE_LINK = '#0133ae'
export const GREEN_BASE = '#c3e6e4'

export const LIGHT_BG_GRADIENT =
  'linear-gradient(to bottom, #ffffff 0%, #edf0dd 35%, rgba(237, 240, 221, 0) 100%), linear-gradient(to bottom, #c3e6e4, #c3e6e4)'
export const LIGHT_BOX_SHADOW = '0 24px 80px 0 rgba(96, 139, 131, 0.5)'
export const DARK_BG_GRADIENT = 'linear-gradient(180deg, rgba(4, 20, 28, 0) 0%, #204444 100%)'
