import { Currency, CurrencyAmount } from '@adaswap/sdk'
import AddRoundedIcon from '@mui/icons-material/AddRounded'
import { Box, Stack, Typography } from '@mui/material'
import CurrencyLogo from 'components/CurrencyLogo'
import { BG_DARK_BLUE, BRAND_COLOR, BRAND_COLOR_LIGHT, DARK_BLUE, DARK_GREEN } from 'constants/index'
import { useActiveWeb3React } from 'hooks'
import { useIsDarkMode } from 'state/user/hooks'
import { useCurrencyBalances } from 'state/wallet/hooks'
import { ArrowDown, ArrowUp } from 'svgComponents'

export interface PreviewItem {
  name: string
  value: string
  currency?: Currency
  darkMode: boolean
}

const PreviewItem = ({ name, value, currency, darkMode }: PreviewItem) => (
  <Stack spacing="10px" width="50%" alignItems={currency ? 'flex-end' : 'flex-start'}>
    <Typography component="span" color={darkMode ? 'white' : BG_DARK_BLUE} fontSize={{ xs: '1.125rem', md: '1.25rem' }}>
      {name}
    </Typography>

    <Stack direction="row" alignItems="center" spacing={currency ? 2 : 0}>
      {/* {icon && <img src={icon} alt="" height={28} />} */}
      {currency && <CurrencyLogo currency={currency} size={'28px'} />}

      <Typography
        component="h6"
        color={darkMode ? BRAND_COLOR_LIGHT : DARK_GREEN}
        fontSize={{ xs: '1.25rem', md: '1.5rem' }}
      >
        {value}
      </Typography>
    </Stack>
  </Stack>
)

export enum PreviewBarType {
  Swap,
  AddLiquidity
}
export interface PreviewBarProps {
  currencyA?: CurrencyAmount
  currencyB?: CurrencyAmount
  topTitle?: string
  bottomTitle?: string
  type: PreviewBarType
}

const styles = {
  icons: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    fontSize: { xs: '2rem', md: '2.5rem' }
  }
}

export default function PreviewBar({
  currencyA,
  currencyB,
  topTitle = 'Balance',
  bottomTitle = 'Balance',
  type
}: PreviewBarProps) {
  const { account } = useActiveWeb3React()
  const [tokenA, tokenB] = [currencyA?.currency, currencyB?.currency]
  const [balanceA, balanceB] = useCurrencyBalances(account ?? undefined, [tokenA, tokenB])
  const darkMode = useIsDarkMode()

  if (!currencyA || !currencyB) return null

  return (
    <Stack
      direction="row"
      flexWrap="wrap"
      rowGap={8}
      padding={3}
      borderRadius={4}
      border={darkMode ? `solid 1px ${BRAND_COLOR}` : `solid 1px ${DARK_GREEN}`}
      position="relative"
    >
      <PreviewItem darkMode={darkMode} name={topTitle} value={balanceA?.toSignificant(6) || '0.00'} />
      <PreviewItem
        darkMode={darkMode}
        name={tokenA?.symbol || '?'}
        value={currencyA.toSignificant(6)}
        currency={tokenA}
      />
      <PreviewItem darkMode={darkMode} name={bottomTitle} value={balanceB?.toSignificant(6) || '0.00'} />
      <PreviewItem
        darkMode={darkMode}
        name={tokenB?.symbol || '?'}
        value={currencyB.toSignificant(6)}
        currency={tokenB}
      />

      {type === PreviewBarType.AddLiquidity && <AddRoundedIcon color="primary" sx={styles.icons} />}
      {type === PreviewBarType.Swap && (
        <Box sx={styles.icons}>
          <ArrowUp color={darkMode ? BRAND_COLOR_LIGHT : DARK_BLUE} style={{ width: '16px' }} />
          <ArrowDown color={darkMode ? BRAND_COLOR_LIGHT : DARK_BLUE} style={{ width: '16px' }} />
        </Box>
      )}
    </Stack>
  )
}
