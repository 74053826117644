import { Percent } from '@adaswap/sdk'
import React from 'react'
import { TYPE } from 'theme'

import { ONE_BIPS } from '../../constants'

/**
 * Formatted version of price impact text with warning colors
 */
export default function FormattedPriceImpact({
  priceImpact,
  darkText = false
}: {
  priceImpact?: Percent
  darkText?: boolean
}) {
  const TextComponent = darkText ? TYPE.black : TYPE.white
  return (
    <TextComponent fontWeight={500} fontSize={16}>
      {priceImpact ? (priceImpact.lessThan(ONE_BIPS) ? '<0.01%' : `${priceImpact.toFixed(2)}%`) : '<0.01%'}
    </TextComponent>
  )
}
