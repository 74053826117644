import * as React from 'react'

interface Props {
  color?: string
  className?: string
}

export const TelegramIcon = (props: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
    <path
      d="m22.94 4.622-3.17 14.944c-.238 1.054-.862 1.317-1.748.82l-4.828-3.558-2.33 2.24c-.258.259-.473.474-.97.474l.347-4.917 8.948-8.086c.39-.347-.084-.539-.604-.192L7.522 13.313 2.76 11.822c-1.036-.323-1.055-1.036.215-1.533l18.628-7.176c.863-.324 1.617.192 1.336 1.509z"
      fill={props.color || '#7CE5CF'}
    />
  </svg>
)
