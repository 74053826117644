import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { IconButton, Stack, Tooltip, Typography } from '@mui/material'
import { DARK_GREEN } from 'constants/index'
import { useIsDarkMode } from 'state/user/hooks'

export interface FarmItemTooltipProps {
  contentList?: string[]
  size?: number
}

const DEFAULT_DATA = [
  'The Multiplier represents the proportion of $ASW reward each farm receives, as a proportion of the $ASW produced each block.',
  'For example, if a 1x farm received 1$ASW per second, 1 40x farm would receive 40$ASW per second.',
  'This amount is already included in all APR calculations from the farm.'
]

const CustomTooltip = ({ contentList = DEFAULT_DATA }: FarmItemTooltipProps) => (
  <Stack spacing={2} alignItems="flex-start">
    {contentList.map(text => (
      <Typography className="tooltip-text" key={text} fontSize="0.875rem">
        {text}
      </Typography>
    ))}
  </Stack>
)

export function FarmItemTooltip(props: FarmItemTooltipProps) {
  const darkMode = useIsDarkMode()

  return (
    <Tooltip title={<CustomTooltip {...props} />} placement="top-start">
      <IconButton sx={{ p: 0 }}>
        <InfoOutlinedIcon
          sx={{
            color: darkMode ? 'white' : DARK_GREEN,
            fontSize: props.size ?? 22
          }}
        />
      </IconButton>
    </Tooltip>
  )
}
