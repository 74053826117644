import { AutoColumn } from 'components/Column'
import QuestionHelper from 'components/QuestionHelper'
import { RowBetween, RowFixed } from 'components/Row'
import { BG_DARK_BLUE, DARK_BLUE, LIGHT_GREEN, MIDDLE_GREEN } from 'constants/index'
import { useContext, useMemo } from 'react'
import { Field } from 'state/swap/actions'
import styled, { ThemeContext } from 'styled-components'
import { TYPE } from 'theme'
import { computeSlippageAdjustedAmounts, computeTradePriceBreakdown } from 'utils/prices'

import FormattedPriceImpact from '../FormattedPriceImpact'
import SwapRoute from '../SwapRoute'
import { SwapInfoInterface } from './SwapInfo'

const StyledSwapInfo = styled.div<{ isAbsoluteMode: boolean }>`
  padding: 1rem 1.5rem;
  margin: 0.25rem 0 2rem 0;
  width: 100%;
  border-radius: 1rem;
  color: ${({ theme }) => theme.white};
  background-color: ${({ theme }) => (theme.isDarkTheme ? theme.black : MIDDLE_GREEN)};
  display: flex;
  justify-content: center;
  flex-direction: column;
  border: ${({ theme }) => (theme.isDarkTheme ? `1px solid ${theme.primary2}` : 'none')};
  /* background-color: ${({ theme }) => (theme.isDarkTheme ? BG_DARK_BLUE : LIGHT_GREEN)}; */
  
  ${({ isAbsoluteMode, theme }) =>
    isAbsoluteMode &&
    `
    border: none;
    background-color: ${theme.isDarkTheme ? BG_DARK_BLUE : LIGHT_GREEN};
    margin: 0.25rem 0 0rem 0;
  `}
`
interface SwapInfoContentInterface extends SwapInfoInterface {
  isAbsoluteMode?: boolean
}

export const SwapInfoContent = ({
  trade,
  allowedSlippage,
  deadline,
  isPreview = false,
  isAbsoluteMode = false
}: SwapInfoContentInterface) => {
  const theme = useContext(ThemeContext)
  const textColor = isAbsoluteMode && !theme.isDarkTheme ? DARK_BLUE : theme.white
  const darkText = isAbsoluteMode ? true : false
  const slippageAdjustedAmounts = useMemo(() => computeSlippageAdjustedAmounts(trade, allowedSlippage), [
    allowedSlippage,
    trade
  ])
  const { priceImpactWithoutFee, realizedLPFee } = useMemo(() => computeTradePriceBreakdown(trade), [trade])
  const minimumReceived = (): string => {
    let content: string = ''
    if (trade) {
      content += `${slippageAdjustedAmounts[Field.OUTPUT]?.toSignificant(4) ?? '-'} ${
        trade.outputAmount.currency.symbol
      }`
    } else {
      content = '0.0000'
    }
    return content
  }
  return (
    <StyledSwapInfo isAbsoluteMode={isAbsoluteMode}>
      <AutoColumn gap="sm">
        {isPreview && (
          <RowBetween>
            <TYPE.primary2
              style={{
                textAlign: 'center',
                fontWeight: 'normal',
                paddingBottom: '1rem',
                color: theme.isDarkTheme ? theme.primary2 : LIGHT_GREEN
              }}
            >
              Output is estimated.
              <br /> If the price changes by more than {allowedSlippage / 100} % your transaction will revert.
            </TYPE.primary2>
          </RowBetween>
        )}
        <RowBetween>
          <RowFixed>
            <TYPE.black fontSize={16} fontWeight={400} color={textColor}>
              Route
            </TYPE.black>
            <QuestionHelper
              text="Routing through these tokens resulted in the best price for your trade."
              darkText={darkText}
            />
          </RowFixed>
          <RowFixed>
            <TYPE.black color={textColor} fontSize={16}>
              {trade ? <SwapRoute trade={trade} darkText={darkText} /> : '-'}
            </TYPE.black>
          </RowFixed>
        </RowBetween>
        <RowBetween>
          <RowFixed>
            <TYPE.black fontSize={16} fontWeight={400} color={textColor}>
              Minimum received
            </TYPE.black>
            <QuestionHelper
              text="Your transaction will revert if there is a large, unfavorable price movement before it is confirmed."
              darkText={darkText}
            />
          </RowFixed>
          <RowFixed>
            <TYPE.black color={textColor} fontSize={16}>
              {minimumReceived()}
            </TYPE.black>
          </RowFixed>
        </RowBetween>
        <RowBetween>
          <RowFixed>
            <TYPE.black fontSize={16} fontWeight={400} color={textColor}>
              Price Impact
            </TYPE.black>
            <QuestionHelper
              text="The difference between the market price and estimated price due to trade size."
              darkText={darkText}
            />
          </RowFixed>
          <RowFixed>
            <TYPE.black color={textColor} fontSize={16}>
              <FormattedPriceImpact priceImpact={priceImpactWithoutFee} darkText={darkText} />
            </TYPE.black>
          </RowFixed>
        </RowBetween>

        <RowBetween>
          <RowFixed>
            <TYPE.black fontSize={16} fontWeight={400} color={textColor}>
              Liquidity Provider Fee
            </TYPE.black>
            <QuestionHelper
              text="A portion of each trade (0.30%) goes to liquidity providers as a protocol incentive."
              darkText={darkText}
            />
          </RowFixed>
          <TYPE.black fontSize={16} color={textColor}>
            {realizedLPFee && trade
              ? realizedLPFee?.toSignificant(6) + ' ' + trade.inputAmount.currency.symbol
              : '0.0000'}
          </TYPE.black>
        </RowBetween>

        {isPreview && (
          <RowBetween>
            <RowFixed>
              <TYPE.black fontSize={16} fontWeight={400} color={textColor}>
                Deadline
              </TYPE.black>
              <QuestionHelper
                text="Your transaction will revert if it is left confirming for longer than this time."
                darkText={darkText}
              />
            </RowFixed>
            <TYPE.black fontSize={16} color={textColor}>
              {(deadline / 60).toString()} Minutes
            </TYPE.black>
          </RowBetween>
        )}

        <RowBetween>
          <RowFixed>
            <TYPE.black fontSize={16} fontWeight={400} color={textColor}>
              Slippage Tolerance
            </TYPE.black>
            <QuestionHelper
              text="Setting a high slippage tolerance can help transactions succeed, but you may not get such a good price. Use with caution."
              darkText={darkText}
            />
          </RowFixed>
          <TYPE.black fontSize={16} color={textColor}>
            {allowedSlippage / 100} %
          </TYPE.black>
        </RowBetween>
      </AutoColumn>
    </StyledSwapInfo>
  )
}
