import { Avatar, Stack } from '@mui/material'
import { TOKENS } from 'constants/index'

function TokenPair({ icon1, icon2, iconSize }: { icon1?: string; icon2?: string; iconSize?: number }) {
  const getUnknownIcon = (icon?: string) => {
    if (!icon || icon === 'default') return TOKENS.GENERIC
    return undefined
  }

  return (
    <Stack direction="row" justifyContent="center" alignItems="center">
      <Avatar
        src={getUnknownIcon(icon1)}
        sx={{
          img: { width: '100%' },
          width: { xs: iconSize || 32 },
          height: { xs: iconSize || 32 },
          transform: 'translateY(-20%)'
        }}
      >
        <img src={icon1} alt="" />
      </Avatar>

      <Avatar
        src={getUnknownIcon(icon2)}
        sx={{
          img: { width: '100%' },
          width: { xs: iconSize || 32 },
          height: { xs: iconSize || 32 },
          transform: 'translate(-40%, 20%)'
        }}
      >
        <img src={icon2} alt="" />
      </Avatar>
    </Stack>
  )
}

export default TokenPair
