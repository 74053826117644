import { ApolloClient, ApolloError, useQuery } from '@apollo/client'
import BigNumber from 'bignumber.js'
import { getMcAddress } from 'constants/index'
import { PAIRS } from 'graphql/query'
import { useActiveWeb3React } from 'hooks'
import { useAswUsdcPrice } from 'hooks/useBUSDPrice'
import { useMasterChefContract, useTokenContract } from 'hooks/useContract'
import { useSingleCallResult } from 'lib/hooks/muticall'
import { DeserializedFarm, DeserializedFarmsState, DeserializedFarmUserData, FarmWithStakedValue, Pool } from 'models'
import { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useBlockNumber } from 'state/application/hooks'
import { nativeWrappedAdaToken } from 'utils'
import { getFarmApr } from 'utils/apr'
import { BIG_ZERO } from 'utils/bigNumber'
import { latinise } from 'utils/latinise'

import { fetchFarmList } from './actions'
import { farmSelector } from './selectors'

export const usePollCoreFarmData = () => {
  const dispatch = useDispatch()
  const { chainId, account } = useActiveWeb3React()
  const masterChefContract = useMasterChefContract()
  const lastBlockNumber = useBlockNumber()

  useEffect(() => {
    if (chainId && masterChefContract) {
      dispatch(fetchFarmList({ chainId, masterChefContract, account: account ?? undefined }))
    }
  }, [chainId, dispatch, masterChefContract, account, lastBlockNumber])
}

export const useFarms = (): DeserializedFarmsState => {
  const { chainId } = useActiveWeb3React()
  return useSelector(useMemo(() => farmSelector(chainId), [chainId]))
}

export const useLockFarmIndex = (
  lockFarmList: FarmWithStakedValue[],
  index: number,
  isWithdraw = false,
  isHarvest = false
): {
  activeLockFarm: FarmWithStakedValue | null
  lockIdList: number[]
} => {
  const withdrawLockFarmList = lockFarmList.filter(farm => (farm.userData ? farm.userData.stakedBalance.gt(0) : false))
  const harvestLockFarmList = lockFarmList.filter(farm => (farm.userData ? farm.userData.earnings.gt(0) : false))

  const filteredLockFarmList = isWithdraw ? withdrawLockFarmList : isHarvest ? harvestLockFarmList : lockFarmList
  const activeLockFarm = filteredLockFarmList[index] ?? null
  const lockIdList = filteredLockFarmList.map(farm => farm.lockTimeId)

  return { activeLockFarm, lockIdList }
}

export const useFarmsWithStakedValue = (search: string) => {
  const { getPool } = usePoolListData()
  const aswPrice = useAswPrice()
  const { aswPerSecond, totalAllocPoint } = useFarms()

  return useCallback(
    (farmsToDisplay: DeserializedFarm[]): FarmWithStakedValue[] => {
      // all lock pool weight
      const poolTotalWeight = farmsToDisplay.reduce((prev: { [pid: number]: number }, farm) => {
        return { ...prev, [farm.pid]: (prev[farm.pid] ?? 0) + Number(farm.poolWeight ?? 0) }
      }, {})

      // all lock alloc point
      const poolTotalAllocPoint = farmsToDisplay.reduce((prev: { [pid: number]: number }, farm) => {
        return { ...prev, [farm.pid]: (prev[farm.pid] ?? 0) + (farm.allocPoint ?? 0) }
      }, {})

      let farmsToDisplayWithAPR: FarmWithStakedValue[] = farmsToDisplay.map(farm => {
        if (!farm.lpTotalSupply || !farm.lpTokenPrice) {
          return farm
        }

        const pool = getPool(farm.lpAddress)
        const farmLiquidityUsd = farm.lpTokenPrice.times(farm.lpTotalSupply.div(1e18))
        const poolAswPerSecond =
          totalAllocPoint && totalAllocPoint > 0 && aswPerSecond
            ? aswPerSecond * (poolTotalAllocPoint[farm.pid] / totalAllocPoint)
            : 0

        const { cakeRewardsApr, lpRewardsApr } = getFarmApr(
          poolAswPerSecond,
          poolTotalWeight[farm.pid],
          farm.poolWeight,
          aswPrice,
          farmLiquidityUsd,
          pool ? Number(pool.reserveUSD) : 0,
          pool ? Number(pool.untrackedVolumeUSD) : 0
        )

        return {
          ...farm,
          apr: cakeRewardsApr,
          lpRewardsApr,
          liquidity: farmLiquidityUsd
        }
      })

      if (search) {
        const lowercaseQuery = latinise(search.toLowerCase())
        farmsToDisplayWithAPR = farmsToDisplayWithAPR.filter((farm: FarmWithStakedValue) => {
          const [token, quoteToken] = [nativeWrappedAdaToken(farm.token), nativeWrappedAdaToken(farm.quoteToken)]
          const farmSymbol = `${token.symbol}-${quoteToken.symbol}`

          return latinise(farmSymbol.toLowerCase()).includes(lowercaseQuery)
        })
      }

      return farmsToDisplayWithAPR
    },
    [search, aswPrice, aswPerSecond]
  )
}

export const useFarmUser = (pid: number, lockTimeId: number): DeserializedFarmUserData => {
  const { chainId, account } = useActiveWeb3React()
  const { data: farmList } = useFarms()
  const masterChefAddress = getMcAddress(chainId)
  const masterChefContract = useMasterChefContract()

  const farm = farmList.find(farm => farm.pid === pid && farm.lockTimeId === lockTimeId)
  const lpTokenContract = useTokenContract(farm ? farm.lpAddress : undefined)

  const { result: balanceOf } = useSingleCallResult(lpTokenContract, 'balanceOf', [account ?? undefined])

  const { result: allowance } = useSingleCallResult(lpTokenContract, 'allowance', [
    account ?? undefined,
    masterChefAddress
  ])

  const { result: userInfo } = useSingleCallResult(masterChefContract, 'userInfo', [
    pid,
    lockTimeId,
    account ?? undefined
  ])

  const { result: pendingAdaSwap } = useSingleCallResult(masterChefContract, 'pendingAdaSwap', [
    pid,
    lockTimeId,
    account ?? undefined
  ])

  return {
    allowance: allowance ? allowance[0] : BIG_ZERO,
    tokenBalance: balanceOf ? balanceOf[0] : BIG_ZERO,
    stakedBalance: userInfo ? userInfo[0] : BIG_ZERO,
    earnings: pendingAdaSwap ? pendingAdaSwap[0] : BIG_ZERO,
    proxy: undefined
  }
}

export const useAswPrice = ({ forceMainnet } = { forceMainnet: false }): BigNumber => {
  const price = useAswUsdcPrice({ forceMainnet })
  return useMemo(() => (price ? new BigNumber(price.toSignificant(6)) : BIG_ZERO), [price])
}

export const usePoolListData = (
  client?: ApolloClient<any>
): {
  poolList: Pool[]
  getPool: (lpAddress: string) => Pool | undefined
  loading: boolean
  error?: ApolloError
} => {
  const { data, error, loading } = useQuery<{ pairs: Pool[] }>(PAIRS, { client })
  const mapPair = new Map<string, Pool>()
  const poolList = useMemo(() => (data ? data.pairs : []), [data])

  poolList.forEach(pair => mapPair.set(pair.id, { ...pair }))
  const getPool = (lpAddress: string) => mapPair.get(lpAddress)

  return { poolList, getPool, loading, error }
}
