import { useActiveWeb3React } from 'hooks'
import { useMasterChefContract } from 'hooks/useContract'
import { useSingleCallResult } from 'lib/hooks/muticall'
import { FarmWithStakedValue } from 'models'

export function useCheckCanUnlockOrWithDraw(farm: FarmWithStakedValue | null) {
  const masterChefContract = useMasterChefContract()
  const { account } = useActiveWeb3React()

  const { result, loading } = useSingleCallResult(masterChefContract, 'nextUnlockedTime', [
    farm ? farm.pid : undefined,
    farm ? farm.lockTimeId : undefined,
    account ?? undefined
  ])

  const unlockTime = Number(result ?? 0) * 1000
  const isEnoughLockTime = !loading && Date.now() > unlockTime

  return { unlockTime, loading, isEnoughLockTime }
}
