import { Trade } from '@adaswap/sdk'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Accordion, AccordionDetails, AccordionSummary, Button } from '@mui/material'
import { useState } from 'react'

import { SwapInfoContent } from './SwapInfoContent'

const styles = {
  root: {
    border: 'none',
    background: 'transparent',
    boxShadow: 'none',
    '&.MuiAccordion-root.Mui-expanded': {
      margin: 0
    },
    '&.MuiPaper-root::before': {
      display: 'none'
    }
  },
  summary: {
    justifyContent: 'flex-end',
    '& .MuiAccordionSummary-content': {
      flexGrow: 0,
      marginTop: '1.5rem'
    }
  },
  btn: {
    fontSize: '1rem'
  },
  icon: {
    width: '30px',
    height: '30px'
  }
}
const expandMoreIcon = <ExpandMoreIcon color="primary" sx={styles.icon} />
const expandLessIcon = <ExpandLessIcon color="primary" sx={styles.icon} />

export interface SwapInfoInterface {
  trade: Trade | undefined
  allowedSlippage: number
  deadline: number
  isPreview: boolean
}

export default function SwapInfo(props: SwapInfoInterface) {
  const [expand, setExpand] = useState(false)

  return (
    <Accordion sx={styles.root} expanded={expand} disableGutters>
      <AccordionSummary sx={styles.summary}>
        <Button
          sx={styles.btn}
          endIcon={expand ? expandLessIcon : expandMoreIcon}
          onClick={() => setExpand(current => !current)}
        >
          Swap Summary
        </Button>
      </AccordionSummary>
      <AccordionDetails sx={{ p: 0, m: 0 }}>
        <SwapInfoContent {...props} />
      </AccordionDetails>
    </Accordion>
  )
}
