import { BigNumber as EthersBigNumber } from '@ethersproject/bignumber'
import { createSelector } from '@reduxjs/toolkit'
import BigNumber from 'bignumber.js'
import { FARM_AUCTION_HOSTING_IN_SECONDS } from 'constants/index'
import addSeconds from 'date-fns/addSeconds'
import { DeserializedFarm, DeserializedFarmUserData, SerializedFarm } from 'models'
import { AppState } from 'state'
import { deserializeToken } from 'state/lists/hooks'
import isUndefinedOrNull from 'utils'
import { BIG_ZERO } from 'utils/bigNumber'

export const ETHER_BIG_ZERO = EthersBigNumber.from(0)

const deserializeFarmUserData = (farm: SerializedFarm): DeserializedFarmUserData => {
  return {
    allowance: farm.userData ? EthersBigNumber.from(farm.userData.allowance) : ETHER_BIG_ZERO,
    tokenBalance: farm.userData ? EthersBigNumber.from(farm.userData.tokenBalance) : ETHER_BIG_ZERO,
    stakedBalance: farm.userData ? EthersBigNumber.from(farm.userData.stakedBalance) : ETHER_BIG_ZERO,
    earnings: farm.userData ? EthersBigNumber.from(farm.userData.earnings) : ETHER_BIG_ZERO,
    proxy: {
      allowance: farm?.userData?.proxy ? EthersBigNumber.from(farm?.userData?.proxy.allowance) : ETHER_BIG_ZERO,
      tokenBalance: farm?.userData?.proxy ? EthersBigNumber.from(farm?.userData?.proxy.tokenBalance) : ETHER_BIG_ZERO,
      stakedBalance: farm?.userData?.proxy ? EthersBigNumber.from(farm?.userData?.proxy.stakedBalance) : ETHER_BIG_ZERO,
      earnings: farm?.userData?.proxy ? EthersBigNumber.from(farm?.userData?.proxy.earnings) : ETHER_BIG_ZERO
    }
  }
}

const deserializeFarm = (farm: SerializedFarm): DeserializedFarm => {
  const {
    lpAddress,
    lpSymbol,
    pid,
    lockTimeId,
    dual,
    multiplier,
    isCommunity,
    auctionHostingStartSeconds,
    quoteTokenPriceBusd,
    tokenPriceBusd,
    boosted,
    infoStableSwapAddress
  } = farm

  const auctionHostingStartDate = !isUndefinedOrNull(auctionHostingStartSeconds)
    ? new Date((auctionHostingStartSeconds as number) * 1000)
    : null
  const auctionHostingEndDate = auctionHostingStartDate
    ? addSeconds(auctionHostingStartDate, FARM_AUCTION_HOSTING_IN_SECONDS)
    : null
  const now = Date.now()
  const isFarmCommunity =
    isCommunity ||
    !!(
      auctionHostingStartDate &&
      auctionHostingEndDate &&
      auctionHostingStartDate.getTime() < now &&
      auctionHostingEndDate.getTime() > now
    )

  return {
    lpAddress,
    lpSymbol,
    pid,
    lockTimeId,
    dual,
    multiplier,
    isCommunity: isFarmCommunity,
    auctionHostingEndDate: auctionHostingEndDate?.toJSON(),
    quoteTokenPriceBusd,
    tokenPriceBusd,
    token: deserializeToken(farm.token),
    quoteToken: deserializeToken(farm.quoteToken),
    userData: deserializeFarmUserData(farm),
    tokenAmountTotal: farm.tokenAmountTotal ? new BigNumber(farm.tokenAmountTotal) : BIG_ZERO,
    quoteTokenAmountTotal: farm.quoteTokenAmountTotal ? new BigNumber(farm.quoteTokenAmountTotal) : BIG_ZERO,
    lpTotalInQuoteToken: farm.lpTotalInQuoteToken ? new BigNumber(farm.lpTotalInQuoteToken) : BIG_ZERO,
    lpTotalSupply: farm.lpTotalSupply ? new BigNumber(farm.lpTotalSupply) : BIG_ZERO,
    lpTokenPrice: farm.lpTokenPrice ? new BigNumber(farm.lpTokenPrice) : BIG_ZERO,
    tokenPriceVsQuote: farm.tokenPriceVsQuote ? new BigNumber(farm.tokenPriceVsQuote) : BIG_ZERO,
    poolWeight: farm.poolWeight ? new BigNumber(farm.poolWeight) : BIG_ZERO,
    boosted,
    isStable: Boolean(infoStableSwapAddress),
    allocPoint: farm.allocPoint
  }
}

export const farmSelector = (chainId?: number) =>
  createSelector(
    (state: AppState) => state.farms,
    farms => {
      const deserializedFarmsData = farms.data.map(deserializeFarm).filter(farm => farm.token.chainId === chainId)
      return { ...farms, data: deserializedFarmsData }
    }
  )
