import { Currency, Percent, Price } from '@adaswap/sdk'
import { Stack, Typography } from '@mui/material'
import React, { useContext } from 'react'
import { Text } from 'rebass'
import { ThemeContext } from 'styled-components'

import { AutoColumn } from '../../components/Column'
import { AutoRow } from '../../components/Row'
import { ONE_BIPS } from '../../constants'
import { Field } from '../../state/mint/actions'
import { TYPE } from '../../theme'

export function PoolPriceBar({
  currencies,
  noLiquidity,
  poolTokenPercentage,
  price,
  showPreview = false
}: {
  currencies: { [field in Field]?: Currency }
  noLiquidity?: boolean
  poolTokenPercentage?: Percent
  price?: Price
  showPreview?: boolean
}) {
  const theme = useContext(ThemeContext)

  if (!showPreview) {
    return (
      <Stack
        direction="row"
        spacing={1}
        justifyContent="space-between"
        sx={{
          span: {
            color: 'white',
            fontWeight: 400,
            fontSize: { xs: '0.875rem', sm: '1rem' }
          }
        }}
      >
        <AutoColumn justify="flex-start" gap=".5rem">
          <Typography component="span">Rates</Typography>
          <Typography component="span">&nbsp;</Typography>
          <Typography component="span">Share of pool</Typography>
        </AutoColumn>

        <AutoColumn justify="flex-end" gap=".5rem">
          <Typography component="span">
            {price?.toSignificant(6) ?? ''} {currencies[Field.CURRENCY_B]?.symbol} per{' '}
            {currencies[Field.CURRENCY_A]?.symbol}
          </Typography>
          <Typography component="span">
            {price?.invert()?.toSignificant(6) ?? ''} {currencies[Field.CURRENCY_A]?.symbol} per{' '}
            {currencies[Field.CURRENCY_B]?.symbol}
          </Typography>
          <Typography component="span">
            {noLiquidity && price
              ? '100'
              : (poolTokenPercentage?.lessThan(ONE_BIPS) ? '<0.01' : poolTokenPercentage?.toFixed(2)) ?? '0'}
            %
          </Typography>
        </AutoColumn>
      </Stack>
    )
  }

  return (
    <AutoColumn gap="md">
      <AutoRow justify="space-between" gap="4px">
        <AutoColumn justify="center">
          <TYPE.primary2 fontSize={18}>{price?.toSignificant(6) ?? '-'}</TYPE.primary2>
          <Text fontWeight={500} fontSize={16} color={theme.text1} pt={1}>
            {currencies[Field.CURRENCY_B]?.symbol} per {currencies[Field.CURRENCY_A]?.symbol}
          </Text>
        </AutoColumn>
        <AutoColumn justify="center">
          <TYPE.primary2 fontSize={18}>{price?.invert()?.toSignificant(6) ?? '-'}</TYPE.primary2>
          <Text fontWeight={500} fontSize={16} color={theme.text1} pt={1}>
            {currencies[Field.CURRENCY_A]?.symbol} per {currencies[Field.CURRENCY_B]?.symbol}
          </Text>
        </AutoColumn>
        <AutoColumn justify="center">
          <TYPE.primary2 fontSize={18}>
            {noLiquidity && price
              ? '100'
              : (poolTokenPercentage?.lessThan(ONE_BIPS) ? '<0.01' : poolTokenPercentage?.toFixed(2)) ?? '0'}
            %
          </TYPE.primary2>
          <Text fontWeight={500} fontSize={16} color={theme.text1} pt={1}>
            Share of Pool
          </Text>
        </AutoColumn>
      </AutoRow>
    </AutoColumn>
  )
}
