import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'
import { Accordion, AccordionDetails, AccordionSummary, Button, Divider, Grid, Stack, Typography } from '@mui/material'
import { useWeb3React } from '@web3-react/core'
import {
  BRAND_COLOR,
  BRAND_COLOR_LIGHT,
  DARK_BG_GRADIENT,
  DARK_BLUE,
  DARK_GREEN,
  LIGHT_BG_GRADIENT,
  LT_DISABLED_TEXT_COLOR_DARK
} from 'constants/index'
import ENV_VARS from 'env'
import { Pool } from 'models'
import { useHistory } from 'react-router-dom'
import { useIsDarkMode } from 'state/user/hooks'
import { getTokenIcon } from 'utils/index'

import LinkWithIcon from '../LinkWithIcon'
import TokenPair from '../TokenPair'

const getStyles = (isDarkTheme: boolean) => ({
  root: {
    px: 3,
    border: isDarkTheme ? `1px solid ${BRAND_COLOR}` : 'none',
    borderRadius: '24px !important',
    background: isDarkTheme ? DARK_BG_GRADIENT : LIGHT_BG_GRADIENT,

    '&.Mui-expanded': {
      boxShadow: isDarkTheme ? `0 0 10px -5px ${BRAND_COLOR}` : 'none'
    },

    '.MuiAccordionSummary-content': {
      my: 2
    }
  },

  name: {
    fontSize: { xs: '1rem' },
    color: isDarkTheme ? 'primary.light' : DARK_BLUE
  },

  textWhite: {
    fontSize: { xs: '0.875rem', lg: '1rem' },
    color: isDarkTheme ? 'white' : DARK_GREEN
  },

  textPrimary: {
    fontSize: { xs: '0.875rem', lg: '1rem' },
    color: isDarkTheme ? BRAND_COLOR_LIGHT : DARK_GREEN
  },

  chip: {
    fontSize: { xs: '0.875rem', lg: '1rem' },
    fontWeight: 600,
    '.MuiChip-label': { px: 2.5 }
  },

  button: {
    fontSize: '1rem',
    fontWeight: 500,
    color: 'primary.light',

    py: 1.25,
    minWidth: 200
  }
})

export interface Content {
  label: string
  value: string
}

const mapToItem = ({ label, value }: Content, styles: any) => (
  <Grid item key={label} xs={value === '40x' ? 3 : 2.5}>
    <Stack height="100%" alignItems="flex-start" justifyContent="space-between">
      <Typography sx={styles.textWhite}>{label}</Typography>
      <Typography sx={label.includes('ALP') ? styles.textPrimary : styles.textWhite}>{value}</Typography>

      {/* {value === '40x' && (
        <Stack direction="row" spacing={2} alignItems="center" mt={0.5}>
          <Chip label={value} variant="filled" color="primary" sx={styles.chip} />
          <PoolTooltip />
        </Stack>
      )} */}
    </Stack>
  </Grid>
)

function PoolItemListView({ pool }: { pool: Pool }) {
  const { active } = useWeb3React()
  const history = useHistory()

  const expandIcon = <ExpandMoreRoundedIcon color="primary" fontSize="large" />
  const { id: pairAddress, token0, token1, reserveUSD: liquidity, untrackedVolumeUSD: volume24h, pooledALP } = pool

  const content: Content[] = [
    { label: 'Pooled ALP', value: pooledALP ? pooledALP.toSignificant(4) : '0' },
    { label: 'Liquidity', value: `${Number(liquidity).toLocaleString()} USD` },
    { label: 'Volume (24h)', value: `${Number(volume24h).toLocaleString()} USD` }
  ]

  const handleAddLiquidityClick = () => {
    history.push(`/add/${token0.id}/${token1.id}`, {
      prevPath: 'pools'
    })
  }

  const handleWithdrawClick = () => {
    history.push(`/remove/${token0.id}/${token1.id}`)
  }
  const darkMode = useIsDarkMode()
  const styles = getStyles(darkMode)

  return (
    <Accordion sx={styles.root} disableGutters>
      <AccordionSummary expandIcon={expandIcon}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Stack direction="row" spacing={2} height="100%" alignItems="center">
              <TokenPair
                icon1={getTokenIcon(token0.symbol, token0.id)}
                icon2={getTokenIcon(token1.symbol, token1.id)}
              />
              <Typography>
                {token0.symbol}-{token1.symbol}
              </Typography>
            </Stack>
          </Grid>

          {content.map(item => mapToItem(item, styles))}
        </Grid>
      </AccordionSummary>

      <Divider sx={{ borderColor: darkMode ? 'rgba(124, 229, 207, 0.5)' : 'rgba(28, 65, 79, 0.5)' }} />

      <AccordionDetails sx={{ px: 2, py: 3 }}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Stack spacing={2} alignItems="flex-start">
            <LinkWithIcon text="View Contract" href={`${ENV_VARS.explorerScan}/address/${pairAddress}`} />
            <LinkWithIcon text="Pair Info" href={`${ENV_VARS.explorerScan}/address/${pairAddress}/tokens`} />
          </Stack>

          <Stack direction="row" spacing={5}>
            {active && (
              <Button
                onClick={handleWithdrawClick}
                variant="outlined"
                size="large"
                disabled={!pool.isMyLP}
                sx={{
                  ...styles.button,
                  bgcolor: 'transparent',
                  ...(!darkMode && {
                    color: 'primary.main',
                    borderColor: 'primary.main',
                    '&:hover': {
                      color: 'primary.light'
                    },
                    '&.Mui-disabled': {
                      WebkitTextFillColor: LT_DISABLED_TEXT_COLOR_DARK
                    }
                  })
                }}
              >
                Withdraw
              </Button>
            )}

            <Button variant="outlined" size="large" onClick={handleAddLiquidityClick} sx={styles.button}>
              Add Liquidity
            </Button>
          </Stack>
        </Stack>
      </AccordionDetails>
    </Accordion>
  )
}

export default PoolItemListView
