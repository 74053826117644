import { Price } from '@adaswap/sdk'
import { BG_DARK_BLUE, BRAND_COLOR_LIGHT } from 'constants/index'
import React from 'react'
import { useContext } from 'react'
import { Text } from 'rebass'
import { useIsDarkMode } from 'state/user/hooks'
import { ThemeContext } from 'styled-components'
import { RefreshIcon } from 'svgComponents'

import { StyledBalanceMaxMini } from './styleds'

interface TradePriceProps {
  price?: Price
  showInverted: boolean
  setShowInverted: (showInverted: boolean) => void
}

export default function TradePrice({ price, showInverted, setShowInverted }: TradePriceProps) {
  const theme = useContext(ThemeContext)

  const formattedPrice = showInverted ? price?.toSignificant(6) : price?.invert()?.toSignificant(6)

  const show = Boolean(price?.baseCurrency && price?.quoteCurrency)
  const label = showInverted
    ? `${price?.quoteCurrency?.symbol} Per ${price?.baseCurrency?.symbol}`
    : `${price?.baseCurrency?.symbol} Per ${price?.quoteCurrency?.symbol}`

  const darkMode = useIsDarkMode()
  return (
    <Text
      fontWeight={400}
      fontSize={16}
      color={theme.text1}
      style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}
    >
      {show ? (
        <>
          {formattedPrice ?? '-'} {label}
          <StyledBalanceMaxMini onClick={() => setShowInverted(!showInverted)}>
            <RefreshIcon color={darkMode ? BRAND_COLOR_LIGHT : BG_DARK_BLUE} />
          </StyledBalanceMaxMini>
        </>
      ) : (
        '-'
      )}
    </Text>
  )
}
