import { ETHER, Trade } from '@adaswap/sdk'
import { Fragment, memo, useContext } from 'react'
import { ChevronRight } from 'react-feather'
import { Flex } from 'rebass'
import { ThemeContext } from 'styled-components'
import { unwrappedToken } from 'utils/wrappedCurrency'

import { TYPE } from '../../theme'

export default memo(function SwapRoute({ trade, darkText = false }: { trade: Trade; darkText?: boolean }) {
  const theme = useContext(ThemeContext)
  const TextComponent = darkText ? TYPE.black : TYPE.white

  return (
    <Flex flexWrap="wrap" width="100%" justifyContent="flex-end" alignItems="center">
      {trade.route.path.map((token, i, path) => {
        const isLastItem: boolean = i === path.length - 1
        const currency = trade.route.input === ETHER || trade.route.output === ETHER ? unwrappedToken(token) : token

        return (
          <Fragment key={i}>
            <Flex alignItems="end">
              <TextComponent fontSize={14} ml="0.125rem" mr="0.125rem">
                {currency.symbol}
              </TextComponent>
            </Flex>
            {isLastItem ? null : <ChevronRight size={12} color={theme.white} />}
          </Fragment>
        )
      })}
    </Flex>
  )
})
