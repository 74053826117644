import { ApolloClient, InMemoryCache } from '@apollo/client'
import { TokenType } from 'models'
import { usePoolListData } from 'state/farm/hooks'

import { useGetToken } from './useGetUserToken'

const oldApolloClient = new ApolloClient({
  uri: 'https://milkomeda.adaswap.app/subgraphs/name/adaswap-subgraph',
  cache: new InMemoryCache()
})

function useMigratePool() {
  const ALPToken = useGetToken(TokenType.ALP)
  const { poolList, loading, error } = usePoolListData(oldApolloClient)
  const oldPoolIdList = poolList.map(pool => pool.id)
  const ALPTokenWithOldPools = ALPToken.filter(token => oldPoolIdList.includes(token.address))

  return { ALPTokenWithOldPools, loading, error }
}

export default useMigratePool
