import { Box, Stack, Typography } from '@mui/material'
import { BRAND_COLOR, DARK_GREEN, IMAGES } from 'constants/index'
import React from 'react'
import { useIsDarkMode } from 'state/user/hooks'
import { LogoHorizontalIcon } from 'svgComponents'

const getStyles = (isDarkTheme: boolean) => ({
  root: {
    maxWidth: '100%',
    mb: 5,
    bgcolor: isDarkTheme ? '#020b10' : DARK_GREEN,
    borderRadius: 2,
    overflow: 'hidden',
    boxShadow: '0 12px 24px 0 rgba(0, 0, 0, 0.25)'
  },

  text1: {
    color: isDarkTheme ? 'primary.main' : BRAND_COLOR,
    fontSize: { xs: '1.5rem', xl: '2rem' },
    fontWeight: 600,
    maxWidth: 1 / 2
  },

  text2: {
    color: isDarkTheme ? 'primary.main' : BRAND_COLOR,
    fontSize: { xs: '1.5rem', md: '2rem', lg: '2.5rem', xl: '3rem' },
    position: 'relative',

    span: {
      fontSize: { xs: '1rem', md: '1.25rem' },
      fontWeight: 500,
      position: 'absolute',
      left: '100%',
      top: 0,
      transform: 'translate(25%, -25%)'
    }
  },

  right: {
    maxWidth: 1 / 2,
    display: { xs: 'none', lg: 'block' },
    img: {
      width: '100%',
      height: '100%',
      objectFit: 'cover'
    }
  }
})

export function PoolBanner() {
  const darkMode = useIsDarkMode()
  const styles = getStyles(darkMode)
  return (
    <Stack direction="row" justifyContent="space-between" sx={styles.root}>
      <Stack spacing={2} px={4} py={2}>
        <LogoHorizontalIcon color={darkMode ? BRAND_COLOR : BRAND_COLOR} style={{ width: '150px' }} />

        <Stack direction="row" spacing={5}>
          <Typography sx={styles.text1}>Lock your Token & Get up to</Typography>

          <Typography sx={styles.text2}>
            105% <span>APR</span>
          </Typography>
        </Stack>
      </Stack>

      <Box sx={styles.right}>
        <img src={IMAGES.BG_BANNER_LG} alt="" />
      </Box>
    </Stack>
  )
}
