import * as React from 'react'

interface Props {
  color?: string
}

export const RefreshIcon = (props: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={25} height={20} fill="none">
    <path
      d="M13.018.65a9.391 9.391 0 0 0-7.96 4.374.885.885 0 0 0 1.493.95 7.631 7.631 0 0 1 6.468-3.555c3.992 0 7.28 3.07 7.628 6.972l-1.311-1.177a.883.883 0 1 0-1.181 1.316l2.684 2.41a.885.885 0 0 0 1.181 0l2.686-2.41a.883.883 0 1 0-1.181-1.316l-1.118 1.003C21.97 4.42 17.927.65 13.018.65zM4.57 8.154a.886.886 0 0 0-.592.227l-2.686 2.41a.883.883 0 1 0 1.181 1.317l1.199-1.076c.402 4.831 4.461 8.64 9.393 8.64a9.401 9.401 0 0 0 7.963-4.376.884.884 0 1 0-1.494-.95 7.636 7.636 0 0 1-6.47 3.557c-3.962 0-7.231-3.026-7.62-6.887l1.216 1.092a.882.882 0 0 0 1.249-.067.885.885 0 0 0-.067-1.25l-2.684-2.41a.88.88 0 0 0-.59-.227h.002z"
      fill={props.color || '#77FEFF'}
    />
  </svg>
)
