import { ChainId, Currency, CurrencyAmount, TokenAmount } from '@adaswap/sdk'
import { Box, Stack } from '@mui/material'
import CurrencyLogo from 'components/CurrencyLogo'
import TokenPair from 'components/pool/TokenPair'
import Popup from 'components/Popup/Popup'
import { AutoRow } from 'components/Row'
import {
  BRAND_COLOR,
  BRAND_COLOR_LIGHT,
  DARK_BLUE,
  DARK_GREEN,
  DARKER_MAIN,
  ICONS,
  LIGHT_BG_GRADIENT,
  LOADING_GIF,
  LOADING_GIF_LT
} from 'constants/index'
import useAddTokenToMetamask from 'hooks/useAddTokenToMetamask'
import React, { useContext } from 'react'
import { AlertTriangle, CheckCircle, ChevronLeft, Plus } from 'react-feather'
import { Text } from 'rebass'
import { useIsDarkMode } from 'state/user/hooks'
import styled, { ThemeContext } from 'styled-components'
import { ArrowDown, ArrowUp, LiquidityIcon, TransactionSubmittedIcon } from 'svgComponents'

import { useActiveWeb3React } from '../../hooks'
import { ExternalLink, TYPE } from '../../theme'
import { CloseIcon } from '../../theme/components'
import { getEtherscanLink, getTokenIcon } from '../../utils'
import { ButtonPrimary } from '../Button'
import { AutoColumn, ColumnCenter } from '../Column'
import { RowBetween, RowFixed } from '../Row'

const Wrapper = styled.div`
  width: 100%;
  color: ${({ theme }) => theme.primary2};
  position: relative;
`
const Section = styled(AutoColumn)`
  padding: 20px;
`

const BottomSection = styled(Section)`
  /* background-color: ${({ theme }) => theme.bg2}; */
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
`

const ConfirmedIcon = styled(ColumnCenter)`
  padding: 20px 0;
`

const StyledLogo = styled.img`
  height: 16px;
  width: 16px;
  margin-left: 6px;
`
const Information = styled.div`
  border: 1px solid ${({ theme }) => theme.primary0};
  border-radius: 16px;
  padding: 1.5rem;
  width: fit-content;
  margin: 1.5rem auto;
`

const ExplorerBtn = styled(ExternalLink)`
  border: 1px solid ${({ theme }) => (theme.isDarkTheme ? theme.white : theme.primary0)};
  border-radius: 20px;
  padding: 0.5rem 1rem;
  background-color: ${({ theme }) => (theme.isDarkTheme ? theme.bg6 : theme.primary0)};
  :hover {
    background-color: ${({ theme }) => (theme.isDarkTheme ? 'rgba(124, 229, 207, 0.04)' : DARKER_MAIN)};
  }
`

const StyledChevronLeft = styled(ChevronLeft)`
  color: ${({ theme }) => theme.primary0};
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
`

function ConfirmationPendingContent({
  onDismiss,
  type,
  currencyAmountA,
  currencyAmountB,
  liquidityTokenAmount
}: {
  onDismiss: () => void
  type: string
  currencyAmountA?: CurrencyAmount
  currencyAmountB?: CurrencyAmount
  liquidityTokenAmount?: TokenAmount
}) {
  const darkMode = useIsDarkMode()
  const showLiquidityAmount = ['Staking', 'Harvesting', 'Unstacking'].includes(type)

  return (
    <Wrapper>
      <Section>
        {/* <RowBetween>
          <CloseIcon onClick={onDismiss} />
          <div />
        </RowBetween> */}
        <ConfirmedIcon>
          <img src={darkMode ? LOADING_GIF : LOADING_GIF_LT} alt="" width={100} />
        </ConfirmedIcon>
        <AutoColumn gap="12px" justify={'center'}>
          <Text fontWeight={500} fontSize={24}>
            {type}
          </Text>

          {showLiquidityAmount && (
            <Box py={3} px={2.5} width={1}>
              <Stack
                spacing={2}
                alignItems="center"
                sx={{
                  textAlign: 'center',
                  padding: '1rem 1.5rem',
                  border: 1,
                  borderColor: 'primary.main',
                  borderRadius: 4
                }}
              >
                <Stack direction="row" alignItems="center">
                  <TokenPair
                    iconSize={24}
                    icon1={getTokenIcon(currencyAmountA?.currency.symbol)}
                    icon2={getTokenIcon(currencyAmountB?.currency.symbol)}
                  />

                  {type !== 'Harvesting' && (
                    <Text fontWeight={600} fontSize={20}>
                      {liquidityTokenAmount?.toSignificant(3)}
                    </Text>
                  )}
                </Stack>

                <Text fontWeight={500} fontSize={20} flex={1}>
                  {currencyAmountA?.currency.symbol}-{currencyAmountB?.currency.symbol}
                </Text>
              </Stack>
            </Box>
          )}

          {!showLiquidityAmount && (
            <Information>
              <AutoRow gap="8px" justify={'center'} style={{ flexWrap: 'nowrap' }}>
                <CurrencyLogo currency={currencyAmountA?.currency} size={'30px'} />
                <Stack direction="row" alignItems="center" maxWidth={250}>
                  <Text
                    fontWeight={600}
                    fontSize={20}
                    style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                  >
                    {currencyAmountA?.toSignificant(6)}
                  </Text>
                  <Text fontWeight={600} fontSize={20}>
                    &nbsp;{currencyAmountA?.currency.symbol}
                  </Text>
                </Stack>
              </AutoRow>

              <AutoColumn gap="12px" justify={'center'} style={{ padding: '1.5rem 0' }}>
                {type === 'Swapping' ? (
                  <>
                    <ArrowUp color={darkMode ? BRAND_COLOR_LIGHT : DARK_BLUE} />
                    <ArrowDown color={darkMode ? BRAND_COLOR_LIGHT : DARK_BLUE} />
                  </>
                ) : (
                  <Plus size="32" color={darkMode ? BRAND_COLOR_LIGHT : DARK_BLUE} />
                )}
              </AutoColumn>

              <AutoRow gap="8px" justify={'center'} style={{ flexWrap: 'nowrap' }}>
                <CurrencyLogo currency={currencyAmountB?.currency} size={'30px'} />
                <Stack direction="row" alignItems="center" maxWidth={250}>
                  <Text
                    fontWeight={600}
                    fontSize={20}
                    style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                  >
                    {currencyAmountB?.toSignificant(6)}
                  </Text>
                  <Text fontWeight={600} fontSize={20}>
                    &nbsp;{currencyAmountB?.currency.symbol}
                  </Text>
                </Stack>
              </AutoRow>
            </Information>
          )}

          <AutoColumn gap="12px" justify={'center'}>
            <Text fontWeight={500} fontSize={24}>
              Waiting for confirmation
            </Text>
          </AutoColumn>
          <Text fontSize={12} textAlign="center">
            Confirm this transaction in your wallet
          </Text>
        </AutoColumn>
      </Section>
    </Wrapper>
  )
}

function TransactionSubmittedContent({
  onDismiss,
  chainId,
  hash,
  currencyToAdd,
  type
}: {
  onDismiss: () => void
  hash: string | undefined
  type: string
  chainId: ChainId
  currencyToAdd?: Currency | undefined
}) {
  const theme = useContext(ThemeContext)

  const { library } = useActiveWeb3React()

  const { addToken, success } = useAddTokenToMetamask(currencyToAdd)

  return (
    <Wrapper>
      <Section>
        <RowBetween>
          <div />
          <CloseIcon onClick={onDismiss} />
        </RowBetween>
        <ConfirmedIcon>
          {type === 'Swapping' ? (
            <TransactionSubmittedIcon color={theme.isDarkTheme ? BRAND_COLOR : DARK_GREEN} />
          ) : (
            <LiquidityIcon color={theme.isDarkTheme ? BRAND_COLOR : DARK_GREEN} />
          )}
        </ConfirmedIcon>
        <AutoColumn gap="12px" justify={'center'}>
          <Text fontWeight={500} fontSize={20}>
            Transaction Submitted
          </Text>
          {chainId && hash && (
            <ExplorerBtn style={{ textDecoration: 'none' }} href={getEtherscanLink(chainId, hash, 'transaction')}>
              <Text fontWeight={500} fontSize={14} color={theme.white}>
                View on Explorer
              </Text>
            </ExplorerBtn>
          )}
          {currencyToAdd && library?.provider?.isMetaMask && (
            <ButtonPrimary mt="24px" padding="12px 32px" width="fit-content" onClick={addToken} disabled={success}>
              {!success ? (
                <RowFixed>
                  Add {currencyToAdd.symbol} to Metamask <StyledLogo src={ICONS.METAMASK} />
                </RowFixed>
              ) : (
                <RowFixed>
                  Added {currencyToAdd.symbol} <CheckCircle size={'16px'} style={{ marginLeft: '6px' }} />
                </RowFixed>
              )}
            </ButtonPrimary>
          )}
          {/* <ButtonPrimary onClick={onDismiss} style={{ margin: '20px 0 0 0' }}>
            <Text fontWeight={500} fontSize={20}>
              Close
            </Text>
          </ButtonPrimary> */}
        </AutoColumn>
      </Section>
    </Wrapper>
  )
}

export function ConfirmationModalContent({
  title,
  bottomContent,
  onDismiss,
  topContent
}: {
  title: string
  onDismiss: () => void
  topContent: () => React.ReactNode
  bottomContent: () => React.ReactNode
}) {
  const theme = useContext(ThemeContext)
  return (
    <Wrapper>
      <StyledChevronLeft onClick={onDismiss} size={36} />
      <Section>
        <AutoColumn justify="center" gap="24px" style={{ marginBottom: '32px' }}>
          <LiquidityIcon color={theme.isDarkTheme ? BRAND_COLOR : DARK_GREEN} />
          <TYPE.primary0 fontSize={24}>{title}</TYPE.primary0>
        </AutoColumn>

        {topContent()}
      </Section>
      <BottomSection gap="12px">{bottomContent()}</BottomSection>
    </Wrapper>
  )
}

export function TransactionErrorContent({ message, onDismiss }: { message: string; onDismiss: () => void }) {
  const theme = useContext(ThemeContext)
  return (
    <Wrapper>
      <RowBetween>
        <div></div>
        <CloseIcon onClick={onDismiss} />
      </RowBetween>

      <AutoColumn style={{ paddingTop: '20px', paddingBottom: '24px' }} gap="20px" justify="center">
        <AlertTriangle color={theme.red1} style={{ strokeWidth: 1.5 }} size={64} />
        <Text fontWeight={500} fontSize={18} color={theme.red1} textAlign="center">
          {message}
        </Text>
      </AutoColumn>

      <ButtonPrimary onClick={onDismiss} padding="14px 18px">
        Dismiss
      </ButtonPrimary>
    </Wrapper>
  )
}

interface ConfirmationModalProps {
  isOpen: boolean
  onDismiss: () => void
  hash: string | undefined
  content?: () => React.ReactNode
  hasError?: boolean
  attemptingTxn: boolean
  pendingText: string
  currencyToAdd?: Currency
  currencyAmountA?: CurrencyAmount
  currencyAmountB?: CurrencyAmount
  liquidityTokenAmount?: TokenAmount
}

export default function TransactionConfirmationModal({
  isOpen,
  onDismiss,
  attemptingTxn,
  hash,
  hasError = false,
  pendingText,
  content,
  currencyToAdd,
  currencyAmountA,
  currencyAmountB,
  liquidityTokenAmount
}: ConfirmationModalProps) {
  const { chainId } = useActiveWeb3React()
  const type = pendingText.split(' ')[0]
  const darkMode = useIsDarkMode()

  if (!chainId) return null

  const spacing = () => {
    if (hasError) return { p: 3 }
    if (hash) return { p: 0, pb: 2 }
    if (attemptingTxn) return { p: 0, pt: 2 }

    return {
      px: { xs: 4, md: 6 },
      py: { xs: 2, md: 3 }
    }
  }

  // confirmation screen
  return (
    <Popup
      open={isOpen}
      scroll="body"
      paperStyles={{
        ...spacing(),
        border: darkMode ? '2px solid #7ce5cf' : `2px solid #${DARK_GREEN}`,
        borderRadius: 8,
        backgroundBlendMode: darkMode ? 'soft-light, normal, normal' : '',
        backgroundImage: darkMode
          ? 'radial-gradient(circle at 51% 47%, rgba(0, 0, 0, 0), #000 52%), linear-gradient(to bottom, #09374b, #09374b), linear-gradient(to bottom, #1b3536, #1b3536)'
          : LIGHT_BG_GRADIENT
      }}
      maxWidth={attemptingTxn || hash || hasError ? 'xs' : 'sm'}
      onClose={onDismiss}
      hideCloseIcon
    >
      {attemptingTxn ? (
        <ConfirmationPendingContent
          onDismiss={onDismiss}
          type={type}
          currencyAmountA={currencyAmountA}
          currencyAmountB={currencyAmountB}
          liquidityTokenAmount={liquidityTokenAmount}
        />
      ) : hash ? (
        <TransactionSubmittedContent
          chainId={chainId}
          hash={hash}
          onDismiss={onDismiss}
          currencyToAdd={currencyToAdd}
          type={type}
        />
      ) : (
        content && content()
      )}
    </Popup>
  )
}
