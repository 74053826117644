import { Currency, Pair } from '@adaswap/sdk'
import { Button, Stack, Typography } from '@mui/material'
import Loader from 'components/Loader'
import { darken } from 'polished'
import { useCallback, useState } from 'react'
import styled from 'styled-components'

import { ReactComponent as DropDown } from '../../assets/images/dropdown.svg'
import { useActiveWeb3React } from '../../hooks'
import useTheme from '../../hooks/useTheme'
import { useCurrencyBalance } from '../../state/wallet/hooks'
import CurrencyLogo from '../CurrencyLogo'
import DoubleCurrencyLogo from '../DoubleLogo'
import { Input as NumericalInput } from '../NumericalInput'
import CurrencySearchModal from '../SearchModal/CurrencySearchModal'

const CurrencySelect = styled.button<{ selected: boolean }>`
  height: 2.2rem;
  padding: 0 0.5rem;
  border: none;
  border-radius: 12px;
  outline: none;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  transition: all 200ms ease-in-out;

  :hover {
    background-color: ${({ theme }) => theme.hoverColor};
  }
`

const StyledDropDown = styled(DropDown)<{ selected: boolean }>`
  margin: 0 0.25rem 0 0.5rem;
  height: 8px;

  path {
    stroke: ${({ theme }) => theme.primary2};
    stroke-width: 1.5px;
  }
`

const InputPanel = styled.div`
  ${({ theme }) => theme.flexColumnNoWrap}
  position: relative;
  z-index: 1;
`

const Container = styled.div<{ hideInput: boolean; isFocus: boolean }>`
  border-radius: ${({ hideInput }) => (hideInput ? '8px' : '16px')};
  border: 1px solid ${({ theme, isFocus }) => (isFocus ? theme.primary2 : 'transparent')};
  background-color: ${({ theme }) => theme.bg7};

  &:hover {
    border-color: ${({ theme }) => theme.primary2};
  }

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
      border-radius: 8px;
  `};
`

const StyledTokenName = styled.span<{ active?: boolean; showLogo?: boolean }>`
  margin-right: 0.25rem;
  margin-left: ${({ active, showLogo }) => (active && !showLogo ? '0.75rem' : '0.25rem')};
  font-size: 1.25rem;
  font-weight: 500;
  color: ${({ theme }) => theme.primary2};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 1.125rem;
  `};

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    font-size: 1rem;
  `};
`

interface CurrencyInputPanelProps {
  value: string
  onUserInput: (value: string) => void
  onMax?: () => void
  showMaxButton: boolean
  label?: string
  onCurrencySelect?: (currency: Currency) => void
  currency?: Currency | null
  disableCurrencySelect?: boolean
  hideBalance?: boolean
  pair?: Pair | null
  hideInput?: boolean
  otherCurrency?: Currency | null
  id: string
  showCommonBases?: boolean
  customBalanceText?: string
  disableCurrencyLogo?: boolean
}

export default function CurrencyInputPanel({
  value,
  onUserInput,
  onMax,
  showMaxButton,
  label = 'Balance',
  onCurrencySelect,
  currency,
  disableCurrencySelect = false,
  disableCurrencyLogo = false,
  hideBalance = false,
  pair = null, // used for double token logo
  hideInput = false,
  otherCurrency,
  id,
  showCommonBases
}: CurrencyInputPanelProps) {
  const [modalOpen, setModalOpen] = useState(false)
  const [isFocus, setIsFocus] = useState(false)

  const { account } = useActiveWeb3React()
  const selectedCurrencyBalance = useCurrencyBalance(account ?? undefined, currency ?? undefined)
  const theme = useTheme()

  const handleDismissSearch = useCallback(() => {
    setModalOpen(false)
  }, [setModalOpen])

  const showBalance = () => {
    if (hideBalance) return null
    if (!currency) return '0.0'
    if (selectedCurrencyBalance) return selectedCurrencyBalance?.toSignificant(6)
    return <Loader />
  }

  const whiteLabel = {
    fontSize: { xs: '1rem', sm: '1.125rem', md: '1.25rem' },
    color: theme.text1,
    fontWeight: 400
  }

  return (
    <InputPanel id={id}>
      {!hideInput && (
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          padding={{ xs: '0 0.5rem 0.75rem', sm: '0 1rem 0.75rem' }}
        >
          <Typography component="span" sx={whiteLabel}>
            {label}
          </Typography>

          {account && (
            <Typography
              component="span"
              onClick={onMax}
              sx={{
                ...whiteLabel,
                cursor: 'pointer',
                ':hover': { color: darken(0.2, theme.text2) }
              }}
            >
              {showBalance()}
            </Typography>
          )}
        </Stack>
      )}

      <Container hideInput={hideInput} isFocus={isFocus}>
        <Stack
          direction="row"
          alignItems="center"
          sx={{
            p: { xs: 1.5, sm: 2 },
            pr: disableCurrencySelect ? 1 : 1.5
          }}
        >
          {!hideInput && (
            <>
              <NumericalInput
                className="token-amount-input"
                value={value}
                style={{ marginRight: 16 }}
                onFocus={() => setIsFocus(true)}
                onBlur={() => setIsFocus(false)}
                onUserInput={val => {
                  onUserInput(val)
                }}
              />

              {account && currency && showMaxButton && label !== 'To' && (
                <Button
                  variant="contained"
                  disableElevation
                  onClick={onMax}
                  sx={{
                    py: 0,
                    mr: 1,
                    fontSize: { xs: '0.875rem', sm: '1rem' },
                    fontWeight: 600
                  }}
                >
                  Max
                </Button>
              )}
            </>
          )}

          <CurrencySelect
            selected={!!currency}
            className="open-currency-select-button"
            onClick={() => {
              if (!disableCurrencySelect) {
                setModalOpen(true)
              }
            }}
          >
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              sx={{
                img: {
                  width: { xs: '20px', sm: '24px' },
                  height: { xs: '20px', sm: '24px' }
                }
              }}
            >
              {pair ? (
                <DoubleCurrencyLogo currency0={pair.token0} currency1={pair.token1} size={24} margin={true} />
              ) : currency && !disableCurrencyLogo ? (
                <CurrencyLogo currency={currency} size={'24px'} />
              ) : null}

              {pair ? (
                <StyledTokenName className="pair-name-container">
                  {pair?.token0.symbol}:{pair?.token1.symbol}
                </StyledTokenName>
              ) : (
                <StyledTokenName
                  className="token-symbol-container"
                  active={Boolean(currency && currency.symbol)}
                  showLogo={disableCurrencyLogo}
                >
                  {(currency && currency.symbol && currency.symbol.length > 20
                    ? currency.symbol.slice(0, 4) +
                      '...' +
                      currency.symbol.slice(currency.symbol.length - 5, currency.symbol.length)
                    : currency?.symbol) || 'Select Token'}
                </StyledTokenName>
              )}

              {!disableCurrencySelect && <StyledDropDown selected={!!currency} />}
            </Stack>
          </CurrencySelect>
        </Stack>
      </Container>

      {!disableCurrencySelect && onCurrencySelect && (
        <CurrencySearchModal
          isOpen={modalOpen}
          onDismiss={handleDismissSearch}
          onCurrencySelect={onCurrencySelect}
          selectedCurrency={currency}
          otherSelectedCurrency={otherCurrency}
          showCommonBases={showCommonBases}
        />
      )}
    </InputPanel>
  )
}
