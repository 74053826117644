import { Divider } from '@mui/material'
import { BRAND_COLOR, DARK_BLUE, DARK_GREEN, DARKER_GREEN, LIGHT_GREEN } from 'constants/index'
import { useRef } from 'react'
import { useIsDarkMode } from 'state/user/hooks'
import styled from 'styled-components'
import { BugsIcon, FeatureRequestIcon, MoreIcon, TelegramIcon, TwitterIcon, WikiIcon } from 'svgComponents'

import { useOnClickOutside } from '../../hooks/useOnClickOutside'
import { ApplicationModal } from '../../state/application/actions'
import { useModalOpen, useToggleModal } from '../../state/application/hooks'
import { ExternalLink } from '../../theme'

const StyledMenuButton = styled.button`
  width: 100%;
  height: 100%;
  border: none;
  background-color: transparent;
  margin: 0;
  padding: 0;
  height: 35px;

  padding: 0.15rem 0.5rem;
  border-radius: 0.5rem;

  :hover,
  :focus {
    cursor: pointer;
    outline: none;
  }

  svg {
    margin-top: 2px;
  }

  // ${({ theme }) => theme.mediaWidth.upToMedium`
  //   > svg {
  //     width: 20px;
  //   }
  // `};
`

const StyledMenu = styled.div`
  margin-left: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border: none;
  text-align: left;
`

const MenuFlyout = styled.span`
  min-width: 8.125rem;
  background-color: ${({ theme }) => (theme.isDarkTheme ? DARK_BLUE : DARKER_GREEN)};
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
    0px 24px 32px rgba(0, 0, 0, 0.01);
  border-radius: 8px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  position: absolute;
  top: 3.5rem;
  right: 0rem;
  z-index: 100;
  gap: 0.5rem;
  border: 1px solid ${({ theme }) => theme.primary0};
`

const MenuItem = styled(ExternalLink)`
  flex: 1;
  padding: 0.5rem;
  color: ${({ theme }) => theme.primary0};
  font-weight: 600;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: all 200ms ease-in-out;
  white-space: nowrap;
  text-decoration: none !important;

  > svg {
    margin-left: 2rem;
  }

  > svg:last-child {
    display: none;
  }

  :hover {
    color: ${({ theme }) => (theme.isDarkTheme ? DARK_BLUE : LIGHT_GREEN)};
    cursor: pointer;

    background-color: ${({ theme }) => theme.primary0};

    > svg:last-child {
      display: block;
    }

    > svg:first-child {
      display: none;
    }
  }
`
const REPORTING_FROM =
  'https://docs.google.com/forms/d/e/1FAIpQLSe11p-pXuYYM0yTo2oFa1FrMSGHxwK7cA793MU8Nltq9SQc7Q/viewform'

export function MoreInfoMenu() {
  const node = useRef<HTMLDivElement>()
  const open = useModalOpen(ApplicationModal.MORE_INFO_MENU)
  const toggle = useToggleModal(ApplicationModal.MORE_INFO_MENU)
  useOnClickOutside(node, open ? toggle : undefined)
  const darkMode = useIsDarkMode()

  const iconColor = darkMode ? BRAND_COLOR : DARK_GREEN
  const iconDarkColor = darkMode ? DARK_BLUE : LIGHT_GREEN
  return (
    // https://github.com/DefinitelyTyped/DefinitelyTyped/issues/30451
    <StyledMenu ref={node as any}>
      <StyledMenuButton onClick={toggle}>
        <MoreIcon color={iconColor} />
      </StyledMenuButton>

      {open && (
        <MenuFlyout>
          <MenuItem id="link" href="https://wiki-dex.adaswap.app/">
            Wiki
            <WikiIcon color={iconColor} />
            <WikiIcon color={iconDarkColor} />
          </MenuItem>
          <MenuItem id="link" href={REPORTING_FROM}>
            Bugs
            <BugsIcon color={iconColor} />
            <BugsIcon color={iconDarkColor} />
          </MenuItem>
          <Divider sx={{ borderColor: '#4c6a64', my: 2 }} />
          <MenuItem id="link" href={REPORTING_FROM}>
            Feature Request
            <FeatureRequestIcon color={iconColor} style={{ marginRight: '-2px' }} />
            <FeatureRequestIcon color={iconDarkColor} style={{ marginRight: '-2px' }} />
          </MenuItem>
          <MenuItem id="link" href="https://t.me/adaswapcommunity">
            Telegram
            <TelegramIcon color={iconColor} />
            <TelegramIcon color={iconDarkColor} />
          </MenuItem>
          <MenuItem id="link" href="https://twitter.com/adaswapapp">
            Twitter
            <TwitterIcon color={iconColor} />
            <TwitterIcon color={iconDarkColor} />
          </MenuItem>
        </MenuFlyout>
      )}
    </StyledMenu>
  )
}
