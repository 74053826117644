import { ChainId } from '@adaswap/sdk'

import { isTestnet } from './common'

const CHAIN = isTestnet
  ? {
      id: `0x${ChainId.TESTNET.toString(16)}`,
      name: 'Milkomeda Cardano Testnet',
      rpcUrl: 'https://rpc-devnet-cardano-evm.c1.milkomeda.com',
      explorerUrl: 'https://explorer-devnet-cardano-evm.c1.milkomeda.com',
      token: {
        name: 'Cardano',
        symbol: 'mADA',
        decimals: 18
      }
    }
  : {
      id: `0x${ChainId.MAINNET.toString(16)}`,
      name: 'Milkomeda Cardano',
      rpcUrl: 'https://rpc-mainnet-cardano-evm.c1.milkomeda.com',
      explorerUrl: 'https://explorer-mainnet-cardano-evm.c1.milkomeda.com',
      token: {
        name: 'Cardano',
        symbol: 'mADA',
        decimals: 18
      }
    }

async function switchNetwork() {
  if (window.ethereum && window.ethereum.request) {
    try {
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: CHAIN.id }]
      })
    } catch (error) {
      if (error.code === 4902) {
        try {
          await window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                chainId: CHAIN.id,
                chainName: CHAIN.name,
                nativeCurrency: CHAIN.token,
                rpcUrls: [CHAIN.rpcUrl],
                blockExplorerUrls: [CHAIN.explorerUrl]
              }
            ]
          })
        } catch (error) {
          console.error('Failed to add Milkomeda network', error)
        }
      }
    }
  }
}

export default switchNetwork
