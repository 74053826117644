import { Currency, ETHER, Token } from '@adaswap/sdk'
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded'
import { IconButton, useMediaQuery } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import { TokenList } from '@uniswap/token-lists'
import Column from 'components/Column'
import useDebounce from 'hooks/useDebounce'
import { useOnClickOutside } from 'hooks/useOnClickOutside'
import usePrevious from 'hooks/usePrevious'
import useTheme from 'hooks/useTheme'
import useToggle from 'hooks/useToggle'
import { KeyboardEvent, RefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { FixedSizeList } from 'react-window'
import { Text } from 'rebass'
import styled from 'styled-components'

import { useAllTokens, useFoundOnInactiveList, useIsUserAddedToken, useToken } from '../../hooks/Tokens'
import useLast from '../../hooks/useLast'
import { isAddress } from '../../utils'
import Modal from '../Modal'
import Row, { RowBetween } from '../Row'
import { CurrencySearch } from './CurrencySearch'
import { filterTokens, useSortedTokensByQuery } from './filtering'
import { ImportList } from './ImportList'
import { ImportToken } from './ImportToken'
import Manage from './Manage'
import { useTokenComparator } from './sorting'
import { SearchInput } from './styleds'
interface CurrencySearchModalProps {
  isOpen: boolean
  onDismiss: () => void
  selectedCurrency?: Currency | null
  onCurrencySelect: (currency: Currency) => void
  otherSelectedCurrency?: Currency | null
  showCommonBases?: boolean
}

export enum CurrencyModalView {
  search,
  manage,
  importToken,
  importList
}

const ContentWrapper = styled(Column)`
  width: 100%;
  color: ${({ theme }) => theme.white};
  padding: 1.5rem;
`
export default function CurrencySearchModal({
  isOpen,
  onDismiss,
  onCurrencySelect,
  selectedCurrency,
  otherSelectedCurrency,
  showCommonBases = false
}: CurrencySearchModalProps) {
  const [modalView, setModalView] = useState<CurrencyModalView>(CurrencyModalView.manage)
  const lastOpen = useLast(isOpen)

  const theme = useTheme()
  const isDown1440 = useMediaQuery('(max-width: 1440px)')

  // refs for fixed size lists
  const fixedList = useRef<FixedSizeList>()

  const [searchQuery, setSearchQuery] = useState<string>('')
  const debouncedQuery = useDebounce(searchQuery, 200)

  const [invertSearchOrder] = useState<boolean>(false)

  const allTokens = useAllTokens()

  const { token: searchToken, loading } = useToken(debouncedQuery)
  const searchTokenIsAdded = useIsUserAddedToken(searchToken)
  const showETH: boolean = useMemo(() => {
    const s = debouncedQuery.toLowerCase().trim()
    return 'mada'.includes(s)
  }, [debouncedQuery])

  const tokenComparator = useTokenComparator(invertSearchOrder)

  const filteredTokens: Token[] = useMemo(() => {
    return filterTokens(Object.values(allTokens), debouncedQuery)
  }, [allTokens, debouncedQuery])

  const sortedTokens: Token[] = useMemo(() => {
    return filteredTokens.sort(tokenComparator)
  }, [filteredTokens, tokenComparator])

  const filteredSortedTokens = useSortedTokensByQuery(sortedTokens, debouncedQuery)

  const handleCurrencySelect = useCallback(
    (currency: Currency) => {
      onCurrencySelect(currency)
      onDismiss()
    },
    [onDismiss, onCurrencySelect]
  )

  // clear the input on open
  useEffect(() => {
    if (isOpen) setSearchQuery('')
  }, [isOpen])

  // manage focus on modal show
  const inputRef = useRef<HTMLInputElement>()
  const handleInput = useCallback(event => {
    const input = event.target.value
    const checksummedInput = isAddress(input)
    setSearchQuery(checksummedInput || input)
    fixedList.current?.scrollTo(0)
  }, [])

  const handleEnter = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        const s = debouncedQuery.toLowerCase().trim()
        if (s === 'mada') {
          handleCurrencySelect(ETHER)
        } else if (filteredSortedTokens.length > 0) {
          if (
            filteredSortedTokens[0].symbol?.toLowerCase() === debouncedQuery.trim().toLowerCase() ||
            filteredSortedTokens.length === 1
          ) {
            handleCurrencySelect(filteredSortedTokens[0])
          }
        }
      }
    },
    [filteredSortedTokens, handleCurrencySelect, debouncedQuery]
  )

  // menu ui
  const [open, toggle] = useToggle(false)
  const node = useRef<HTMLDivElement>()
  useOnClickOutside(node, open ? toggle : undefined)

  // if no results on main list, show option to expand into inactive
  const inactiveTokens = useFoundOnInactiveList(debouncedQuery)
  const filteredInactiveTokens: Token[] = useSortedTokensByQuery(inactiveTokens, debouncedQuery)

  useEffect(() => {
    if (isOpen && !lastOpen) {
      setModalView(CurrencyModalView.search)
    }
  }, [isOpen, lastOpen])

  // for token import view
  const prevView = usePrevious(modalView)

  // used for import token flow
  const [importToken, setImportToken] = useState<Token | undefined>()

  // used for import list
  const [importList, setImportList] = useState<TokenList | undefined>()
  const [listURL, setListUrl] = useState<string | undefined>()

  // change min height if not searching
  // const minHeight = modalView === CurrencyModalView.importToken || modalView === CurrencyModalView.importList ? 60 : 85

  const minHeight = modalView === CurrencyModalView.importToken || modalView === CurrencyModalView.importList ? 40 : 80

  const handleBack = () => {
    if (modalView !== CurrencyModalView.search) {
      setModalView(CurrencyModalView.search)
      return
    }
    onDismiss()
  }
  return (
    <Modal
      isOpen={isOpen}
      onDismiss={onDismiss}
      maxHeight={90}
      minHeight={isDown1440 ? 90 : 80}
      maxWidth={560}
      isPositionAbsolute={true}
    >
      <ContentWrapper>
        <RowBetween align="flex-start" marginBottom="1rem">
          <IconButton onClick={handleBack}>
            <ArrowBackIosRoundedIcon color="primary" fontSize="inherit" />
          </IconButton>
        </RowBetween>
        <RowBetween align="flex-start">
          <Text fontWeight={500} fontSize="1.75rem" color={theme.primary2}>
            Select Token
          </Text>
        </RowBetween>
        {modalView !== CurrencyModalView.importToken && (
          <Row
            padding="1rem 0"
            style={{
              position: 'relative',
              width: '100%'
            }}
          >
            <SearchInput
              type="text"
              id="token-search-input"
              placeholder="Search name or paste address"
              autoComplete="off"
              value={searchQuery}
              ref={inputRef as RefObject<HTMLInputElement>}
              onChange={handleInput}
              onKeyDown={handleEnter}
            />
            {loading && (
              <CircularProgress
                size={25}
                sx={{
                  position: 'absolute',
                  right: '.5rem'
                }}
              />
            )}
          </Row>
        )}
        {modalView === CurrencyModalView.search ? (
          <CurrencySearch
            onDismiss={onDismiss}
            onCurrencySelect={handleCurrencySelect}
            selectedCurrency={selectedCurrency}
            otherSelectedCurrency={otherSelectedCurrency}
            showImportView={() => setModalView(CurrencyModalView.importToken)}
            setImportToken={setImportToken}
            showManageView={() => setModalView(CurrencyModalView.manage)}
            searchToken={searchToken}
            searchTokenIsAddedOrDefaultToken={searchTokenIsAdded || filteredTokens.length > 0}
            filteredSortedTokens={filteredSortedTokens}
            filteredInactiveTokens={filteredInactiveTokens}
            showETH={showETH}
          />
        ) : modalView === CurrencyModalView.importToken && importToken ? (
          <ImportToken
            tokens={[importToken]}
            onDismiss={onDismiss}
            onBack={() =>
              setModalView(prevView && prevView !== CurrencyModalView.importToken ? prevView : CurrencyModalView.search)
            }
            handleCurrencySelect={handleCurrencySelect}
          />
        ) : modalView === CurrencyModalView.importList && importList && listURL ? (
          <ImportList list={importList} listURL={listURL} onDismiss={onDismiss} setModalView={setModalView} />
        ) : modalView === CurrencyModalView.manage ? (
          <Manage
            onDismiss={onDismiss}
            onCurrencySelect={handleCurrencySelect}
            selectedCurrency={selectedCurrency}
            otherSelectedCurrency={otherSelectedCurrency}
            showImportView={() => setModalView(CurrencyModalView.importToken)}
            setImportToken={setImportToken}
            searchToken={searchToken}
            searchTokenIsAddedOrDefaultToken={searchTokenIsAdded || filteredTokens.length > 0}
            filteredSortedTokens={filteredSortedTokens}
            filteredInactiveTokens={filteredInactiveTokens}
            showETH={showETH}
          />
        ) : (
          ''
        )}
      </ContentWrapper>
    </Modal>
  )
}
