import LaunchRoundedIcon from '@mui/icons-material/LaunchRounded'
import { Link, Stack, Typography } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'

export interface LinkWithIconProps {
  text: string
  href?: string
  underline?: boolean
  to?: string
}

const LinkStyled = ({ text, underline = false }: { text: string; underline?: boolean }) => (
  <Stack direction="row" alignItems="center" spacing={1}>
    <Typography
      sx={{
        fontSize: '1rem',
        fontWeight: 500,
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: 'primary.main',
        textDecoration: underline ? 'underline' : 'none'
      }}
    >
      {text}
    </Typography>

    <LaunchRoundedIcon fontSize="small" />
  </Stack>
)

function LinkWithIcon({ href = '', to, ...other }: LinkWithIconProps) {
  if (to && !href)
    return (
      <Link component={RouterLink} to={to}>
        <LinkStyled {...other} />
      </Link>
    )

  return (
    <Link target="_blank" href={href}>
      <LinkStyled {...other} />
    </Link>
  )
}

export default LinkWithIcon
