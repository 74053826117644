import { Box, Button, Grid } from '@mui/material'
import { LOADING_GIF, LOADING_GIF_LT } from 'constants/index'
import ENV_VARS from 'env'
import { useActiveWeb3React } from 'hooks'
import { useMasterChefContract } from 'hooks/useContract'
import { FarmWithStakedValue } from 'models'
import { useDispatch } from 'react-redux'
import { fetchFarmMore } from 'state/farm/actions'
import { useFarms } from 'state/farm/hooks'
import { useIsDarkMode } from 'state/user/hooks'

import { FarmItemListView, FarmItemThumbnailView } from './FarmItem'

export interface FarmListProps {
  list: FarmWithStakedValue[]
  isThumbnailView?: boolean
  onOpenStakePopup?: (pid: number) => void
  onOpenWithdrawPopup?: (pid: number) => void
  onOpenCalculatorPopup?: (pid: number) => void
  onOpenHarvestPopup?: (pid: number) => void
}

export function FarmList({
  list,
  isThumbnailView = false,
  onOpenStakePopup,
  onOpenCalculatorPopup,
  onOpenHarvestPopup,
  onOpenWithdrawPopup
}: FarmListProps) {
  const dispatch = useDispatch()
  const { chainId, account } = useActiveWeb3React()
  const darkMode = useIsDarkMode()
  const { totalFetched, poolLength, isFetchingMore } = useFarms()
  const masterChefContract = useMasterChefContract()
  const handleLoadMore = () => {
    if (chainId && masterChefContract)
      dispatch(fetchFarmMore({ chainId, masterChefContract, account: account ?? undefined }))
  }
  return (
    <Box pt={5} maxWidth={{ xs: 400, md: 'none' }} mx="auto">
      <Grid container spacing={5}>
        {list.map(farm => {
          const { pid, token, quoteToken, lpAddress } = farm
          const actionLink = {
            addLiquidity: `/add/${token.address}/${quoteToken.address}`,
            viewContract: `${ENV_VARS.explorerScan}/address/${lpAddress}`,
            seePairInfo: `${ENV_VARS.explorerScan}/address/${lpAddress}/tokens`
          }

          const formattedTotalStacked =
            farm.totalStacked && farm.totalStacked.gt(0)
              ? `$${Number(farm.totalStacked).toLocaleString(undefined, { maximumFractionDigits: 3 })}`
              : '$0'

          const props = {
            farm,
            actionLink,
            totalStacked: formattedTotalStacked,
            onOpenCalculator: () => {
              if (!onOpenCalculatorPopup) return
              onOpenCalculatorPopup(pid)
            },

            onOpenStake: () => {
              if (!onOpenStakePopup) return
              onOpenStakePopup(pid)
            },

            onOpenHarvest: () => {
              if (!onOpenHarvestPopup) return
              onOpenHarvestPopup(pid)
            },

            onOpenWithdraw: () => {
              if (!onOpenWithdrawPopup) return
              onOpenWithdrawPopup(pid)
            }
          }

          return isThumbnailView ? (
            <Grid item key={pid} xs={12} md={6} lg={4}>
              <FarmItemThumbnailView {...props} />
            </Grid>
          ) : (
            <Grid item key={pid} xs={12}>
              <FarmItemListView {...props} />
            </Grid>
          )
        })}
      </Grid>
      <Box textAlign="center" pt={5}>
        {isFetchingMore && <img src={darkMode ? LOADING_GIF : LOADING_GIF_LT} alt="" width={150} />}
        {totalFetched < poolLength && !isFetchingMore && (
          <Button
            onClick={handleLoadMore}
            variant="contained"
            sx={{
              minWidth: { xs: 180, md: 220 },
              py: { xs: 1.25, md: 1.5 }
            }}
          >
            Load more
          </Button>
        )}
      </Box>
    </Box>
  )
}
