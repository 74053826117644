import * as React from 'react'

interface Props {
  color?: string
  style?: any
  className?: any
}

export const UncheckCircle = ({ color, ...rest }: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} fill="none" {...rest}>
    <circle cx={16} cy={16} r={15.5} stroke={color || '#7CE5CF'} />
  </svg>
)
