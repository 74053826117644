export default function lockTimeIdToDay(
  lockTimeId: number
): {
  label: string
  symbol: string
  value: number
} {
  switch (lockTimeId) {
    case 1:
      return { label: '7 days', symbol: '7D', value: 7 }
    case 2:
      return { label: '14 days', symbol: '14D', value: 14 }
    case 3:
      return { label: '30 days', symbol: '30D', value: 30 }
    case 4:
      return { label: '60 days', symbol: '60D', value: 60 }
    case 5:
      return { label: '90 days', symbol: '90D', value: 90 }
    case 6:
      return { label: '180 days', symbol: '180D', value: 180 }
    case 7:
      return { label: '365 days', symbol: '365D', value: 365 }
  }

  return { label: 'flexible days', symbol: 'None', value: 0 } // flexible
}
