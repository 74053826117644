import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import { Box, Dialog, IconButton } from '@mui/material'
import { LIGHT_GREEN } from 'constants/index'
import { useOnClickOutside } from 'hooks/useOnClickOutside'
import { useContext, useRef } from 'react'
import { Settings, X } from 'react-feather'
import { Text } from 'rebass'
import { ApplicationModal } from 'state/application/actions'
import { useModalOpen, useToggleSettingsMenu } from 'state/application/hooks'
import { useUserSingleHopOnly, useUserSlippageTolerance, useUserTransactionTTL } from 'state/user/hooks'
import styled, { ThemeContext } from 'styled-components'

import { ButtonPrimary } from '../Button'
import TransactionSettings from '../TransactionSettings'

const StyledMenuIcon = styled(Settings)`
  height: 40px;
  width: 40px;

  > * {
    stroke: ${({ theme }) => theme.primary2};
  }

  :hover {
    opacity: 0.7;
  }
`

const StyledCloseIcon = styled(X)`
  height: 20px;
  width: 20px;
  :hover {
    cursor: pointer;
  }

  > * {
    stroke: ${({ theme }) => theme.text1};
  }
`

const StyledMenuButton = styled.button`
  position: relative;
  width: 100%;
  height: 100%;
  border: none;
  background-color: transparent;
  margin: 0;
  padding: 0;
  height: 35px;

  padding: 0.15rem 0.5rem;
  border-radius: 0.5rem;

  :hover,
  :focus {
    cursor: pointer;
    outline: none;
  }

  svg {
    margin-top: 2px;
  }
`
const EmojiWrapper = styled.div`
  position: absolute;
  bottom: -6px;
  right: 0px;
  font-size: 14px;
`

const StyledMenu = styled.div`
  margin-left: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border: none;
  text-align: left;
`

const MenuFlyout = styled.span`
  min-width: 20.125rem;
  background-color: ${({ theme }) => theme.bg2};
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
    0px 24px 32px rgba(0, 0, 0, 0.01);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  position: absolute;
  top: 3rem;
  right: 0rem;
  z-index: 100;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    min-width: 18.125rem;
  `};
`

const Break = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.bg3};
`

const ModalContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem 0;
  background-color: ${({ theme }) => theme.bg2};
  border-radius: 20px;
`
const ModalDialog = styled(Dialog)`
  & .MuiDialog-paper {
    max-width: 466px;
    padding: 40px 32px;
    // top: -5%;
    background-color: ${({ theme }) => (theme.isDarkTheme ? 'rgba(0, 0, 0, 0.5)' : LIGHT_GREEN)};
    border: ${({ theme }) => (theme.isDarkTheme ? `solid 1px ${theme.primary2}` : 'none')};
    border-radius: 16px;
    position: relative;
  }
`

const BtnSave = styled(ButtonPrimary)`
  margin-top: 2.5rem;
  font-size: 18px;
  font-weight: 600;
  padding: 14px 18px;
  text-transform: uppercase;
`

const Icon = styled(IconButton)`
  & .MuiSvgIcon-root {
    color: ${({ theme }) => theme.primary2};
  }
  padding: 0;
  position: absolute !important;
  top: 0;
  right: 0;
`

export default function SettingsTab() {
  const node = useRef<HTMLDivElement>()
  const open = useModalOpen(ApplicationModal.SETTINGS)
  const toggle = useToggleSettingsMenu()

  const theme = useContext(ThemeContext)
  const [userSlippageTolerance, setUserslippageTolerance] = useUserSlippageTolerance()

  const [ttl, setTtl] = useUserTransactionTTL()

  // const [expertMode, toggleExpertMode] = useExpertModeManager()

  const [singleHopOnly, setSingleHopOnly] = useUserSingleHopOnly()

  // show confirmation view before turning on
  // const [showConfirmation, setShowConfirmation] = useState(false)

  useOnClickOutside(node, open ? toggle : undefined)

  return (
    // https://github.com/DefinitelyTyped/DefinitelyTyped/issues/30451
    <ModalDialog ref={node as any} fullWidth open={open} onClose={toggle}>
      {/* <Modal isOpen={showConfirmation} onDismiss={() => setShowConfirmation(false)} maxHeight={100}>
        <ModalContentWrapper>
          <AutoColumn gap="lg">
            <RowBetween style={{ padding: '0 2rem' }}>
              <div />
              <Text fontWeight={500} fontSize={20}>
                Are you sure?
              </Text>
              <StyledCloseIcon onClick={() => setShowConfirmation(false)} />
            </RowBetween>
            <Break />
            <AutoColumn gap="lg" style={{ padding: '0 2rem' }}>
              <Text fontWeight={500} fontSize={20}>
                Expert mode turns off the confirm transaction prompt and allows high slippage trades that often result
                in bad rates and lost funds.
              </Text>
              <Text fontWeight={600} fontSize={20}>
                ONLY USE THIS MODE IF YOU KNOW WHAT YOU ARE DOING.
              </Text>
              <ButtonError
                error={true}
                padding={'12px'}
                onClick={() => {
                  if (window.prompt(`Please type the word "confirm" to enable expert mode.`) === 'confirm') {
                    toggleExpertMode()
                    setShowConfirmation(false)
                  }
                }}
              >
                <Text fontSize={20} fontWeight={500} id="confirm-expert-mode">
                  Turn On Expert Mode
                </Text>
              </ButtonError>
            </AutoColumn>
          </AutoColumn>
        </ModalContentWrapper>
      </Modal> 
      <StyledMenuButton onClick={toggle} id="open-settings-dialog-button">
        <StyledMenuIcon />
        {expertMode ? (
          <EmojiWrapper>
            <span role="img" aria-label="wizard-icon">
              🧙
            </span>
          </EmojiWrapper>
        ) : null}
      </StyledMenuButton> */}
      {/* <MenuFlyout> */}

      <Box textAlign="center" position="relative" mb={1}>
        <Text fontWeight={600} fontSize="1.75rem" textAlign="center" color={theme.primary2}>
          Settings
        </Text>

        <Icon onClick={toggle}>
          <CloseRoundedIcon fontSize="large" />
        </Icon>
      </Box>

      <TransactionSettings
        rawSlippage={userSlippageTolerance}
        setRawSlippage={setUserslippageTolerance}
        deadline={ttl}
        setDeadline={setTtl}
        isActiveMultihop={singleHopOnly}
        onToggleMultiHop={() => {
          setSingleHopOnly(!singleHopOnly)
        }}
      />

      {/* <AutoColumn gap="md"> */}
      {/* <Text fontWeight={600} fontSize={14}>
          Interface Settings
        </Text> */}
      {/* <RowBetween>
          <RowFixed>
            <TYPE.black fontWeight={400} fontSize={14} color={theme.text2}>
              Toggle Expert Mode
            </TYPE.black>
            <QuestionHelper text="Bypasses confirmation modals and allows high slippage trades. Use at your own risk." />
          </RowFixed>
          <Toggle
            id="toggle-expert-mode-button"
            isActive={expertMode}
            toggle={
              expertMode
                ? () => {
                    toggleExpertMode()
                    setShowConfirmation(false)
                  }
                : () => {
                    toggle()
                    setShowConfirmation(true)
                  }
            }
          />
        </RowBetween>
        */}
      {/* </AutoColumn> */}
      {/* </MenuFlyout> */}

      <BtnSave onClick={toggle}>Save</BtnSave>
    </ModalDialog>
  )
}
