import { Box, Stack } from '@mui/material'
import { useWeb3React } from '@web3-react/core'
import { LayoutFilter, SearchFilter, StackedFilter } from 'components/filter'
import { useIsDarkMode } from 'state/user/hooks'

export interface FarmFilterState {
  view: number
  search: string
  stakedOnly: boolean
}

export interface FarmFilterProps {
  filter: FarmFilterState
  onViewChange: (event: any, newLayout: number | null) => void
  onToggleStackedOnly: () => void
  onSearchChange: (search: string) => void
}

export function FarmFilter({ filter, onViewChange, onToggleStackedOnly, onSearchChange }: FarmFilterProps) {
  const darkMode = useIsDarkMode()
  const { active } = useWeb3React()

  return (
    <Box
      sx={{
        border: darkMode ? '1px solid rgba(124, 229, 207, 0.5)' : '1px solid rgba(28, 65, 79, 0.5)',
        borderRadius: 2,
        padding: 3
      }}
    >
      <Stack
        direction={{ xs: 'column', md: 'row' }}
        gap={{ xs: 3, md: 0 }}
        alignItems={{ xs: 'flex-start', md: 'center' }}
        justifyContent="space-between"
      >
        <Stack direction="row" alignItems="center" spacing={{ xs: 3, md: 5, lg: 7 }}>
          <LayoutFilter value={filter.view} onChange={onViewChange} />
          {active && <StackedFilter isChecked={filter.stakedOnly} onToggle={onToggleStackedOnly} />}
          {/* <StatusFilter value="live" onChange={() => console.log(123)} /> */}
        </Stack>

        <Stack
          pl={1}
          minWidth={{ xs: '100%', sm: 0 }}
          direction={{ xs: 'column', sm: 'row' }}
          spacing={{ xs: 3, md: 5 }}
        >
          {/* <SortFilter value="hot" onChange={() => console.log(123)} /> */}
          <SearchFilter onSearch={onSearchChange} placeholderText="Search Farms" />
        </Stack>
      </Stack>
    </Box>
  )
}
