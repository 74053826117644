import { gql } from '@apollo/client'

const MAX_TAKE = 100

export const PAIRS = gql`
  query getPair {
    pairs {
      id
      token0 {
        name
        symbol
        id
        decimals
      }
      token1 {
        name
        symbol
        id
        decimals
      }
      totalSupply
      reserveUSD
      untrackedVolumeUSD
    }
  }
`

export const USER_TRANSACTIONS = gql`
  query transactions($user: Bytes!) {
    mints(first: ${MAX_TAKE}, orderBy: timestamp, orderDirection: desc, where: { to: $user }) {
      id
      transaction {
        id
        timestamp
      }
      pair {
        id
        token0 {
          id
          symbol
        }
        token1 {
          id
          symbol
        }
      }
      to
      liquidity
      amount0
      amount1
      amountUSD
    }
    burns(first: ${MAX_TAKE}, orderBy: timestamp, orderDirection: desc, where: { sender: $user }) {
      id
      transaction {
        id
        timestamp
      }
      pair {
        id
        token0 {
          symbol
        }
        token1 {
          symbol
        }
      }
      sender
      to
      liquidity
      amount0
      amount1
      amountUSD
    }
    swaps(first: ${MAX_TAKE}, orderBy: timestamp, orderDirection: desc, where: { from: $user }) {
      id
      transaction {
        id
        timestamp
      }
      pair {
        token0 {
          symbol
        }
        token1 {
          symbol
        }
      }
      amount0In
      amount0Out
      amount1In
      amount1Out
      amountUSD
      to
    }
  }
`
