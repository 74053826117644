import { Box, Stack, Typography } from '@mui/material'
import { IMAGES, LIGHT_BG_GRADIENT } from 'constants/index'
import React from 'react'
import { useDarkModeManager } from 'state/user/hooks'

const getStyles = (isDarkTheme: boolean) => ({
  wrapper: {
    minHeight: { xs: '45rem', md: '30rem' },
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    overflow: 'hidden',
    bgcolor: isDarkTheme ? 'rgba(0, 0, 0, 0.4)' : 'transparent',
    backgroundImage: isDarkTheme ? '' : LIGHT_BG_GRADIENT
  },

  top: {
    position: 'absolute',
    top: 0,
    left: '50%',
    transform: 'translateX(-50%)',
    height: '100%',
    img: { height: '100%' }
  },

  content: {
    position: 'absolute',
    zIndex: 1,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',

    width: '100%',
    maxWidth: 1000,
    px: '20px',

    alignItems: 'center',
    justifyContent: 'space-between',
    textAlign: { xs: 'center', md: 'left' }
  },

  title: {
    fontSize: { xs: '3rem' },
    lineHeight: 'normal',
    letterSpacing: 'normal',
    mb: { xs: 2, md: 1 }
  },

  description: {
    fontSize: { xs: '1rem', md: '1.25rem' },
    fontWeight: { xs: 400 }
  }
})

export function PoolHero() {
  const [darkMode] = useDarkModeManager()
  const styles = getStyles(darkMode)
  return (
    <Stack sx={styles.wrapper}>
      {darkMode && (
        <Box sx={styles.top}>
          <img src={IMAGES.HERO_TOP} alt="" />
        </Box>
      )}

      <Stack direction={{ xs: 'column', md: 'row' }} spacing={{ xs: 10, md: 0 }} sx={styles.content}>
        <Box>
          <Typography component="h1" sx={styles.title}>
            Liquidity Pools
          </Typography>

          <Typography sx={styles.description}>Stake LP Tokens with us and Get Rewards Right away!</Typography>
        </Box>

        <Box
          sx={{
            maxWidth: { xs: 200, sm: 250, md: 'none' },
            img: { width: '100%' }
          }}
        >
          <img src={IMAGES.HERO_BOX_1} alt="" />
        </Box>
      </Stack>
    </Stack>
  )
}
