import { JSBI, TokenAmount } from '@adaswap/sdk'
import { Box, Button, Stack, Typography } from '@mui/material'
import LinkWithIcon from 'components/pool/LinkWithIcon'
import TokenPair from 'components/pool/TokenPair'
import Popup from 'components/Popup/Popup'
import TransactionConfirmationModal from 'components/TransactionConfirmationModal'
import { BRAND_COLOR, BRAND_COLOR_LIGHT, DARK_BLUE, DARK_GREEN } from 'constants/index'
import { useCheckCanUnlockOrWithDraw } from 'hooks/useCheckCanUnlockOrWithDraw'
import { useHarvestFarm } from 'hooks/useHarvestFarm'
import { FarmWithStakedValue } from 'models'
import { DEFAULT_LOCK_TIME_INDEX } from 'pages/Farm'
import { useEffect, useState } from 'react'
import { useAswPrice, useLockFarmIndex } from 'state/farm/hooks'
import { useIsDarkMode } from 'state/user/hooks'
import { StakeIcon } from 'svgComponents'
import { getTokenIcon, nativeWrappedAdaToken } from 'utils'
import { ethersToBigNumber } from 'utils/bigNumber'
import { getFullDisplayBalance } from 'utils/formatBalance'
import lockTimeIdToDay from 'utils/lockTimeIdToDay'

import { ToggleButtonGroup } from './FarmCalculatorPopup/ToggleButtonGroup'
import FarmCountdown from './FarmCountdown'

interface FarmHarvestPopupProps {
  onCloseHarvestPopup: () => void
  lockFarmList: FarmWithStakedValue[]
}

export function FarmHarvestPopup({ onCloseHarvestPopup, lockFarmList }: FarmHarvestPopupProps) {
  const darkMode = useIsDarkMode()
  const lightColor = darkMode ? BRAND_COLOR_LIGHT : DARK_BLUE
  const aswPrice = useAswPrice()

  const [lockTimeIndex, setLockTimeIndex] = useState(DEFAULT_LOCK_TIME_INDEX)
  const [hash, setHash] = useState('')
  const [attemptingTxn, setAttemptingTxn] = useState(false)

  const { activeLockFarm, lockIdList } = useLockFarmIndex(lockFarmList, lockTimeIndex, false, true)
  const { token, quoteToken, userData } = activeLockFarm ?? {}
  const [wrappedToken, wrapperQuoteToken] = [nativeWrappedAdaToken(token), nativeWrappedAdaToken(quoteToken)]
  const farmSymbol = `${wrappedToken.symbol}-${wrapperQuoteToken.symbol}`

  useEffect(() => {
    if (activeLockFarm !== null) return

    if (lockIdList.length === 0) {
      setHash('')
      onCloseHarvestPopup()
    } else {
      setLockTimeIndex(DEFAULT_LOCK_TIME_INDEX)
    }
  }, [activeLockFarm, lockIdList])

  const { loading, unlockTime, isEnoughLockTime } = useCheckCanUnlockOrWithDraw(activeLockFarm)

  const onHarvest = useHarvestFarm(activeLockFarm, setHash, setAttemptingTxn)

  return (
    <Popup
      open={lockFarmList.length > 0}
      onClose={onCloseHarvestPopup}
      scroll="body"
      paperStyles={{ pt: 8, pb: 5, maxWidth: 576 }}
    >
      <TransactionConfirmationModal
        isOpen={attemptingTxn || Boolean(hash)}
        attemptingTxn={attemptingTxn}
        hash={hash}
        currencyAmountA={activeLockFarm ? new TokenAmount(wrappedToken, JSBI.BigInt(0)) : undefined}
        currencyAmountB={activeLockFarm ? new TokenAmount(wrapperQuoteToken, JSBI.BigInt(0)) : undefined}
        pendingText="Harvesting"
        onDismiss={() => {
          setHash('')
          !attemptingTxn && onCloseHarvestPopup()
        }}
      />

      <Stack direction="row" alignItems="center" justifyContent="center" spacing={2} pb={5}>
        <StakeIcon color={darkMode ? BRAND_COLOR : DARK_GREEN} />
        <Typography fontSize={{ xs: '1.75rem', md: '2rem' }} color={lightColor}>
          Harvest
        </Typography>
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="center" spacing={2}>
        <TokenPair icon1={getTokenIcon(wrappedToken.symbol)} icon2={getTokenIcon(wrapperQuoteToken.symbol)} />
        <Typography fontSize="1.25rem" color={lightColor}>
          {farmSymbol}
        </Typography>
      </Stack>

      <Box py={4}>
        <Stack spacing={1} pb={4}>
          <Typography
            component="span"
            sx={{
              color: lightColor,
              fontSize: { xs: '1.125rem', md: '1.25rem' }
            }}
          >
            Earned
          </Typography>

          <Stack
            spacing={1}
            sx={{
              border: 1,
              borderColor: lightColor,
              borderRadius: 4,
              px: 2.5,
              py: 3
            }}
          >
            <Typography
              component="span"
              sx={{
                color: lightColor,
                fontSize: { xs: '1.25rem', md: '1.5rem' }
              }}
            >
              {userData ? getFullDisplayBalance(ethersToBigNumber(userData.earnings), 18, 18) : 0}

              <Typography component="span" fontSize="inherit" color={darkMode ? 'white' : DARK_GREEN}>
                &nbsp;ASW
              </Typography>
            </Typography>

            <Typography
              component="span"
              sx={{
                color: lightColor,
                fontSize: { xs: '1rem', md: '1.125rem' }
              }}
            >
              ~ {userData ? getFullDisplayBalance(ethersToBigNumber(userData.earnings).times(aswPrice), 18, 3) : 0} USD
            </Typography>
          </Stack>
        </Stack>

        <ToggleButtonGroup
          heading="Harvest with locked for"
          data={lockIdList.map(lockTimeIdToDay).map(x => x.symbol)}
          selected={lockTimeIndex}
          onSelect={setLockTimeIndex}
        />
      </Box>

      <Stack
        spacing={1}
        direction={{ xs: 'column', sm: 'row' }}
        justifyContent="space-between"
        alignItems="center"
        pb={4}
      >
        <Typography fontWeight="normal" color="primary.main">
          Need more {farmSymbol} LP?
        </Typography>
        <LinkWithIcon text={`Get ${farmSymbol} LP`} underline />
      </Stack>

      <Button
        fullWidth
        variant="outlined"
        sx={{
          height: 70,
          fontSize: { xs: '1.125rem', md: '1.25rem' },
          textTransform: 'uppercase'
        }}
        disabled={loading || !isEnoughLockTime}
        onClick={onHarvest}
      >
        <FarmCountdown unlockTime={unlockTime} text="Harvest now" />
      </Button>
    </Popup>
  )
}
