import * as React from 'react'

interface Props {
  color?: string
  style?: any
}

export const ArrowDown = (props: Props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" width="21" height="13" viewBox="0 0 21 13" style={props.style}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.233 1.105a1.56 1.56 0 0 1 2.202 0 1.56 1.56 0 0 1 0 2.201l-8.89 8.89a1.56 1.56 0 0 1-2.201 0l-8.89-8.89a1.56 1.56 0 0 1 0-2.201 1.56 1.56 0 0 1 2.202 0l7.792 7.794 7.785-7.794z"
        fill={props.color || '#77FEFF'}
      />
    </svg>
  )
}
