import { ChainId, Token } from '@adaswap/sdk'
import { isStage } from 'utils'

export const WADA = 'WADA'
export const mADA = 'mADA'
export const NATIVE_TOKEN_ADDRESS = 'mADA'

export const WADA_MAINNET = new Token(
  ChainId.MAINNET,
  '0xAE83571000aF4499798d1e3b0fA0070EB3A3E3F9',
  18,
  'WADA',
  'Wrapped ADA'
)

export const WADA_TESTNET = new Token(
  ChainId.TESTNET,
  '0x65a51E52eCD17B641f8F0D1d56a6c9738951FDC9',
  18,
  'WADA',
  'Wrapped ADA'
)

export const ASW_MAINNET = new Token(
  ChainId.MAINNET,
  '0xAb033ae98BEeB92C267D1f02E3963A424A02B406',
  18,
  'ASW',
  'AdaSwap'
)

export const ASW_TESTNET = new Token(
  ChainId.TESTNET,
  isStage ? '0x21b1473359b5DB85c7c6Bfea25ce6723f716a884' : '0x84A0b87Eb42EED5A0A9185d510910AD9dEeE4470',
  18,
  'ASW',
  'AdaSwap'
)

export const USDC_MAINNET = new Token(
  ChainId.MAINNET,
  '0xB44a9B6905aF7c801311e8F4E76932ee959c663C',
  6,
  'USDC',
  'USD Coin'
)

export const USDC_TESTNET = new Token(
  ChainId.TESTNET,
  '0xD9Bc92569C14C1FB62Ff0CeE95aCE5d5de4b36EA',
  6,
  'USDC',
  'USD Coin'
)

export const ASW = {
  [ChainId.TESTNET]: ASW_TESTNET,
  [ChainId.MAINNET]: ASW_MAINNET
}

export const USDC_TOKEN = {
  [ChainId.TESTNET]: USDC_TESTNET,
  [ChainId.MAINNET]: USDC_MAINNET
}

export const WADA_TOKEN = {
  [ChainId.TESTNET]: WADA_TESTNET,
  [ChainId.MAINNET]: WADA_MAINNET
}
