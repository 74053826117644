export interface Token {
  label: string
  symbol: string
  address: string
  icon: string
  decimals?: number
  balance: number
}

export enum ApproveStatus {
  NotApprove,
  Approving,
  Approved
}

export enum Unit {
  kWei = 'Kwei',
  Mwei = 'Mwei',
  Gwei = 'Gwei',
  ether = 'ether'
}

export enum TokenType {
  NORMAL,
  ALP
}

export interface SearchToken {
  balance?: string
  contractAddress: string
  decimals: string
  name: string
  symbol: string
}
