const ENV_VARS = {
  cdnURL: process.env.REACT_APP_CDN_URL,
  subgraphUrl: process.env.REACT_APP_SUBGRAPH_URL,
  explorerScan: process.env.REACT_APP_EXPLORER_SCAN,
  infoWebsiteUrl: process.env.REACT_APP_INFO_WEBSITE_URL,
  routerContract: process.env.REACT_APP_ROUTER_CONTRACT!,
  environmentDeploy: process.env.REACT_APP_ENVIRONMENT_DEPLOY
}

export default ENV_VARS
