/* eslint-disable @typescript-eslint/no-unused-vars */
import { Components, Theme } from '@mui/material'
import {
  BG_DARK_BLUE,
  BRAND_COLOR_LIGHT,
  DARK_GREEN,
  DARKER_MAIN,
  LIGHT_GREEN,
  LT_DISABLED_TEXT_COLOR_LIGHT
} from 'constants/index'

import { ComponentParams } from '../index'

const MuiButton = ({ globalTheme, isDarkTheme }: ComponentParams): Components<Theme>['MuiButton'] => ({
  styleOverrides: {
    root: {
      borderRadius: 90,
      textTransform: 'capitalize'
    },

    outlined: {
      color: isDarkTheme ? BRAND_COLOR_LIGHT : LIGHT_GREEN,
      borderColor: isDarkTheme ? BRAND_COLOR_LIGHT : 'white',
      backgroundColor: isDarkTheme ? BG_DARK_BLUE : DARK_GREEN,

      '&:hover': {
        borderColor: isDarkTheme ? BRAND_COLOR_LIGHT : 'white',
        backgroundColor: isDarkTheme ? 'rgba(124, 229, 207, 0.04)' : DARKER_MAIN
      },

      '&.Mui-disabled': {
        border: isDarkTheme ? '1px solid rgba(119, 254, 255, 0.5)' : '1px solid rgba(28, 65, 79, 0.5)',
        color: isDarkTheme ? 'rgba(119, 254, 255, 0.5)' : LT_DISABLED_TEXT_COLOR_LIGHT
      }
    }
  }
})

export default MuiButton
