import { mapListToCDN } from 'utils/index'

const iconList = {
  COPY: 'copy.svg',
  // REFRESH: 'refresh.svg',
  // CORE: 'core.svg',
  // ARROW_DOWN: 'down.svg',
  // ARROW_UP: 'up.svg',
  MENU: 'menu.svg',
  SETTING: 'setting.svg',
  // CHECK_CIRCLE: 'check-circle.svg',
  // UNCHECK_CIRCLE: 'uncheck-circle.svg',
  // LIQUIDITY: 'liquidity.svg',
  // LIQUIDITY_LG: 'liquidity-lg.svg',
  PAIR: 'pair.svg',
  METAMASK: 'metamask.png',
  TRANSACTION_SUBMITTED: 'transaction-submitted.svg'
  // MORE: 'more.svg',
  // TWITTER: 'twitter.svg',
  // TELEGRAM: 'telegram.svg',
  // FEATURE_REQUEST: 'feature-request.svg',
  // BUGS: 'bugs.svg',
  // WIKI: 'wiki.svg',

  // TELEGRAM_DARK: 'telegram-dark.webp',
  // FEATURE_REQUEST_DARK: 'feature-request-dark.webp',
  // BUGS_DARK: 'bugs-dark.webp',
  // WIKI_DARK: 'wiki-dark.webp',
  // TWITTER_DARK: 'twitter-dark.webp',
  // COPY_DARK: 'copy-dark.svg',

  // LOGO_HORIZONTAL: 'logo-horizontal.svg',
  // LOGO_VERTICAL: 'logo-vertical.svg',
  // LOGO: 'logo.svg',
  // LOGO_MOBILE: 'logo-mobile.svg'
}

const imageList = {
  BG_FULL: 'bg-full.png',
  BG_ONLY: 'bg-only.png',
  BG_BANNER_LG: 'bg-banner-lg.png',
  // BG_BANNER: 'bg-banner.png',
  LAND_GRID: 'land.svg',
  LAND_LIGHTMODE: 'land-lightmode.svg',
  GRID_LIGHTMODE: 'grid-lightmode.svg',

  HERO_BOX_1: 'hero-box-1.png',
  HERO_BOX_2: 'hero-box-2.png',
  HERO_CIRCLE: 'hero-circle.png',
  HERO_TOP: 'hero-top.png',
  HERO_VECTOR: 'hero-vector.png',

  UNKNOWN: 'unknown.png',
  EMPTY: 'empty.png',

  DESKTOP: 'desktop.png',
  TOAST_LOGO: 'toast-logo.png',
  THEME_SWITCH_LIGHT: 'theme-switch-light-mode.svg',
  THEME_SWITCH_DARK: 'theme-switch-dark-mode.svg'
}

const tokenList = {
  ASW: 'asw.svg',
  ADA: 'ada.svg',
  GENERIC: 'generic.webp',

  TRX: 'trx.svg',
  UNI: 'uni.svg',
  NEAR: 'near.svg',
  LINK: 'link.svg',
  ATOM: 'atom.svg',
  LTC: 'ltc.svg',
  DAI: 'dai.svg',
  WBTC: 'wbtc.svg',
  CRO: 'cro.svg',
  MATIC: 'matic.svg',
  BUSD: 'busd.svg',
  SHIB: 'shib.svg',
  DOGE: 'doge.svg',
  DOT: 'dot.svg',
  AVAX: 'avax.svg',
  LUNA: 'luna.svg',
  SOL: 'sol.svg',
  XRP: 'xrp.svg',
  USDC: 'usdc.svg',
  BNB: 'bnb.svg',
  USDT: 'usdt.svg',
  ETH: 'eth.svg',
  BTC: 'btc.svg',

  BASHO: 'basho.svg',
  BYRON: 'byron.svg',
  GOGUEN: 'goguen.svg',
  SHELLEY: 'shelley.svg',
  VOLTAIRE: 'voltaire.svg'
}

export const TOKENS = mapListToCDN(tokenList, 'tokens')
export const IMAGES = mapListToCDN(imageList, 'images')
export const ICONS = mapListToCDN(iconList, 'icons')
export const LOADING_GIF = `${process.env.REACT_APP_CDN_URL}/adaswap_loading.gif`
export const LOADING_GIF_LT = `${process.env.REACT_APP_CDN_URL}/adaswap_loading_lightmode.gif`
