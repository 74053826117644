interface Props {
  color?: string
  style?: any
}

export const CalculatorIcon = (props: Props) => (
  <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.45 9.985h7.652v6.047c0 .877-.693 1.569-1.569 1.569H9.45V9.985zm-8.55 0h7.65v7.616H2.469A1.556 1.556 0 0 1 .9 16.03V9.986zm8.55-8.587h6.084c.876 0 1.568.693 1.568 1.569v6.119H9.45V1.398zm-6.982 0h6.083v7.689L.899 9.086v-6.12c0-.875.692-1.568 1.57-1.568zm0-.898A2.473 2.473 0 0 0 0 2.967v13.065A2.474 2.474 0 0 0 2.468 18.5h13.066c1.359 0 2.466-1.11 2.466-2.468V2.967A2.472 2.472 0 0 0 15.534.5H2.467z"
      fill={props.color || '#fff'}
    />
    <path
      d="M4.708 2.968a.449.449 0 0 0-.443.457v1.44h-1.44c-.6 0-.6.899 0 .899h1.44v1.44a.45.45 0 1 0 .9 0v-1.44h1.442c.6 0 .6-.899 0-.899H5.165v-1.44a.45.45 0 0 0-.457-.457zM2.78 13.305c-.6.03-.554.928.045.898h3.782a.45.45 0 1 0 0-.9l-3.782.002H2.78zM11.888 3.523a.45.45 0 0 0-.313.77l1.019 1.018-1.02 1.021a.449.449 0 1 0 .636.635l1.019-1.02 1.019 1.02a.45.45 0 1 0 .635-.635l-1.019-1.02 1.019-1.02a.449.449 0 1 0-.635-.635l-1.02 1.02-1.018-1.02a.447.447 0 0 0-.322-.134zM11.338 12.41a.45.45 0 1 0 0 .9h3.782a.45.45 0 1 0 0-.9h-3.781zM11.338 14.193a.45.45 0 1 0 0 .9h3.782a.45.45 0 1 0 0-.9h-3.781z"
      fill={props.color || '#fff'}
    />
  </svg>
)
