import { Box, Stack, Typography } from '@mui/material'
import { useWeb3React } from '@web3-react/core'
import { PoolNavigation } from 'components/pool'
import { IMAGES } from 'constants/index'
import React from 'react'

export function EmptyContent() {
  return (
    <React.Fragment>
      <Typography fontWeight={600} fontSize={{ xs: '1.25rem', sm: '1.875rem' }} sx={{ pb: 2 }}>
        There’s nothing here...
      </Typography>

      <Box
        sx={{
          maxWidth: { xs: 150, sm: 200 },
          '& > img': { width: 1 },
          margin: '0 auto'
        }}
      >
        <img src={IMAGES.EMPTY} alt="" />
      </Box>
    </React.Fragment>
  )
}

export function EmptyStage({ description, liquidityMode = false }: { description?: string; liquidityMode?: boolean }) {
  const { active } = useWeb3React()

  return (
    <Stack
      spacing={5}
      alignItems="center"
      sx={{
        mt: 3,
        px: 3,
        py: 6,
        border: 1,
        borderColor: 'primary.main',
        borderRadius: 3
      }}
    >
      <EmptyContent />

      <Typography fontSize={{ xs: '0.875rem', sm: '1rem' }}>
        {active ? description : 'Please connect to your wallet'}
      </Typography>

      <PoolNavigation showAddLiquidity={liquidityMode} />
    </Stack>
  )
}
