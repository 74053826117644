import { ChainId, Currency, Price, Token } from '@adaswap/sdk'
import { ASW, USDC_TOKEN } from 'constants/index'
import { usePair } from 'data/Reserves'
import { useActiveWeb3React } from 'hooks'
import { wrappedCurrency } from 'utils/wrappedCurrency'

export const usePriceByPairs = (currencyA?: Currency, currencyB?: Currency) => {
  const { chainId } = useActiveWeb3React()
  const [, pair] = usePair(currencyA, currencyB)
  const [tokenA, tokenB] = [wrappedCurrency(currencyA, chainId), wrappedCurrency(currencyB, chainId)]

  if (tokenA && tokenB && pair) {
    return pair.priceOf(tokenB)
  }

  return undefined
}

// @Note: only fetch from one pair
export const useAswUsdcPrice = ({ forceMainnet } = { forceMainnet: false }): Price | undefined => {
  const { chainId } = useActiveWeb3React()
  const isTestnet = !forceMainnet && chainId === ChainId.TESTNET

  const asw: Token = isTestnet ? ASW[ChainId.TESTNET] : ASW[ChainId.MAINNET]
  return usePriceByPairs(USDC_TOKEN[asw.chainId], asw)
}
