import { Currency as ICurrency, JSBI, Pair, Percent } from '@adaswap/sdk'
import OpenInNewRoundedIcon from '@mui/icons-material/OpenInNewRounded'
import { IconButton, Stack, Typography } from '@mui/material'
import CurrencyLogo from 'components/CurrencyLogo'
import TokenPair from 'components/pool/TokenPair'
import { BG_DARK_BLUE, BRAND_COLOR, DARK_GREEN, LIGHT_GREEN, MIDDLE_GREEN } from 'constants/index'
import { useTotalSupply } from 'data/TotalSupply'
import ENV_VARS from 'env'
import { useActiveWeb3React } from 'hooks'
// import useShareOfPool from 'hooks/useShareOfPool'
import { ReactNode, useMemo } from 'react'
import { toast } from 'react-toastify'
import { useIsDarkMode } from 'state/user/hooks'
import { useTokenBalance } from 'state/wallet/hooks'
import { CopyIcon } from 'svgComponents'
import { getTokenIcon } from 'utils'
import { unwrappedToken } from 'utils/wrappedCurrency'

const Item = ({
  name,
  value,
  children,
  isInfoMode
}: {
  name: string
  value?: string
  children?: ReactNode
  isInfoMode: boolean
}) => {
  const darkMode = useIsDarkMode()
  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={5}>
      <Typography
        component="span"
        color={darkMode ? 'white' : isInfoMode ? BG_DARK_BLUE : LIGHT_GREEN}
        fontSize={{ xs: '1rem', md: '1.125rem' }}
        flexShrink={0}
      >
        {name}
      </Typography>

      {children ?? (
        <Typography
          component="span"
          color={darkMode ? 'white' : isInfoMode ? BG_DARK_BLUE : LIGHT_GREEN}
          fontSize={{ xs: '1rem', md: '1.25rem' }}
          sx={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
          }}
        >
          {value}
        </Typography>
      )}
    </Stack>
  )
}

const Currency = ({
  amount,
  currency,
  symbol,
  isInfoMode
}: {
  amount: string
  currency?: ICurrency
  symbol?: string
  isInfoMode: boolean
}) => {
  const darkMode = useIsDarkMode()
  return (
    <Stack
      direction="row"
      spacing={2}
      alignItems="center"
      sx={{
        '& > span': {
          fontSize: { xs: '1rem', md: '1.25rem' },
          color: darkMode || !isInfoMode ? 'primary.light' : 'primary.main'
        }
      }}
    >
      <Typography component="span">{amount}</Typography>
      {/* <Avatar sx={{ width: 28, height: 28 }}>
      <img src={icon} alt="" />
    </Avatar> */}
      <CurrencyLogo currency={currency} size={'28px'} />
      {symbol && <Typography component="span">{symbol}</Typography>}
    </Stack>
  )
}

export interface RemoveLiquidityInfoProps {
  isInfoMode?: boolean
  pair?: Pair
  showUnwrapped?: boolean
}

export function RemoveLiquidityInfo({ isInfoMode = false, pair, showUnwrapped = false }: RemoveLiquidityInfoProps) {
  const { account } = useActiveWeb3React()

  const [currency0, currency1] = useMemo(() => {
    if (!pair) return [undefined, undefined]
    if (showUnwrapped) return [pair.token0, pair.token1]
    return [unwrappedToken(pair.token0), unwrappedToken(pair.token1)]
  }, [pair, showUnwrapped])

  const userPoolBalance = useTokenBalance(account ?? undefined, pair?.liquidityToken)
  const totalPoolTokens = useTotalSupply(pair?.liquidityToken)

  const pairAddress = pair ? pair.liquidityToken.address : ''

  const poolTokenPercentage =
    !!userPoolBalance && !!totalPoolTokens && JSBI.greaterThanOrEqual(totalPoolTokens.raw, userPoolBalance.raw)
      ? new Percent(userPoolBalance.raw, totalPoolTokens.raw)
      : undefined

  const [token0Deposited, token1Deposited] =
    !!pair &&
    !!totalPoolTokens &&
    !!userPoolBalance &&
    // this condition is a short-circuit in the case where useTokenBalance updates sooner than useTotalSupply
    JSBI.greaterThanOrEqual(totalPoolTokens.raw, userPoolBalance.raw)
      ? [
          pair.getLiquidityValue(pair.token0, totalPoolTokens, userPoolBalance, false),
          pair.getLiquidityValue(pair.token1, totalPoolTokens, userPoolBalance, false)
        ]
      : [undefined, undefined]

  const handleCopy = () => {
    navigator.clipboard.writeText(pairAddress).then(() => toast.info('Copy to clipboard successfully'))
  }

  const handleOpen = () => {
    window.open(`${ENV_VARS.explorerScan}/address/${pair?.liquidityToken.address}`, '_blank')
  }

  const darkMode = useIsDarkMode()
  return (
    <>
      {isInfoMode && (
        <Stack direction="row" alignItems="center" justifyContent="center" spacing={3}>
          <TokenPair icon1={getTokenIcon(currency0?.symbol)} icon2={getTokenIcon(currency1?.symbol)} />
          <Typography
            sx={{
              fontSize: 24,
              fontWeight: 500,
              color: darkMode ? 'primary.light' : 'primary.main',
              textTransform: 'uppercase',
              transform: 'translateY(20%)'
            }}
          >
            {currency0?.symbol}-{currency1?.symbol}
          </Typography>
        </Stack>
      )}

      <Stack
        spacing={isInfoMode ? 3 : 2}
        sx={{
          // mt: isInfoMode ? 5 : 0,
          p: isInfoMode ? 3 : 2,
          border: 1,
          borderColor: 'primary.main',
          borderRadius: 4,
          bgcolor: isInfoMode ? 'transparent' : darkMode ? '#000' : MIDDLE_GREEN
        }}
      >
        <Item
          name="Total pooled tokens"
          value={userPoolBalance ? userPoolBalance.toSignificant(4) : '0.0000'}
          isInfoMode={isInfoMode}
        />

        {isInfoMode && <Item name="Pooled tokens in rewards pool" value="0" isInfoMode={isInfoMode} />}

        <Item name={`Pooled ${currency0?.symbol || ''}`} isInfoMode={isInfoMode}>
          <Currency
            amount={token0Deposited ? token0Deposited.toSignificant(6) : ''}
            currency={currency0}
            symbol={currency0?.symbol}
            isInfoMode={isInfoMode}
          />
        </Item>

        <Item name={`Pooled ${currency1?.symbol || ''}`} isInfoMode={isInfoMode}>
          <Currency
            amount={token1Deposited ? token1Deposited.toSignificant(6) : ''}
            currency={currency1}
            symbol={currency1?.symbol}
            isInfoMode={isInfoMode}
          />
        </Item>

        <Item
          name="Pooled share"
          value={poolTokenPercentage ? poolTokenPercentage.toFixed(6) + '%' : '-'}
          isInfoMode={isInfoMode}
        />

        {isInfoMode && (
          <Item name="Pair address" isInfoMode={isInfoMode}>
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography
                component="span"
                color={darkMode ? 'white' : isInfoMode ? BG_DARK_BLUE : LIGHT_GREEN}
                fontSize={{ xs: '1rem', md: '1.25rem' }}
              >
                {pairAddress ? `${pairAddress.slice(0, 4)}...${pairAddress.slice(-4)}` : '-'}
              </Typography>

              {pairAddress && (
                <IconButton onClick={handleCopy}>
                  <CopyIcon color={darkMode ? BRAND_COLOR : DARK_GREEN} />
                </IconButton>
              )}

              {pairAddress && (
                <IconButton onClick={handleOpen}>
                  <OpenInNewRoundedIcon color="primary" />
                </IconButton>
              )}
            </Stack>
          </Item>
        )}
      </Stack>
    </>
  )
}
