import { FarmWithStakedValue, PoolToken } from 'models'
import { DEFAULT_LOCK_TIME_INDEX } from 'pages/Farm'
import { ETHER_BIG_ZERO } from 'state/farm/selectors'
import { SerializedWrappedToken } from 'state/user/actions'

import { BIG_ZERO, ethersToBigNumber } from './bigNumber'
import { nativeWrappedAdaToken } from './common'

export const getMultiplierValue = (multiplier = '0X') => Number(multiplier.split('X')[0]) // 0.1X -> 0.1

export const getMaxIndex = (numberList: number[]) => {
  let maxIndex = 0
  numberList.forEach((apr, index) => apr > numberList[maxIndex] && (maxIndex = index))
  return maxIndex
}

export const getTotalAndMaxDataFromFarmList = (farmList: FarmWithStakedValue[]) => {
  const maxAprIndex = getMaxIndex(farmList.map(farm => farm.apr ?? 0))

  const maxMultiplier = Math.max(...farmList.map(farm => getMultiplierValue(farm.multiplier)))
  const { apr: maxApr, lpRewardsApr: maxLpApr } = farmList[maxAprIndex]

  const totalStakedBalance = farmList.reduce(
    (total, farm) => total.plus(farm.userData ? ethersToBigNumber(farm.userData.stakedBalance) : BIG_ZERO),
    BIG_ZERO
  )

  const totalPendingAsw = farmList.reduce(
    (total, farm) => total.add(farm.userData ? farm.userData.earnings : ETHER_BIG_ZERO),
    ETHER_BIG_ZERO
  )

  const totalStacked = farmList.reduce((total, farm) => total.plus(farm.liquidity ?? BIG_ZERO), BIG_ZERO)

  return {
    totalStakedBalance,
    totalPendingAsw,
    totalStacked,
    maxApr,
    maxLpApr,
    maxMultiplier
  }
}

export const getWrappedFarm = (farmList: FarmWithStakedValue[]) => {
  const defaultFarmLock = farmList[DEFAULT_LOCK_TIME_INDEX]

  return {
    ...defaultFarmLock,
    token: nativeWrappedAdaToken(defaultFarmLock.token),
    quoteToken: nativeWrappedAdaToken(defaultFarmLock.quoteToken)
  }
}

export const getSerializedWrappedToken = (token: PoolToken, chainId: number): SerializedWrappedToken => ({
  chainId,
  address: token.id,
  decimals: Number.parseInt(token.decimals),
  name: token.name,
  symbol: token.symbol
})
