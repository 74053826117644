import * as React from 'react'

interface Props {
  color?: string
  style?: any
}

export const LogoIcon = (props: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={54} height={28} fill="none" viewBox="0 0 54 28" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.128 1.62c3.64 3.49 11.39 3.471 15.216 0 2.015-1.827 3.488-.241 4.762.124 1.273.366 1.239.61 5.302 1.168 4.064.558 5.97.645 6.967 3.435.758 2.118 2.098 10.08 2.098 10.08l-4.508-6.589s-3.973.438-10.615 9.467c-2.613 3.552-4.916 5.06-7.42 6.65-1.059.672-2.465 1.224-3.567 1.302-1.407.099-2.616-.36-4.052-1.139-4.042-2.19-6.136-5.027-8.01-7.466C9.092 10.578 4.507 9.838 4.507 9.838L0 16.428S1.34 8.465 2.098 6.347C3.095 3.557 5 3.47 9.064 2.912c4.064-.559 4.03-.802 5.303-1.168 1.273-.365 2.791-2.012 4.761-.124z"
      fill="#61948A"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.62 2.422c3.59 3.033 11.233 3.016 15.007 0 1.986-1.588 3.44-.21 4.695.107 1.256.318 1.222.53 5.23 1.015 4.007.485 5.886.56 6.87 2.985.748 1.842 2.069 8.762 2.069 8.762l-4.446-5.727s-3.687.354-10.238 8.201c-4.799 5.615-7.918 7.624-9.74 8.301-1.064.396-2.758.39-3.723.058-1.797-.616-4.959-2.573-9.903-8.359C8.89 9.918 5.202 9.564 5.202 9.564L.757 15.29s1.321-6.92 2.068-8.762c.984-2.424 2.863-2.5 6.87-2.985 4.009-.485 3.974-.697 5.23-1.015 1.256-.317 2.753-1.749 4.696-.107z"
      fill="#fff"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.915 10.297S3.898 8.17 4.16 7.481c.262-.688.85-1.06 3.415-1.226 2.566-.166 7.113-.699 7.113-.699l-2.451 5.962S7.329 7.305 5.869 7.224c-1.459-.081-2.954 3.073-2.954 3.073z"
      fill="#000"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.262 7.357a.668.668 0 0 1 .218-.14c.59-.203 6.442.965 7.988 1.28l-1.243 3.022S7.317 7.306 5.858 7.225c-.2-.01-.4.038-.596.132z"
      fill="#264841"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.477 7.4c-.243-.103-.453-.166-.62-.175a1.235 1.235 0 0 0-.595.132.668.668 0 0 1 .218-.14c.59-.203 6.442.965 7.988 1.28l-.133.324c-1.363-.299-5.932-1.294-6.782-1.41l-.076-.01z"
      fill={props.color || '#7CE5CF'}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M51.746 10.297s-.983-2.127-1.245-2.816c-.262-.688-.85-1.06-3.416-1.226-2.565-.166-7.113-.699-7.113-.699l2.452 5.962s4.908-4.213 6.367-4.294c1.46-.081 2.955 3.073 2.955 3.073z"
      fill="#000"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M49.4 7.357a.667.667 0 0 0-.22-.14c-.589-.203-6.44.965-7.987 1.28l1.243 3.022s4.908-4.213 6.367-4.294c.2-.01.4.038.596.132z"
      fill="#264841"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M48.184 7.4c.243-.103.453-.166.62-.175.199-.01.399.038.595.132a.667.667 0 0 0-.218-.14c-.59-.203-6.442.965-7.988 1.28l.134.324c1.363-.299 5.931-1.294 6.782-1.41l.075-.01z"
      fill={props.color || '#7CE5CF'}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m22.984 5.74 1.064 6.745s-.782-1.655-1.877-2.356c-1.326-1.069-3.607-.15-5.474-1.581-.719-.393-1.077-1.07-1.253-1.713 1.116-1.888 2.168-2.272 2.168-2.272l5.372 1.176zm8.28 0 5.372-1.177s1.052.384 2.168 2.272c-.175.644-.534 1.32-1.253 1.713-1.867 1.43-4.148.512-5.474 1.58-1.094.702-1.877 2.357-1.877 2.357-.582-2.288-.32-4.54 1.065-6.746z"
      fill="#264841"
    />
    <g filter="url(#a)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.656 9.096s.581 1.084 3.004 1.145c2.422.062 3.128 2.328 3.128 2.328s-.495-.975-2.983-.72c-2.489.255-3.15-2.753-3.15-2.753zm21.014 0s-.58 1.084-3.004 1.145c-2.422.062-3.128 2.328-3.128 2.328s.495-.975 2.984-.72c2.488.255 3.148-2.753 3.148-2.753z"
        fill={props.color || '#7CE5CF'}
      />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.072 7.487c.32.532.8 1.37 1.077 2.035.44 1.057.944 2.63 1.999 2.993 1.054.364 2.58.052 3.567.93.825.733.7 1.492 1.01 1.903.313.412 2 2.2 2 2.2h4.768s1.687-1.788 1.999-2.2c.311-.411.186-1.17 1.01-1.903.988-.878 2.513-.566 3.568-.93 1.055-.363 1.559-1.936 1.998-2.993.277-.664.758-1.503 1.077-2.035l.052.105c1.253 2.548 2.17 3.849.067 7.642-1.138 1.539-4.697 5.793-7.222 7.713-1.34 1.018-2.444 1.6-3.265 1.931-1.067.431-2.341.43-3.531-.081-.798-.343-1.834-.91-3.07-1.85-2.525-1.92-6.084-6.174-7.222-7.713-2.103-3.793-1.186-5.094.066-7.642l.052-.105zm6.989-1.95 2.01.44c2.073.313 4.102.332 6.076 0l1.675-.366c-.357.614-1.03 1.851-1.164 2.646-.434 2.573-.32 6.361-.32 6.361l-1.387 1.563h-3.976l-1.387-1.563s.113-3.788-.32-6.36c-.14-.829-.864-2.136-1.207-2.72z"
      fill="#000"
    />
    <defs>
      <filter
        id="a"
        x={16.185}
        y={8.625}
        width={21.956}
        height={4.414}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation={0.235} result="effect1_foregroundBlur_228_4541" />
      </filter>
    </defs>
  </svg>
)
