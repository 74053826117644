import * as React from 'react'

interface Props {
  color?: string
}

export const MoreIcon = (props: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={6} fill="none">
    <path
      d="M15 3c0 1.68-1.38 3-3 3S9 4.62 9 3s1.38-3 3-3 3 1.38 3 3zm6-3c-1.68 0-3 1.38-3 3s1.32 3 3 3 3-1.38 3-3-1.32-3-3-3zM3 0C1.32 0 0 1.38 0 3s1.38 3 3 3 3-1.38 3-3-1.32-3-3-3z"
      fill={props.color || '#7CE5CF'}
    />
  </svg>
)
