import { ToggleButton, ToggleButtonGroup } from '@mui/material'
import { BG_DARK_BLUE, BRAND_COLOR, DARK_GREEN, DARKER_GREEN, LIGHT_GREEN } from 'constants/index'
import { useIsDarkMode } from 'state/user/hooks'

const getButtonSx = (isDarkMode: boolean) => ({
  padding: '2px 8px',
  border: 'none',
  borderRadius: '90px !important',

  fontSize: '1rem',
  lineHeight: 'normal',
  textTransform: 'capitalize',
  color: 'primary.main',
  opacity: 0.6,

  '&.Mui-selected': {
    opacity: 1,
    color: isDarkMode ? BG_DARK_BLUE : LIGHT_GREEN,
    bgcolor: `${isDarkMode ? BRAND_COLOR : DARK_GREEN} !important`
  },

  '&.Mui-disabled': {
    color: isDarkMode ? 'rgba(124, 229, 207, 0.5)' : 'rgb(28, 65, 79, 0.5)',
    border: 'none'
  }
})

export interface StatusFilterProps {
  value: string
  onChange: (event: any, newStatus: string | null) => void
  data: string[]
  disabledKey?: string
}

export function StatusFilter({ value, onChange, data, disabledKey }: StatusFilterProps) {
  const darkMode = useIsDarkMode()

  return (
    <ToggleButtonGroup
      value={value}
      onChange={onChange}
      exclusive
      sx={{
        gap: '6px',
        padding: '6px',
        borderRadius: '90px',
        bgcolor: darkMode ? 'rgba(124, 229, 207, 0.2)' : DARKER_GREEN
      }}
    >
      {data.map(item => (
        <ToggleButton key={item} value={item} sx={getButtonSx(darkMode)} disabled={disabledKey === item}>
          {item}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  )
}
