import BigNumber from 'bignumber.js'
import memoize from 'lodash/memoize'

import { BIG_TEN } from './bigNumber'

export const getFullDecimalMultiplier = memoize(
  (decimals: number): BigNumber => {
    return BIG_TEN.pow(decimals)
  }
)

/**
 * Take a formatted amount, e.g. 15 BNB and convert it to full decimal value, e.g. 15000000000000000
 */
export const getDecimalAmount = (amount: BigNumber, decimals = 18) => {
  return new BigNumber(amount).times(getFullDecimalMultiplier(decimals))
}

export const getBalanceAmount = (amount: BigNumber, decimals = 18) => {
  return new BigNumber(amount).dividedBy(getFullDecimalMultiplier(decimals))
}

/**
 * This function is not really necessary but is used throughout the site.
 */
export const getBalanceNumber = (balance: BigNumber, decimals = 18) => {
  return Number(getBalanceAmount(balance, decimals))
}

export const getFullDisplayBalance = (balance: BigNumber, decimals = 18, displayDecimals = 0): string => {
  return getBalanceAmount(balance, decimals).toFixed(displayDecimals)
}
