import 'react-toastify/dist/ReactToastify.css'

import BanMobilePopup from 'components/Popup/BanMobilePopup'
import { IMAGES } from 'constants/index'
import { Suspense } from 'react'
import { Route, Switch, useLocation } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { usePollCoreFarmData } from 'state/farm/hooks'
import styled from 'styled-components'

import GoogleAnalyticsReporter from '../components/analytics/GoogleAnalyticsReporter'
import AddressClaimModal from '../components/claim/AddressClaimModal'
import Header from '../components/Header'
import Web3ReactManager from '../components/Web3ReactManager'
import { ApplicationModal } from '../state/application/actions'
import { useModalOpen, useToggleModal } from '../state/application/hooks'
import DarkModeQueryParamReader from '../theme/DarkModeQueryParamReader'
import AddLiquidity from './AddLiquidity'
import { RedirectDuplicateTokenIds, RedirectOldAddLiquidityPathStructure } from './AddLiquidity/redirects'
import Farm from './Farm'
import HistoryFeature from './History'
import MigrateLP from './MigrateLp'
import Pool from './Pool'
import PoolFinder from './PoolFinder'
import RemoveLiquidity from './RemoveLiquidity'
import { RedirectOldRemoveLiquidityPathStructure } from './RemoveLiquidity/redirects'
import Swap from './Swap'
import { RedirectPathToSwapOnly, RedirectToSwap } from './Swap/redirects'

declare module '@mui/material/styles' {
  interface Theme {
    isDarkTheme?: boolean
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    isDarkTheme?: boolean
  }
}

const AppWrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  overflow-x: hidden;
  padding-bottom: 5rem;
`

const HeaderWrapper = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  width: 100%;
  justify-content: space-between;
  position: fixed;
  top: 0;
  z-index: 2;
`

const BodyWrapper = styled.div<{ noPaddingTop: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: ${({ noPaddingTop }) => (noPaddingTop ? '0 !important' : '8rem')};
  align-items: center;
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 1;
  background-color: ${({ theme }) => theme.bg0};

  ${({ theme }) => theme.mediaWidth.upToMedium`
    padding-top: 10rem;
  `};
`

function TopLevelModals() {
  const open = useModalOpen(ApplicationModal.ADDRESS_CLAIM)
  const toggle = useToggleModal(ApplicationModal.ADDRESS_CLAIM)
  return <AddressClaimModal isOpen={open} onDismiss={toggle} />
}

export default function App() {
  usePollCoreFarmData()
  const { pathname } = useLocation()
  const noPaddingTop = ['/pool', '/farm'].includes(pathname)

  return (
    <Suspense fallback={null}>
      <Route component={GoogleAnalyticsReporter} />
      <Route component={DarkModeQueryParamReader} />
      <AppWrapper>
        {/* <URLWarning /> */}
        <HeaderWrapper>
          <Header />
        </HeaderWrapper>
        <BodyWrapper noPaddingTop={noPaddingTop}>
          {/* <Popups /> */}
          {/* <Polling /> */}
          <TopLevelModals />
          <Web3ReactManager>
            <Switch>
              <Route exact strict path="/swap" component={Swap} />
              {/* <Route exact strict path="/claim" component={OpenClaimAddressModalAndRedirectToSwap} /> */}
              <Route exact strict path="/swap/:outputCurrency" component={RedirectToSwap} />
              <Route exact strict path="/send" component={RedirectPathToSwapOnly} />
              <Route exact strict path="/find" component={PoolFinder} />
              <Route exact strict path="/pool" component={Pool} />
              <Route exact strict path="/farm" component={Farm} />
              {/* <Route exact strict path="/uni" component={Earn} /> */}
              {/* <Route exact strict path="/vote" component={Vote} /> */}
              {/* <Route exact strict path="/create" component={RedirectToAddLiquidity} /> */}
              <Route exact path="/add" component={AddLiquidity} />
              <Route exact path="/add/:currencyIdA" component={RedirectOldAddLiquidityPathStructure} />
              <Route exact path="/add/:currencyIdA/:currencyIdB" component={RedirectDuplicateTokenIds} />
              {/* <Route exact path="/create" component={AddLiquidity} />
              <Route exact path="/create/:currencyIdA" component={RedirectOldAddLiquidityPathStructure} />
              <Route exact path="/create/:currencyIdA/:currencyIdB" component={RedirectDuplicateTokenIds} /> */}
              <Route exact strict path="/remove/:tokens" component={RedirectOldRemoveLiquidityPathStructure} />
              <Route exact strict path="/remove/:currencyIdA/:currencyIdB" component={RemoveLiquidity} />
              <Route exact path="/history" component={HistoryFeature} />
              <Route exact path="/migrate-lp" component={MigrateLP} />
              {/* <Route exact strict path="/uni/:currencyIdA/:currencyIdB" component={Manage} /> */}
              {/* <Route exact strict path="/vote/:id" component={VotePage} /> */}
              <Route component={RedirectPathToSwapOnly} />
            </Switch>
          </Web3ReactManager>
          <ToastContainer
            theme="colored"
            position="bottom-right"
            limit={1}
            icon={<img src={IMAGES.TOAST_LOGO} width="40px" height="40px" />}
          />
        </BodyWrapper>
      </AppWrapper>
      <BanMobilePopup />
    </Suspense>
  )
}
