import { parse } from 'qs'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom'
import { updateUserDarkMode } from 'state/user/actions'

import { AppDispatch } from '../state'

export default function DarkModeQueryParamReader({ location: { search } }: RouteComponentProps): null {
  const dispatch = useDispatch<AppDispatch>()

  useEffect(() => {
    if (!search) return
    if (search.length < 2) return

    const parsed = parse(search, {
      parseArrays: false,
      ignoreQueryPrefix: true
    })

    const theme = parsed.dm // '1' or '0'
    if (typeof theme !== 'string' || !['1', '0'].includes(theme)) return
    dispatch(updateUserDarkMode({ userDarkMode: !!+theme }))
  }, [dispatch, search])

  return null
}
