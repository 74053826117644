import React, { useEffect } from 'react'
import { getRoi } from 'utils/compoundApyHelpers'

import { CalculatorMode, RoiCalculatorReducerState } from './useFarmCalculatorReducer'

export function LockedRoiStrategy({
  apr,
  state,
  dispatch,
  earningTokenPrice,
  stakingTokenPrice,
  duration
}: {
  apr: number
  state: RoiCalculatorReducerState
  dispatch: React.Dispatch<{ type: string; payload?: any }>
  earningTokenPrice: number
  stakingTokenPrice: number
  duration: number
}) {
  const { principalAsUSD, roiUSD } = state.data
  const { compounding, compoundingFrequency, stakingDuration, mode } = state.controls

  useEffect(() => {
    if (mode === CalculatorMode.ROI_BASED_ON_PRINCIPAL) {
      const principalInUSDAsNumber = parseFloat(principalAsUSD)
      const interest = earningTokenPrice
        ? (principalInUSDAsNumber / earningTokenPrice) * (apr / 100) * (duration / 365)
        : 0

      const hasInterest = !Number.isNaN(interest)
      const roiTokens = hasInterest ? interest : 0
      const roiAsUSD = hasInterest ? roiTokens * earningTokenPrice : 0
      const roiPercentage = hasInterest
        ? getRoi({
            amountEarned: roiAsUSD,
            amountInvested: principalInUSDAsNumber
          })
        : 0
      dispatch({ type: 'setRoi', payload: { roiUSD: roiAsUSD, roiTokens, roiPercentage } })
    }
  }, [principalAsUSD, stakingDuration, earningTokenPrice, compounding, compoundingFrequency, mode, duration, dispatch])

  useEffect(() => {
    if (mode === CalculatorMode.PRINCIPAL_BASED_ON_ROI) {
      const principalUSD = apr && duration ? roiUSD / (apr / 100) / (duration / 365) : 0
      const roiPercentage = getRoi({
        amountEarned: roiUSD,
        amountInvested: principalUSD
      })
      const principalToken = stakingTokenPrice ? principalUSD / stakingTokenPrice : 0
      dispatch({
        type: 'setPrincipalForTargetRoi',
        payload: {
          principalAsUSD: principalUSD.toFixed(2),
          principalAsToken: principalToken.toFixed(10),
          roiPercentage
        }
      })
    }
  }, [dispatch, duration, mode, roiUSD, stakingTokenPrice])

  return null
}
