import { ChainId } from '@adaswap/sdk'
import { Button } from '@mui/material'
import { useWeb3React } from '@web3-react/core'
import { BRAND_COLOR_LIGHT, DARK_GREEN, LIGHT_GREEN } from 'constants/index'
import useAddTokenToMetamask from 'hooks/useAddTokenToMetamask'
import { useFaucetContract } from 'hooks/useContract'
import React, { useEffect, useState } from 'react'
import { useDefaultTokenList } from 'state/lists/hooks'
import { useIsDarkMode } from 'state/user/hooks'

import FaucetWizard from './FaucetWizard'
import StepWizard from './StepWizard'

function FundWallet() {
  const faucet = useFaucetContract()
  const [isInProgress, setIsInProgress] = useState(false)
  const { account } = useWeb3React()

  const defaultTokenList = useDefaultTokenList()
  const tokenAddressList = defaultTokenList[ChainId.TESTNET]
  const tokenList = Object.values(tokenAddressList)
    .map(({ token }) => token)
    .filter(({ symbol }) => symbol !== 'WADA')

  const addTokenList = [
    useAddTokenToMetamask(undefined, tokenList[0]),
    useAddTokenToMetamask(undefined, tokenList[1]),
    useAddTokenToMetamask(undefined, tokenList[2]),
    useAddTokenToMetamask(undefined, tokenList[3]),
    useAddTokenToMetamask(undefined, tokenList[4]),
    useAddTokenToMetamask(undefined, tokenList[5]),
    useAddTokenToMetamask(undefined, tokenList[6])
  ]

  const [stepOpen, setStepOpen] = useState(false)
  const [faucetOpen, setFaucetOpen] = useState(false)
  const [lockTime, setLockTime] = useState(0)

  const handleNext = () => {
    setStepOpen(false)
    setFaucetOpen(true)
  }

  useEffect(() => {
    if (!faucet || !account) return

    setFaucetOpen(false)
    ;(async () => {
      try {
        const timeBigNumber = await faucet.lockTime(account)
        const timeNumber = Number(timeBigNumber.toString())

        setLockTime(timeNumber * 1000)
      } catch (error) {
        console.error('Failed to get lock time', error)
      }
    })()
  }, [faucet, account])

  const handleFaucet = async () => {
    if (!faucet) return
    setIsInProgress(true)

    try {
      await faucet.faucets()
      // toast.info('Faucet request successfully')
      setLockTime(Date.now() + 1000 * 60 * 60)

      addTokenList.forEach(({ addToken }) => addToken())
    } catch (error) {
      console.error('Failed to faucet', error)
    }

    setIsInProgress(false)
    setFaucetOpen(false)
  }

  const handleOpenStep = () => setStepOpen(true)
  const handleCloseStep = () => setStepOpen(false)
  const handleCloseFaucet = () => setFaucetOpen(false)
  const darkMode = useIsDarkMode()

  return (
    <React.Fragment>
      <Button
        variant="outlined"
        onClick={handleOpenStep}
        sx={{
          minHeight: 44,
          fontSize: '0.875rem',
          color: 'primary.main',
          borderColor: 'primary.main',
          textTransform: 'capitalize',
          borderRadius: '90px',
          lineHeight: 'normal',
          whiteSpace: 'nowrap',
          mr: 2,
          bgcolor: 'transparent',
          display: { xs: 'none', md: 'flex' },
          ...(darkMode
            ? { ':hover': { borderColor: BRAND_COLOR_LIGHT } }
            : { ':hover': { color: LIGHT_GREEN, backgroundColor: DARK_GREEN } })
        }}
      >
        Fund your wallet
      </Button>

      <StepWizard open={stepOpen} onClose={handleCloseStep} onNext={handleNext} />

      <FaucetWizard
        open={faucetOpen}
        onClose={handleCloseFaucet}
        onFaucet={handleFaucet}
        lockTime={lockTime}
        isInProgress={isInProgress}
      />
    </React.Fragment>
  )
}

export default FundWallet
