import { Stack, styled, TextField, ToggleButton, Typography } from '@mui/material'
import { BG_DARK_BLUE, BG_DARK_GREEN, DARK_BLUE, DARKER_GREEN } from 'constants/index'
import { ChangeEvent, InputHTMLAttributes, useState } from 'react'
import { useIsDarkMode } from 'state/user/hooks'

const StyledToggleButton = styled(ToggleButton)({
  padding: '4px 16px',
  border: '1px solid #7ce5cf !important',
  borderRadius: '90px !important',
  transition: 'all 200ms ease-in-out',

  color: '#7ce5cf',
  fontWeight: 600,
  fontSize: '1rem',
  textTransform: 'none',
  lineHeight: 'normal',

  '&.Mui-selected': {
    backgroundColor: '#7ce5cf !important',
    color: '#091d28'
  }
})

const getStyles = (isDarkTheme: boolean) => ({
  wrapper: {
    p: '8px 20px',
    border: 'solid 1px transparent',
    borderRadius: 4,
    bgcolor: isDarkTheme ? BG_DARK_GREEN : DARKER_GREEN,
    transition: 'all 200ms ease-in-out',

    ':hover': {
      borderColor: 'primary.light'
    },

    '& > span': {
      minWidth: 'max-content',
      fontSize: { xs: '1rem', sm: '1.25rem' },
      '&:last-of-type': { color: isDarkTheme ? 'primary.light' : BG_DARK_BLUE }
    }
  },

  input: {
    '.MuiOutlinedInput-input': {
      py: { xs: '0.75rem', sm: '1.125rem' },
      fontSize: { xs: '1.125rem', sm: '1.5rem' },
      fontWeight: 500,
      color: isDarkTheme ? 'primary.light' : DARK_BLUE,
      textAlign: 'right'
    },

    '.MuiOutlinedInput-notchedOutline': {
      border: ' none'
    }
  },

  buttonGroup: {
    display: 'flex',
    gap: '8px',
    justifyContent: 'flex-end',
    mt: 3
  }
})

export interface PercentInputFieldProps {
  label?: string
  value: string
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
  onSelectPercent?: (event: any, newPercent: string | null) => void
}

export function PercentInputField({
  label = 'Amount to remove',
  value,
  onChange,
  onSelectPercent
}: PercentInputFieldProps) {
  const [isFocus, setIsFocus] = useState(false)

  const inputProps: InputHTMLAttributes<HTMLInputElement> = {
    placeholder: '100',
    inputMode: 'decimal',
    autoCorrect: 'off',
    spellCheck: 'false',
    maxLength: 3,
    pattern: '^[0-9]*$'
  }

  const darkMode = useIsDarkMode()
  const styles = getStyles(darkMode)
  return (
    <Stack
      direction="row"
      alignItems="center"
      sx={{ ...styles.wrapper, borderColor: isFocus ? 'primary.light' : 'transparent' }}
    >
      <Typography component="span">{label}</Typography>

      <TextField
        fullWidth
        value={value}
        onChange={onChange}
        onFocus={() => setIsFocus(true)}
        onBlur={() => setIsFocus(false)}
        inputProps={inputProps}
        InputProps={{ autoComplete: 'off', sx: styles.input }}
      />

      <Typography component="span">%</Typography>
    </Stack>
  )
}
