import { Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import { BRAND_COLOR, BRAND_COLOR_LIGHT, DARK_BLUE, DARK_GREEN, MIDDLE_GREEN } from 'constants/index'
import React from 'react'
import { useIsDarkMode } from 'state/user/hooks'
import { CheckCircle, UncheckCircle } from 'svgComponents'

export interface StepItemProps {
  stepNum: number
  name: string
  isChecked?: boolean
  disabled?: boolean
  reload?: boolean
  onClick?: () => void
}

function StepItem({ stepNum, name, isChecked = false, disabled = false, reload = false, onClick }: StepItemProps) {
  const handleClick = () => {
    if (disabled || isChecked || !onClick) return
    onClick()
  }

  const theme = useTheme()
  const isDownSm = useMediaQuery(theme.breakpoints.down('sm'))
  const darkMode = useIsDarkMode()
  return (
    <Stack spacing={2} alignItems="center" mb={5}>
      <Typography
        component="span"
        sx={{
          fontSize: { xs: '0.875rem', sm: '1.25rem' },
          color: darkMode ? '#fff' : DARK_BLUE,
          fontWeight: 'normal'
        }}
      >
        Step {stepNum}
      </Typography>

      <Stack
        direction="row"
        onClick={handleClick}
        sx={{
          width: 1,
          minHeight: { xs: 64, sm: 90 },
          alignItems: 'center',
          justifyContent: 'space-between',
          p: '4px 20px',
          border: 1,
          borderColor: darkMode ? 'primary.light' : 'primary.main',
          borderRadius: 4,
          cursor: disabled || isChecked ? 'auto' : 'pointer',
          transition: 'all 300ms ease-in-out',

          ':hover': {
            boxShadow: disabled || isChecked ? 'none' : `0 0 12px 0 ${darkMode ? BRAND_COLOR_LIGHT : MIDDLE_GREEN}`
          }
        }}
      >
        <Stack>
          <Typography
            component="span"
            sx={{
              fontSize: { xs: '1rem', sm: '1.5rem' },
              color: darkMode ? 'primary.light' : DARK_BLUE
            }}
          >
            {name}
          </Typography>

          {!isChecked && reload && (
            <Typography
              component="span"
              sx={{
                fontSize: { xs: '0.75rem', sm: '0.875rem' },
                color: darkMode ? '#fff' : DARK_BLUE,
                fontStyle: 'italic'
              }}
            >
              *Need to reload after install
            </Typography>
          )}
        </Stack>

        {isChecked && (
          <CheckCircle
            color={darkMode ? BRAND_COLOR : DARK_GREEN}
            style={{
              width: isDownSm ? 24 : 32
            }}
          />
        )}
        {!isChecked && (
          <UncheckCircle
            color={darkMode ? BRAND_COLOR : DARK_GREEN}
            style={{
              width: isDownSm ? 24 : 32
            }}
          />
        )}
      </Stack>
    </Stack>
  )
}

export default StepItem
