/* eslint-disable @typescript-eslint/no-unused-vars */
import { Components, Theme } from '@mui/material'

import { ComponentParams } from '../index'

const MuiLink = ({ globalTheme, isDarkTheme }: ComponentParams): Components<Theme>['MuiLink'] => ({
  defaultProps: {
    underline: 'none',
    fontSize: '0.875rem'
  }
})

export default MuiLink
