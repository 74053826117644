import { Pool } from './pool'

export interface Transaction {
  id: string
  timestamp: string
}

export interface CommonTransaction {
  id: string
  transaction: Transaction
  pair: Pool
  to: string
}

export interface Mints extends CommonTransaction {
  liquidity: string
  amount0: string
  amount1: string
  amountUSD: string
}

export interface Burns extends CommonTransaction {
  sender: string
  liquidity: string
  amount0: string
  amount1: string
  amountUSD: string
}

export interface Swaps extends CommonTransaction {
  amount0In: string
  amount0Out: string
  amount1In: string
  amount1Out: string
  amountUSD: string
}

export interface UserTransaction {
  mints: Array<Mints>
  burns: Array<Burns>
  swaps: Array<Swaps>
}

export enum TransactionType {
  ALL = 'All',
  SWAP = 'Swap',
  ADD = 'Add',
  REMOVE = 'Remove'
}

export interface Txn {
  hash: string
  timestamp: string
  type: TransactionType
  token0Amount: string
  token1Amount: string
  account: string
  token0Symbol: string
  token1Symbol: string
  amountUSD: string
}
