import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { IconButton, Stack, Tooltip, Typography } from '@mui/material'

const CONTENT_LIST = [
  { name: 'Rewards in ASW', value: '105%' },
  { name: 'Earning from Fees', value: '4%' }
]

const CustomTooltip = () => (
  <Stack spacing={2} minWidth={200}>
    {CONTENT_LIST.map(({ name, value }) => (
      <Stack key={name} direction="row" justifyContent="space-between" alignItems="center">
        <Typography className="tooltip-text" fontSize="0.875rem">
          {name}
        </Typography>
        <Typography className="tooltip-text" fontWeight="bold">
          {value}
        </Typography>
      </Stack>
    ))}
  </Stack>
)

function PoolTooltip() {
  return (
    <Tooltip title={<CustomTooltip />} arrow placement="top-start">
      <IconButton>
        <InfoOutlinedIcon color="primary" sx={{ fontSize: { xs: 20, lg: 24 } }} />
      </IconButton>
    </Tooltip>
  )
}

export default PoolTooltip
