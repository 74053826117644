import { useWeb3React } from '@web3-react/core'
import accountApi from 'api/accountApi'
import { TOKENS } from 'constants/index'
import ENV_VARS from 'env'
import { Token, TokenType } from 'models'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'

export function useGetToken(typeToken: TokenType, address = ''): Token[] {
  const { account: userAddress } = useWeb3React()
  const [tokens, setTokens] = useState<Token[]>([])
  const url = `${ENV_VARS.explorerScan}/api`
  if (!url) throw Error('Error when get token of user, url invalid!')

  useEffect(() => {
    if (!address && !userAddress) {
      setTokens([])
      return
    }
    ;(async () => {
      try {
        const { result } = await accountApi.getTokenList(address ? address : userAddress + '')
        if (!result || !result.length) {
          setTokens([])
          return
        }

        const filteredToken = result.filter(token =>
          typeToken === TokenType.ALP ? token.symbol === 'ALP' : token.symbol !== 'ALP'
        )

        setTokens(
          filteredToken.map(item => ({
            label: item.name,
            symbol: item.symbol,
            address: item.contractAddress.toLowerCase(),
            icon: TOKENS.ADA,
            balance: item.balance ? +item.balance : 0
          }))
        )
      } catch (error) {
        toast.error('Error when get user token')
      }
    })()
  }, [address, userAddress])

  return tokens
}
