import { currencyEquals, ETHER, JSBI, Percent, WETH } from '@adaswap/sdk'
import { BigNumber } from '@ethersproject/bignumber'
import { splitSignature } from '@ethersproject/bytes'
import { Contract } from '@ethersproject/contracts'
import { TransactionResponse } from '@ethersproject/providers'
import { Button, Divider, Stack } from '@mui/material'
import { PercentInputField } from 'components/form-fields'
import { ChangeEvent, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import ReactGA from 'react-ga'
import { RouteComponentProps } from 'react-router'
import { toast } from 'react-toastify'
import { Text } from 'rebass'
import { useTokenBalance } from 'state/wallet/hooks'
import { ThemeContext } from 'styled-components'

import { ButtonPrimary } from '../../components/Button'
import { OutlineCard } from '../../components/Card'
import { AutoColumn } from '../../components/Column'
import CurrencyLogo from '../../components/CurrencyLogo'
import DoubleCurrencyLogo from '../../components/DoubleLogo'
import { AddRemoveTabs } from '../../components/NavigationTabs'
import { RowBetween, RowFixed } from '../../components/Row'
import TransactionConfirmationModal, { ConfirmationModalContent } from '../../components/TransactionConfirmationModal'
import { ROUTER_ADDRESS } from '../../constants'
import { useActiveWeb3React } from '../../hooks'
import { useCurrency } from '../../hooks/Tokens'
import { ApprovalState, useApproveCallback } from '../../hooks/useApproveCallback'
import { usePairContract } from '../../hooks/useContract'
import useIsArgentWallet from '../../hooks/useIsArgentWallet'
import useTransactionDeadline from '../../hooks/useTransactionDeadline'
import { useWalletModalToggle } from '../../state/application/hooks'
import { Field } from '../../state/burn/actions'
import { useBurnActionHandlers, useBurnState, useDerivedBurnInfo } from '../../state/burn/hooks'
import { useTransactionAdder } from '../../state/transactions/hooks'
import { useUserSlippageTolerance } from '../../state/user/hooks'
import { TYPE } from '../../theme'
import { calculateGasMargin, calculateSlippageAmount, getRouterContract } from '../../utils'
import useDebouncedChangeHandler from '../../utils/useDebouncedChangeHandler'
import { wrappedCurrency } from '../../utils/wrappedCurrency'
import { BackgroundGrid, MaxButton, Wrapper } from '../PoolUni/styleds'
import { RemoveLiquidityInfo } from './RemoveLiquidityInfo'

export default function RemoveLiquidity({
  history,
  match: {
    params: { currencyIdA, currencyIdB }
  }
}: RouteComponentProps<{ currencyIdA: string; currencyIdB: string }>) {
  const [currencyA, currencyB] = [useCurrency(currencyIdA) ?? undefined, useCurrency(currencyIdB) ?? undefined]
  const { account, chainId, library } = useActiveWeb3React()
  const [tokenA, tokenB] = useMemo(() => [wrappedCurrency(currencyA, chainId), wrappedCurrency(currencyB, chainId)], [
    currencyA,
    currencyB,
    chainId
  ])

  const theme = useContext(ThemeContext)

  // toggle wallet when disconnected
  const toggleWalletModal = useWalletModalToggle()

  // burn state
  const { independentField, typedValue } = useBurnState()
  const { pair, parsedAmounts, error } = useDerivedBurnInfo(currencyA ?? undefined, currencyB ?? undefined)
  const { onUserInput: _onUserInput } = useBurnActionHandlers()
  const isValid = !error

  // modal and loading
  const [showPercent, setShowPercent] = useState<boolean>(false)
  const [showConfirm, setShowConfirm] = useState<boolean>(false)
  const [showDetailed, setShowDetailed] = useState<boolean>(false)
  const [attemptingTxn, setAttemptingTxn] = useState(false) // clicked confirm

  // txn values
  const [txHash, setTxHash] = useState<string>('')
  const deadline = useTransactionDeadline()
  const [allowedSlippage] = useUserSlippageTolerance()

  useEffect(() => {
    onUserInput(Field.LIQUIDITY_PERCENT, '0')
  }, [])

  const formattedAmounts = {
    [Field.LIQUIDITY_PERCENT]: parsedAmounts[Field.LIQUIDITY_PERCENT].equalTo('0')
      ? '0'
      : parsedAmounts[Field.LIQUIDITY_PERCENT].lessThan(new Percent('1', '100'))
      ? '<1'
      : parsedAmounts[Field.LIQUIDITY_PERCENT].toFixed(0),
    [Field.LIQUIDITY]:
      independentField === Field.LIQUIDITY ? typedValue : parsedAmounts[Field.LIQUIDITY]?.toSignificant(6) ?? '',
    [Field.CURRENCY_A]:
      independentField === Field.CURRENCY_A ? typedValue : parsedAmounts[Field.CURRENCY_A]?.toSignificant(6) ?? '',
    [Field.CURRENCY_B]:
      independentField === Field.CURRENCY_B ? typedValue : parsedAmounts[Field.CURRENCY_B]?.toSignificant(6) ?? ''
  }

  const atMaxAmount = parsedAmounts[Field.LIQUIDITY_PERCENT]?.equalTo(new Percent('1'))

  // pair contract
  const pairContract: Contract | null = usePairContract(pair?.liquidityToken?.address)

  // allowance handling
  const [signatureData, setSignatureData] = useState<{ v: number; r: string; s: string; deadline: number } | null>(null)
  const [approval, approveCallback] = useApproveCallback(parsedAmounts[Field.LIQUIDITY], ROUTER_ADDRESS)

  const userPoolBalance = useTokenBalance(account ?? undefined, pair?.liquidityToken)

  const isArgentWallet = useIsArgentWallet()

  async function onAttemptToApprove() {
    if (!pairContract || !pair || !library || !deadline) throw new Error('missing dependencies')
    const liquidityAmount = parsedAmounts[Field.LIQUIDITY]
    if (!liquidityAmount) throw new Error('missing liquidity amount')

    if (isArgentWallet) {
      return approveCallback()
    }

    // try to gather a signature for permission
    const nonce = await pairContract.nonces(account)

    const EIP712Domain = [
      { name: 'name', type: 'string' },
      { name: 'version', type: 'string' },
      { name: 'chainId', type: 'uint256' },
      { name: 'verifyingContract', type: 'address' }
    ]
    const domain = {
      name: 'Adaswap LP Token',
      version: '1',
      chainId: chainId,
      verifyingContract: pair.liquidityToken.address
    }
    const Permit = [
      { name: 'owner', type: 'address' },
      { name: 'spender', type: 'address' },
      { name: 'value', type: 'uint256' },
      { name: 'nonce', type: 'uint256' },
      { name: 'deadline', type: 'uint256' }
    ]
    const message = {
      owner: account,
      spender: ROUTER_ADDRESS,
      value: liquidityAmount.raw.toString(),
      nonce: nonce.toHexString(),
      deadline: deadline.toNumber()
    }
    const data = JSON.stringify({
      types: {
        EIP712Domain,
        Permit
      },
      domain,
      primaryType: 'Permit',
      message
    })

    library
      .send('eth_signTypedData_v4', [account, data])
      .then(splitSignature)
      .then(signature => {
        setSignatureData({
          v: signature.v,
          r: signature.r,
          s: signature.s,
          deadline: deadline.toNumber()
        })
      })
      .catch(error => {
        // for all errors other than 4001 (EIP-1193 user rejected request), fall back to manual approve
        if (error?.code !== 4001) {
          approveCallback()
        }
      })
  }

  // wrapped onUserInput to clear signatures
  const onUserInput = useCallback(
    (field: Field, typedValue: string) => {
      setSignatureData(null)
      return _onUserInput(field, typedValue)
    },
    [_onUserInput]
  )

  const onLiquidityInput = useCallback((typedValue: string): void => onUserInput(Field.LIQUIDITY, typedValue), [
    onUserInput
  ])
  // const onCurrencyAInput = useCallback((typedValue: string): void => onUserInput(Field.CURRENCY_A, typedValue), [
  //   onUserInput
  // ])
  // const onCurrencyBInput = useCallback((typedValue: string): void => onUserInput(Field.CURRENCY_B, typedValue), [
  //   onUserInput
  // ])

  // tx sending
  const addTransaction = useTransactionAdder()
  async function onRemove() {
    if (!chainId || !library || !account || !deadline) throw new Error('missing dependencies')
    const { [Field.CURRENCY_A]: currencyAmountA, [Field.CURRENCY_B]: currencyAmountB } = parsedAmounts
    if (!currencyAmountA || !currencyAmountB) {
      throw new Error('missing currency amounts')
    }
    const router = getRouterContract(chainId, library, account)

    const amountsMin = {
      [Field.CURRENCY_A]: calculateSlippageAmount(currencyAmountA, allowedSlippage)[0],
      [Field.CURRENCY_B]: calculateSlippageAmount(currencyAmountB, allowedSlippage)[0]
    }

    if (!currencyA || !currencyB) throw new Error('missing tokens')
    const liquidityAmount = parsedAmounts[Field.LIQUIDITY]
    if (!liquidityAmount) throw new Error('missing liquidity amount')

    const currencyBIsETH = currencyB === ETHER
    const oneCurrencyIsETH = currencyA === ETHER || currencyBIsETH

    if (!tokenA || !tokenB) throw new Error('could not wrap')

    let methodNames: string[], args: Array<string | string[] | number | boolean>
    // we have approval, use normal remove liquidity
    if (approval === ApprovalState.APPROVED) {
      // removeLiquidityETH
      if (oneCurrencyIsETH) {
        methodNames = ['removeLiquidityETH', 'removeLiquidityETHSupportingFeeOnTransferTokens']
        args = [
          currencyBIsETH ? tokenA.address : tokenB.address,
          liquidityAmount.raw.toString(),
          amountsMin[currencyBIsETH ? Field.CURRENCY_A : Field.CURRENCY_B].toString(),
          amountsMin[currencyBIsETH ? Field.CURRENCY_B : Field.CURRENCY_A].toString(),
          account,
          deadline.toHexString()
        ]
      }
      // removeLiquidity
      else {
        methodNames = ['removeLiquidity']
        args = [
          tokenA.address,
          tokenB.address,
          liquidityAmount.raw.toString(),
          amountsMin[Field.CURRENCY_A].toString(),
          amountsMin[Field.CURRENCY_B].toString(),
          account,
          deadline.toHexString()
        ]
      }
    }
    // we have a signataure, use permit versions of remove liquidity
    else if (signatureData !== null) {
      // removeLiquidityETHWithPermit
      if (oneCurrencyIsETH) {
        methodNames = ['removeLiquidityETHWithPermit', 'removeLiquidityETHWithPermitSupportingFeeOnTransferTokens']
        args = [
          currencyBIsETH ? tokenA.address : tokenB.address,
          liquidityAmount.raw.toString(),
          amountsMin[currencyBIsETH ? Field.CURRENCY_A : Field.CURRENCY_B].toString(),
          amountsMin[currencyBIsETH ? Field.CURRENCY_B : Field.CURRENCY_A].toString(),
          account,
          signatureData.deadline,
          false,
          signatureData.v,
          signatureData.r,
          signatureData.s
        ]
      }
      // removeLiquidityETHWithPermit
      else {
        methodNames = ['removeLiquidityWithPermit']
        args = [
          tokenA.address,
          tokenB.address,
          liquidityAmount.raw.toString(),
          amountsMin[Field.CURRENCY_A].toString(),
          amountsMin[Field.CURRENCY_B].toString(),
          account,
          signatureData.deadline,
          false,
          signatureData.v,
          signatureData.r,
          signatureData.s
        ]
      }
    } else {
      throw new Error('Attempting to confirm without approval or a signature. Please contact support.')
    }

    const safeGasEstimates: (BigNumber | undefined)[] = await Promise.all(
      methodNames.map(methodName =>
        router.estimateGas[methodName](...args)
          .then(calculateGasMargin)
          .catch(error => {
            console.error(`estimateGas failed`, methodName, args, error)
            return undefined
          })
      )
    )

    const indexOfSuccessfulEstimation = safeGasEstimates.findIndex(safeGasEstimate =>
      BigNumber.isBigNumber(safeGasEstimate)
    )

    // all estimations failed...
    if (indexOfSuccessfulEstimation === -1) {
      console.error('This transaction would fail. Please contact support.')
    } else {
      const methodName = methodNames[indexOfSuccessfulEstimation]
      const safeGasEstimate = safeGasEstimates[indexOfSuccessfulEstimation]

      setAttemptingTxn(true)
      await router[methodName](...args, {
        gasLimit: safeGasEstimate
      })
        .then((response: TransactionResponse) => {
          setAttemptingTxn(false)

          addTransaction(response, {
            summary:
              'Remove ' +
              parsedAmounts[Field.CURRENCY_A]?.toSignificant(3) +
              ' ' +
              currencyA?.symbol +
              ' and ' +
              parsedAmounts[Field.CURRENCY_B]?.toSignificant(3) +
              ' ' +
              currencyB?.symbol
          })

          setTxHash(response.hash)
          setShowPercent(false)

          ReactGA.event({
            category: 'Liquidity',
            action: 'Remove',
            label: [currencyA?.symbol, currencyB?.symbol].join('/')
          })
        })
        .catch((error: any) => {
          setAttemptingTxn(false)
          // we only care if the error is something _other_ than the user rejected the tx
          if (error?.code === 4001) {
            toast.error('Transaction rejected.')
            return
          }

          if (error?.code === -32603) {
            setShowConfirm(false)
            toast.error(
              'Please choose a higher slippage tolerance, currently the gas price is below configured minimum gas price'
            )
          } else {
            toast.error(`Failed to remove liquidity: ${error?.message}`)
          }

          console.error(error)
        })
    }
  }

  const receiveWrapper = () => (
    <AutoColumn gap="24px">
      <Text fontSize={20} fontWeight={500} color={theme.primary0}>
        You will receive
      </Text>

      <OutlineCard>
        <AutoColumn gap="40px">
          <RowBetween>
            <Text
              fontWeight={500}
              fontSize={20}
              color={theme.primary0}
              flexShrink={0}
              id="remove-liquidity-tokena-symbol"
            >
              Received {currencyA?.symbol}
            </Text>
            <Stack direction="row" spacing={1.5} alignItems="center" pl={5} overflow="hidden">
              <Text
                fontWeight={500}
                fontSize={20}
                sx={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis'
                }}
              >
                {formattedAmounts[Field.CURRENCY_A] || '0.0000'}
              </Text>
              <CurrencyLogo size="28px" currency={currencyA} />
            </Stack>
          </RowBetween>
          <RowBetween>
            <Text
              fontWeight={500}
              fontSize={20}
              color={theme.primary0}
              flexShrink={0}
              id="remove-liquidity-tokenb-symbol"
            >
              Received {currencyB?.symbol}
            </Text>
            <Stack direction="row" spacing={1.5} alignItems="center" pl={5} overflow="hidden">
              <Text
                fontWeight={500}
                fontSize={20}
                sx={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis'
                }}
              >
                {formattedAmounts[Field.CURRENCY_B] || '0.0000'}
              </Text>
              <CurrencyLogo size="28px" currency={currencyB} />
            </Stack>
          </RowBetween>
        </AutoColumn>
      </OutlineCard>
    </AutoColumn>
  )

  function modalHeader() {
    return (
      <AutoColumn gap={'md'} style={{ marginTop: '20px' }}>
        {receiveWrapper()}

        {/* <TYPE.primary2 fontSize={14} fontWeight={400} textAlign="center">
          {`Output is estimated. If the price changes by more than ${allowedSlippage /
            100}% your transaction will revert.`}
        </TYPE.primary2> */}
      </AutoColumn>
    )
  }

  function modalBottom() {
    return (
      <>
        {pair && (
          <>
            <RowBetween>
              <Text color={theme.text2} fontWeight={500} fontSize={16}>
                Rates
              </Text>
              <Text fontWeight={500} fontSize={16} color={theme.text1}>
                1 {currencyA?.symbol} = {tokenA ? pair.priceOf(tokenA).toSignificant(6) : '-'} {currencyB?.symbol}
              </Text>
            </RowBetween>
            <RowBetween>
              <div />
              <Text fontWeight={500} fontSize={16} color={theme.text1}>
                1 {currencyB?.symbol} = {tokenB ? pair.priceOf(tokenB).toSignificant(6) : '-'} {currencyA?.symbol}
              </Text>
            </RowBetween>
          </>
        )}

        <Divider sx={{ borderColor: theme.primary0, my: 2 }} />

        <RowBetween>
          <Text color={theme.text2} fontWeight={500} fontSize={16}>
            {currencyA?.symbol + '/' + currencyB?.symbol} Burned
          </Text>
          <RowFixed>
            <DoubleCurrencyLogo currency0={currencyA} currency1={currencyB} margin={true} />
            <Text fontWeight={500} fontSize={16}>
              {parsedAmounts[Field.LIQUIDITY]?.toSignificant(6)}
            </Text>
          </RowFixed>
        </RowBetween>

        <ButtonPrimary
          disabled={!(approval === ApprovalState.APPROVED || signatureData !== null)}
          onClick={onRemove}
          mt={40}
        >
          <Text fontWeight={500} fontSize={20}>
            CONFIRM REMOVE
          </Text>
        </ButtonPrimary>
      </>
    )
  }

  const pendingText = `Removing ${parsedAmounts[Field.CURRENCY_A]?.toSignificant(6)} ${
    currencyA?.symbol
  } and ${parsedAmounts[Field.CURRENCY_B]?.toSignificant(6)} ${currencyB?.symbol}`

  const liquidityPercentChangeCallback = useCallback(
    (value: number) => {
      onUserInput(Field.LIQUIDITY_PERCENT, value.toString())
    },
    [onUserInput]
  )

  const oneCurrencyIsETH = currencyA === ETHER || currencyB === ETHER
  const oneCurrencyIsWETH = Boolean(
    chainId &&
      ((currencyA && currencyEquals(WETH[chainId], currencyA)) ||
        (currencyB && currencyEquals(WETH[chainId], currencyB)))
  )

  // const handleSelectCurrencyA = useCallback(
  //   (currency: Currency) => {
  //     if (currencyIdB && currencyId(currency) === currencyIdB) {
  //       history.push(`/remove/${currencyId(currency)}/${currencyIdA}`)
  //     } else {
  //       history.push(`/remove/${currencyId(currency)}/${currencyIdB}`)
  //     }
  //   },
  //   [currencyIdA, currencyIdB, history]
  // )
  // const handleSelectCurrencyB = useCallback(
  //   (currency: Currency) => {
  //     if (currencyIdA && currencyId(currency) === currencyIdA) {
  //       history.push(`/remove/${currencyIdB}/${currencyId(currency)}`)
  //     } else {
  //       history.push(`/remove/${currencyIdA}/${currencyId(currency)}`)
  //     }
  //   },
  //   [currencyIdA, currencyIdB, history]
  // )

  const handleDismissConfirmation = useCallback(() => {
    !attemptingTxn && setShowConfirm(false)
    // setSignatureData(null) // important that we clear signature data to avoid bad sigs
    // if there was a tx hash, we want to clear the input
    if (txHash) {
      onUserInput(Field.LIQUIDITY_PERCENT, '0')
    }
    setTxHash('')
  }, [onUserInput, txHash, attemptingTxn])

  const [innerLiquidityPercentage, setInnerLiquidityPercentage] = useDebouncedChangeHandler(
    Number.parseInt(parsedAmounts[Field.LIQUIDITY_PERCENT].toFixed(0)),
    liquidityPercentChangeCallback
  )

  const handlePercentChange = (event: ChangeEvent<HTMLInputElement>) => {
    const isValid = event.target.validity.valid && Number(event.target.value) <= 100
    onUserInput(
      Field.LIQUIDITY_PERCENT,
      isValid ? event.target.value : parsedAmounts[Field.LIQUIDITY_PERCENT].toFixed(0)
    )
  }

  return (
    <Wrapper>
      <BackgroundGrid />
      <TransactionConfirmationModal
        isOpen={showConfirm}
        onDismiss={handleDismissConfirmation}
        attemptingTxn={attemptingTxn}
        hash={txHash ? txHash : ''}
        content={() => (
          <ConfirmationModalContent
            title={'Remove Liquidity'}
            onDismiss={handleDismissConfirmation}
            topContent={modalHeader}
            bottomContent={modalBottom}
          />
        )}
        pendingText={pendingText}
        currencyAmountA={parsedAmounts[Field.CURRENCY_A]}
        currencyAmountB={parsedAmounts[Field.CURRENCY_B]}
      />

      <AutoColumn gap={showPercent ? '2rem' : '3rem'}>
        <AddRemoveTabs creating={false} adding={false} onBack={showPercent ? () => setShowPercent(false) : undefined} />

        {showPercent && (
          <AutoColumn gap="16px">
            <PercentInputField
              value={
                parsedAmounts[Field.LIQUIDITY_PERCENT].greaterThan(new Percent('0'))
                  ? parsedAmounts[Field.LIQUIDITY_PERCENT].toFixed(0)
                  : ''
              }
              onChange={handlePercentChange}
            />

            {/* <Slider value={innerLiquidityPercentage} onChange={setInnerLiquidityPercentage} /> */}
            <RowBetween style={{ justifyContent: 'flex-end' }}>
              <MaxButton
                active={formattedAmounts[Field.LIQUIDITY_PERCENT] === '25'}
                onClick={() => onUserInput(Field.LIQUIDITY_PERCENT, '25')}
                width="20%"
              >
                25%
              </MaxButton>
              <MaxButton
                active={formattedAmounts[Field.LIQUIDITY_PERCENT] === '50'}
                onClick={() => onUserInput(Field.LIQUIDITY_PERCENT, '50')}
                width="20%"
              >
                50%
              </MaxButton>
              <MaxButton
                active={formattedAmounts[Field.LIQUIDITY_PERCENT] === '75'}
                onClick={() => onUserInput(Field.LIQUIDITY_PERCENT, '75')}
                width="20%"
              >
                75%
              </MaxButton>
              <MaxButton
                active={formattedAmounts[Field.LIQUIDITY_PERCENT] === '100'}
                onClick={() => onUserInput(Field.LIQUIDITY_PERCENT, '100')}
                width="20%"
              >
                Max
              </MaxButton>
            </RowBetween>
          </AutoColumn>
        )}

        {showPercent && receiveWrapper()}

        {/* {showDetailed && (
          <>
            <CurrencyInputPanel
              value={formattedAmounts[Field.LIQUIDITY]}
              onUserInput={onLiquidityInput}
              onMax={() => {
                onUserInput(Field.LIQUIDITY_PERCENT, '100')
              }}
              showMaxButton={!atMaxAmount}
              disableCurrencySelect
              currency={pair?.liquidityToken}
              pair={pair}
              id="liquidity-amount"
            />
            <ColumnCenter>
              <ArrowDown size="16" color={theme.text2} />
            </ColumnCenter>
            <CurrencyInputPanel
              hideBalance={true}
              value={formattedAmounts[Field.CURRENCY_A]}
              onUserInput={onCurrencyAInput}
              onMax={() => onUserInput(Field.LIQUIDITY_PERCENT, '100')}
              showMaxButton={!atMaxAmount}
              currency={currencyA}
              label={'Output'}
              onCurrencySelect={handleSelectCurrencyA}
              id="remove-liquidity-tokena"
            />
            <ColumnCenter>
              <Plus size="16" color={theme.text2} />
            </ColumnCenter>
            <CurrencyInputPanel
              hideBalance={true}
              value={formattedAmounts[Field.CURRENCY_B]}
              onUserInput={onCurrencyBInput}
              onMax={() => onUserInput(Field.LIQUIDITY_PERCENT, '100')}
              showMaxButton={!atMaxAmount}
              currency={currencyB}
              label={'Output'}
              onCurrencySelect={handleSelectCurrencyB}
              id="remove-liquidity-tokenb"
            />
          </>
        )} */}

        <RemoveLiquidityInfo isInfoMode={!showPercent} showUnwrapped={oneCurrencyIsWETH} pair={pair ?? undefined} />
        {!showPercent && (
          <Button
            variant="outlined"
            disabled={userPoolBalance?.equalTo(JSBI.BigInt(0))}
            onClick={() => setShowPercent(true)}
            sx={{
              py: 2,
              fontSize: 24,
              fontWeight: 500
            }}
          >
            REMOVE
          </Button>
        )}

        {!showPercent && (
          <TYPE.primary0 fontWeight={400} textAlign="center">
            Removing pool tokens converts your position back into underlying tokens at the current rate, proportional to
            your share of the pool. Accrued fees are included in the amounts you receive.
          </TYPE.primary0>
        )}

        {showPercent && (
          <div style={{ position: 'relative' }}>
            {error && (
              <Text color="#eb5e55" textAlign="center" mb={24} fontSize={18} fontWeight={400}>
                {error}
              </Text>
            )}

            {!account ? (
              <ButtonPrimary onClick={toggleWalletModal}>Connect Wallet</ButtonPrimary>
            ) : (
              <>
                {/* <ButtonPrimary
                  onClick={onAttemptToApprove}
                  // confirmed={approval === ApprovalState.APPROVED || signatureData !== null}
                  disabled={approval !== ApprovalState.NOT_APPROVED || signatureData !== null}
                >
                  {approval === ApprovalState.PENDING ? (
                    <Dots>Approving</Dots>
                  ) : approval === ApprovalState.APPROVED || signatureData !== null ? (
                    'Approved'
                  ) : (
                    'Approve'
                  )}
                </ButtonPrimary> */}

                <ButtonPrimary
                  onClick={signatureData !== null ? () => setShowConfirm(true) : onAttemptToApprove}
                  // disabled={!isValid || (signatureData === null && approval !== ApprovalState.APPROVED)}
                  // error={!isValid && !!parsedAmounts[Field.CURRENCY_A] && !!parsedAmounts[Field.CURRENCY_B]}
                  disabled={!!error || attemptingTxn}
                >
                  <Text fontSize={24} fontWeight={500}>
                    {!parsedAmounts[Field.LIQUIDITY] || signatureData !== null ? 'REMOVE' : 'Approve ALP'}
                  </Text>
                </ButtonPrimary>
              </>
            )}
          </div>
        )}

        {/* {pair ? (
          <AutoColumn gap="16px" style={{ width: '100%' }}>
            <AutoColumn gap="10px" style={{ padding: '0 16px' }}>
              <RowBetween>
                Price:
                <div>
                  1 {currencyA?.symbol} = {tokenA ? pair.priceOf(tokenA).toSignificant(6) : '-'} {currencyB?.symbol}
                </div>
              </RowBetween>
              <RowBetween>
                <div />
                <div>
                  1 {currencyB?.symbol} = {tokenB ? pair.priceOf(tokenB).toSignificant(6) : '-'} {currencyA?.symbol}
                </div>
              </RowBetween>
            </AutoColumn>

            <MinimalPositionCard showUnwrapped={oneCurrencyIsWETH} pair={pair} />
          </AutoColumn>
        ) : null} */}
      </AutoColumn>
    </Wrapper>
  )
}
