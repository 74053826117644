import * as React from 'react'

interface Props {
  color?: string
  className?: string
}

export const WikiIcon = (props: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
    <g clipPath="url(#a)" fill={props.color || '#7CE5CF'}>
      <path d="M7.5 18a.75.75 0 1 0 0 1.5h3a.75.75 0 1 0 0-1.5h-3zm-.75-3a.75.75 0 0 1 .75-.75h6a.75.75 0 1 1 0 1.5h-6a.75.75 0 0 1-.75-.75zm.75-4.5a.75.75 0 1 0 0 1.5h9a.75.75 0 1 0 0-1.5h-9z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.75 8.256V8.25c0-.016 0-.032-.002-.047a.476.476 0 0 1 .002.053L13.6.006A.711.711 0 0 0 13.5 0H6a3.75 3.75 0 0 0-3.75 3.75v16.5A3.75 3.75 0 0 0 6 24h12a3.75 3.75 0 0 0 3.75-3.75V8.256zm-9-3.006A3.75 3.75 0 0 0 16.5 9h3.75v11.25A2.25 2.25 0 0 1 18 22.5H6a2.25 2.25 0 0 1-2.25-2.25V3.75A2.25 2.25 0 0 1 6 1.5h6.75v3.75zm1.5-2.69 4.94 4.94H16.5a2.25 2.25 0 0 1-2.25-2.25V2.56z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
)
