import { useActiveWeb3React } from 'hooks'
import { useToken } from 'hooks/Tokens'
import { Pool } from 'models'
import { useTokenBalance } from 'state/wallet/hooks'

import PoolItemListView from './PoolItemListView'
import PoolItemThumbnailView from './PoolItemThumbnailView'

export function PoolItem({ isListView = true, pool }: { isListView?: boolean; pool: Pool }) {
  const { account } = useActiveWeb3React()
  const { token } = useToken(pool.id)
  const userPoolBalance = useTokenBalance(account ?? undefined, token ?? undefined)

  const poolWithALP: Pool = { ...pool, pooledALP: userPoolBalance }

  return isListView ? <PoolItemListView pool={poolWithALP} /> : <PoolItemThumbnailView pool={poolWithALP} />
}
