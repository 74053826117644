import { Currency, Token } from '@adaswap/sdk'
import useTheme from 'hooks/useTheme'
import React, { useCallback, useRef } from 'react'
import AutoSizer from 'react-virtualized-auto-sizer'
import { FixedSizeList } from 'react-window'

import { TYPE } from '../../theme'
import Column from '../Column'
import CurrencyList from './CurrencyList'
import ImportRow from './ImportRow'

export interface TokenListProps {
  onDismiss: () => void
  selectedCurrency?: Currency | null
  onCurrencySelect: (currency: Currency) => void
  otherSelectedCurrency?: Currency | null
  showImportView: () => void
  setImportToken: (token: Token) => void
  searchToken: Token | null | undefined
  searchTokenIsAddedOrDefaultToken: boolean
  filteredSortedTokens: Token[]
  filteredInactiveTokens: Token[]
  showETH: boolean
}

export default function TokenListView({
  searchToken,
  searchTokenIsAddedOrDefaultToken,
  showImportView,
  setImportToken,
  filteredInactiveTokens,
  filteredSortedTokens,
  onCurrencySelect,
  onDismiss,
  otherSelectedCurrency,
  selectedCurrency,
  showETH
}: TokenListProps) {
  const theme = useTheme()
  const handleCurrencySelect = useCallback(
    (currency: Currency) => {
      onCurrencySelect(currency)
      onDismiss()
    },
    [onDismiss, onCurrencySelect]
  )

  const fixedList = useRef<FixedSizeList>()
  return (
    <React.Fragment>
      {searchToken && !searchTokenIsAddedOrDefaultToken ? (
        <Column style={{ padding: '20px 0', height: '100%' }}>
          <ImportRow token={searchToken} showImportView={showImportView} setImportToken={setImportToken} />
        </Column>
      ) : filteredSortedTokens?.length > 0 || filteredInactiveTokens?.length > 0 || showETH ? (
        <div style={{ flex: '1', marginRight: '-1.5rem' }}>
          <AutoSizer disableWidth>
            {({ height }) => (
              <CurrencyList
                height={height}
                showETH={showETH}
                currencies={
                  filteredInactiveTokens ? filteredSortedTokens.concat(filteredInactiveTokens) : filteredSortedTokens
                }
                breakIndex={undefined}
                onCurrencySelect={handleCurrencySelect}
                otherCurrency={otherSelectedCurrency}
                selectedCurrency={selectedCurrency}
                fixedListRef={fixedList}
                showImportView={showImportView}
                setImportToken={setImportToken}
              />
            )}
          </AutoSizer>
        </div>
      ) : (
        <Column style={{ padding: '20px', height: '100%' }}>
          <TYPE.main color={theme.text3} textAlign="center" mb="20px">
            No results found.
          </TYPE.main>
        </Column>
      )}
    </React.Fragment>
  )
}
