import { Box, Link, styled, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import Paper from '@mui/material/Paper'
import { EmptyStage } from 'components/EmptyContent'
import {
  BG_DARK_BLUE,
  BRAND_COLOR,
  BRAND_COLOR_LIGHT,
  DARK_BLUE,
  DARK_GREEN,
  GREEN_BASE,
  LIGHT_GREEN,
  LOADING_GIF,
  LOADING_GIF_LT
} from 'constants/index'
import ENV_VARS from 'env'
import { SortColumn, TransactionData } from 'pages/History'
import * as React from 'react'
import { useIsDarkMode } from 'state/user/hooks'

import { useActiveWeb3React } from '../../hooks/index'
import HistoryPagination from './HistoryPagination'

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(even)': {
    backgroundColor: theme.isDarkTheme ? BG_DARK_BLUE : GREEN_BASE
  },
  fontWeight: 500
}))

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  color: theme.isDarkTheme ? BRAND_COLOR : DARK_BLUE,
  border: 0,
  fontWeight: 500
}))

const getStyles = (isDarkTheme: boolean) => ({
  paper: {
    width: '100%',
    overflow: 'hidden',
    backgroundColor: isDarkTheme ? '#02111a' : LIGHT_GREEN,
    border: 0,
    boxShadow: 'none',
    position: 'relative'
  },
  table: {
    minHeight: 300,
    maxHeight: 440,
    border: `1px solid ${isDarkTheme ? BRAND_COLOR_LIGHT : DARK_GREEN}`,
    px: '.5rem',
    pb: '1rem',
    borderRadius: '.5rem',

    '&::-webkit-scrollbar-thumb': {
      background: isDarkTheme ? BRAND_COLOR : GREEN_BASE,
      '&:hover': {
        background: isDarkTheme ? BRAND_COLOR : GREEN_BASE
      }
    }
  },

  loading: {
    left: '50%',
    position: 'absolute',
    transform: 'translate(-50%)',
    fontSize: '1.5rem'
  },
  header: {
    backgroundColor: isDarkTheme ? '#02111a' : LIGHT_GREEN,
    border: 0,
    color: isDarkTheme ? 'white' : DARK_BLUE,

    '&:hover': {
      cursor: 'pointer',
      opacity: 0.6
    }
  },
  pagination: {
    justifyContent: 'center',
    pt: 4
  }
})

interface Column {
  id: 'type' | 'totalValue' | 'tokenAmountA' | 'tokenAmountB' | 'account' | 'time'
  label: string
  minWidth?: number
  align?: 'right'
  sortColumnName?: SortColumn
}

interface HistoryTableProps {
  transactions: TransactionData[]
  currentPage: number
  maxPage: number
  onChangePage: (page: number) => void
  loading: boolean
  errorContent: string
  onChangeSortDirection: (direction: boolean) => void
  onChangeSortedField: (newField: SortColumn) => void
  sortedColumn: SortColumn
  sortDirection: boolean
}

const transactionLink = (hash: string) => `${ENV_VARS.explorerScan}/tx/${hash}`
const accountLink = (accountAddress: string | null | undefined) => `${ENV_VARS.explorerScan}/address/${accountAddress}`

export default function HistoryTable({
  transactions,
  currentPage,
  maxPage,
  onChangePage,
  loading,
  errorContent,
  onChangeSortDirection,
  onChangeSortedField,
  sortedColumn,
  sortDirection
}: HistoryTableProps) {
  const columns: Column[] = React.useMemo(() => {
    return [
      { id: 'type', label: '', minWidth: 170 },
      { id: 'totalValue', label: 'Total Value', minWidth: 100, sortColumnName: SortColumn.AmountUsd },
      {
        id: 'tokenAmountA',
        label: 'Token Amount',
        minWidth: 170,
        align: 'right',
        sortColumnName: SortColumn.Amount0
      },
      {
        id: 'tokenAmountB',
        label: 'Token Amount',
        minWidth: 170,
        align: 'right',
        sortColumnName: SortColumn.Amount1
      },
      {
        id: 'account',
        label: 'Account',
        minWidth: 170,
        align: 'right'
      },
      {
        id: 'time',
        label: 'Time',
        minWidth: 170,
        align: 'right',
        sortColumnName: SortColumn.TimeStamp
      }
    ]
  }, [])
  const { account } = useActiveWeb3React()
  const darkMode = useIsDarkMode()
  const styles = getStyles(darkMode)

  if (!loading && !transactions.length) return <EmptyStage />
  if (loading)
    return (
      <Box textAlign="center" pt={4}>
        <img src={darkMode ? LOADING_GIF : LOADING_GIF_LT} alt="" width={150} />
      </Box>
    )

  return (
    <Paper sx={styles.paper}>
      <TableContainer sx={styles.table}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map(column => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  sx={styles.header}
                  style={{ minWidth: column.minWidth }}
                  onClick={() => {
                    if (column.sortColumnName) {
                      onChangeSortedField(column.sortColumnName)
                      onChangeSortDirection(sortedColumn !== column.sortColumnName ? true : !sortDirection)
                    }
                  }}
                >
                  {column.label}
                  <Box
                    component="span"
                    sx={{
                      fontSize: '1.2rem',
                      ml: 1
                    }}
                  >
                    {sortedColumn === column.sortColumnName ? (sortDirection ? '↓' : '↑') : ''}
                  </Box>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {transactions.map((row, item) => {
              const hash = row.hash
              return (
                <StyledTableRow hover role="checkbox" tabIndex={-1} key={`${row.time} + ${item}`}>
                  {columns.map((column, index) => {
                    const value = row[column.id]
                    const isLink = column.id === 'type' || column.id === 'account'
                    return (
                      <StyledTableCell key={`${row.time} - ${index}`} align={column.align}>
                        {isLink ? (
                          <Link
                            sx={{ color: darkMode ? '#ffe600' : DARK_BLUE }}
                            href={column.id === 'type' ? transactionLink(hash) : accountLink(account)}
                            target="_blank"
                          >
                            {value}
                          </Link>
                        ) : (
                          value
                        )}
                      </StyledTableCell>
                    )
                  })}
                </StyledTableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <HistoryPagination currentPage={currentPage} maxPage={maxPage} onChangePage={onChangePage} />
    </Paper>
  )
}
