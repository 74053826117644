import * as React from 'react'

interface Props {
  color?: string
  style?: any
}

export const ArrowUp = (props: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="21" height="13" viewBox="0 0 21 13" fill="none" style={props.style}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.233 12.196a1.56 1.56 0 0 0 2.202 0 1.56 1.56 0 0 0 0-2.202l-8.89-8.89a1.56 1.56 0 0 0-2.201 0l-8.89 8.89a1.56 1.56 0 0 0 0 2.202 1.56 1.56 0 0 0 2.202 0l7.792-7.794 7.785 7.794z"
      fill={props.color || '#77FEFF'}
    />
  </svg>
)
