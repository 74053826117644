import { useQuery } from '@apollo/client'
import { Box, Container, Typography } from '@mui/material'
import HistoryFilter from 'components/History/Filter'
import HistoryTable from 'components/History/HistoryTable'
import { BRAND_COLOR, DARK_GREEN, LIGHT_GREEN } from 'constants/index'
import { default as dayjs } from 'dayjs'
import { USER_TRANSACTIONS } from 'graphql/query'
import { useActiveWeb3React } from 'hooks'
import { Swaps, TransactionType, Txn, UserTransaction } from 'models'
import { useEffect, useState } from 'react'
import { useIsDarkMode } from 'state/user/hooks'
import { formattedNum, formatTime, getTransactionType } from 'utils'

const getStyles = (isDarkTheme: boolean) => ({
  wrapper: {
    flex: 1,
    pt: 3,
    pb: 10,
    width: '100vw'
  },
  title: {
    fontSize: '40px',
    fontWeight: isDarkTheme ? 500 : 600,
    color: isDarkTheme ? 'white' : DARK_GREEN,
    pb: 4
  },
  content: {
    fontSize: '24px',
    fontWeight: 600,
    color: isDarkTheme ? BRAND_COLOR : DARK_GREEN,
    pt: '1.5rem',
    pb: '1rem',
    pl: '1rem'
  },
  error: {
    fontSize: '1rem',
    py: '1.5rem'
  }
})

const ITEMS_PER_PAGE = 20

export enum SortColumn {
  TimeStamp = 'timestamp',
  Amount0 = 'token0Amount',
  Amount1 = 'token1Amount',
  AmountUsd = 'amountUSD'
}
export interface TransactionData {
  type: string
  totalValue: string
  tokenAmountA: string
  tokenAmountB: string
  account: string
  time: string
  hash: string
}

export enum Duration {
  All = 'All Time',
  Week = 'Last Week',
  Month = 'Last Month',
  Year = 'Last Year'
}

function createData(
  type: string,
  totalValue: string,
  tokenAmountA: string,
  tokenAmountB: string,
  account: string,
  time: string,
  hash: string
): TransactionData {
  return { type, totalValue, tokenAmountA, tokenAmountB, account, time, hash }
}

const calculateSwapTxn = (start: Swaps, stop: Swaps): Txn => {
  let input = 1
  let output = 1
  const startAmount0In = +start.amount0In
  const stopAmount0Out = +stop.amount0Out
  if (startAmount0In) {
    input = 0
  }
  if (stopAmount0Out) {
    output = 0
  }
  const newTxn: Txn = {
    hash: stop.transaction.id,
    timestamp: stop.transaction.timestamp,
    type: TransactionType.SWAP,
    amountUSD: stop.amountUSD,
    account: stop.to,
    token0Symbol: input ? start.pair.token1.symbol : start.pair.token0.symbol,
    token1Symbol: output ? stop.pair.token1.symbol : stop.pair.token0.symbol,
    token0Amount: input ? start.amount1In : start.amount0In,
    token1Amount: output ? stop.amount1Out : stop.amount0Out
  }
  return newTxn
}

export default function HistoryFeature() {
  const { account } = useActiveWeb3React()
  const darkMode = useIsDarkMode()
  const styles = getStyles(darkMode)

  const { data, loading, error, startPolling, stopPolling } = useQuery<UserTransaction>(USER_TRANSACTIONS, {
    variables: { user: account }
  })
  const [transactions, setTransactions] = useState<TransactionData[]>([])
  const [page, setPage] = useState(1)
  const [maxPage, setMaxPage] = useState(1)
  const [searchValue, setSearchValue] = useState('')
  const [transactionType, setTransactionType] = useState(TransactionType.ALL)
  const [sortedColumn, setSortedColumn] = useState(SortColumn.TimeStamp)
  const [sortDirection, setSortDirection] = useState(true)
  const [duration, setDuration] = useState(Duration.All)

  useEffect(() => {
    if (data) {
      let newTxns: Txn[] = []
      if (data.mints.length > 0) {
        const mintTxn: Txn[] = data.mints.map(mint => {
          const newTxn: Txn = {
            hash: mint.transaction.id,
            timestamp: mint.transaction.timestamp,
            type: TransactionType.ADD,
            token0Amount: mint.amount0,
            token1Amount: mint.amount1,
            account: mint.to,
            token0Symbol: mint.pair.token0.symbol,
            token1Symbol: mint.pair.token1.symbol,
            amountUSD: mint.amountUSD
          }
          return newTxn
        })
        newTxns = [...newTxns, ...mintTxn]
      }
      if (data.burns.length > 0) {
        const burnTxn: Txn[] = data.burns.map(burn => {
          const newTxn: Txn = {
            hash: burn.transaction.id,
            timestamp: burn.transaction.timestamp,
            type: TransactionType.REMOVE,
            token0Amount: burn.amount0,
            token1Amount: burn.amount1,
            account: burn.sender,
            token0Symbol: burn.pair.token0.symbol,
            token1Symbol: burn.pair.token1.symbol,
            amountUSD: burn.amountUSD
          }
          return newTxn
        })
        newTxns = [...newTxns, ...burnTxn]
      }
      if (data.swaps.length > 0) {
        const swaps = data.swaps
        const swapObj = swaps.reduce<{ [address: string]: Swaps[] }>((obj, item) => {
          obj[item.transaction.id] = item.transaction.id in obj ? [...obj[item.transaction.id], item] : [item]
          return obj
        }, {})
        const swapTxn = Object.values(swapObj).map(swapItem => {
          if (swapItem.length === 1) return calculateSwapTxn(swapItem[0], swapItem[0])
          const length = swapItem.length
          const stop = swapItem.find(item => item.id.includes(`-${length - 1}`))
          const start = swapItem.find(item => item.id.includes(`-0`))
          if (!start || !stop) return
          return calculateSwapTxn(start, stop)
        })
        const swapNotNull = swapTxn.filter((swap): swap is Txn => swap !== null)
        newTxns = [...newTxns, ...swapNotNull]
      }

      const filteredType = newTxns.filter(item => {
        if (transactionType !== TransactionType.ALL) {
          return item.type === transactionType
        }
        return true
      })

      const filteredDirection =
        filteredType &&
        filteredType.sort((a, b) => {
          return parseFloat(a[sortedColumn]) > parseFloat(b[sortedColumn])
            ? (sortDirection ? -1 : 1) * 1
            : (sortDirection ? -1 : 1) * -1
        })

      const filteredTime = filteredDirection.filter(item => {
        const now = dayjs()
        const timestamp = dayjs.unix(+item.timestamp)
        const isWeek = now.diff(timestamp, 'day')
        const isMonth = now.diff(timestamp, 'month')
        const isYear = now.diff(timestamp, 'year')
        if (duration === Duration.Week) return isWeek <= 7
        if (duration === Duration.Month) return isMonth === 0
        if (duration === Duration.Year) return isYear === 0
        return true
      })

      const rowTransactions = filteredTime.map(txn =>
        createData(
          getTransactionType(txn.type, txn.token0Symbol, txn.token1Symbol),
          formattedNum(txn.amountUSD, true),
          formattedNum(txn.token0Amount) + ' ' + txn.token0Symbol,
          formattedNum(txn.token1Amount) + ' ' + txn.token1Symbol,
          txn.account && txn.account.slice(0, 6) + '...' + txn.account.slice(38, 42),
          formatTime(+txn.timestamp),
          txn.hash
        )
      )
      const transactions = rowTransactions.filter(item => {
        const { hash, account, ...other } = item
        return Object.values(other)
          .map(i => typeof i === 'string' && i.toLowerCase())
          .join()
          .includes(searchValue.toLowerCase())
      })

      setTransactions(transactions)
      let extraPages = 1
      if (transactions.length % ITEMS_PER_PAGE === 0) {
        extraPages = 0
      }
      if (transactions.length === 0) {
        setMaxPage(1)
      } else {
        setMaxPage(Math.floor(transactions.length / ITEMS_PER_PAGE) + extraPages)
      }
    }
  }, [data, transactionType, sortedColumn, sortDirection, searchValue, duration])

  useEffect(() => {
    setPage(1)
  }, [transactionType, searchValue, duration])

  useEffect(() => {
    startPolling(10000)
    return () => {
      stopPolling()
    }
  }, [startPolling, stopPolling])

  const handleSearch = (value: string) => {
    setSearchValue(value)
  }
  const handleChangeTransactionType = (type: TransactionType) => {
    setTransactionType(type)
  }
  const handleChangePage = (newPage: number) => {
    setPage(newPage)
  }
  const handleChangeSortedField = (field: SortColumn) => {
    setSortedColumn(field)
  }

  const handleChangeSortDirection = (direction: boolean) => {
    setSortDirection(direction)
  }

  const handleChangeDuration = (duration: Duration) => {
    setDuration(duration)
  }

  const errorContent = !account
    ? 'Please connect wallet to get your transactions'
    : error
    ? 'Error when loading transactions'
    : ''

  const filteredPage = transactions.slice(ITEMS_PER_PAGE * (page - 1), page * ITEMS_PER_PAGE)

  return (
    <Box sx={styles.wrapper} bgcolor={darkMode ? '#02111a' : LIGHT_GREEN}>
      <Container maxWidth="xl">
        <Typography sx={styles.title}>My Orders History</Typography>
        <HistoryFilter
          onSearch={handleSearch}
          transactionType={transactionType}
          onChangeTransactionType={handleChangeTransactionType}
          duration={duration}
          onChangeDuration={handleChangeDuration}
        />
        <Typography sx={styles.content}>Transactions</Typography>
        <HistoryTable
          transactions={data ? filteredPage : []}
          loading={loading}
          currentPage={page}
          maxPage={maxPage}
          onChangePage={handleChangePage}
          errorContent={errorContent}
          onChangeSortDirection={handleChangeSortDirection}
          onChangeSortedField={handleChangeSortedField}
          sortedColumn={sortedColumn}
          sortDirection={sortDirection}
        />
      </Container>
    </Box>
  )
}
