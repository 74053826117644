import { Button, Stack, Typography } from '@mui/material'
import { useWeb3React } from '@web3-react/core'
import LinkWithIcon from 'components/pool/LinkWithIcon'
import TokenPair from 'components/pool/TokenPair'
import { BRAND_COLOR, DARK_BLUE, LIGHT_BG_GRADIENT, MIGRATION_CONTRACT_ADDRESS } from 'constants/index'
import ENV_VARS from 'env'
import { useMigrationContract, useTokenContract } from 'hooks/useContract'
import { useGetToken } from 'hooks/useGetUserToken'
import { Token, TokenType } from 'models'
import { useState } from 'react'
import { toast } from 'react-toastify'
import { useTransactionAdder } from 'state/transactions/hooks'
import { useIsDarkMode } from 'state/user/hooks'
import { getTokenIcon } from 'utils'

import { Dots } from '../PoolUni/styleds'

const getStyles = (isDarkTheme: boolean) => ({
  root: {
    px: 4,
    py: 2,
    my: 2,
    border: isDarkTheme ? `1px solid ${BRAND_COLOR}` : 'none',
    borderRadius: '24px !important',
    background: isDarkTheme ? 'linear-gradient(180deg, rgba(4, 20, 28, 0) 0%, #204444 100%)' : LIGHT_BG_GRADIENT,
    boxShadow: isDarkTheme ? `0 0 10px -5px ${BRAND_COLOR}` : 'none',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  name: {
    fontSize: '1.25rem',
    color: isDarkTheme ? 'primary.light' : DARK_BLUE
  },
  button: {
    fontSize: '1rem',
    fontWeight: 500,
    color: 'primary.light',

    py: 1.25,
    minWidth: 200
  }
})

enum MigrateStatus {
  idle,
  migrated,
  approving,
  migrating
}

function LPItem({ alpToken }: { alpToken: Token }) {
  const darkMode = useIsDarkMode()
  const styles = getStyles(darkMode)
  const { library } = useWeb3React()
  const tokens = useGetToken(TokenType.NORMAL, alpToken.address)
  const alpTokenContract = useTokenContract(alpToken?.address)
  const migrationContract = useMigrationContract()
  const [status, setStatus] = useState(MigrateStatus.idle)
  const spender = MIGRATION_CONTRACT_ADDRESS

  let token0, token1

  if (tokens.length >= 2) {
    token0 = tokens[0]
    token1 = tokens[1]
  }

  const addTransaction = useTransactionAdder()
  const amountToApprove = (alpToken.balance * 1e18).toLocaleString('fullwide', { useGrouping: false })

  const disableBtn =
    status === MigrateStatus.approving || status === MigrateStatus.migrating || status === MigrateStatus.migrated
  const contentBtn = () => {
    let content
    switch (status) {
      case MigrateStatus.idle:
        content = 'Approve your ALP'
        break
      case MigrateStatus.approving:
        content = <Dots>Approving</Dots>
        break
      case MigrateStatus.migrating:
        content = <Dots>Migrating</Dots>
        break
      case MigrateStatus.migrated:
        content = 'Migrated'
        break
    }
    return content
  }

  const handleMigrate = async () => {
    if (alpTokenContract && migrationContract) {
      try {
        setStatus(MigrateStatus.approving)
        const res = await alpTokenContract.approve(spender, amountToApprove)
        addTransaction(res, { summary: 'Approve ' + alpToken.symbol })
        let receiptApprove
        do {
          receiptApprove = await library.getTransactionReceipt(res?.hash)
          await new Promise(r => setTimeout(r, 4000))
        } while (!receiptApprove)

        setStatus(MigrateStatus.migrating)
        let receiptMigration
        const resMigration = await migrationContract.migrate(alpToken.address)
        addTransaction(resMigration, { summary: 'Migration ' + alpToken.symbol })
        do {
          receiptMigration = await library.getTransactionReceipt(res?.hash)
          await new Promise(r => setTimeout(r, 4000))
        } while (!receiptMigration)
        setStatus(MigrateStatus.migrated)
        toast.info('Migrate your pools successfully')
      } catch (error) {
        setStatus(MigrateStatus.idle)
        toast.error(error)
      }
    } else {
      toast.error("Can't init contract")
    }
  }
  if (status === MigrateStatus.migrated) return null
  if (token0 && token1) {
    return (
      <Stack sx={styles.root} direction="row" spacing={6}>
        <TokenPair icon1={getTokenIcon(token0.symbol)} icon2={getTokenIcon(token1.symbol)} />

        <Typography component="h1" sx={styles.name}>
          {token0.symbol}-{token1.symbol}
        </Typography>

        <Stack spacing={4} alignItems="flex-start" direction="row">
          <Stack spacing={2} alignItems="flex-start">
            <LinkWithIcon text="View Contract" href={`${ENV_VARS.explorerScan}/address/${alpToken.address}`} />
            <LinkWithIcon text="Pair Info" href={`${ENV_VARS.explorerScan}/address/${alpToken.address}/tokens`} />
          </Stack>

          <Button variant="outlined" size="large" sx={styles.button} onClick={handleMigrate} disabled={disableBtn}>
            {contentBtn()}
          </Button>
        </Stack>
      </Stack>
    )
  }
  return null
}
export default LPItem
