import * as React from 'react'

interface Props {
  color?: string
  style?: any
}

export const FeatureRequestIcon = (props: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
    <path
      d="M16.68 2.02a.72.72 0 0 0-.984.264l-.96 1.663a.72.72 0 0 0 1.247.72l.96-1.663a.72.72 0 0 0-.263-.984zm-7.285-.958a.719.719 0 0 0-1.056.81l.497 1.855a.722.722 0 0 0 1.393-.374L9.732 1.5a.719.719 0 0 0-.337-.437zM21.152 7.85a.72.72 0 0 0-.547-.074l-1.854.497a.722.722 0 0 0 .373 1.393l1.854-.496a.722.722 0 0 0 .174-1.32zM14.52 5.762c-2.858-1.65-6.896-1.016-8.714 2.132-1.131 1.96-1.088 3.678-.961 5.056.126 1.378.286 2.354-.315 3.394l-1.44 2.494a1.69 1.69 0 0 0 .615 2.295l2.91 1.68a1.69 1.69 0 0 0 2.295-.615l1.44-2.494c.6-1.04 1.526-1.39 2.782-1.97 1.256-.579 2.767-1.401 3.898-3.36 1.818-3.148.347-6.962-2.51-8.612zm-.72 1.247c2.212 1.277 3.377 4.23 1.983 6.644-.951 1.649-2.049 2.223-3.247 2.775-1.022.47-2.172.92-3.02 1.962l-3.43-1.98c.478-1.256.292-2.477.19-3.597-.122-1.315-.173-2.552.78-4.2 1.393-2.415 4.533-2.882 6.746-1.604H13.8zM3.566 5.534a.72.72 0 0 0-.72 1.247l1.663.96a.72.72 0 0 0 .72-1.247l-1.663-.96zm14.965 8.64a.72.72 0 0 0-.72 1.247l1.663.96a.72.72 0 0 0 .72-1.247l-1.663-.96zM5.417 17.688l3.325 1.92-.24.415-3.325-1.92.24-.415zm-.96 1.663 3.325 1.92-.12.208c-.078.136-.224.147-.328.088l-2.91-1.68a.234.234 0 0 1-.088-.328l.12-.208h.001z"
      fill={props.color || '#7CE5CF'}
    />
  </svg>
)
