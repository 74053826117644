import { Box, Container, Stack, useMediaQuery, useTheme } from '@mui/material'
import { EmptyStage } from 'components/EmptyContent'
import { FarmCalculatorPopup, FarmFilter, FarmFilterState, FarmHero, FarmList, FarmStakePopup } from 'components/farm'
import { FarmHarvestPopup } from 'components/farm/FarmHarvestPopup'
import { LIGHT_GREEN, LOADING_GIF, LOADING_GIF_LT } from 'constants/index'
import { FarmWithStakedValue } from 'models'
import { useMemo, useState } from 'react'
import { useFarms, useFarmsWithStakedValue } from 'state/farm/hooks'
import { useIsDarkMode } from 'state/user/hooks'
import { getTotalAndMaxDataFromFarmList, getWrappedFarm } from 'utils/farm'

export const DEFAULT_LOCK_TIME_INDEX = 0 // lockIndex !== lockId

function Farm() {
  const darkMode = useIsDarkMode()
  const theme = useTheme()
  const isDownMd = useMediaQuery(theme.breakpoints.down('md'))
  const { data: allFarms, poolLength, isFetching, userDataLoaded } = useFarms()

  const [filter, setFilter] = useState<FarmFilterState>({
    view: 1,
    search: '',
    stakedOnly: false
  })

  const [activeStakePid, setActiveStakePid] = useState<number | null>(null)
  const [activeCalculatorPid, setActiveCalculatorPid] = useState<number | null>(null)
  const [activeHarvestPid, setActiveHarvestPid] = useState<number | null>(null)
  const [activeWithdrawPid, setActiveWithdrawPid] = useState<number | null>(null)

  const farmList = useFarmsWithStakedValue(filter.search)
  const activeFarms = allFarms.filter(farm => farm.multiplier !== '0X' && (!poolLength || poolLength > farm.pid))

  const chosenFarms = farmList(activeFarms)

  const farmListByPid = useMemo(() => {
    return chosenFarms.reduce((prev: { [pid: number]: FarmWithStakedValue[] }, farm: FarmWithStakedValue) => {
      const currentList = prev[farm.pid] ?? []
      return { ...prev, [farm.pid]: [...currentList, farm] }
    }, {})
  }, [chosenFarms])

  const displayedFarmList: FarmWithStakedValue[] = useMemo(() => {
    const pidList = Object.keys(farmListByPid).map(pid => Number.parseInt(pid))

    const mapFarmListByPid = pidList.map(pid => {
      const farmLockList = farmListByPid[pid]
      const wrappedFarm: FarmWithStakedValue = getWrappedFarm(farmLockList)

      const {
        totalPendingAsw,
        totalStacked,
        totalStakedBalance,
        maxApr,
        maxLpApr,
        maxMultiplier
      } = getTotalAndMaxDataFromFarmList(farmLockList)

      return {
        ...wrappedFarm,
        totalStacked,
        totalStakedBalance,
        totalPendingAsw,
        maxApr,
        maxLpApr,
        maxMultiplier
      }
    })

    return mapFarmListByPid.filter(x => (filter.stakedOnly ? x.totalStakedBalance.isGreaterThan(0) : true))
  }, [farmListByPid])

  const [activeStakeList, activeWithdrawList, activeHarvestList, activeCalculatorList] = useMemo(
    () => [
      activeStakePid !== null ? farmListByPid[activeStakePid] : [],
      activeWithdrawPid !== null ? farmListByPid[activeWithdrawPid] : [],
      activeHarvestPid !== null ? farmListByPid[activeHarvestPid] : [],
      activeCalculatorPid !== null ? farmListByPid[activeCalculatorPid] : []
    ],
    [farmListByPid, activeStakePid, activeWithdrawPid, activeHarvestPid]
  )

  const handleViewChange = (event: any, newLayout: number | null) => {
    if (newLayout === null) return
    setFilter({ ...filter, view: newLayout })
  }

  const handleToggleStackedOnly = () => {
    setFilter({ ...filter, stakedOnly: !filter.stakedOnly })
  }

  const handleSearchChange = (search: string) => {
    setFilter({ ...filter, search })
  }

  // stake
  const handleOpenStakePopup = (pid: number) => {
    setActiveStakePid(pid)
  }

  const handleCloseStakePopup = () => {
    setActiveStakePid(null)
  }

  const handleOpenCalculatorFromStake = () => {
    if (activeStakePid === null) return
    handleOpenCalculatorPopup(activeStakePid)
  }

  // calculator
  const handleOpenCalculatorPopup = (pid: number) => {
    setActiveCalculatorPid(pid)
  }

  const handleCloseCalculatorPopup = () => {
    setActiveCalculatorPid(null)
  }

  const handleOpenHarvestPopup = (pid: number) => {
    setActiveHarvestPid(pid)
  }

  const handleCloseHarvestPopup = () => {
    setActiveHarvestPid(null)
  }

  const handleOpenWithdrawPopup = (pid: number) => {
    setActiveWithdrawPid(pid)
  }

  const handleCloseWithdrawPopup = () => {
    setActiveWithdrawPid(null)
  }

  return (
    <Stack minHeight="100vh" width={1}>
      <FarmHero />

      <Box flex={1} bgcolor={darkMode ? '#02111a' : LIGHT_GREEN} pt={5} pb={10}>
        <Container maxWidth="lg" sx={{ '&.MuiContainer-maxWidthLg': { maxWidth: '83rem' } }}>
          <FarmFilter
            filter={filter}
            onViewChange={handleViewChange}
            onToggleStackedOnly={handleToggleStackedOnly}
            onSearchChange={handleSearchChange}
          />

          {isFetching && !userDataLoaded && (
            <Box textAlign="center" pt={5}>
              <img src={darkMode ? LOADING_GIF : LOADING_GIF_LT} alt="" width={150} />
            </Box>
          )}

          {displayedFarmList.length > 0 && (
            <FarmList
              list={displayedFarmList}
              isThumbnailView={isDownMd || filter.view === 2}
              onOpenStakePopup={handleOpenStakePopup}
              onOpenCalculatorPopup={handleOpenCalculatorPopup}
              onOpenHarvestPopup={handleOpenHarvestPopup}
              onOpenWithdrawPopup={handleOpenWithdrawPopup}
            />
          )}

          {!isFetching && displayedFarmList.length === 0 && <EmptyStage />}
        </Container>
      </Box>

      <FarmStakePopup
        isWithdrawMode={false}
        onCloseStakePopup={handleCloseStakePopup}
        onCloseWithdrawPopup={handleCloseWithdrawPopup}
        lockFarmList={activeStakeList}
      />

      <FarmStakePopup
        isWithdrawMode={true}
        onCloseStakePopup={handleCloseStakePopup}
        onCloseWithdrawPopup={handleCloseWithdrawPopup}
        lockFarmList={activeWithdrawList}
      />

      <FarmCalculatorPopup lockFarmList={activeCalculatorList} onCloseCalculatorPopup={handleCloseCalculatorPopup} />
      <FarmHarvestPopup onCloseHarvestPopup={handleCloseHarvestPopup} lockFarmList={activeHarvestList} />
    </Stack>
  )
}

export default Farm
