import { Currency, CurrencyAmount, Fraction, Percent } from '@adaswap/sdk'
import { Divider } from '@mui/material'
import React from 'react'
import { Text } from 'rebass'
import styled from 'styled-components'

import { ButtonPrimary } from '../../components/Button'
import CurrencyLogo from '../../components/CurrencyLogo'
import { RowBetween, RowFixed } from '../../components/Row'
import { Field } from '../../state/mint/actions'
import { TYPE } from '../../theme'

const TextBody = styled(TYPE.body)`
  font-size: 18px;
`

export function ConfirmAddModalBottom({
  noLiquidity,
  price,
  currencies,
  parsedAmounts,
  poolTokenPercentage,
  onAdd
}: {
  noLiquidity?: boolean
  price?: Fraction
  currencies: { [field in Field]?: Currency }
  parsedAmounts: { [field in Field]?: CurrencyAmount }
  poolTokenPercentage?: Percent
  onAdd: () => void
}) {
  return (
    <>
      <RowBetween>
        <TextBody>Rates</TextBody>
        <TextBody>
          {`1 ${currencies[Field.CURRENCY_A]?.symbol} = ${price?.toSignificant(4)} ${
            currencies[Field.CURRENCY_B]?.symbol
          }`}
        </TextBody>
      </RowBetween>

      <RowBetween style={{ justifyContent: 'flex-end' }}>
        <TextBody>
          {`1 ${currencies[Field.CURRENCY_B]?.symbol} = ${price?.invert().toSignificant(4)} ${
            currencies[Field.CURRENCY_A]?.symbol
          }`}
        </TextBody>
      </RowBetween>

      <Divider sx={{ borderColor: 'primary.main', my: 2 }} />

      <RowBetween>
        <TextBody>{currencies[Field.CURRENCY_A]?.symbol} Deposited</TextBody>
        <RowFixed>
          <CurrencyLogo currency={currencies[Field.CURRENCY_A]} style={{ marginRight: '8px' }} />
          <TextBody>{parsedAmounts[Field.CURRENCY_A]?.toSignificant(6)}</TextBody>
        </RowFixed>
      </RowBetween>

      <RowBetween>
        <TextBody>{currencies[Field.CURRENCY_B]?.symbol} Deposited</TextBody>
        <RowFixed>
          <CurrencyLogo currency={currencies[Field.CURRENCY_B]} style={{ marginRight: '8px' }} />
          <TextBody>{parsedAmounts[Field.CURRENCY_B]?.toSignificant(6)}</TextBody>
        </RowFixed>
      </RowBetween>

      <RowBetween>
        <TextBody>Share of Pool:</TextBody>
        <TextBody>{noLiquidity ? '100' : poolTokenPercentage?.toSignificant(4)}%</TextBody>
      </RowBetween>
      <ButtonPrimary style={{ margin: '20px 0 0 0' }} onClick={onAdd}>
        <Text fontWeight={500} fontSize={20}>
          {noLiquidity ? 'Create Pool & Supply' : 'CONFIRM SUPPLY'}
        </Text>
      </ButtonPrimary>
    </>
  )
}
