import { Stack } from '@mui/material'
import { FarmItemTooltip } from 'components/farm'
import { StackedFilter } from 'components/filter'
import { AutoRow } from 'components/Row'
import { DARK_GREEN, LIGHT_GREEN } from 'constants/index'
import { darken } from 'polished'
import { useContext } from 'react'
import { Text } from 'rebass'
import styled, { ThemeContext } from 'styled-components'

import { AutoColumn } from '../Column'
import { RowBetween, RowFixed } from '../Row'

enum SlippageError {
  InvalidInput = 'InvalidInput',
  RiskyLow = 'RiskyLow',
  RiskyHigh = 'RiskyHigh'
}

enum DeadlineError {
  InvalidInput = 'InvalidInput'
}

const FancyButton = styled.button`
  color: ${({ theme }) => theme.text1};
  align-items: center;
  height: 2.5rem;
  border-radius: 36px;
  font-size: 16px;
  width: auto;
  // padding: .5rem .75rem;
  min-width: 25%;
  background: ${({ theme }) => theme.bg1};
  font-weight: 500;
  /* border: 1px solid ${({ theme }) => theme.bg3}; */
  /* outline: none; */
  /* :hover {
    border: 1px solid ${({ theme }) => theme.bg4};
  } */
  /* :focus {
    border: 1px solid ${({ theme }) => theme.primary1};
  } */

  ${({ theme }) => theme.mediaWidth.upToSmall`
    min-width: 110px;
  `};
`

const Option = styled(FancyButton)<{ active: boolean }>`
  // margin-right: 8px;
  :hover {
    cursor: pointer;
  }
  background-color: ${({ active, theme }) =>
    active ? (theme.isDarkTheme ? theme.primary0 : DARK_GREEN) : 'transparent'};
  color: ${({ active, theme }) => (active ? (theme.isDarkTheme ? theme.black : LIGHT_GREEN) : theme.primary2)};
  border: 1px solid ${({ theme }) => (theme.isDarkTheme ? theme.primary0 : DARK_GREEN)};
`

const Input = styled.input`
  background: ${({ theme }) => theme.bg1};
  font-size: 16px;
  width: auto;
  outline: none;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  color: ${({ theme, color }) => (color === 'red' ? theme.red1 : theme.text1)};
  text-align: right;
`

const OptionCustom = styled(FancyButton)<{ active?: boolean; warning?: boolean }>`
  height: 2rem;
  position: relative;
  padding: 0 0.75rem;
  flex: 1;
  border: ${({ theme, active, warning }) => active && `1px solid ${warning ? theme.red1 : theme.primary1}`};
  :hover {
    border: ${({ theme, active, warning }) =>
      active && `1px solid ${warning ? darken(0.1, theme.red1) : darken(0.1, theme.primary1)}`};
  }

  input {
    width: 100%;
    height: 100%;
    border: 0px;
    border-radius: 2rem;
  }
`

const SlippageEmojiContainer = styled.span`
  color: #f3841e;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: none;  
  `}
`

const Tittle = styled(RowFixed)`
  margin-bottom: 0.5rem;
  gap: 0.5rem;
`

export interface SlippageTabsProps {
  rawSlippage: number
  setRawSlippage: (rawSlippage: number) => void
  deadline: number
  setDeadline: (deadline: number) => void
  isActiveMultihop: boolean
  onToggleMultiHop: () => void
}

export default function SlippageTabs({
  rawSlippage,
  setRawSlippage,
  deadline,
  setDeadline,
  isActiveMultihop,
  onToggleMultiHop
}: SlippageTabsProps) {
  const theme = useContext(ThemeContext)

  // const inputRef = useRef<HTMLInputElement>()

  // const [slippageInput, setSlippageInput] = useState('')
  // const [deadlineInput, setDeadlineInput] = useState('')

  // const slippageInputIsValid =
  //   slippageInput === '' || (rawSlippage / 100).toFixed(2) === Number.parseFloat(slippageInput).toFixed(2)
  // const deadlineInputIsValid = deadlineInput === '' || (deadline / 60).toString() === deadlineInput

  // let slippageError: SlippageError | undefined
  // if (slippageInput !== '' && !slippageInputIsValid) {
  //   slippageError = SlippageError.InvalidInput
  // } else if (slippageInputIsValid && rawSlippage < 50) {
  //   slippageError = SlippageError.RiskyLow
  // } else if (slippageInputIsValid && rawSlippage > 500) {
  //   slippageError = SlippageError.RiskyHigh
  // } else {
  //   slippageError = undefined
  // }

  // let deadlineError: DeadlineError | undefined
  // if (deadlineInput !== '' && !deadlineInputIsValid) {
  //   deadlineError = DeadlineError.InvalidInput
  // } else {
  //   deadlineError = undefined
  // }

  // function parseCustomSlippage(value: string) {
  //   setSlippageInput(value)

  //   try {
  //     const valueAsIntFromRoundedFloat = Number.parseInt((Number.parseFloat(value) * 100).toString())
  //     if (!Number.isNaN(valueAsIntFromRoundedFloat) && valueAsIntFromRoundedFloat < 5000) {
  //       setRawSlippage(valueAsIntFromRoundedFloat)
  //     }
  //   } catch {}
  // }

  // function parseCustomDeadline(value: string) {
  //   setDeadlineInput(value)

  //   try {
  //     const valueAsInt: number = Number.parseInt(value) * 60
  //     if (!Number.isNaN(valueAsInt) && valueAsInt > 0) {
  //       setDeadline(valueAsInt)
  //     }
  //   } catch {}
  // }

  const SettingTooltip = ({ text }: { text: string }) => <FarmItemTooltip size={20} contentList={[text]} />

  return (
    <AutoColumn gap="md">
      <AutoColumn gap="sm" style={{ paddingTop: '2rem' }}>
        <Tittle>
          <Text fontSize={18} color={theme.text1} fontWeight={400}>
            Slippage tolerance
          </Text>

          <SettingTooltip text="Your transaction will revert if the price changes unfavorably by more than this percentage." />
        </Tittle>

        <Stack direction="row" spacing={3}>
          <Option
            onClick={() => {
              // setSlippageInput('')
              setRawSlippage(10)
            }}
            active={rawSlippage === 10}
          >
            0.1%
          </Option>
          <Option
            onClick={() => {
              // setSlippageInput('')
              setRawSlippage(50)
            }}
            active={rawSlippage === 50}
          >
            0.5%
          </Option>
          <Option
            onClick={() => {
              // setSlippageInput('')
              setRawSlippage(100)
            }}
            active={rawSlippage === 100}
          >
            1%
          </Option>
          {/* <OptionCustom active={![10, 50, 100].includes(rawSlippage)} warning={!slippageInputIsValid} tabIndex={-1}>
            <RowBetween>
              {!!slippageInput &&
              (slippageError === SlippageError.RiskyLow || slippageError === SlippageError.RiskyHigh) ? (
                <SlippageEmojiContainer>
                  <span role="img" aria-label="warning">
                    ⚠️
                  </span>
                </SlippageEmojiContainer>
              ) : null} */}
          {/* https://github.com/DefinitelyTyped/DefinitelyTyped/issues/30451 */}
          {/* <Input
                ref={inputRef as any}
                placeholder={(rawSlippage / 100).toFixed(2)}
                value={slippageInput}
                onBlur={() => {
                  parseCustomSlippage((rawSlippage / 100).toFixed(2))
                }}
                onChange={e => parseCustomSlippage(e.target.value)}
                color={!slippageInputIsValid ? 'red' : ''}
              />
              %
            </RowBetween>
          </OptionCustom> */}
        </Stack>
        {/* {!!slippageError && (
          <RowBetween
            style={{
              fontSize: '14px',
              paddingTop: '7px',
              color: slippageError === SlippageError.InvalidInput ? 'red' : '#F3841E'
            }}
          >
            {slippageError === SlippageError.InvalidInput
              ? 'Enter a valid slippage percentage'
              : slippageError === SlippageError.RiskyLow
              ? 'Your transaction may fail'
              : 'Your transaction may be frontrun'}
          </RowBetween>
        )} */}
      </AutoColumn>

      <AutoColumn gap="sm" style={{ paddingTop: '2rem' }}>
        <Tittle>
          <Text fontSize={18} color={theme.text1} fontWeight={400}>
            Transaction deadline time
          </Text>
          <SettingTooltip text="Your transaction will revert if it is pending for more than this long." />
        </Tittle>
        <RowBetween>
          {/* <OptionCustom style={{ width: '80px' }} tabIndex={-1}>
            <Input
              color={!!deadlineError ? 'red' : undefined}
              onBlur={() => {
                parseCustomDeadline((deadline / 60).toString())
              }}
              placeholder={(deadline / 60).toString()}
              value={deadlineInput}
              onChange={e => parseCustomDeadline(e.target.value)}
            />
          </OptionCustom>
          <TYPE.body style={{ paddingLeft: '8px' }} fontSize={14}>
            minutes
          </TYPE.body> */}
          <Option active={true}>{(deadline / 60).toString()} mins</Option>
        </RowBetween>
      </AutoColumn>

      <AutoRow
        style={{ paddingTop: '2rem', display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}
      >
        <Tittle>
          <Text fontSize={18} color={theme.text1} fontWeight={400}>
            Disable Multihops
          </Text>
          <SettingTooltip text="Restricts swaps to direct pairs only." />
        </Tittle>
        {/* <SwitchField checked={isActiveMultihop} onChange={onToggleMultiHop} /> */}
        <StackedFilter hideLabel isChecked={isActiveMultihop} onToggle={onToggleMultiHop} />
      </AutoRow>
    </AutoColumn>
  )
}
