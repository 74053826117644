import { createAsyncThunk } from '@reduxjs/toolkit'
import { BigNumber, Contract } from 'ethers'
import { PAIRS } from 'graphql/query'
import { apolloClient } from 'index'
import { Pool, SerializedFarm } from 'models'
import { AppState } from 'state'
import { getSerializedWrappedToken } from 'utils/farm'

import {
  fetchMcAdaswapPerSecond,
  fetchMcLockTimeLength,
  fetchMcPoolLength,
  fetchMcTotalAllocPoint
} from './fetchMasterChefData'

export interface PoolInfo {
  allocPoint: BigNumber
  weight: BigNumber
  accAdaSwapPerShare: BigNumber
  lastRewardTime: BigNumber
}

const LIMIT = 10
const range = (start: number, stop: number, step = 1) => {
  return Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step)
}

const fetchFarmChunk = async (
  masterChefContract: Contract,
  chainId: number,
  start: number,
  stop: number,
  lockTimeLength: number,
  account?: string
) => {
  const poolLengthList = range(start, stop)
  const poolInfoCallList: Promise<PoolInfo>[] = poolLengthList.map(pid => masterChefContract.poolInfo(pid))
  const lpAddressCallList: Promise<string>[] = poolLengthList.map(pid => masterChefContract.lpToken(pid))
  // const lockBitMaskedCallList: Promise<string>[] = poolLengthList.map(pid => masterChefContract.lockBitMasked(pid))

  const [poolInfoList, lpAddressList, poolQueryResult] = await Promise.all([
    Promise.all(poolInfoCallList),
    Promise.all(lpAddressCallList),
    apolloClient.query<{ pairs: Pool[] }>({ query: PAIRS })
  ])

  const baseFarmList: SerializedFarm[] = []

  for (let pid = 0; pid <= stop - start; pid++) {
    const lpAddress = lpAddressList[pid].toLowerCase()
    const poolQuery = poolQueryResult.data.pairs.find(pair => pair.id.toLowerCase() === lpAddress)

    if (!poolQuery) continue
    const { token0, token1, reserveUSD, totalSupply } = poolQuery

    for (let lockTimeId = 0; lockTimeId < lockTimeLength; lockTimeId++) {
      const farm: SerializedFarm = {
        pid: pid + start,
        lockTimeId,
        lpAddress,
        lpSymbol: `${token0.symbol}-${token1.symbol}`,
        token: getSerializedWrappedToken(token0, chainId),
        quoteToken: getSerializedWrappedToken(token1, chainId),

        lpTokenPrice: +totalSupply > 0 ? (+reserveUSD / +totalSupply).toString() : undefined
      }

      baseFarmList.push(farm)
    }
  }

  const lockInfoCallList = baseFarmList.map(farm => masterChefContract.lockInfo(farm.pid, farm.lockTimeId))
  const userInfoCallList = baseFarmList.map(farm => masterChefContract.userInfo(farm.pid, farm.lockTimeId, account))
  const pendingAdaSwapCallList = baseFarmList.map(farm =>
    masterChefContract.pendingAdaSwap(farm.pid, farm.lockTimeId, account)
  )

  const [lockInfoList, userInfoList, pendingAdaSwapList]: [
    Array<{ allocPoint: BigNumber; supply: BigNumber }>,
    Array<{ amount: BigNumber }>,
    BigNumber[]
  ] = await Promise.all([
    Promise.all(lockInfoCallList),
    Promise.all(userInfoCallList),
    Promise.all(pendingAdaSwapCallList)
  ])

  const farmList: SerializedFarm[] = lockInfoList.map(({ allocPoint, supply }, index) => {
    const farm: SerializedFarm = baseFarmList[index]
    const { allocPoint: poolAllocPoint } = poolInfoList[farm.pid - start]

    const [allocPointNum, poolAllocPointNum] = [Number(allocPoint), Number(poolAllocPoint)]
    const multiplier = !allocPointNum || !poolAllocPointNum ? 0 : (allocPointNum / poolAllocPointNum).toFixed(2)

    return {
      ...farm,
      poolWeight: allocPoint.mul(supply).toString(),
      lpTotalSupply: supply.toString(),
      multiplier: `${multiplier}X`,
      allocPoint: Number(allocPoint),

      userData: {
        allowance: '0',
        tokenBalance: '0',
        stakedBalance: userInfoList[index].amount.toString(),
        earnings: pendingAdaSwapList[index].toString()
      }
    }
  })

  return farmList
}

export const fetchFarmList = createAsyncThunk<
  {
    farmList: SerializedFarm[]
    totalFetched: number
    poolLength: number
    adaswapPerSecond: number
    totalAllocPoint: number
    lockTimeLength: number
  },
  { masterChefContract: Contract; chainId: number; account?: string },
  {
    state: AppState
  }
>(
  'farms/fetchFarmList',
  async ({ masterChefContract, chainId, account }, thunkApi) => {
    const [poolLength, lockTimeLength, totalAllocPoint, adaswapPerSecond] = await Promise.all([
      fetchMcPoolLength(masterChefContract),
      fetchMcLockTimeLength(masterChefContract),
      fetchMcTotalAllocPoint(masterChefContract),
      fetchMcAdaswapPerSecond(masterChefContract)
    ])

    const { userDataLoaded, totalFetched } = thunkApi.getState().farms
    const stop = userDataLoaded ? totalFetched : Math.min(poolLength, LIMIT)
    const farmList = await fetchFarmChunk(masterChefContract, chainId, 0, stop - 1, lockTimeLength, account)

    return { farmList, totalFetched: stop, poolLength, adaswapPerSecond, totalAllocPoint, lockTimeLength }
  },
  {
    condition: (_, { getState }) => {
      const { farms } = getState()
      if (farms.isFetching) {
        return false
      }
      return true
    }
  }
)

export const fetchFarmMore = createAsyncThunk<
  {
    farmList: SerializedFarm[]
    totalFetched: number
  },
  { masterChefContract: Contract; chainId: number; account?: string },
  {
    state: AppState
  }
>('farms/fetchFarmMore', async ({ masterChefContract, chainId, account }, thunkApi) => {
  const { totalFetched, poolLength, lockTimeLength } = thunkApi.getState().farms
  const start = totalFetched
  const stop = totalFetched + LIMIT >= poolLength ? poolLength : totalFetched + LIMIT
  const farmList = await fetchFarmChunk(masterChefContract, chainId, start, stop - 1, lockTimeLength, account)
  return { farmList, totalFetched: stop }
})
