import { ChainId, Percent, Token, TokenAmount } from '@adaswap/sdk'
import { Box, Button, Stack, Typography } from '@mui/material'
import { PercentInputField } from 'components/form-fields'
import LinkWithIcon from 'components/pool/LinkWithIcon'
import TokenPair from 'components/pool/TokenPair'
import Popup from 'components/Popup/Popup'
import TransactionConfirmationModal from 'components/TransactionConfirmationModal'
import {
  BIG_INT_ZERO,
  BRAND_COLOR,
  BRAND_COLOR_LIGHT,
  DARK_BLUE,
  DARK_GREEN,
  getMcAddress,
  WRAPPED_ADA
} from 'constants/index'
import { useActiveWeb3React } from 'hooks'
import { ApprovalState, useApproveCallback } from 'hooks/useApproveCallback'
import { useCheckCanUnlockOrWithDraw } from 'hooks/useCheckCanUnlockOrWithDraw'
import { useDepositToFarm } from 'hooks/useDepositToFarm'
import { useWithdrawFarm } from 'hooks/useWithdrawFarm'
import { FarmWithStakedValue } from 'models'
import { DEFAULT_LOCK_TIME_INDEX } from 'pages/Farm'
import { Dots, MaxButton } from 'pages/PoolUni/styleds'
import { ChangeEvent, useEffect, useMemo, useState } from 'react'
import { useLockFarmIndex } from 'state/farm/hooks'
import { useIsDarkMode } from 'state/user/hooks'
import { useTokenBalance } from 'state/wallet/hooks'
import { StakeIcon } from 'svgComponents'
import { getTokenIcon, nativeWrappedAdaToken } from 'utils'
import lockTimeIdToDay from 'utils/lockTimeIdToDay'

import { ToggleButtonGroup } from './FarmCalculatorPopup/ToggleButtonGroup'
import FarmCountdown from './FarmCountdown'

export interface FarmStakePopupProps {
  onCloseStakePopup: () => void
  onCloseWithdrawPopup: () => void
  onOpenCalculatorFromStake?: () => void
  lockFarmList: FarmWithStakedValue[]
  isWithdrawMode: boolean
}

export function FarmStakePopup({
  onCloseStakePopup,
  onCloseWithdrawPopup,
  onOpenCalculatorFromStake,
  lockFarmList,
  isWithdrawMode
}: FarmStakePopupProps) {
  const darkMode = useIsDarkMode()
  const { account, chainId } = useActiveWeb3React()
  const lightColor = darkMode ? BRAND_COLOR_LIGHT : DARK_BLUE
  const whiteColor = darkMode ? 'white' : DARK_GREEN

  const whiteLabel = {
    fontSize: { xs: '1rem', sm: '1.125rem', md: '1.25rem' },
    fontWeight: 400,
    color: whiteColor
  }

  const [lockTimeIndex, setLockTimeIndex] = useState(DEFAULT_LOCK_TIME_INDEX)

  const [typedValue, setTypedValue] = useState('0')
  const percentToStake = useMemo(() => new Percent(typedValue, '100'), [typedValue])

  const [hash, setHash] = useState('')
  const [attemptingTxn, setAttemptingTxn] = useState(false)

  const { activeLockFarm, lockIdList } = useLockFarmIndex(lockFarmList, lockTimeIndex, isWithdrawMode)
  const { lpAddress, token, quoteToken, userData } = activeLockFarm ?? {}
  const [wrappedToken, wrapperQuoteToken] = [nativeWrappedAdaToken(token), nativeWrappedAdaToken(quoteToken)]
  const farmSymbol = `${wrappedToken.symbol}-${wrapperQuoteToken.symbol}`

  useEffect(() => {
    if (activeLockFarm !== null || !isWithdrawMode) return

    if (lockIdList.length === 0) {
      setHash('')
      onCloseWithdrawPopup()
    } else {
      setLockTimeIndex(DEFAULT_LOCK_TIME_INDEX)
    }
  }, [activeLockFarm, lockIdList, isWithdrawMode])

  const { isEnoughLockTime, unlockTime, loading } = useCheckCanUnlockOrWithDraw(activeLockFarm)

  const alpToken = lpAddress ? new Token(chainId as ChainId, lpAddress, 18, 'ALP', 'ALP') : WRAPPED_ADA
  const userPoolBalance = useTokenBalance(account ?? undefined, alpToken)
  const userStakedBalance = new TokenAmount(alpToken, userData ? userData.stakedBalance.toString() : '')
  const userBalance = isWithdrawMode ? userStakedBalance : userPoolBalance

  const tokenAmount = useMemo(
    () => new TokenAmount(alpToken, userBalance ? percentToStake.multiply(userBalance.raw).quotient : BIG_INT_ZERO),
    [alpToken, userBalance, percentToStake]
  )

  const [approval, approveCallback] = useApproveCallback(tokenAmount, getMcAddress(chainId))
  const onDeposit = useDepositToFarm(activeLockFarm, setHash, setAttemptingTxn)
  const onWithdraw = useWithdrawFarm(activeLockFarm, setHash, setAttemptingTxn)

  const handlePercentChange = (event: ChangeEvent<HTMLInputElement>) => {
    const isValid = event.target.validity.valid && Number(event.target.value) <= 100
    setTypedValue(isValid ? event.target.value : percentToStake.toFixed(0))
  }

  const getConfirmButtonContent = () => {
    if (userBalance && userBalance.equalTo(BIG_INT_ZERO)) return 'Insufficient balance'
    if (percentToStake.equalTo(BIG_INT_ZERO)) return 'Enter an amount'
    if (isWithdrawMode) return <FarmCountdown unlockTime={unlockTime} text="CONFIRM WITHDRAW" />
    return 'CONFIRM DEPOSIT'
  }

  return (
    <Popup
      open={lockFarmList.length > 0}
      onClose={isWithdrawMode ? onCloseWithdrawPopup : onCloseStakePopup}
      scroll="body"
      paperStyles={{ pt: 8, pb: 5, maxWidth: 576 }}
    >
      <TransactionConfirmationModal
        isOpen={attemptingTxn || Boolean(hash)}
        attemptingTxn={attemptingTxn}
        hash={hash}
        currencyAmountA={activeLockFarm ? new TokenAmount(wrappedToken, BIG_INT_ZERO) : undefined}
        currencyAmountB={activeLockFarm ? new TokenAmount(wrapperQuoteToken, BIG_INT_ZERO) : undefined}
        liquidityTokenAmount={tokenAmount}
        pendingText={isWithdrawMode ? 'Unstacking' : 'Staking'}
        onDismiss={() => {
          setHash('')
          !attemptingTxn && (isWithdrawMode ? onCloseWithdrawPopup() : onCloseStakePopup())
        }}
      />

      <Stack direction="row" alignItems="center" justifyContent="center" spacing={2} pb={5}>
        <StakeIcon color={darkMode ? BRAND_COLOR : DARK_GREEN} />
        <Typography fontSize={{ xs: '1.75rem', md: '2rem' }} color={lightColor}>
          {isWithdrawMode ? 'Withdraw' : 'Stake'}
        </Typography>
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="center" spacing={2}>
        <TokenPair icon1={getTokenIcon(wrappedToken.symbol)} icon2={getTokenIcon(wrapperQuoteToken.symbol)} />
        <Typography fontSize="1.25rem" color={lightColor}>
          {farmSymbol}
        </Typography>
      </Stack>

      <Box py={4}>
        {/* <CurrencyInputPanel
          id="stake-liquidity-input"
          value="213"
          onUserInput={val => console.log(val)}
          showMaxButton={true}
          currency={ETHER}
          disableCurrencySelect
          disableCurrencyLogo
        /> */}

        <Stack direction="row" justifyContent="space-between" alignItems="center" padding="0.75rem 1rem">
          <Typography component="span" sx={whiteLabel}>
            {isWithdrawMode ? 'Staked' : 'Balance'}
          </Typography>

          {account && (
            <Typography component="span" sx={whiteLabel}>
              {userBalance ? userBalance.toSignificant(6) : '0.00'}
            </Typography>
          )}
        </Stack>

        <PercentInputField
          label={isWithdrawMode ? 'Amount to withdraw' : 'Amount to stake'}
          value={percentToStake.greaterThan(new Percent('0')) ? percentToStake.toFixed(0) : ''}
          onChange={handlePercentChange}
        />

        <Stack direction="row" justifyContent="flex-end" pt={2} pb={4}>
          <MaxButton active={percentToStake.toFixed(0) === '25'} onClick={() => setTypedValue('25')} width="20%">
            25%
          </MaxButton>
          <MaxButton active={percentToStake.toFixed(0) === '50'} onClick={() => setTypedValue('50')} width="20%">
            50%
          </MaxButton>
          <MaxButton active={percentToStake.toFixed(0) === '75'} onClick={() => setTypedValue('75')} width="20%">
            75%
          </MaxButton>
          <MaxButton active={percentToStake.toFixed(0) === '100'} onClick={() => setTypedValue('100')} width="20%">
            Max
          </MaxButton>
        </Stack>

        <ToggleButtonGroup
          heading={isWithdrawMode ? 'Choose farm to withdraw' : 'Locked for'}
          data={lockIdList.map(lockTimeIdToDay).map(x => x.symbol)}
          selected={lockTimeIndex}
          onSelect={setLockTimeIndex}
        />
      </Box>

      {/* {!isWithdrawMode && (
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Typography color={whiteColor} fontWeight="normal">
            Annual ROI at current Rates
          </Typography>

          <Stack direction="row" alignItems="center" spacing={2}>
            <Typography color={lightColor} fontSize={{ xs: '1rem', md: '1.125rem' }}>
              $0
            </Typography>

            <Stack
              alignItems="center"
              onClick={onOpenCalculatorFromStake}
              sx={{
                ':hover': {
                  cursor: 'pointer',
                  opacity: 0.5,
                  transition: 'all 200ms ease-in-out'
                }
              }}
            >
              <CalculatorIcon color={whiteColor} />
            </Stack>
          </Stack>
        </Stack>
      )} */}

      <Stack
        spacing={1}
        direction={{ xs: 'column', sm: 'row' }}
        justifyContent="space-between"
        alignItems="center"
        pb={4}
      >
        <Typography fontWeight="normal" color="primary.main">
          Need more {farmSymbol} LP?
        </Typography>
        <LinkWithIcon
          text={`Get ${farmSymbol} LP`}
          underline
          href={`/add/${wrappedToken.address}/${wrapperQuoteToken.address}`}
        />
      </Stack>

      {!isWithdrawMode && (approval === ApprovalState.NOT_APPROVED || approval === ApprovalState.PENDING) ? (
        <Button
          fullWidth
          variant="outlined"
          sx={{ fontSize: '1.25rem', py: 2, textTransform: 'none' }}
          disabled={approval === ApprovalState.PENDING}
          onClick={approveCallback}
        >
          {approval === ApprovalState.PENDING ? <Dots>{`Approving ${farmSymbol}`}</Dots> : `Approve ${farmSymbol}`}
        </Button>
      ) : (
        <Button
          fullWidth
          variant="outlined"
          sx={{
            fontSize: { xs: '1.125rem', md: '1.25rem' },
            height: 70,
            textTransform: 'none'
          }}
          disabled={
            (!isWithdrawMode && approval !== ApprovalState.APPROVED) ||
            (isWithdrawMode && (loading || !isEnoughLockTime)) ||
            tokenAmount.equalTo(BIG_INT_ZERO)
          }
          onClick={() => (isWithdrawMode ? onWithdraw(tokenAmount) : onDeposit(tokenAmount))}
        >
          {getConfirmButtonContent()}
        </Button>
      )}
    </Popup>
  )
}
