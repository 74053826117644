import * as React from 'react'

interface Props {
  color?: string
  style?: any
}

export const LiquidityIcon = (props: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={30} height={40} viewBox="0 0 30 40" fill="none" style={props.style}>
    <path
      d="M15.88.365a1.244 1.244 0 0 0-1.76 0C13.54.938 0 14.52 0 23.408 0 32.558 6.729 40 15 40s15-7.443 15-16.592C30 14.518 16.458.938 15.88.365zM15 37.5c-6.892 0-12.5-6.323-12.5-14.092 0-6.657 9.517-17.216 12.5-20.36 2.983 3.144 12.5 13.703 12.5 20.36 0 7.77-5.608 14.092-12.5 14.092z"
      fill={props.color || '#7CE5CF'}
    />
  </svg>
)
