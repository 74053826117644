import { TokenAmount } from '@adaswap/sdk'
import AddRoundedIcon from '@mui/icons-material/AddRounded'
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'
import NorthRoundedIcon from '@mui/icons-material/NorthRounded'
import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded'
import { Accordion, AccordionDetails, AccordionSummary, Button, Divider, Grid, Stack, Typography } from '@mui/material'
import { useWeb3React } from '@web3-react/core'
import LinkWithIcon from 'components/pool/LinkWithIcon'
import TokenPair from 'components/pool/TokenPair'
import {
  ASW_TESTNET,
  BRAND_COLOR,
  BRAND_COLOR_LIGHT,
  DARK_BG_GRADIENT,
  DARK_GREEN,
  LIGHT_BG_GRADIENT
} from 'constants/index'
import { useWalletModalToggle } from 'state/application/hooks'
import { useAswPrice } from 'state/farm/hooks'
import { useIsDarkMode } from 'state/user/hooks'
import { CalculatorIcon } from 'svgComponents'
import { getTokenIcon } from 'utils'
import { getDisplayAprPercent } from 'utils/apr'
import { BIG_ZERO, ethersToBigNumber } from 'utils/bigNumber'
import { getFullDisplayBalance } from 'utils/formatBalance'

import { FarmItemProps, FarmItemTooltip } from '.'

export interface Content {
  name: string
  value: string
  description?: string[]
  isUpTo?: boolean
  size?: number
}

const TEXT_SIZE = { xs: '0.875rem', lg: '1rem' }

const mapToItem = (
  { name, value, description, isUpTo = false, size = 2 }: Content,
  darkMode: boolean,
  onOpenCalculator: () => void
) => {
  const color = darkMode ? 'white' : DARK_GREEN
  const isAPR = name === 'APR'

  return (
    <Grid item key={name} xs={size}>
      <Stack spacing={1} alignItems="flex-start">
        <Typography color={color} fontSize={TEXT_SIZE}>
          {name}
        </Typography>

        <Stack
          spacing={1}
          direction="row"
          alignItems="center"
          sx={{
            transition: 'all 200ms ease-in-out',
            ':hover': { opacity: isAPR ? 0.5 : 1 }
          }}
          onClick={event => {
            if (!isAPR) return

            event.stopPropagation()
            onOpenCalculator()
          }}
        >
          <Stack direction="row" alignItems="center">
            {isUpTo && value !== '0%' && <NorthRoundedIcon sx={{ color: 'primary.main', fontSize: 16 }} />}
            <Typography color={color} fontSize={TEXT_SIZE}>
              {value}
            </Typography>
          </Stack>

          {isAPR && <CalculatorIcon color={color} />}
          {!isAPR && (description ? <FarmItemTooltip contentList={description} /> : <FarmItemTooltip />)}
        </Stack>
      </Stack>
    </Grid>
  )
}

export function FarmItemListView({
  farm,
  actionLink,
  totalStacked,
  onOpenStake,
  onOpenWithdraw,
  onOpenCalculator,
  onOpenHarvest
}: FarmItemProps) {
  const darkMode = useIsDarkMode()
  const aswPrice = useAswPrice()
  const { active } = useWeb3React()
  const toggleWalletModal = useWalletModalToggle()

  const { token, quoteToken, maxMultiplier, maxApr, maxLpApr, totalStakedBalance, totalPendingAsw, lpTokenPrice } = farm
  const farmSymbol = `${token.symbol}-${quoteToken.symbol}`

  const [formattedTotalStakedBalance, formattedTotalPendingAsw] = [
    totalStakedBalance ? getFullDisplayBalance(totalStakedBalance, 18, 18) : '',
    // totalPendingAsw ?  getFullDisplayBalance(totalPendingAsw, 18, 9) : ''
    new TokenAmount(ASW_TESTNET, totalPendingAsw ? totalPendingAsw.toString() : '')
  ]

  const [usdTotalStakedBalance, usdTotalPendingAsw] = [
    totalStakedBalance ? getFullDisplayBalance(totalStakedBalance.times(lpTokenPrice ?? BIG_ZERO), 18, 3) : '',
    totalPendingAsw ? getFullDisplayBalance(ethersToBigNumber(totalPendingAsw).times(aswPrice), 18, 3) : ''
  ]

  const [hasStackedBalance, hasPendingAsw] = [
    totalStakedBalance ? totalStakedBalance.gt(0) : false,
    totalPendingAsw ? totalPendingAsw.gt(0) : false
  ]

  const CONTENT_LIST: Content[] = [
    {
      name: 'APR',
      value: getDisplayAprPercent(maxApr, maxLpApr),
      isUpTo: true,
      size: 2.5
    },
    {
      name: 'Total Staked',
      value: totalStacked,
      description: ['Total value of the funds in this farm’s liquidity pool']
    },
    {
      name: 'Multiplier',
      value: `${maxMultiplier ?? 0}X`,
      isUpTo: true
    }
  ]

  const boxedSx = {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'space-between',

    border: 1,
    borderColor: 'primary.main',
    borderRadius: 2,
    p: 2
  }

  return (
    <Accordion
      disableGutters
      sx={{
        px: 3,
        background: darkMode ? DARK_BG_GRADIENT : LIGHT_BG_GRADIENT,
        borderRadius: '24px !important',
        border: darkMode ? `1px solid ${BRAND_COLOR}` : 'none',
        boxShadow: 'none',

        '&.Mui-expanded': {
          boxShadow: darkMode ? `0 0 10px -5px ${BRAND_COLOR}` : 'none'
        },

        '.MuiAccordionSummary-content': { my: 2 }
      }}
    >
      <AccordionSummary expandIcon={<ExpandMoreRoundedIcon color="primary" fontSize="large" />} sx={{ px: 0 }}>
        <Grid container columnSpacing={2}>
          <Grid item xs={3.5}>
            <Stack height="100%" direction="row" spacing={2} alignItems="center">
              <TokenPair icon1={getTokenIcon(token.symbol)} icon2={getTokenIcon(quoteToken.symbol)} />
              <Typography>{farmSymbol}</Typography>
            </Stack>
          </Grid>

          <Grid item xs={2}>
            <Stack spacing={1}>
              <Typography color={darkMode ? 'white' : DARK_GREEN} fontSize={TEXT_SIZE}>
                Earned
              </Typography>

              <Typography color={darkMode ? BRAND_COLOR_LIGHT : DARK_GREEN} fontSize={TEXT_SIZE}>
                {formattedTotalPendingAsw.toSignificant(6, { groupSeparator: ',' })}
              </Typography>
            </Stack>
          </Grid>

          {CONTENT_LIST.map(item => mapToItem(item, darkMode, onOpenCalculator))}
        </Grid>
      </AccordionSummary>

      <Divider sx={{ borderColor: darkMode ? 'rgba(124, 229, 207, 0.5)' : 'rgba(28, 65, 79, 0.5)' }} />

      <AccordionDetails sx={{ px: 0, py: 3 }}>
        <Stack direction="row" spacing={{ xs: 10, lg: 5 }} alignItems="center">
          {/* <Box>
            <Button
              variant="outlined"
              startIcon={<RefreshIcon color={darkMode ? BRAND_COLOR : LIGHT_GREEN} />}
              sx={{
                color: darkMode ? BRAND_COLOR : LIGHT_GREEN,
                bgcolor: darkMode ? 'transparent' : DARK_GREEN
              }}
            >
              Auto
            </Button>
          </Box> */}

          <Stack spacing={1} flexShrink={0} alignItems="flex-start">
            <LinkWithIcon text={`Get ${farmSymbol} LP`} to={actionLink.addLiquidity} />
            <LinkWithIcon text="View Contract" href={actionLink.viewContract} />
            <LinkWithIcon text="See Pair Info" href={actionLink.seePairInfo} />
          </Stack>

          <Stack direction={{ xs: 'column', lg: 'row' }} spacing={3} flex={1}>
            <Stack direction="row" sx={boxedSx}>
              <Stack spacing={1} flexShrink={0} height="100%">
                <Typography>
                  ASW&nbsp;
                  <Typography component="span" color={darkMode ? 'white' : DARK_GREEN}>
                    Earned
                  </Typography>
                </Typography>

                <Typography
                  color={darkMode ? BRAND_COLOR_LIGHT : DARK_GREEN}
                  fontWeight={600}
                  fontSize={{ xs: '1.125rem', lg: '1.25rem' }}
                >
                  {formattedTotalPendingAsw.toSignificant(6)}
                </Typography>

                {hasPendingAsw && <Typography fontSize="0.875rem">~ {usdTotalPendingAsw} USD</Typography>}
              </Stack>

              {/* <Box
                component="ul"
                sx={{
                  my: 0,
                  pr: 5,
                  '& > li': { fontSize: TEXT_SIZE }
                }}
              >
                <Typography component="li">1% Unstacking fee if withdrawn within 24h</Typography>
                <Typography component="li">Performance Fee 2%</Typography>
              </Box> */}

              <Button
                onClick={onOpenHarvest}
                variant="outlined"
                disabled={!hasPendingAsw}
                sx={{
                  minWidth: { xs: 200, lg: 210 },
                  py: 1.5,
                  fontSize: TEXT_SIZE
                }}
              >
                Harvest
              </Button>
            </Stack>

            <Stack direction="row" sx={boxedSx}>
              <Stack spacing={1} flexShrink={0} height="100%">
                <Typography>
                  {hasStackedBalance ? farmSymbol : ''}
                  <Typography component="span" color={darkMode ? 'white' : DARK_GREEN}>
                    &nbsp;{hasStackedBalance ? 'Staked' : 'Start farming'}
                  </Typography>
                </Typography>
                <Typography
                  sx={{
                    color: darkMode ? BRAND_COLOR_LIGHT : DARK_GREEN,
                    fontWeight: 600,
                    fontSize: { xs: '1.125rem', lg: '1.25rem' },

                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                  }}
                >
                  {hasStackedBalance ? formattedTotalStakedBalance : ''}
                </Typography>

                {hasStackedBalance && <Typography fontSize="0.875rem">~ {usdTotalStakedBalance} USD</Typography>}
              </Stack>

              {hasStackedBalance ? (
                <Stack direction="row" spacing={2}>
                  <Button
                    variant="outlined"
                    onClick={onOpenWithdraw}
                    sx={{
                      py: 1.25,
                      minWidth: { xs: 92, lg: 64 }
                    }}
                  >
                    <RemoveRoundedIcon sx={{ color: 'primary.light' }} />
                  </Button>

                  <Button
                    variant="outlined"
                    onClick={onOpenStake}
                    sx={{
                      py: 1.25,
                      minWidth: { xs: 92, lg: 64 }
                    }}
                  >
                    <AddRoundedIcon sx={{ color: 'primary.light' }} />
                  </Button>
                </Stack>
              ) : (
                <Button
                  disableElevation
                  variant={active ? 'outlined' : 'contained'}
                  onClick={active ? onOpenStake : toggleWalletModal}
                  sx={{
                    minWidth: { xs: 200, lg: 210 },
                    py: 1.5,
                    fontSize: TEXT_SIZE
                  }}
                >
                  {active ? 'Stake LP' : 'Connect wallet'}
                </Button>
              )}
            </Stack>
          </Stack>
        </Stack>
      </AccordionDetails>
    </Accordion>
  )
}
