import { CurrencyAmount, JSBI, Trade } from '@adaswap/sdk'
import { IconButton } from '@mui/material'
import PreviewBar, { PreviewBarType } from 'components/PreviewBar'
import SettingsTab from 'components/Settings'
import ConfirmSwapModal from 'components/swap/ConfirmSwapModal'
import SwapInfo from 'components/swap/swap-info/SwapInfo'
import SwapHeader from 'components/swap/SwapHeader'
import { BRAND_COLOR_LIGHT, DARK_BLUE } from 'constants/index'
import { useIsTransactionUnsupported } from 'hooks/Trades'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Text } from 'rebass'
import { ApplicationModal } from 'state/application/actions'
import {
  useExpertModeManager,
  useIsDarkMode,
  useUserSingleHopOnly,
  useUserSlippageTolerance,
  useUserTransactionTTL
} from 'state/user/hooks'
import { ThemeContext } from 'styled-components'
import { ArrowDown, ArrowUp } from 'svgComponents'

import { ButtonPrimary } from '../../components/Button'
import { AutoColumn } from '../../components/Column'
import CurrencyInputPanel from '../../components/CurrencyInputPanel'
import { AutoRow, RowBetween } from '../../components/Row'
import confirmPriceImpactWithoutFee from '../../components/swap/confirmPriceImpactWithoutFee'
import { BackgroundFull, Wrapper } from '../../components/swap/styleds'
import TradePrice from '../../components/swap/TradePrice'
import { useActiveWeb3React } from '../../hooks'
import { ApprovalState, useApproveCallbackFromTrade } from '../../hooks/useApproveCallback'
import useENSAddress from '../../hooks/useENSAddress'
import { useSwapCallback } from '../../hooks/useSwapCallback'
import useToggledVersion, { Version } from '../../hooks/useToggledVersion'
import useWrapCallback, { getWrapErrorText, WrapType } from '../../hooks/useWrapCallback'
import { useModalOpen, useWalletModalToggle } from '../../state/application/hooks'
import { Field } from '../../state/swap/actions'
import { useDerivedSwapInfo, useSwapActionHandlers, useSwapState } from '../../state/swap/hooks'
import { maxAmountSpend } from '../../utils/maxAmountSpend'
import { computeTradePriceBreakdown, warningSeverity } from '../../utils/prices'
import AppBody from '../AppBody'
import { Dots, MainButton } from '../PoolUni/styleds'

export default function Swap() {
  const { account } = useActiveWeb3React()
  const theme = useContext(ThemeContext)
  const open = useModalOpen(ApplicationModal.SETTINGS)
  const darkMode = useIsDarkMode()

  // toggle wallet when disconnected
  const toggleWalletModal = useWalletModalToggle()

  // for expert mode
  const [isExpertMode] = useExpertModeManager()

  // get custom setting values for user
  const [allowedSlippage] = useUserSlippageTolerance()
  const [deadline] = useUserTransactionTTL()

  // swap state
  const { independentField, typedValue, recipient } = useSwapState()
  const {
    v1Trade,
    v2Trade,
    currencyBalances,
    parsedAmount,
    currencies,
    inputError: swapInputError
  } = useDerivedSwapInfo()

  const { wrapType, execute: onWrap, inputError: wrapInputError } = useWrapCallback(
    currencies[Field.INPUT],
    currencies[Field.OUTPUT],
    typedValue
  )
  const showWrap: boolean = wrapType !== WrapType.NOT_APPLICABLE
  const { address: recipientAddress } = useENSAddress(recipient)

  const toggledVersion = useToggledVersion()
  const tradesByVersion = {
    [Version.v1]: v1Trade,
    [Version.v2]: v2Trade
  }
  const trade = showWrap ? undefined : tradesByVersion[toggledVersion]

  const parsedAmounts = useMemo(
    () =>
      showWrap
        ? {
            [Field.INPUT]: parsedAmount,
            [Field.OUTPUT]: parsedAmount
          }
        : {
            [Field.INPUT]: independentField === Field.INPUT ? parsedAmount : trade?.inputAmount,
            [Field.OUTPUT]: independentField === Field.OUTPUT ? parsedAmount : trade?.outputAmount
          },
    [independentField, parsedAmount, showWrap, trade]
  )

  const { onSwitchTokens, onCurrencySelection, onUserInput, onChangeRecipient, onReset } = useSwapActionHandlers()
  const isValid = !swapInputError
  const dependentField: Field = independentField === Field.INPUT ? Field.OUTPUT : Field.INPUT

  const handleTypeInput = useCallback(
    (value: string) => {
      onUserInput(Field.INPUT, value)
    },
    [onUserInput]
  )
  const handleTypeOutput = useCallback(
    (value: string) => {
      onUserInput(Field.OUTPUT, value)
    },
    [onUserInput]
  )

  // modal and loading
  const [{ showConfirm, tradeToConfirm, swapErrorMessage, attemptingTxn, txHash }, setSwapState] = useState<{
    showConfirm: boolean
    tradeToConfirm: Trade | undefined
    attemptingTxn: boolean
    swapErrorMessage: string | undefined
    txHash: string | undefined
  }>({
    showConfirm: false,
    tradeToConfirm: undefined,
    attemptingTxn: false,
    swapErrorMessage: undefined,
    txHash: undefined
  })

  const formattedAmounts = useMemo(
    () => ({
      [independentField]: typedValue,
      [dependentField]: showWrap
        ? parsedAmounts[independentField]?.toExact() ?? ''
        : parsedAmounts[dependentField]?.toSignificant(6) ?? ''
    }),
    [dependentField, independentField, parsedAmounts, showWrap, typedValue]
  )

  const routeNotFound = !trade?.route
  const userHasSpecifiedInputOutput = Boolean(
    currencies[Field.INPUT] && currencies[Field.OUTPUT] && parsedAmounts[independentField]?.greaterThan(JSBI.BigInt(0))
  )

  // check whether the user has approved the router on the input token
  const [approval, approveCallback, pendingApproval] = useApproveCallbackFromTrade(trade, allowedSlippage)

  // check if user has gone through approval process, used to show two step buttons, reset on token change
  const [approvalSubmitted, setApprovalSubmitted] = useState<boolean>(false)
  const resetField = () => {
    onReset()
    onUserInput(Field.INPUT, '')
  }

  // mark when a user has submitted an approval, reset onTokenSelection for input field
  useEffect(() => {
    if (pendingApproval) {
      setApprovalSubmitted(true)
    }
  }, [pendingApproval, approvalSubmitted])

  useEffect(() => {
    resetField()
  }, [])

  const maxAmountInput: CurrencyAmount | undefined = maxAmountSpend(currencyBalances[Field.INPUT])
  const atMaxAmountInput = Boolean(maxAmountInput && parsedAmounts[Field.INPUT]?.equalTo(maxAmountInput))

  // the callback to execute the swap
  const { callback: swapCallback, error: swapCallbackError } = useSwapCallback(trade, allowedSlippage, recipient)

  const { priceImpactWithoutFee } = computeTradePriceBreakdown(trade)

  const [singleHopOnly] = useUserSingleHopOnly()

  const [showPreview, setShowPreview] = useState<boolean>(false)

  const handleSwap = useCallback(() => {
    if (priceImpactWithoutFee && !confirmPriceImpactWithoutFee(priceImpactWithoutFee)) {
      return
    }
    if (!swapCallback) {
      return
    }
    setSwapState({
      attemptingTxn: true,
      tradeToConfirm,
      showConfirm: true,
      swapErrorMessage: undefined,
      txHash: undefined
    })
    swapCallback()
      .then(hash => {
        setSwapState({
          attemptingTxn: false,
          tradeToConfirm,
          showConfirm: true,
          swapErrorMessage: undefined,
          txHash: hash
        })
        setShowPreview(false)
        resetField()
      })
      .catch(error => {
        setSwapState({
          attemptingTxn: false,
          tradeToConfirm,
          showConfirm: true,
          swapErrorMessage: error.message,
          txHash: undefined
        })
      })
  }, [
    priceImpactWithoutFee,
    swapCallback,
    tradeToConfirm,
    showConfirm,
    recipient,
    recipientAddress,
    account,
    trade,
    singleHopOnly
  ])

  // errors
  const [showInverted, setShowInverted] = useState<boolean>(false)

  // warnings on slippage
  const priceImpactSeverity = warningSeverity(priceImpactWithoutFee)
  const priceImpactTooHigh = priceImpactSeverity > 3 && !isExpertMode

  // show approve flow when: no error on inputs, not approved or pending, or approved in current session
  // never show if price impact is above threshold in non expert mode
  const showApproveFlow =
    !swapInputError &&
    (approval === ApprovalState.NOT_APPROVED || approval === ApprovalState.PENDING) &&
    !priceImpactTooHigh

  const handleConfirmDismiss = useCallback(() => {
    setSwapState({
      showConfirm: attemptingTxn ? true : false,
      tradeToConfirm,
      attemptingTxn,
      swapErrorMessage,
      txHash
    })
    // if there was a tx hash, we want to clear the input
    if (txHash) {
      onUserInput(Field.INPUT, '')
    }
  }, [attemptingTxn, onUserInput, swapErrorMessage, tradeToConfirm, txHash])

  const handleAcceptChanges = useCallback(() => {
    setSwapState({ tradeToConfirm: trade, swapErrorMessage, txHash, attemptingTxn, showConfirm })
  }, [attemptingTxn, showConfirm, swapErrorMessage, trade, txHash])

  const handleInputSelect = useCallback(
    inputCurrency => {
      setApprovalSubmitted(false) // reset 2 step UI for approvals
      onCurrencySelection(Field.INPUT, inputCurrency)
    },
    [onCurrencySelection]
  )

  const handleMaxInput = useCallback(() => {
    maxAmountInput && onUserInput(Field.INPUT, maxAmountInput.toExact())
  }, [maxAmountInput, onUserInput])

  const handleOutputSelect = useCallback(outputCurrency => onCurrencySelection(Field.OUTPUT, outputCurrency), [
    onCurrencySelection
  ])

  const swapIsUnsupported = useIsTransactionUnsupported(currencies?.INPUT, currencies?.OUTPUT)

  return (
    <AppBody hide={open}>
      <BackgroundFull />
      <SettingsTab />

      <ConfirmSwapModal
        isOpen={showConfirm && (Boolean(swapErrorMessage) || Boolean(txHash) || attemptingTxn)}
        trade={trade}
        originalTrade={tradeToConfirm}
        onAcceptChanges={handleAcceptChanges}
        attemptingTxn={attemptingTxn}
        txHash={txHash}
        recipient={recipient}
        allowedSlippage={allowedSlippage}
        onConfirm={handleSwap}
        swapErrorMessage={swapErrorMessage}
        onDismiss={handleConfirmDismiss}
      />

      <SwapHeader showPreview={showPreview} onBack={() => setShowPreview(false)} />

      <Wrapper>
        {showPreview && (
          <PreviewBar
            currencyA={parsedAmounts[Field.INPUT]}
            currencyB={parsedAmounts[Field.OUTPUT]}
            type={PreviewBarType.Swap}
            topTitle="From"
            bottomTitle="To (estimated)"
          />
        )}

        {!showPreview && (
          <AutoColumn gap={'md'}>
            <CurrencyInputPanel
              label={independentField === Field.OUTPUT && !showWrap && trade ? 'From (estimated)' : 'From'}
              value={formattedAmounts[Field.INPUT]}
              showMaxButton={!atMaxAmountInput}
              currency={currencies[Field.INPUT]}
              onUserInput={handleTypeInput}
              onMax={handleMaxInput}
              onCurrencySelect={handleInputSelect}
              otherCurrency={currencies[Field.OUTPUT]}
              id="swap-currency-input"
            />

            <AutoRow justify={isExpertMode ? 'space-between' : 'center'} style={{ padding: '0.5rem 1rem' }}>
              <IconButton
                color="primary"
                sx={{ borderRadius: 4 }}
                onClick={() => {
                  setApprovalSubmitted(false) // reset 2 step UI for approvals
                  onSwitchTokens()
                }}
              >
                <ArrowUp color={darkMode ? BRAND_COLOR_LIGHT : DARK_BLUE} style={{ width: '16px' }} />
                <ArrowDown color={darkMode ? BRAND_COLOR_LIGHT : DARK_BLUE} style={{ width: '16px' }} />
              </IconButton>
            </AutoRow>

            <CurrencyInputPanel
              value={formattedAmounts[Field.OUTPUT]}
              onUserInput={handleTypeOutput}
              label={independentField === Field.INPUT && !showWrap && trade ? 'To (estimated)' : 'To'}
              showMaxButton={false}
              currency={currencies[Field.OUTPUT]}
              onCurrencySelect={handleOutputSelect}
              otherCurrency={currencies[Field.INPUT]}
              id="swap-currency-output"
            />
          </AutoColumn>
        )}

        {!showWrap && Boolean(trade) && (
          <RowBetween align="center" padding="1rem 1rem 0 1rem">
            <Text fontWeight={500} fontSize={16} color={theme.text1}>
              Price
            </Text>
            <TradePrice price={trade?.executionPrice} showInverted={showInverted} setShowInverted={setShowInverted} />
          </RowBetween>
        )}

        {!showWrap && (
          <SwapInfo trade={trade} allowedSlippage={allowedSlippage} deadline={deadline} isPreview={showPreview} />
        )}

        {swapIsUnsupported ? (
          <ButtonPrimary disabled={true}>Unsupported Asset</ButtonPrimary>
        ) : !account ? (
          <ButtonPrimary onClick={toggleWalletModal}>Connect Wallet</ButtonPrimary>
        ) : showWrap ? (
          <ButtonPrimary disabled={Boolean(wrapInputError)} onClick={onWrap} mt={40}>
            {wrapInputError
              ? getWrapErrorText(wrapInputError)
              : wrapType === WrapType.WRAP
              ? 'Wrap'
              : wrapType === WrapType.UNWRAP
              ? 'Unwrap'
              : null}
          </ButtonPrimary>
        ) : routeNotFound && userHasSpecifiedInputOutput ? (
          <ButtonPrimary disabled={true}>
            <span>Insufficient liquidity for this trade</span>
            {singleHopOnly && <span>Try enabling multi-hop trades</span>}
          </ButtonPrimary>
        ) : showApproveFlow ? (
          <MainButton onClick={approveCallback} disabled={approval === ApprovalState.PENDING}>
            {approval === ApprovalState.PENDING ? (
              <Dots>Approving {currencies[Field.INPUT]?.symbol}</Dots>
            ) : (
              'Approve ' + currencies[Field.INPUT]?.symbol
            )}
          </MainButton>
        ) : (
          <MainButton
            id="swap-button"
            disabled={!isValid || priceImpactTooHigh || !!swapCallbackError}
            error={isValid && priceImpactSeverity > 2 && !swapCallbackError}
            onClick={() => {
              if (isExpertMode || showPreview) {
                handleSwap()
              } else {
                setShowPreview(true)
                setSwapState({
                  tradeToConfirm: trade,
                  attemptingTxn: false,
                  swapErrorMessage: undefined,
                  showConfirm: true,
                  txHash: undefined
                })
              }
            }}
          >
            {swapInputError
              ? swapInputError
              : priceImpactTooHigh
              ? `Price impact too high`
              : `${showPreview ? 'CONFIRM' : 'SWAP'} ${priceImpactSeverity > 2 ? ' ANYWAY' : ''}`}
          </MainButton>
        )}
      </Wrapper>
    </AppBody>
  )
}
