import { Box, Container, Stack, Typography } from '@mui/material'
import { IMAGES, LIGHT_BG_GRADIENT } from 'constants/index'
import { useIsDarkMode } from 'state/user/hooks'

export function FarmHero() {
  const darkMode = useIsDarkMode()

  const centerStyles = {
    position: 'absolute',
    top: 0,
    left: '50%',
    transform: 'translateX(-50%)'
  }

  return (
    <Box
      sx={{
        pt: { xs: 20, md: 15 },
        pb: { xs: 0, md: 5 },
        position: 'relative',
        overflow: 'hidden',
        backgroundImage: darkMode ? 'none' : LIGHT_BG_GRADIENT
      }}
    >
      {darkMode && (
        <Box sx={centerStyles}>
          <img src={IMAGES.HERO_TOP} alt="" />
        </Box>
      )}

      <Box sx={{ ...centerStyles, opacity: '0.1' }}>
        <img src={darkMode ? IMAGES.HERO_VECTOR : IMAGES.GRID_LIGHTMODE} alt="" />
      </Box>

      <Container maxWidth="md" sx={{ '&.MuiContainer-maxWidthMd': { maxWidth: 960 } }}>
        <Stack
          spacing={{ xs: 10, md: 0 }}
          direction={{ xs: 'column', md: 'row' }}
          alignItems="center"
          justifyContent="space-between"
        >
          <Box zIndex={1} textAlign={{ xs: 'center', md: 'left' }}>
            <Typography component="h1" fontSize={{ xs: '3rem', md: '3.5rem' }} fontWeight={500}>
              $ASW Pools
            </Typography>

            <Typography fontSize={{ xs: '1rem', md: '1.5rem' }} fontWeight={600} mt={1} mb={5}>
              Stake some tokens to earn. High APR, low risk.
            </Typography>

            {/* <Button
              variant="outlined"
              sx={{
                fontSize: { xs: '1rem', md: '1.125rem' },
                fontWeight: 600,
                minHeight: 50,
                px: { xs: 6, md: 8 }
              }}
            >
              Stake Now
            </Button> */}
          </Box>

          <Box position="relative">
            <img src={IMAGES.HERO_BOX_2} alt="" height={300} />

            <Box
              sx={{
                position: 'absolute',
                bottom: 0,
                left: '50%',
                transform: 'translateX(-50%)',
                zIndex: -1
              }}
            >
              <img src={IMAGES.HERO_CIRCLE} alt="" />
            </Box>
          </Box>
        </Stack>
      </Container>
    </Box>
  )
}
