/* eslint-disable @typescript-eslint/no-unused-vars */
import { Components, Theme } from '@mui/material'

import { ComponentParams } from '../index'

const MuiContainer = ({ globalTheme, isDarkTheme }: ComponentParams): Components<Theme>['MuiContainer'] => ({
  styleOverrides: {
    maxWidthXl: {
      '@media (min-width: 1536px)': {
        maxWidth: 1488
      }
    }
  }
})

export default MuiContainer
