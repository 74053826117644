import { createMulticall, ListenerOptions } from '@adaswap/redux-multicall'
import { useInterfaceMulticall } from 'hooks/useContract'
import { useMemo } from 'react'
import { useBlockNumber } from 'state/application/hooks'
import { isTestnet } from 'utils'

const multicall = createMulticall()
// const reducer = combineReducers({ [multicall.reducerPath]: multicall.reducer })
// export const store = createStore(reducer)

export default multicall

export function MulticallUpdater() {
  const chainId = isTestnet ? 200101 : 2001
  const latestBlockNumber = useBlockNumber()
  const contract = useInterfaceMulticall()
  const listenerOptions: ListenerOptions = useMemo(
    () => ({
      blocksPerFetch: 5
    }),
    [chainId]
  )

  return (
    <multicall.Updater
      chainId={chainId}
      latestBlockNumber={latestBlockNumber}
      contract={contract}
      listenerOptions={listenerOptions}
    />
  )
}
