import { Button } from '@mui/material'
import { useWeb3React } from '@web3-react/core'
import { BRAND_COLOR, LIGHT_GREEN } from 'constants/index'
import { useHistory } from 'react-router-dom'
import { useWalletModalToggle } from 'state/application/hooks'
import { useIsDarkMode } from 'state/user/hooks'
import { LiquidityIcon } from 'svgComponents'

export function PoolNavigation({
  showAddLiquidity = false,
  addLiquidityOnly = false,
  smallSize = false
}: {
  showAddLiquidity?: boolean
  addLiquidityOnly?: boolean
  smallSize?: boolean
}) {
  const history = useHistory()
  const toggleWalletModal = useWalletModalToggle()
  const { active } = useWeb3React()
  const darkMode = useIsDarkMode()

  const sx = {
    minWidth: { xs: 180, md: 220 },
    py: smallSize ? { xs: 1, md: 1.25 } : { xs: 1.25, md: 1.5 }
  }

  return (
    <>
      {((active && showAddLiquidity) || addLiquidityOnly) && (
        <Button
          variant="outlined"
          startIcon={
            <LiquidityIcon color={darkMode ? BRAND_COLOR : LIGHT_GREEN} style={{ width: '19px', height: '24px' }} />
          }
          onClick={() => history.push('/add')}
          sx={{
            ...sx,
            fontWeight: 'normal',
            color: 'primary.light'
          }}
        >
          Add liquidity
        </Button>
      )}

      {!active && !addLiquidityOnly && (
        <Button variant="contained" onClick={toggleWalletModal} sx={sx}>
          Connect wallet
        </Button>
      )}
    </>
  )
}
