import { ChainId } from '@adaswap/sdk'
import { useMediaQuery, useTheme } from '@mui/material'
import FundWallet from 'components/fund-wallet'
import { AnalyticsMenu, MoreInfoMenu } from 'components/Menu'
import { BRAND_COLOR, DARK_GREEN } from 'constants/index'
import useMigratePool from 'hooks/useMigratePool'
import { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { Text } from 'rebass'
import styled, { css } from 'styled-components'
import { LogoHorizontalIcon, LogoIcon } from 'svgComponents'
import { isTestnet } from 'utils'

import { useActiveWeb3React } from '../../hooks'
import { useToggleSelfClaimModal } from '../../state/application/hooks'
import { useUserHasAvailableClaim } from '../../state/claim/hooks'
import { useUserHasSubmittedClaim } from '../../state/transactions/hooks'
import { useIsDarkMode } from '../../state/user/hooks'
import { ExternalLink } from '../../theme'
import { YellowCard } from '../Card'
import ClaimModal from '../claim/ClaimModal'
import Modal from '../Modal'
import Row, { RowFixed } from '../Row'
import Web3Status from '../Web3Status'
import { ToggleTheme } from './ToggleTheme'
import UniBalanceContent from './UniBalanceContent'

const HeaderFrame = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 100%;
  top: 0;
  position: relative;
  padding: 1rem 2.5rem;
  z-index: 2;
  backdrop-filter: blur(4px);
  -o-backdrop-filter: blur(4px);
  -moz-backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  ${({ theme }) => theme.mediaWidth.upToMedium`
    padding: 1rem;
    background-color: ${theme.isDarkTheme ? 'rgba(13, 19, 35, 0.7) !important' : 'rgba(216, 236, 231, 0.7)'};
  `};
`

const HeaderControls = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-self: flex-end;
`

const HeaderElement = styled.div`
  display: flex;
  align-items: center;

  /* addresses safari's lack of support for "gap" */
  & > *:not(:first-child) {
    margin-left: 8px;
  }

  ${({ theme }) => theme.mediaWidth.upToMedium`
   flex-direction: row-reverse;
    align-items: center;
  `};
`

const HeaderElementWrap = styled.div`
  display: flex;
  align-items: center;
`

const HeaderRow = styled(RowFixed)`
  ${({ theme }) => theme.mediaWidth.upToMedium`
   width: 100%;
  `};
`

const HeaderLinks = styled(Row)`
  justify-content: center;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    padding: 0.5rem 0.25rem;
    justify-content: flex-start;
    position: absolute;
    left: 0;
    top: 100%;
    width: 100%;
    background-color: ${({ theme }) => theme.bg7};
`};

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    gap: 32px;
    padding: 0.5rem 1rem;
`};
`

const AccountElement = styled.div<{ active: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${({ theme, active }) => (!active ? theme.bg1 : theme.bg3)};
  border-radius: 12px;
  white-space: nowrap;
  width: 100%;
  cursor: pointer;

  :focus {
    border: 1px solid blue;
  }
`

const UNIAmount = styled(AccountElement)`
  color: white;
  padding: 4px 8px;
  height: 36px;
  font-weight: 500;
  background-color: ${({ theme }) => theme.bg3};
  background: radial-gradient(174.47% 188.91% at 1.84% 0%, #ff007a 0%, #2172e5 100%), #edeef2;
`

const UNIWrapper = styled.span`
  width: fit-content;
  position: relative;
  cursor: pointer;

  :hover {
    opacity: 0.8;
  }

  :active {
    opacity: 0.9;
  }
`

const HideSmall = styled.span`
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: none;
  `};
`

const NetworkCard = styled(YellowCard)`
  border-radius: 12px;
  padding: 8px 12px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin: 0;
    margin-right: 0.5rem;
    width: initial;
    overflow: hidden;
    text-overflow: ellipsis;
    flex-shrink: 1;
  `};
`

const BalanceText = styled(Text)`
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    display: none;
  `};
`

const Title = styled(NavLink)`
  display: flex;
  align-items: center;
  pointer-events: auto;
  justify-self: flex-start;
  margin-right: 24px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    justify-self: center;
  `};
  :hover {
    cursor: pointer;
  }
  ${({ theme }) => theme.mediaWidth.upToLarge`
    margin-right: 12px;
  `};
`

const UniIcon = styled.div`
  transition: transform 0.3s ease;
  :hover {
    transform: rotate(-5deg);
  }
`

const activeClassName = 'ACTIVE'

const StyledBaseLink = css`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: left;
  border-radius: 3rem;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  color: ${({ theme }) => theme.primary0};
  font-size: 0.875rem;
  width: fit-content;
  margin: 0 16px;
  font-weight: 500;
  opacity: 0.6;
  transition: all 200ms ease-in-out;

  &.${activeClassName} {
    border-radius: 12px;
    opacity: 1;
  }

  :hover,
  :focus {
    opacity: 1;
    text-decoration: none;
  }

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    margin: 0;
`};
`

const StyledNavLink = styled(NavLink).attrs({
  activeClassName
})`
  ${StyledBaseLink}
`

const StyledExternalLink = styled(ExternalLink).attrs({
  activeClassName
})<{ isActive?: boolean }>`
  ${StyledBaseLink}
`

export const StyledMenuButton = styled.div`
  margin-left: 8px;
  padding: 0.15rem 0.5rem;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    padding: 0;
  `};
`

const NETWORK_LABELS: { [chainId in ChainId]?: string } = {
  [ChainId.MAINNET]: 'Milkomeda',
  [ChainId.TESTNET]: 'Milkomeda Testnet'
}

export default function Header() {
  const { account, chainId } = useActiveWeb3React()

  const [isShrink, setIsShrink] = useState(false)

  // const userEthBalance = useETHBalances(account ? [account] : [])?.[account ?? '']

  const toggleClaimModal = useToggleSelfClaimModal()

  const availableClaim: boolean = useUserHasAvailableClaim(account)

  const { claimTxn } = useUserHasSubmittedClaim(account ?? undefined)

  // const aggregateBalance: TokenAmount | undefined = useAggregateUniBalance()

  const [showUniBalanceModal, setShowUniBalanceModal] = useState(false)
  const { ALPTokenWithOldPools } = useMigratePool()
  // const showClaimPopup = useShowClaimPopup()

  // const countUpValue = aggregateBalance?.toFixed(0) ?? '0'
  // const countUpValuePrevious = usePrevious(countUpValue) ?? '0'

  useEffect(() => {
    const handleScroll = () => {
      if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
        setIsShrink(true)
      } else {
        setIsShrink(false)
      }
    }

    window.addEventListener('scroll', handleScroll)

    return () => window.removeEventListener('scroll', handleScroll)
  }, [])
  const darkMode = useIsDarkMode()
  const theme = useTheme()
  const upToLarge = useMediaQuery(theme.breakpoints.up('lg'))

  return (
    <HeaderFrame
      style={{
        backgroundColor: isShrink ? (darkMode ? 'rgba(13, 19, 35, 0.7)' : 'rgba(216, 236, 231, 0.7)') : 'transparent'
      }}
    >
      <ClaimModal />
      <Modal isOpen={showUniBalanceModal} onDismiss={() => setShowUniBalanceModal(false)}>
        <UniBalanceContent setShowUniBalanceModal={setShowUniBalanceModal} />
      </Modal>
      <HeaderRow>
        <Title to="/">
          {upToLarge && <LogoHorizontalIcon color={darkMode ? BRAND_COLOR : DARK_GREEN} />}
          {!upToLarge && <LogoIcon color={darkMode ? BRAND_COLOR : DARK_GREEN} />}
        </Title>
        <HeaderLinks>
          <StyledNavLink id={`swap-nav-link`} to={'/swap'}>
            SWAP
          </StyledNavLink>
          <StyledNavLink
            id={`pool-nav-link`}
            to={'/pool'}
            isActive={(match, { pathname }) =>
              Boolean(match) ||
              pathname.startsWith('/add') ||
              pathname.startsWith('/remove') ||
              pathname.startsWith('/create') ||
              pathname.startsWith('/find')
            }
          >
            POOL
          </StyledNavLink>
          {isTestnet && ALPTokenWithOldPools.length > 0 && (
            <StyledNavLink id={`migrate-nav-link`} to={'/migrate-lp'}>
              MIGRATE POOLS
            </StyledNavLink>
          )}
          <StyledNavLink id={`farm-nav-link`} to={'/farm'}>
            FARM
          </StyledNavLink>
          <AnalyticsMenu />
        </HeaderLinks>
      </HeaderRow>
      <HeaderControls>
        {isTestnet && <FundWallet />}

        <HeaderElement>
          {/* <HideSmall>
            {chainId && NETWORK_LABELS[chainId] && (
              <NetworkCard title={NETWORK_LABELS[chainId]}>{NETWORK_LABELS[chainId]}</NetworkCard>
            )}
          </HideSmall> */}
          {/* {availableClaim && !showClaimPopup && (
            <UNIWrapper onClick={toggleClaimModal}>
              <UNIAmount active={!!account && !availableClaim} style={{ pointerEvents: 'auto' }}>
                <TYPE.white padding="0 2px">
                  {claimTxn && !claimTxn?.receipt ? <Dots>Claiming UNI</Dots> : 'Claim UNI'}
                </TYPE.white>
              </UNIAmount>
              <CardNoise />
            </UNIWrapper>
          )}
          {!availableClaim && aggregateBalance && (
            <UNIWrapper onClick={() => setShowUniBalanceModal(true)}>
              <UNIAmount active={!!account && !availableClaim} style={{ pointerEvents: 'auto' }}>
                {account && (
                  <HideSmall>
                    <TYPE.white
                      style={{
                        paddingRight: '.4rem'
                      }}
                    >
                      <CountUp
                        key={countUpValue}
                        isCounting
                        start={parseFloat(countUpValuePrevious)}
                        end={parseFloat(countUpValue)}
                        thousandsSeparator={','}
                        duration={1}
                      />
                    </TYPE.white>
                  </HideSmall>
                )}
                UNI
              </UNIAmount>
              <CardNoise />
            </UNIWrapper>
          )} */}
          {/* <AccountElement active={!!account} style={{ pointerEvents: 'auto' }}>
            {account && userEthBalance ? (
              <BalanceText style={{ flexShrink: 0 }} pl="0.75rem" pr="0.5rem" fontWeight={500}>
                {userEthBalance?.toSignificant(4)} mADA
              </BalanceText>
            ) : null}
            <Web3Status />
          </AccountElement> */}

          <Web3Status />
        </HeaderElement>
        <HeaderElementWrap>
          <StyledMenuButton>
            <ToggleTheme />
          </StyledMenuButton>
          <MoreInfoMenu />
        </HeaderElementWrap>
      </HeaderControls>
    </HeaderFrame>
  )
}
