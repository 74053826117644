import { Box, Grid, useMediaQuery, useTheme } from '@mui/material'
import { Pool, Token } from 'models'
import React from 'react'

import { PoolItem } from './PoolItem'

interface PoolListProps {
  pools: Array<Pool>
  ALPTokenList: Array<Token>
  isListView: boolean
}

export function PoolList({ isListView, pools, ALPTokenList }: PoolListProps) {
  const theme = useTheme()
  const isDownMd = useMediaQuery(theme.breakpoints.down('md'))

  const poolList = pools.map(pool => ({
    ...pool,
    isMyLP: ALPTokenList.some(({ address }) => address.toLowerCase() === pool.id.toLowerCase())
  }))

  return (
    <Box py={5} maxWidth={{ xs: 400, md: 'none' }} mx="auto">
      <Grid container rowSpacing={3} columnSpacing={3}>
        {poolList.map((pool: any, idx: number) => {
          if (!isListView || isDownMd) {
            return (
              <Grid key={idx} item xs={12} md={6} lg={4} xl={3}>
                <PoolItem isListView={false} pool={pool} />
              </Grid>
            )
          }

          return (
            <Grid key={idx} item xs={12}>
              <PoolItem isListView={true} pool={pool} />
            </Grid>
          )
        })}
      </Grid>
    </Box>
  )
}
