import BigNumber from 'bignumber.js'

import { BIG_ZERO } from './bigNumber'
/**
 * Get farm APR value in %
 * @param poolWeight allocationPoint / totalAllocationPoint
 * @param aswPriceUsd Cake price in USD
 * @param poolLiquidityUsd Total pool liquidity in USD
 * @returns Farm Apr
 */
export const getFarmApr = (
  poolAswPerSecond: number,
  poolTotalWeight: number,
  poolWeight = BIG_ZERO,
  aswPriceUsd = BIG_ZERO,
  farmLiquidityUsd = BIG_ZERO,
  poolLiquidityUsd = 0,
  volume24h = 0
): { cakeRewardsApr: number; lpRewardsApr: number } => {
  const yearlyAswRewardAllocationAllPool = poolAswPerSecond * 86400 * 365
  const ratioWeight = poolTotalWeight ? poolWeight.div(new BigNumber(poolTotalWeight)) : BIG_ZERO
  const yearlyAswRewardAllocationCurrentPool = ratioWeight
    ? ratioWeight.times(yearlyAswRewardAllocationAllPool)
    : new BigNumber(NaN)
  const cakeRewardsApr = yearlyAswRewardAllocationCurrentPool
    .times(aswPriceUsd)
    .div(farmLiquidityUsd)
    .times(100)
  let cakeRewardsAprAsNumber = 0
  if (!cakeRewardsApr.isNaN() && cakeRewardsApr.isFinite()) {
    cakeRewardsAprAsNumber = Number(cakeRewardsApr)
  }

  // Todo: currently, no have migrate contract, so lpRewardApr = 0, update this if we have migrate contract
  // let lpRewardsAprAsNumber = 0
  // if (poolLiquidityUsd) {
  //   const yearlyFeeShare = ((volume24h * FEE_TRADING) / 100) * 365
  //   lpRewardsAprAsNumber = (yearlyFeeShare / poolLiquidityUsd) * 100
  // }
  // return { cakeRewardsApr: cakeRewardsAprAsNumber, lpRewardsApr: lpRewardsAprAsNumber }
  return { cakeRewardsApr: cakeRewardsAprAsNumber, lpRewardsApr: 0 } // temp
}

export const getDisplayApr = (cakeRewardsApr?: number, lpRewardsApr?: number) => {
  if (cakeRewardsApr && lpRewardsApr) {
    return (cakeRewardsApr + lpRewardsApr).toLocaleString('en-US', { maximumFractionDigits: 0 })
  }
  if (cakeRewardsApr) {
    return cakeRewardsApr.toLocaleString('en-US', { maximumFractionDigits: 0 })
  }
  return null
}

export const getDisplayAprPercent = (cakeRewardsApr?: number, lpRewardsApr?: number) => {
  return `${getDisplayApr(cakeRewardsApr, lpRewardsApr) ?? 0}%`
}
