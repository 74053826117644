interface Props {
  color?: string
  style?: any
}

export const PairsIcon = (props: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
    <g clipPath="url(#tu20rk6p4a)">
      <path
        d="M8 .272c2.667 0 4.898 2.177 4.898 4.898a4.758 4.758 0 0 1-2.123 3.972c2.776 1.034 4.844 3.538 5.225 6.585h-1.415c-.436-3.265-3.265-5.714-6.585-5.714s-6.15 2.449-6.585 5.714H0a8.117 8.117 0 0 1 5.224-6.585A4.758 4.758 0 0 1 3.102 5.17C3.102 2.449 5.332.272 8 .272zm0 1.36c-1.96 0-3.483 1.579-3.483 3.538A3.472 3.472 0 0 0 8 8.653a3.472 3.472 0 0 0 3.483-3.483c0-1.96-1.524-3.538-3.483-3.538z"
        fill={props.color || '#7CE5CF'}
      />
    </g>
    <defs>
      <clipPath id="tu20rk6p4a">
        <path fill={props.color || '#7CE5CF'} d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
)
