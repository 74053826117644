import { FormControl, MenuItem, Theme } from '@mui/material'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import { makeStyles } from '@mui/styles'
import { BRAND_COLOR, DARK_BLUE, DARK_GREEN, DARKER_GREEN, LIGHT_GREEN, MIDDLE_GREEN } from 'constants/index'
import { Duration } from 'pages/History'
import { useIsDarkMode } from 'state/user/hooks'

export interface HistoryFilterDayProps {
  duration: Duration
  onChangeDuration: (newDuration: Duration) => void
}

interface DarkModeProps {
  darkMode: boolean
}

const useStyles = makeStyles<Theme, DarkModeProps>({
  paper: {
    backgroundColor: props => (props.darkMode ? DARK_BLUE : DARKER_GREEN),
    marginTop: '1rem'
  },
  list: {
    padding: '.5rem 1rem',
    boxShadow: props =>
      props.darkMode
        ? '0px 0px 1px rgb(0 0 0 / 1%), 0px 4px 8px rgb(0 0 0 / 4%), 0px 16px 24px rgb(0 0 0 / 4%), 0px 24px 32px rgb(0 0 0 / 1%)'
        : '0 12px 24px 0 rgba(0, 0, 0, 0.25)',
    ...props =>
      props.darkMode && {
        border: `1px solid ${BRAND_COLOR}`,
        borderRadius: '8px'
      },
    '& li': {
      fontWeight: 600,
      fontSize: '1rem',
      padding: '8px',
      borderRadius: '4px',
      margin: '.5rem 0',
      color: props => (props.darkMode ? BRAND_COLOR : DARK_GREEN)
    },
    '& li:hover': {
      backgroundColor: props => (props.darkMode ? DARK_GREEN : DARK_BLUE),
      color: props => (props.darkMode ? BRAND_COLOR : LIGHT_GREEN)
    },
    '& li.Mui-selected': {
      color: 'white'
    },
    '& li.Mui-selected:hover': {
      cursor: 'auto'
    }
  }
})

const getStyles = (darkMode: boolean) => ({
  form: {
    minWidth: 190,
    height: 40,
    '& .MuiInputBase-root': {
      borderRadius: '4px',
      padding: '3px !important',
      minWidth: '120px',
      justifyContent: 'center',
      backgroundColor: 'transparent'
    },

    ...(darkMode && {
      '& .MuiSvgIcon-root': {
        color: BRAND_COLOR
      }
    }),

    '& .MuiInputBase-input': {
      color: darkMode ? BRAND_COLOR : MIDDLE_GREEN
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: darkMode ? `none !important` : `solid 1px ${MIDDLE_GREEN}!important`
    },

    '& .MuiInputBase-root:hover': {
      border: 'none !important'
    },
    '& .MuiInputBase-root:active': {
      border: 'none !important',
      padding: '0 !important'
    }
  }
})

export default function HistoryFilterDay({ duration, onChangeDuration }: HistoryFilterDayProps) {
  const darkMode = useIsDarkMode()
  const classes = useStyles({ darkMode })
  const styles = getStyles(darkMode)

  const handleChange = (event: SelectChangeEvent) => {
    onChangeDuration(event.target.value as Duration)
  }

  return (
    <FormControl sx={styles.form} size="small">
      <Select
        value={duration}
        onChange={handleChange}
        MenuProps={{
          classes: {
            paper: classes.paper,
            list: classes.list
          }
        }}
      >
        <MenuItem value={Duration.All}>All Time</MenuItem>
        <MenuItem value={Duration.Week}>Last Week</MenuItem>
        <MenuItem value={Duration.Month}>Last Month</MenuItem>
        <MenuItem value={Duration.Year}>Last Year</MenuItem>
      </Select>
    </FormControl>
  )
}
