import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded'
import { IconButton, Stack } from '@mui/material'
import { BRAND_COLOR, DARK_GREEN } from 'constants/index'
import { useToggleSettingsMenu } from 'state/application/hooks'
import { useIsDarkMode } from 'state/user/hooks'
import { LogoIcon, SettingIcon } from 'svgComponents'

export default function SwapHeader({ showPreview, onBack }: { showPreview: boolean; onBack: () => void }) {
  const darkMode = useIsDarkMode()
  const toggle = useToggleSettingsMenu()

  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between" px={2} pt={2} mb={3}>
      <IconButton
        onClick={onBack}
        sx={{ opacity: showPreview ? 1 : 0, visibility: showPreview ? 'visible' : 'hidden' }}
      >
        <ArrowBackIosRoundedIcon sx={{ fontSize: 32 }} />
      </IconButton>

      <LogoIcon color={darkMode ? BRAND_COLOR : DARK_GREEN} style={{ width: 75, height: 35 }} />

      <SettingIcon
        onClick={toggle}
        color={darkMode ? BRAND_COLOR : DARK_GREEN}
        style={{
          width: 32,
          height: 32,
          cursor: 'pointer',
          opacity: showPreview ? 0 : 1,
          visibility: showPreview ? 'hidden' : 'visible'
        }}
      />
    </Stack>
  )
}
