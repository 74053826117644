import { DARK_GREEN, DARKER_MAIN, LIGHT_GREEN, LT_DISABLED_TEXT_COLOR_LIGHT } from 'constants/index'
import { darken, lighten } from 'polished'
import React from 'react'
import { ChevronDown } from 'react-feather'
import { Button as RebassButton, ButtonProps } from 'rebass/styled-components'
import styled from 'styled-components'

import { RowBetween } from '../Row'

const Base = styled(RebassButton)<{
  padding?: string
  width?: string
  borderRadius?: string
  altDisabledStyle?: boolean
}>`
  padding: ${({ padding }) => (padding ? padding : '18px')};
  width: ${({ width }) => (width ? width : '100%')};
  font-weight: 500;
  text-align: center;
  border-radius: 90px;
  border-radius: ${({ borderRadius }) => borderRadius && borderRadius};
  outline: none;
  border: 1px solid transparent;
  color: ${({ theme }) => theme.primary2};
  text-decoration: none;
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  align-items: center;
  cursor: pointer;
  position: relative;
  z-index: 1;
  &:disabled {
    cursor: auto;
  }

  > * {
    user-select: none;
  }
`

export const ButtonPrimary = styled(Base)`
  background-color: ${({ theme }) => (theme.isDarkTheme ? theme.bg6 : DARK_GREEN)};
  color: ${({ theme }) => (theme.isDarkTheme ? theme.primary2 : LIGHT_GREEN)};
  border-color: ${({ theme }) => theme.primary2};
  // &:focus {
  //   box-shadow: 0 0 0 1pt ${({ theme }) => darken(0.05, theme.bg6)};
  //   background-color: ${({ theme }) => darken(0.05, theme.bg6)};
  // }
  &:hover {
    background-color: ${({ theme }) => (theme.isDarkTheme ? darken(0.05, theme.bg6) : DARKER_MAIN)};
  }
  &:active {
    box-shadow: 0 0 0 1pt ${({ theme }) => darken(0.1, theme.isDarkTheme ? theme.bg6 : DARK_GREEN)};
    background-color: ${({ theme }) => darken(0.1, theme.isDarkTheme ? theme.bg6 : DARK_GREEN)};
  }
  &:disabled {
    background-color: ${({ theme, altDisabledStyle, disabled }) => (theme.isDarkTheme ? theme.bg6 : DARK_GREEN)};
    color: ${({ theme }) => (theme.isDarkTheme ? 'rgba(119, 254, 255, 0.5)' : LT_DISABLED_TEXT_COLOR_LIGHT)};
    cursor: auto;
    box-shadow: none;
    border: 1px solid ${({ theme }) =>
      theme.isDarkTheme ? 'rgba(119, 254, 255, 0.5)' : LT_DISABLED_TEXT_COLOR_LIGHT};;
    outline: none;
    // opacity: ${({ altDisabledStyle }) => (altDisabledStyle ? '0.5' : '1')};
  }
`

export const ButtonSecondary = styled(Base)`
  border: 1px solid ${({ theme }) => theme.primary0};
  color: ${({ theme }) => theme.primary0};
  background-color: transparent;
  font-size: 16px;
  border-radius: 90px;
  padding: ${({ padding }) => (padding ? padding : '10px')};

  &:focus {
    border: 1px solid ${({ theme }) => theme.primary0};
  }
  &:hover {
    border: 1px solid ${({ theme }) => theme.primary2};
  }
  &:active {
    border: 1px solid ${({ theme }) => theme.primary2};
  }
  &:disabled {
    opacity: 50%;
    cursor: auto;
  }
  a:hover {
    text-decoration: none;
  }
  ${({ theme }) =>
    !theme.isDarkTheme &&
    `&:hover {
      color: ${LIGHT_GREEN};
      background-color: ${DARK_GREEN};
      border-color: ${DARK_GREEN};
    }`}
`

export const ButtonEmpty = styled(Base)`
  background-color: transparent;
  color: ${({ theme }) => theme.primary1};
  display: flex;
  justify-content: center;
  align-items: center;

  &:focus {
    text-decoration: underline;
  }
  &:hover {
    text-decoration: none;
  }
  &:active {
    text-decoration: none;
  }
  &:disabled {
    opacity: 50%;
    cursor: auto;
  }
`

const ButtonConfirmedStyle = styled(Base)`
  background-color: ${({ theme }) => lighten(0.5, theme.green1)};
  color: ${({ theme }) => theme.green1};
  border: 1px solid ${({ theme }) => theme.green1};

  &:disabled {
    opacity: 50%;
    cursor: auto;
  }
`

const ButtonErrorStyle = styled(Base)`
  background-color: ${({ theme }) => theme.red1};
  border: 1px solid ${({ theme }) => theme.red1};

  &:focus {
    box-shadow: 0 0 0 1pt ${({ theme }) => darken(0.05, theme.red1)};
    background-color: ${({ theme }) => darken(0.05, theme.red1)};
  }
  &:hover {
    background-color: ${({ theme }) => darken(0.05, theme.red1)};
  }
  &:active {
    box-shadow: 0 0 0 1pt ${({ theme }) => darken(0.1, theme.red1)};
    background-color: ${({ theme }) => darken(0.1, theme.red1)};
  }
  &:disabled {
    opacity: 50%;
    cursor: auto;
    box-shadow: none;
    background-color: ${({ theme }) => theme.red1};
    border: 1px solid ${({ theme }) => theme.red1};
  }
`

export function ButtonConfirmed({
  confirmed,
  altDisabledStyle,
  ...rest
}: { confirmed?: boolean; altDisabledStyle?: boolean } & ButtonProps) {
  if (confirmed) {
    return <ButtonConfirmedStyle {...rest} />
  } else {
    return <ButtonPrimary {...rest} altDisabledStyle={altDisabledStyle} />
  }
}

export function ButtonError({ error, ...rest }: { error?: boolean } & ButtonProps) {
  if (error) {
    return <ButtonErrorStyle {...rest} />
  } else {
    return <ButtonPrimary {...rest} />
  }
}

export function ButtonDropdown({ disabled = false, children, ...rest }: { disabled?: boolean } & ButtonProps) {
  return (
    <ButtonPrimary {...rest} disabled={disabled}>
      <RowBetween>
        <div style={{ display: 'flex', alignItems: 'center' }}>{children}</div>
        <ChevronDown size={24} />
      </RowBetween>
    </ButtonPrimary>
  )
}
