/* eslint-disable @typescript-eslint/no-unused-vars */
import { Components, Theme } from '@mui/material'
import { BRAND_COLOR, DARK_GREEN } from 'constants/index'

import { ComponentParams } from '../index'

const MuiTooltip = ({ globalTheme, isDarkTheme }: ComponentParams): Components<Theme>['MuiTooltip'] => ({
  styleOverrides: {
    tooltip: {
      padding: '16px',
      border: `1px solid ${isDarkTheme ? BRAND_COLOR : 'transparent'}`,
      borderRadius: '8px',
      fontSize: '0.875rem',
      backgroundColor: isDarkTheme ? '#000' : 'white',
      boxShadow: '0 12px 24px 0 rgba(0, 0, 0, 0.2)',

      '.tooltip-text': {
        color: isDarkTheme ? 'white' : DARK_GREEN
      }
    },

    arrow: {
      color: isDarkTheme ? '#000' : 'white',
      '&::before': {
        border: `1px solid ${isDarkTheme ? BRAND_COLOR : 'transparent'}`
      }
    }
  }
})

export default MuiTooltip
