import { Button, Stack, Typography } from '@mui/material'
import { useWeb3React } from '@web3-react/core'
import Popup from 'components/Popup/Popup'
import { BG_DARK_BLUE, BRAND_COLOR_LIGHT, DARK_BLUE, LT_DISABLED_TEXT_COLOR_DARK } from 'constants/index'
import React from 'react'
import { useWalletModalToggle } from 'state/application/hooks'
import { useIsDarkMode } from 'state/user/hooks'

import StepItem from './StepItem'

export const FLINT_WALLET_EXTENSION =
  'https://chrome.google.com/webstore/detail/flint-wallet/hnhobjmcibchnmglfbldbfabcgaknlkj'

export const METAMASK_EXTENSION = 'https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn'

const getStyles = (isDarkTheme: boolean) => ({
  title: {
    mb: 5,
    color: isDarkTheme ? 'primary.light' : DARK_BLUE,
    fontSize: { xs: '1.5rem', sm: '2rem' }
  },

  step: {
    fontSize: { xs: '0.875rem', sm: '1.25rem' },
    color: isDarkTheme ? '#fff' : DARK_BLUE,
    fontWeight: 'normal'
  },

  button: {
    py: 2,
    fontSize: { xs: '1rem', sm: '1.5rem' },
    fontWeight: { xs: 600, sm: 500 },
    color: isDarkTheme ? `${BRAND_COLOR_LIGHT} !important` : DARK_BLUE,
    borderColor: isDarkTheme ? `${BRAND_COLOR_LIGHT} !important` : DARK_BLUE,
    borderRadius: '90px',
    backgroundColor: isDarkTheme ? BG_DARK_BLUE : 'transparent',

    ':hover': {
      color: isDarkTheme ? 'primary.main' : 'primary.light'
    },
    '&.Mui-disabled': {
      WebkitTextFillColor: isDarkTheme ? 'rgba(119, 254, 255, 0.5)' : LT_DISABLED_TEXT_COLOR_DARK
    }
  }
})

export interface StepWizardProps {
  open: boolean
  onClose?: () => void
  onNext?: () => void
}

function StepWizard({ open, onClose, onNext }: StepWizardProps) {
  const { active, activate } = useWeb3React()
  const toggleWalletModal = useWalletModalToggle()

  const isFlintInstalled = Boolean(window.cardano?.flint)
  const isMetamaskInstalled = typeof window.ethereum !== 'undefined'
  const isCompleted = isFlintInstalled && isMetamaskInstalled && active

  const handleFlintClick = () => {
    window.open(FLINT_WALLET_EXTENSION, '_blank')
  }

  const handleMetamaskClick = () => {
    window.open(METAMASK_EXTENSION, '_blank')
  }

  const darkMode = useIsDarkMode()
  const styles = getStyles(darkMode)
  return (
    <Popup open={open} onClose={onClose} scroll="body">
      <Typography component="h6" align="center" sx={styles.title}>
        Easy Steps on How to Test
      </Typography>

      <StepItem reload stepNum={1} name="Download Flint" isChecked={isFlintInstalled} onClick={handleFlintClick} />

      <StepItem
        reload
        stepNum={2}
        name="Download MetaMask"
        isChecked={isMetamaskInstalled}
        disabled={!isFlintInstalled}
        onClick={handleMetamaskClick}
      />

      <StepItem
        stepNum={3}
        name="Connect your Wallet"
        isChecked={isCompleted}
        disabled={!isFlintInstalled || !isMetamaskInstalled}
        onClick={toggleWalletModal}
      />

      <Stack alignItems="center" spacing={2}>
        <Typography component="span" sx={styles.step}>
          Step 4
        </Typography>

        <Button fullWidth variant="outlined" disabled={!isCompleted} sx={styles.button} onClick={onNext}>
          FUND YOUR WALLET
        </Button>
      </Stack>
    </Popup>
  )
}

export default StepWizard
