import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded'
import { Box, Divider, Stack, Typography } from '@mui/material'
import BigNumber from 'bignumber.js'
import { FarmItemTooltip } from 'components/farm/FarmItem'
import { Input as NumericalInput } from 'components/NumericalInput'
import Popup from 'components/Popup/Popup'
import { BG_DARK_BLUE, BRAND_COLOR, BRAND_COLOR_LIGHT, DARK_BLUE, DARK_GREEN, LIGHT_GREEN } from 'constants/index'
import { useActiveWeb3React } from 'hooks'
import { useOnClickOutside } from 'hooks/useOnClickOutside'
import { FarmWithStakedValue } from 'models'
import { DEFAULT_LOCK_TIME_INDEX } from 'pages/Farm'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useAswPrice, useFarmUser, useLockFarmIndex } from 'state/farm/hooks'
import { useIsDarkMode } from 'state/user/hooks'
import { ArrowDown } from 'svgComponents'
import { nativeWrappedAdaToken } from 'utils'
import { getDisplayApr } from 'utils/apr'
import { getBalanceNumber } from 'utils/formatBalance'
import lockTimeIdToDay from 'utils/lockTimeIdToDay'

import { LockedRoiStrategy } from './LockedRoiStrategy'
import { ToggleButtonGroup } from './ToggleButtonGroup'
import useRoiCalculatorReducer, { CalculatorMode, DefaultCompoundStrategy } from './useFarmCalculatorReducer'

const MILLION = 1000000
// const TRILLION = 1000000000000

const MODE = {
  FLEXIBLE: 'flexible',
  LOCKED: 'locked'
}

export interface FarmCalculatorPopupProps {
  lockFarmList: FarmWithStakedValue[]
  onCloseCalculatorPopup?: () => void

  apy?: number
  performanceFee?: number
  isFarm?: boolean
  autoCompoundFrequency?: number
  initialState?: any
  initialValue?: string
  strategy?: any
  earningTokenSymbol?: string
}

export function FarmCalculatorPopup({
  lockFarmList,
  onCloseCalculatorPopup,

  isFarm = true,
  performanceFee = 0,
  autoCompoundFrequency = 0,
  earningTokenSymbol = 'ASW',
  apy,
  initialValue,
  initialState,
  strategy
}: FarmCalculatorPopupProps) {
  const darkMode = useIsDarkMode()
  const { account } = useActiveWeb3React()
  const aswPrice = useAswPrice()

  const lightColor = darkMode ? BRAND_COLOR_LIGHT : DARK_BLUE
  const whiteColor = darkMode ? 'white' : DARK_GREEN

  const headingStyle = {
    color: lightColor,
    fontSize: { xs: '1.125rem', md: '1.25rem' }
  }

  const [lockTimeIndex, setLockTimeIndex] = useState(DEFAULT_LOCK_TIME_INDEX)
  const [durationMode, setDurationMode] = useState(MODE.LOCKED)
  const [expanded, setExpanded] = useState(false)
  const [expectedRoi, setExpectedRoi] = useState('')
  const node = useRef<HTMLInputElement | null>(null)

  const flexibleFarm = lockFarmList.find(farm => farm.lockTimeId === 0)
  const { activeLockFarm, lockIdList } = useLockFarmIndex(
    lockFarmList.filter(farm => farm.lockTimeId !== 0),
    lockTimeIndex
  )

  const { pid = 0, lockTimeId = 0, apr = 0, multiplier, lpRewardsApr, lpTokenPrice, token, quoteToken } =
    durationMode === MODE.FLEXIBLE && flexibleFarm ? flexibleFarm : activeLockFarm ?? {}
  const [wrappedToken, wrapperQuoteToken] = [nativeWrappedAdaToken(token), nativeWrappedAdaToken(quoteToken)]
  const farmSymbol = `${wrappedToken.symbol}-${wrapperQuoteToken.symbol}`

  const stakingTokenPrice = Number(lpTokenPrice ?? 0)
  const { tokenBalance, stakedBalance } = useFarmUser(pid, lockTimeId)

  const userBalanceInFarm = useMemo(() => new BigNumber(Number(stakedBalance) + Number(tokenBalance)), [
    stakedBalance,
    tokenBalance
  ])

  const {
    state,
    setPrincipalFromUSDValue,
    setPrincipalFromTokenValue,
    setStakingDuration,
    toggleCompounding,
    toggleEditingCurrency,
    setCompoundingFrequency,
    setCalculatorMode,
    setTargetRoi,
    dispatch
  } = useRoiCalculatorReducer(
    { stakingTokenPrice, earningTokenPrice: Number(aswPrice), autoCompoundFrequency },
    initialState
  )

  const { principalAsUSD, principalAsToken, roiUSD, roiTokens, roiPercentage } = state.data
  const { compounding, activeCompoundingIndex, stakingDuration, mode } = state.controls

  const durationData = useMemo(() => {
    if (durationMode === MODE.FLEXIBLE) {
      return {
        data: ['1D', '7D', '30D', '1Y', '5Y'],
        selected: stakingDuration,
        onSelect: setStakingDuration
      }
    }

    return {
      data: lockIdList.map(lockTimeIdToDay).map(x => x.symbol),
      selected: lockTimeIndex,
      onSelect: setLockTimeIndex
    }
  }, [durationMode, stakingDuration, lockTimeIndex])

  const detailList = [
    { name: 'APR (incl. LP rewards)', value: `${getDisplayApr(apr, lpRewardsApr) ?? 0}%` },
    { name: '*Base APR', value: `${(apy ?? apr).toFixed(2)}%` },
    { name: '*LP Rewards APR', value: `${(lpRewardsApr ?? 0).toFixed(2)}%` },
    // {
    //   name: `APY (${autoCompoundFrequency > 0 ? autoCompoundFrequency : 1}x daily compound)`,
    //   value: `${(getApy(apr, autoCompoundFrequency > 0 ? autoCompoundFrequency : 1, 365, performanceFee) * 100).toFixed(
    //     2
    //   )}%`
    // },
    { name: 'Farm Multiplier', value: multiplier ?? '0X' }
  ]

  const USD_AMOUNT_LIST = [
    { name: '$100', value: '100' },
    { name: '$1000', value: '1000' },
    { name: 'Max', value: getBalanceNumber(userBalanceInFarm.times(stakingTokenPrice)).toString() }
  ]

  // If user comes to calculator from staking modal - initialize with whatever they put in there
  useEffect(() => {
    if (!initialValue) return

    setPrincipalFromTokenValue(initialValue)
  }, [initialValue, setPrincipalFromTokenValue])

  const onEnterEditing = () => {
    setCalculatorMode(CalculatorMode.PRINCIPAL_BASED_ON_ROI)

    if (!roiUSD) {
      setExpectedRoi('')
    } else {
      setExpectedRoi(roiUSD.toString())

      // setExpectedRoi(
      //   roiUSD.toLocaleString('en', {
      //     minimumFractionDigits: roiUSD > MILLION ? 0 : 2,
      //     maximumFractionDigits: roiUSD > MILLION ? 0 : 2
      //   })
      // )
    }
  }

  const onExitRoiEditing = () => {
    setCalculatorMode(CalculatorMode.ROI_BASED_ON_PRINCIPAL)
  }

  const handleExpectedRoiChange = (value: string) => {
    setTargetRoi(value)
    setExpectedRoi(value)
  }

  useOnClickOutside(node, mode !== CalculatorMode.ROI_BASED_ON_PRINCIPAL ? onExitRoiEditing : undefined)

  return (
    <Popup
      open={lockFarmList.length > 0}
      onClose={onCloseCalculatorPopup}
      scroll="body"
      paperStyles={{ pb: 5, overflowY: 'visible' }}
    >
      <Typography textAlign="center" fontSize={{ xs: '1.75rem', md: '2rem' }} color={lightColor}>
        ROI Calculator
      </Typography>

      {durationMode === MODE.LOCKED ? (
        <LockedRoiStrategy
          apr={apy ?? apr}
          dispatch={dispatch}
          state={state}
          earningTokenPrice={Number(aswPrice)}
          duration={lockTimeIdToDay(lockTimeId).value}
          stakingTokenPrice={stakingTokenPrice}
        />
      ) : (
        <DefaultCompoundStrategy
          apr={apy ?? apr}
          dispatch={dispatch}
          state={state}
          earningTokenPrice={Number(aswPrice)}
          performanceFee={performanceFee}
          stakingTokenPrice={stakingTokenPrice}
        />
      )}

      <Stack spacing={4} pt={6}>
        <Stack spacing={1}>
          <Typography component="span" sx={headingStyle}>
            {farmSymbol} LP Staked
          </Typography>

          <Stack
            spacing={2}
            direction="row"
            sx={{
              border: 1,
              borderColor: lightColor,
              borderRadius: 4,
              px: 2.5,
              py: 2
            }}
          >
            <Stack spacing={1} flex={1} alignItems="flex-start">
              <Stack direction="row" alignItems="center">
                <Typography component="span" fontSize="1.5rem" color={lightColor}>
                  $
                </Typography>

                <NumericalInput
                  autoFocus
                  placeholder="0.00"
                  className="usd-amount-input"
                  value={principalAsUSD}
                  onUserInput={setPrincipalFromUSDValue}
                  fontSize="1.5rem"
                  style={{ width: '100%' }}
                />
              </Stack>

              <Typography component="span" color={lightColor} fontSize="1.125rem">
                USD
              </Typography>
            </Stack>

            <Stack spacing={1} flex={1} alignItems="flex-end">
              <NumericalInput
                className="liquidity-amount-input"
                align="right"
                value={principalAsToken}
                onUserInput={setPrincipalFromTokenValue}
                fontSize="1.5rem"
                style={{ width: '100%' }}
              />

              <Typography component="span" color={lightColor} fontSize="1.125rem">
                {farmSymbol} LP
              </Typography>
            </Stack>
          </Stack>

          <Box maxWidth={{ xs: '100%', md: '60%' }} ml="auto !important">
            <ToggleButtonGroup
              flexEnd
              data={['$100', '$1000', 'Max']}
              selected={USD_AMOUNT_LIST.findIndex(({ value }) => principalAsUSD && principalAsUSD === value)}
              onSelect={index => setPrincipalFromUSDValue(USD_AMOUNT_LIST[index].value)}
              disableMax={
                !Number.isFinite(stakingTokenPrice) ||
                !userBalanceInFarm.isFinite() ||
                userBalanceInFarm.lte(0) ||
                !account
              }
            />
          </Box>
        </Stack>

        <ToggleButtonGroup
          heading={['flexible', 'locked']}
          disabledHeading={!flexibleFarm ? 'flexible' : undefined}
          activeMode={durationMode}
          onModeChange={setDurationMode}
          {...durationData}
        />

        {/* {autoCompoundFrequency === 0 && (
          <ToggleButtonGroup
            heading="Compounding Every"
            data={['1D', '7D', '14D', '30D']}
            selected={activeCompoundingIndex}
            onSelect={setCompoundingFrequency}
            onSelectNone={toggleCompounding}
            activeNone={!compounding}
          />
        )} */}

        <Divider variant="fullWidth" sx={{ bgcolor: 'primary.main' }} />

        <Stack spacing={1}>
          <Typography component="span" sx={headingStyle}>
            Annual ROI at current rates
          </Typography>

          <Stack
            spacing={1}
            sx={{
              border: 1,
              borderColor: lightColor,
              borderRadius: 4,
              px: 2.5,
              py: 3
            }}
          >
            <Stack direction="row" alignItems="center" ref={node}>
              <Typography component="span" fontSize="1.5rem" color={lightColor}>
                $
              </Typography>

              {mode === CalculatorMode.ROI_BASED_ON_PRINCIPAL && (
                <Typography
                  component="span"
                  onClick={onEnterEditing}
                  sx={{
                    flex: 1,
                    cursor: 'pointer',
                    letterSpacing: 'normal',
                    fontSize: '1.5rem',
                    color: lightColor,

                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                  }}
                >
                  {roiUSD.toLocaleString('en', {
                    minimumFractionDigits: roiUSD > MILLION ? 0 : 2,
                    maximumFractionDigits: roiUSD > MILLION ? 0 : 2
                  })}
                </Typography>
              )}

              {mode === CalculatorMode.PRINCIPAL_BASED_ON_ROI && (
                <NumericalInput
                  className="usd-amount-output"
                  fontSize="1.5rem"
                  autoFocus
                  value={expectedRoi}
                  onUserInput={handleExpectedRoiChange}
                />
              )}
            </Stack>

            <Typography
              component="span"
              sx={{
                color: lightColor,
                fontSize: '1.125rem',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
              }}
            >
              ~ {roiTokens} {earningTokenSymbol}(
              {roiPercentage
                ? roiPercentage.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                : 0}
              %)
            </Typography>

            {apr === 0 && (
              <Typography
                component="span"
                sx={{
                  color: lightColor,
                  fontSize: '0.875rem',
                  fontStyle: 'italic'
                }}
              >
                *No one has staked on this locked time yet
              </Typography>
            )}
          </Stack>
        </Stack>
      </Stack>

      <Stack
        direction="row"
        alignItems="flex-start"
        sx={{
          position: 'absolute',
          left: '100%',
          top: '4.5rem',

          '@media (max-width: 1376px)': {
            position: 'static',
            pt: 5
          }
        }}
      >
        <Box
          sx={{
            border: 1,
            borderColor: 'primary.light',
            borderBottomRightRadius: '1rem',
            borderLeftColor: darkMode ? 'primary.light' : DARK_BLUE,
            transform: darkMode ? 'none' : 'translateX(1px)',
            bgcolor: darkMode ? BG_DARK_BLUE : LIGHT_GREEN,
            width: expanded ? { xs: 320, xl: 375 } : 0,
            visibility: expanded ? 'visible' : 'hidden',

            '@media (max-width: 1376px)': {
              width: '100%',
              visibility: 'visible',
              border: 'none'
            }
          }}
        >
          <Box
            sx={{
              p: 2,
              display: expanded ? 'block' : 'none',

              '@media (max-width: 1376px)': {
                p: 0,
                display: 'block',
                hr: { display: 'block', mt: 2 }
              }
            }}
          >
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              onClick={() => setExpanded(x => !x)}
              sx={{
                pointerEvents: 'none',
                svg: { display: 'none' },

                '@media (max-width: 1376px)': {
                  cursor: 'pointer',
                  pointerEvents: 'auto',
                  svg: { display: 'block' }
                }
              }}
            >
              <Typography component="span" sx={headingStyle}>
                Details
              </Typography>

              <ArrowDown
                color={darkMode ? BRAND_COLOR : DARK_GREEN}
                style={{
                  height: 10,
                  transform: expanded ? 'rotate(180deg)' : 'rotate(0)',
                  transition: 'transform 250ms ease-in-out'
                }}
              />
            </Stack>

            <Divider variant="fullWidth" sx={{ bgcolor: 'primary.main', display: 'none' }} />

            <Box
              sx={{
                '@media (max-width: 1376px)': {
                  maxHeight: expanded ? 500 : 0,
                  visibility: expanded ? 'visible' : 'hidden',
                  overflow: expanded ? 'visible' : 'hidden',
                  transition: 'max-height 300ms ease-in-out'
                }
              }}
            >
              <Stack spacing={3} py={4}>
                {detailList.map(({ name, value }, index) => (
                  <Stack
                    key={name}
                    direction="row"
                    justifyContent="space-between"
                    sx={{
                      span: {
                        fontSize: { xs: '0.875rem', sm: '1rem', lg: '0.875rem', xl: '1rem' },
                        color: whiteColor
                      }
                    }}
                  >
                    <Typography component="span">{name}</Typography>

                    <Stack direction="row" alignItems="center" spacing={1}>
                      <Typography component="span">{value}</Typography>
                      {index === detailList.length - 1 && <FarmItemTooltip />}
                    </Stack>
                  </Stack>
                ))}
              </Stack>

              <Stack
                spacing={1}
                component="ul"
                sx={{
                  my: 0,
                  pl: 2,
                  li: { fontSize: { xs: '0.75rem', sm: '0.875rem', lg: '0.75rem', xl: '0.875rem' }, color: whiteColor }
                }}
              >
                <Typography component="li">Calculated based on current rates.</Typography>
                {isFarm && (
                  <Typography component="li">
                    LP rewards: 0.17% trading fees, distributed proportionally among LP token holders.
                  </Typography>
                )}
                <Typography component="li">
                  All figures are estimates provided for your convenience only, and by no means represent guaranteed
                  returns.
                </Typography>
              </Stack>
            </Box>
          </Box>
        </Box>

        <Stack
          width={{ xs: 65, xl: 75 }}
          height={{ xs: 60, xl: 70 }}
          justifyContent="center"
          alignItems="center"
          onClick={() => setExpanded(x => !x)}
          sx={{
            bgcolor: darkMode ? BG_DARK_BLUE : LIGHT_GREEN,
            border: 1,
            borderColor: 'primary.light',
            borderLeftColor: 'transparent',
            borderBottomRightRadius: '1rem',
            borderTopRightRadius: '1rem',
            transform: 'translateX(-1px)',
            cursor: 'pointer',

            '@media (max-width: 1376px)': {
              display: 'none'
            }
          }}
        >
          <ArrowForwardIosRoundedIcon
            color="primary"
            sx={{
              fontSize: { xs: 24, xl: 28 },
              transform: expanded ? 'rotate(180deg)' : 'rotate(0)',
              transition: 'transform 250ms ease-in-out'
            }}
          />
        </Stack>
      </Stack>
    </Popup>
  )
}
