import { Checkbox } from '@mui/material'
import { IMAGES } from 'constants/index'
import React from 'react'
import { useDarkModeManager } from 'state/user/hooks'

export const ToggleTheme = () => {
  const [darkMode, toggleDarkMode] = useDarkModeManager()

  const handleChange = () => {
    toggleDarkMode()
  }

  return (
    <Checkbox
      checked={darkMode}
      onChange={handleChange}
      icon={<img src={IMAGES.THEME_SWITCH_DARK} />}
      checkedIcon={<img src={IMAGES.THEME_SWITCH_LIGHT} />}
      disableRipple
      sx={{
        '& > img': {
          borderRadius: '90px',
          width: '56px'
        }
      }}
    />
  )
}
