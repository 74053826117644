/* eslint-disable @typescript-eslint/no-unused-vars */
import { Components, Theme } from '@mui/material'
import { BG_DARK_GREEN, DARK_BLUE, DARKER_GREEN } from 'constants/index'

import { ComponentParams } from '../index'

export const MuiInputBase = ({ globalTheme, isDarkTheme }: ComponentParams): Components<Theme>['MuiInputBase'] => ({
  styleOverrides: {
    root: {
      backgroundColor: isDarkTheme ? `${BG_DARK_GREEN} !important` : DARKER_GREEN
    },

    input: {
      color: isDarkTheme ? globalTheme.palette.primary.main : DARK_BLUE
    },

    sizeSmall: {
      paddingTop: '7.125px !important',
      paddingBottom: '7.125px !important'
    }
  }
})

export const MuiOutlinedInput = ({
  globalTheme,
  isDarkTheme
}: ComponentParams): Components<Theme>['MuiOutlinedInput'] => ({
  styleOverrides: {
    notchedOutline: {
      borderColor: `${globalTheme.palette.primary.main} !important`
    }
  }
})

export const MuiFilledInput = ({ globalTheme, isDarkTheme }: ComponentParams): Components<Theme>['MuiFilledInput'] => ({
  styleOverrides: {
    root: {
      borderRadius: '8px'
    }
  }
})

export const MuiInputLabel = ({ globalTheme, isDarkTheme }: ComponentParams): Components<Theme>['MuiInputLabel'] => ({
  styleOverrides: {
    root: {
      color: isDarkTheme ? globalTheme.palette.primary.main : DARK_BLUE
    }
  }
})
