import { BigNumber, Contract } from 'ethers'

export const fetchMcPoolLength = async (masterChefContract: Contract): Promise<number> => {
  try {
    const poolLength: BigNumber = await masterChefContract.poolsLength()
    return Number(poolLength)
  } catch (error) {
    console.error('Failed to fetch poolLength', error)
    return 0
  }
}

export const fetchMcLockTimeLength = async (masterChefContract: Contract): Promise<number> => {
  try {
    const lockTimeLength: BigNumber = await masterChefContract.lockTimesLength()
    return Number(lockTimeLength)
  } catch (error) {
    console.error('Failed to fetch lockTimeLength', error)
    return 0
  }
}

export const fetchMcTotalAllocPoint = async (masterChefContract: Contract): Promise<number> => {
  try {
    const totalAllocPoint: BigNumber = await masterChefContract.totalAllocPoint()
    return Number(totalAllocPoint)
  } catch (error) {
    console.error('Failed to fetch totalAllocPoint', error)
    return 0
  }
}

export const fetchMcAdaswapPerSecond = async (masterChefContract: Contract): Promise<number> => {
  try {
    const adaswapPerSecond: BigNumber = await masterChefContract.adaswapPerSecond()
    return Number(adaswapPerSecond) / 1e18
  } catch (error) {
    console.error('Failed to fetch adaswapPerSecond', error)
    return 0
  }
}
