import { useQuery } from '@apollo/client'
import { Box, Container, Divider, SelectChangeEvent, Stack, Typography } from '@mui/material'
import { EmptyStage } from 'components/EmptyContent'
import { PoolBanner, PoolFilter, PoolHero, PoolList } from 'components/pool'
import {
  DARK_GREEN,
  LIGHT_GREEN,
  LOADING_GIF,
  LOADING_GIF_LT,
  mADA,
  NATIVE_TOKEN_ADDRESS,
  WADA,
  WADA_MAINNET,
  WADA_TESTNET
} from 'constants/index'
import { PAIRS } from 'graphql/query'
import { useActiveWeb3React } from 'hooks'
import { useDefaultTokens } from 'hooks/Tokens'
import { useGetToken } from 'hooks/useGetUserToken'
import { Pool as IPool, PoolToken, TokenType } from 'models'
import queryString from 'query-string'
import { useEffect, useMemo, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useIsDarkMode } from 'state/user/hooks'

export function convertWadaToMada(token: PoolToken): PoolToken {
  const { id, symbol } = token
  const wadaAddressList = [WADA_TESTNET.address.toLowerCase(), WADA_MAINNET.address.toLowerCase()]

  return {
    ...token,
    id: wadaAddressList.includes(id.toLowerCase()) ? NATIVE_TOKEN_ADDRESS : id.toLowerCase(),
    symbol: symbol !== WADA ? symbol : mADA
  }
}

export interface PoolQueryParams {
  showMyLP: boolean
  view: number
  filter: string
}

function Pool() {
  const { account } = useActiveWeb3React()
  const defaultTokenList = useDefaultTokens()
  const { data, loading, error, startPolling, stopPolling } = useQuery<{ pairs: IPool[] }>(PAIRS)

  const history = useHistory()
  const location = useLocation()
  const darkMode = useIsDarkMode()

  const [searchValue, setSearchValue] = useState('')
  const [pools, setPools] = useState<Array<IPool>>([])

  const queryParams: PoolQueryParams = useMemo(() => {
    const params = queryString.parse(location.search)

    return {
      ...params,
      showMyLP: params.showMyLP === 'true',
      view: Number(params.view) || 1,
      filter: typeof params.filter === 'string' ? params.filter : 'standard'
    }
  }, [location.search])

  const APLToken = useGetToken(TokenType.ALP)

  const poolList: IPool[] = useMemo(() => {
    if (!data) return []

    return data.pairs.map(pair => ({
      ...pair,
      token0: convertWadaToMada(pair.token0),
      token1: convertWadaToMada(pair.token1)
    }))
  }, [data])

  useEffect(() => {
    startPolling(10000)
    return () => {
      stopPolling()
    }
  }, [startPolling, stopPolling])

  useEffect(() => {
    const paramsJson = localStorage.getItem('poolQueryParams')
    if (!paramsJson || history.location.search) return

    const params: PoolQueryParams = JSON.parse(paramsJson)

    if (params.filter === 'standard' && params.showMyLP === false && params.view === 1) return

    history.push({
      pathname: history.location.pathname,
      search: queryString.stringify(params)
    })
  }, [history])

  useEffect(() => {
    if (!account) {
      history.push({
        pathname: history.location.pathname,
        search: queryString.stringify({ ...queryParams, showMyLP: false })
      })
    }
  }, [history, account])

  useEffect(() => {
    localStorage.setItem('poolQueryParams', JSON.stringify(queryParams))
    const covertSearchValue = searchValue.toLowerCase()
    const tokenAddresses = APLToken.map(token => token.address)
    const defaultTokenAddressList = Object.keys(defaultTokenList).map(address => address.toLowerCase())

    const filteredPoolList = poolList.filter(pool => {
      const [symbol0, symbol1, address0, address1] = [
        pool.token0.symbol.toLowerCase(),
        pool.token1.symbol.toLowerCase(),
        pool.token0.id.toLowerCase(),
        pool.token1.id.toLowerCase()
      ]

      const isFoundSearch = symbol0.includes(covertSearchValue) || symbol1.includes(covertSearchValue)
      const showMyLP = queryParams.showMyLP ? tokenAddresses.includes(pool.id.toLowerCase()) : true

      const showStandardPool =
        queryParams.filter === 'standard'
          ? (defaultTokenAddressList.includes(address0) || address0 === mADA.toLowerCase()) &&
            (defaultTokenAddressList.includes(address1) || address1 === mADA.toLowerCase())
          : true

      return isFoundSearch && showMyLP && showStandardPool
    })

    setPools(filteredPoolList)
  }, [poolList, queryParams, APLToken, searchValue])

  const handleLayoutChange = (event: any, newLayout: number | null) => {
    if (newLayout === null) {
      // setLayout(newLayout as Layout)
      return
    }

    const filters = {
      ...queryParams,
      view: newLayout
    }

    history.push({
      pathname: history.location.pathname,
      search: queryString.stringify(filters)
    })
  }

  const toggleShowMyLP = () => {
    const filters = {
      ...queryParams,
      showMyLP: !queryParams.showMyLP
    }

    history.push({
      pathname: history.location.pathname,
      search: queryString.stringify(filters)
    })
  }

  const handleFilterChange = (event: SelectChangeEvent<string>) => {
    const filters = {
      ...queryParams,
      filter: event.target.value
    }

    history.push({
      pathname: history.location.pathname,
      search: queryString.stringify(filters)
    })
  }

  const handleSearch = (value: string) => {
    setSearchValue(value)
  }

  const textContent = error
    ? 'Error when get list of all pair token address'
    : queryParams.showMyLP
    ? 'My Pools'
    : 'Staked Pools'

  return (
    <Stack minHeight="100vh" width={1}>
      <PoolHero />

      <Box flex={1} pt={3} pb={10} bgcolor={darkMode ? '#02111a' : LIGHT_GREEN} position="relative" zIndex={1}>
        <Container maxWidth="xl">
          <PoolBanner />
          <PoolFilter
            onLayoutChange={handleLayoutChange}
            onFilterChange={handleFilterChange}
            onToggleShowLP={toggleShowMyLP}
            onSearch={handleSearch}
            loading={loading}
            filters={queryParams}
          />

          <Divider sx={{ borderColor: 'primary.main', display: { xs: 'block', sm: 'none' } }} />

          <Typography
            sx={{
              mt: { xs: 5, sm: 10 },
              color: darkMode ? '#fff' : DARK_GREEN,
              fontSize: { xs: '2rem' },
              textAlign: { xs: 'center', md: 'left' }
            }}
          >
            {textContent}
          </Typography>

          {loading && (
            <Box textAlign="center" pt={3}>
              <img src={darkMode ? LOADING_GIF : LOADING_GIF_LT} alt="" width={150} />
            </Box>
          )}

          {pools.length > 0 && <PoolList isListView={queryParams.view === 1} pools={pools} ALPTokenList={APLToken} />}

          {!error && !loading && pools.length === 0 && (
            <EmptyStage liquidityMode description="Start create a pair and add liquidity" />
          )}
        </Container>
      </Box>
    </Stack>
  )
}

export default Pool
