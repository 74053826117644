import { Box, Typography } from '@mui/material'
import { IMAGES, LIGHT_GREEN } from 'constants/index'
import { useEffect } from 'react'
import { isMobile } from 'react-device-detect'
import { useBanMobilePopup } from 'state/application/hooks'
import { useIsDarkMode } from 'state/user/hooks'

import Popup from './Popup'

const getStyles = (isDarkTheme: boolean) => ({
  paper: {
    padding: '32px 32px',
    border: isDarkTheme ? '1.4px solid #7ce5cf' : 'none',
    borderRadius: 8,
    backgroundColor: isDarkTheme ? 'rgba(9, 29, 40, 0.6)' : LIGHT_GREEN
  },

  title: {
    fontSize: '1.5rem'
  },

  content: {
    fontSize: '1rem'
  },

  box: {
    py: 4
  }
})

export default function BanMobilePopup() {
  const [status, changeStatus] = useBanMobilePopup()

  useEffect(() => {
    if (isMobile) {
      changeStatus(true)
    }
  }, [])

  const darkMode = useIsDarkMode()
  const styles = getStyles(darkMode)
  return (
    <Popup open={status} onClose={() => changeStatus(false)} paperStyles={styles.paper} smallCloseIcon>
      <Box textAlign="center" sx={{ maxWidth: 'sm' }}>
        <Typography component="h6" sx={styles.title}>
          Mobile Not Supported
        </Typography>
        <Box sx={styles.box}>
          <img src={IMAGES.DESKTOP} alt="" />
        </Box>
        <Typography component="span" sx={styles.content}>
          This action is not supported on mobile <br />
          Please use desktop browser
        </Typography>
      </Box>
    </Popup>
  )
}
