interface Props {
  color?: string
  style?: any
}

export const TokensIcon = (props: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
    <path
      d="M9 0a9 9 0 0 0-9 9 9 9 0 0 0 9 9 9.009 9.009 0 0 0 9-9 9 9 0 0 0-9-9zm0 16.714A7.714 7.714 0 1 1 9 1.286 7.723 7.723 0 0 1 16.714 9 7.714 7.714 0 0 1 9 16.714z"
      fill={props.color || '#7CE5CF'}
    />
    <path
      d="M9 2.571a6.429 6.429 0 0 0 0 12.857A6.436 6.436 0 0 0 15.43 9 6.429 6.429 0 0 0 9 2.57zm0 11.572A5.143 5.143 0 0 1 9 3.857 5.149 5.149 0 0 1 14.143 9 5.143 5.143 0 0 1 9 14.143z"
      fill={props.color || '#7CE5CF'}
    />
    <path
      d="m11.73 8.182-.738-.402a1.933 1.933 0 0 1-.771-.766l-.405-.737a.932.932 0 0 0-1.632 0l-.405.737c-.179.323-.446.59-.77.765l-.74.403a.93.93 0 0 0 0 1.636l.738.402c.326.176.593.442.772.766l.405.737a.932.932 0 0 0 1.633 0l.404-.737c.179-.323.446-.59.77-.765l.74-.403a.93.93 0 0 0 0-1.636zm-1.357.91c-.54.294-.984.736-1.28 1.275l-.095.172-.094-.172a3.212 3.212 0 0 0-1.28-1.275L7.456 9l.169-.092c.54-.294.984-.736 1.28-1.275L9 7.461l.096.172c.296.539.74.982 1.28 1.275l.167.092-.17.092z"
      fill={props.color || '#7CE5CF'}
    />
  </svg>
)
