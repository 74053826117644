import { Stack } from '@mui/material'
import Countdown, { CountdownRenderProps } from 'react-countdown'

const formatTime = (num: number) => `0${num}`.slice(num > 99 ? -3 : -2)

const Column = ({ label, value }: { label: string; value: string }) => (
  <Stack alignItems="center">
    <span style={{ lineHeight: 'normal' }}>{value}</span>
    <span style={{ textTransform: 'none', fontSize: '0.75rem', lineHeight: 'normal' }}>{label}</span>
  </Stack>
)

function FarmCountdown({ unlockTime, text }: { unlockTime: number; text: string }) {
  const renderer = ({ hours, minutes, seconds, days, completed }: CountdownRenderProps) => {
    if (completed) return <span>{text}</span>

    return (
      <Stack direction="row" spacing={2}>
        <Column label="Day" value={formatTime(days)} />
        <Column label="Hour" value={formatTime(hours)} />
        <Column label="Min" value={formatTime(minutes)} />
        <Column label="Sec" value={formatTime(seconds)} />
      </Stack>
    )
  }

  return unlockTime > Date.now() ? <Countdown date={unlockTime} renderer={renderer} /> : <span>{text}</span>
}

export default FarmCountdown
