import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Chip,
  Divider,
  Stack,
  Typography
} from '@mui/material'
import { useWeb3React } from '@web3-react/core'
import {
  BRAND_COLOR,
  BRAND_COLOR_LIGHT,
  DARK_BLUE,
  DARK_GREEN,
  LIGHT_BG_GRADIENT,
  LT_DISABLED_TEXT_COLOR_DARK
} from 'constants/index'
import ENV_VARS from 'env'
import { Pool } from 'models'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useIsDarkMode } from 'state/user/hooks'
import { ArrowDown, ArrowUp } from 'svgComponents'
import { getTokenIcon } from 'utils/index'

import LinkWithIcon from '../LinkWithIcon'
import TokenPair from '../TokenPair'
import PoolTooltip from './PoolTooltip'

const getStyles = (isDarkTheme: boolean) => ({
  root: {
    p: 2,
    border: isDarkTheme ? `1px solid ${BRAND_COLOR}` : 'none',
    background: isDarkTheme ? 'linear-gradient(180deg, rgba(4, 20, 28, 0) 0%, #204444 100%)' : LIGHT_BG_GRADIENT,
    borderRadius: '1.5rem !important',
    boxShadow: isDarkTheme ? `0 0 10px -5px ${BRAND_COLOR}` : 'none',

    '&.Mui-expanded': {
      boxShadow: 'none'
    }
  },

  summary: {
    '.MuiAccordionSummary-content': {
      display: 'block'
    }
  },

  name: {
    fontSize: '1.25rem',
    color: isDarkTheme ? 'primary.light' : DARK_BLUE
  },

  chip: {
    fontSize: { xs: '0.875rem', lg: '1rem' },
    fontWeight: 600,
    '.MuiChip-label': { px: 2.5 }
  },

  textWhite: {
    fontSize: { xs: '0.875rem', lg: '1rem' },
    color: isDarkTheme ? 'white' : DARK_GREEN
  },

  textPrimary: {
    fontSize: { xs: '0.875rem', lg: '1rem' },
    color: isDarkTheme ? BRAND_COLOR_LIGHT : DARK_GREEN
  },

  details: {
    fontSize: '1rem',
    fontWeight: 'normal'
  },

  button: {
    py: 1.5,
    fontSize: '1rem',
    fontWeight: 500
  }
})

function PoolItemThumbnailView({ pool }: { pool: Pool }) {
  const { active } = useWeb3React()
  const history = useHistory()

  const [expanded, setExpanded] = useState<boolean>(false)

  const { id: pairAddress, token0, token1, reserveUSD: liquidity, untrackedVolumeUSD: volume24h, pooledALP } = pool

  const handleExpanded = () => {
    setExpanded(x => !x)
  }

  const handleAddLiquidityClick = () => {
    history.push(`/add/${token0.id}/${token1.id}`, {
      prevPath: 'pools'
    })
  }

  const handleWithdrawClick = () => {
    history.push(`/remove/${token0.id}/${token1.id}`)
  }

  const data = [
    { name: 'Pooled ALP', value: pooledALP ? pooledALP.toSignificant(4) : '0' },
    { name: 'Liquidity', value: `${Number(liquidity).toLocaleString()} USD` },
    { name: 'Volume (24h)', value: `${Number(volume24h).toLocaleString()} USD` }
  ]

  const darkMode = useIsDarkMode()
  const styles = getStyles(darkMode)
  return (
    <Accordion sx={styles.root} expanded={expanded}>
      <AccordionSummary sx={styles.summary}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <TokenPair icon1={getTokenIcon(token0.symbol)} icon2={getTokenIcon(token1.symbol)} iconSize={40} />

          <Stack spacing={1} alignItems="flex-end">
            <Typography component="h1" sx={styles.name}>
              {token0.symbol}-{token1.symbol}
            </Typography>

            <Stack direction="row" spacing={1} alignItems="center">
              <PoolTooltip />
              <Chip label="40x" variant="filled" color="primary" sx={styles.chip} />
            </Stack>
          </Stack>
        </Stack>

        <Stack pt={5} pb={4} spacing="16px">
          {data.map(({ name, value }) => (
            <Stack key={name} direction="row" justifyContent="space-between" alignItems="flex-start">
              <Typography sx={styles.textWhite} textAlign="left">
                {name}
              </Typography>

              <Typography sx={name.includes('ALP') ? styles.textPrimary : styles.textWhite} textAlign="right">
                {value}
              </Typography>
            </Stack>
          ))}
        </Stack>

        <Divider sx={{ borderColor: darkMode ? 'rgba(124, 229, 207, 0.3)' : 'rgba(28, 65, 79, 0.3)' }} />

        <Stack spacing={3} my={5}>
          {active && (
            <Button
              fullWidth
              variant="outlined"
              onClick={handleWithdrawClick}
              size="large"
              disabled={!pool.isMyLP}
              sx={{
                ...styles.button,
                bgcolor: 'transparent',
                ...(!darkMode && {
                  color: 'primary.main',
                  borderColor: 'primary.main',
                  '&:hover': {
                    color: 'primary.light'
                  },
                  '&.Mui-disabled': {
                    WebkitTextFillColor: LT_DISABLED_TEXT_COLOR_DARK
                  }
                })
              }}
            >
              Withdraw
            </Button>
          )}

          <Button fullWidth variant="outlined" size="large" onClick={handleAddLiquidityClick} sx={styles.button}>
            Add Liquidity
          </Button>
        </Stack>

        <Box textAlign="center">
          <Button
            endIcon={
              expanded ? (
                <ArrowUp color={darkMode ? BRAND_COLOR : DARK_GREEN} style={{ height: '8px' }} />
              ) : (
                <ArrowDown color={darkMode ? BRAND_COLOR : DARK_GREEN} style={{ height: '8px' }} />
              )
            }
            onClick={handleExpanded}
            sx={styles.details}
          >
            Details
          </Button>
        </Box>
      </AccordionSummary>

      <AccordionDetails>
        <Stack spacing={2}>
          <LinkWithIcon text="View Contract" href={`${ENV_VARS.explorerScan}/address/${pairAddress}`} />

          <LinkWithIcon text="Pair Info" href={`${ENV_VARS.explorerScan}/address/${pairAddress}/tokens`} />
        </Stack>
      </AccordionDetails>
    </Accordion>
  )
}

export default PoolItemThumbnailView
