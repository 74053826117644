import { ChainId } from '@adaswap/sdk'
import { Interface } from '@ethersproject/abi'

import V1_EXCHANGE_ABI from './v1_exchange.json'
import V1_FACTORY_ABI from './v1_factory.json'

const V1_FACTORY_ADDRESSES: { [chainId in ChainId]: string } = {
  [ChainId.TESTNET]: '0xAa293D2262cFE5bBCE618f26E582e8E338237c8c',
  [ChainId.MAINNET]: '0xe07C22C184ca252A525511B798BB8Ce96abDCc5b'
}

const V1_FACTORY_INTERFACE = new Interface(V1_FACTORY_ABI)
const V1_EXCHANGE_INTERFACE = new Interface(V1_EXCHANGE_ABI)

export { V1_EXCHANGE_ABI, V1_EXCHANGE_INTERFACE, V1_FACTORY_ABI, V1_FACTORY_ADDRESSES, V1_FACTORY_INTERFACE }
