import { ButtonPrimary } from 'components/Button'
import { IMAGES, LIGHT_BG_GRADIENT, LIGHT_BOX_SHADOW, LIGHT_GREEN, WARNING_COLOR } from 'constants/index'
import { darken } from 'polished'
import { Text } from 'rebass'
import { useIsDarkMode } from 'state/user/hooks'
import styled from 'styled-components'

export const Wrapper = styled.div`
  position: relative;
  padding: 32px 32px 40px;
  border: ${({ theme }) => (theme.isDarkTheme ? `1px solid ${theme.primary2}` : 'none')};
  border-radius: 16px;
  background-color: ${({ theme }) => theme.bg6};
  width: 100%;
  max-width: 552px;
  box-shadow: ${({ theme }) => (theme.isDarkTheme ? '0 12px 60px -10px rgba(0, 0, 0, 0.7)' : LIGHT_BOX_SHADOW)};

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    padding: 18px 24px 32px;
    max-width: calc(100vw - 2rem);
  `};
`

export const GridWrapper = styled.div`
  position: fixed;
  inset: 0;
  z-index: -1;

  > img.top {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  > img.grid {
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0.1;
  }
  ${({ theme }) => !theme.isDarkTheme && `background: ${LIGHT_BG_GRADIENT};`}
`

export const ClickableText = styled(Text)`
  :hover {
    cursor: pointer;
  }
  color: ${({ theme }) => theme.primary1};
`
export const MaxButton = styled.button<{ width: string; active: boolean }>`
  padding: 0.25rem 1rem;
  background-color: ${({ theme, active }) => (active ? theme.primary0 : 'transparent')} 
  border: 1px solid ${({ theme }) => theme.primary0};
  border-radius: 90px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  margin: 0.25rem;
  overflow: hidden;
  color: ${({ theme, active }) => (active ? theme.bg6 : theme.primary0)};
  transition: all 200ms ease-in-out;
  :hover {
    background-color: ${({ theme }) => theme.primary0};
    color: ${({ theme }) => theme.bg6};
  }
  :focus {
    // border: 1px solid ${({ theme }) => theme.primary1};
    outline: none;
  }
`

export const Dots = styled.span`
  &::after {
    display: inline-block;
    animation: ellipsis 1.25s infinite;
    content: '.';
    width: 1em;
    text-align: left;
  }
  @keyframes ellipsis {
    0% {
      content: '.';
    }
    33% {
      content: '..';
    }
    66% {
      content: '...';
    }
  }
`

export const MainButton = styled(ButtonPrimary)<{ error?: boolean }>`
  font-size: 1.25rem;
  transition: box-shadow 200ms ease-in-out;

  border-color: ${({ error }) => error && WARNING_COLOR};
  color: ${({ error }) => error && WARNING_COLOR};
  background-color: ${({ error }) => error && 'transparent'};

  &:hover:not(:disabled) {
    color: ${({ theme, error }) => error && !theme.isDarkTheme && LIGHT_GREEN};
    background-color: ${({ theme, error }) => error && !theme.isDarkTheme && WARNING_COLOR};
  }

  &:active:not(:disabled) {
    box-shadow: ${({ error }) => error && `0 0 0 1pt ${darken(0.1, WARNING_COLOR)}`};
    background-color: ${({ theme, error }) => error && !theme.isDarkTheme && darken(0.1, WARNING_COLOR)};
  }

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    padding: 14px 18px;
    font-size: 1rem;
    font-weight: 600;
  `}
`

export const BackgroundGrid = () => {
  const darkMode = useIsDarkMode()
  return (
    <GridWrapper>
      <img src={darkMode ? IMAGES.HERO_VECTOR : IMAGES.GRID_LIGHTMODE} alt="" className="grid" />
      {darkMode && <img src={IMAGES.HERO_TOP} alt="" className="top" />}
    </GridWrapper>
  )
}
