interface Props {
  color?: string
  style?: any
}

export const AccountIcon = (props: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
    <path
      xmlns="http://www.w3.org/2000/svg"
      d="M13.788 0H8.96v1.448h3.08L8.29 5.2a.414.414 0 0 1-.591 0l-1.26-1.26a1.9 1.9 0 0 0-2.684 0L0 7.695l1.023 1.023 3.756-3.756a.448.448 0 0 1 .635 0l1.26 1.261a1.868 1.868 0 0 0 2.639 0l3.75-3.752v3.08h1.449V.725c0-.4-.324-.724-.724-.724zM.753 10.38v1.93c0 .934.756 1.69 1.69 1.69h9.655a1.69 1.69 0 0 0 1.69-1.69V7h-1.449v5.31a.241.241 0 0 1-.24.242H9.925V8.93H8.477v3.62H6.064V7.967H4.615v4.586H2.443a.242.242 0 0 1-.241-.242v-1.93H.753z"
      fill={props.color || '#7CE5CF'}
    />
  </svg>
)
