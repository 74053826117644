import { SearchToken } from 'models'

import axiosClient from './axiosClient'

const tokenApi = {
  get(address: string): Promise<{ result: SearchToken | null }> {
    return axiosClient.get('/api', {
      params: {
        module: 'token',
        action: 'getToken',
        contractaddress: address
      }
    })
  }
}

export default tokenApi
