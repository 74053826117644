import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt'
import { Button, Stack, styled, Typography } from '@mui/material'
import { BRAND_COLOR, BRAND_COLOR_LIGHT, DARK_GREEN, MIDDLE_GREEN } from 'constants/index'
import { Fragment, useMemo } from 'react'
import { useIsDarkMode } from 'state/user/hooks'

const getStyles = (isDarkTheme: boolean) => ({
  pagination: {
    justifyContent: 'center',
    pt: 4,
    color: 'red'
  },
  text: {
    lineHeight: 2.5,
    color: isDarkTheme ? BRAND_COLOR : DARK_GREEN
  }
})

const StyledArrowIcon = styled(ArrowRightAltIcon)({
  color: BRAND_COLOR_LIGHT
})
interface HistoryPaginationProps {
  currentPage: number
  maxPage: number
  onChangePage: (page: number) => void
}

function HistoryPagination({ currentPage, maxPage, onChangePage }: HistoryPaginationProps) {
  const darkMode = useIsDarkMode()
  const styles = getStyles(darkMode)

  const disablePrev = useMemo(() => {
    return currentPage <= 1
  }, [currentPage, maxPage])

  const disableNext = useMemo(() => {
    return currentPage === maxPage
  }, [currentPage, maxPage])
  return (
    <Fragment>
      <Stack spacing={2} direction="row" sx={styles.pagination}>
        <Button onClick={() => onChangePage(currentPage - 1)} disabled={disablePrev}>
          <StyledArrowIcon
            sx={{
              transform: 'rotate(180deg)',
              display: disablePrev ? 'none' : 'block',
              ...(!darkMode && { fill: MIDDLE_GREEN })
            }}
          />
        </Button>
        <Typography sx={styles.text}>
          Page {currentPage} of {maxPage}
        </Typography>
        <Button onClick={() => onChangePage(currentPage + 1)} disabled={disableNext}>
          <StyledArrowIcon
            sx={{
              display: disableNext ? 'none' : 'block',
              ...(!darkMode && { fill: MIDDLE_GREEN })
            }}
          />
        </Button>
      </Stack>
    </Fragment>
  )
}

export default HistoryPagination
