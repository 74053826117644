import { BRAND_COLOR, DARK_BLUE, DARK_GREEN, DARKER_GREEN, LIGHT_GREEN } from 'constants/index'
import ENV_VARS from 'env'
import { useOnClickOutside } from 'hooks/useOnClickOutside'
import { useRef } from 'react'
import { Link } from 'react-router-dom'
import { ApplicationModal } from 'state/application/actions'
import { useModalOpen, useToggleModal } from 'state/application/hooks'
import { useIsDarkMode } from 'state/user/hooks'
import styled, { css } from 'styled-components'
import { AccountIcon, HistoryIcon, OverviewIcon, PairsIcon, TokensIcon } from 'svgComponents/index'

import { ExternalLink } from '../../theme'

const StyledMenuButton = styled.button`
  border: none;
  background-color: transparent;
  text-transform: uppercase;
  transition: all 200ms ease-in-out;
  font-size: 0.875rem;
  margin: 0;
  padding: 0;
  font-weight: 500;
  color: ${({ theme }) => theme.primary0};
  opacity: 0.6;

  :hover,
  :focus {
    cursor: pointer;
    opacity: 1;
    outline: none;
  }
`

const StyledMenu = styled.div`
  margin-left: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border: none;
  text-align: left;
`

const MenuFlyout = styled.span`
  min-width: 8.125rem;
  background-color: ${({ theme }) => (theme.isDarkTheme ? DARK_BLUE : DARKER_GREEN)};
  box-shadow: ${({ theme }) =>
    theme.isDarkTheme
      ? '0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04), 0px 24px 32px rgba(0, 0, 0, 0.01)'
      : '0 12px 24px 0 rgba(0, 0, 0, 0.25)'};
  border-radius: 8px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  position: absolute;
  top: 2rem;
  right: -200%;
  z-index: 100;
  gap: 0.5rem;
  border: ${({ theme }) => (theme.isDarkTheme ? `1px solid ${theme.primary0}` : 'none')};
`
const commonCssLink = css`
  flex: 1;
  padding: 0.5rem;
  font-weight: 600;
  border-radius: 4px;
  display: flex;
  align-items: center;
  transition: all 200ms ease-in-out;
  white-space: nowrap;
  text-decoration: none !important;
  color: ${({ theme }) => (theme.isDarkTheme ? theme.primary0 : DARK_GREEN)};

  > svg {
    margin-right: 1rem;
  }

  > svg:last-child {
    display: none;
  }

  :hover {
    cursor: pointer;
    > svg:last-child {
      display: block;
    }

    > svg:first-child {
      display: none;
    }
    color: ${({ theme }) => (theme.isDarkTheme ? theme.primary0 : LIGHT_GREEN)};
    background-color: ${DARK_GREEN};
  }
`

const MenuItem = styled(ExternalLink)`
  ${commonCssLink}
`
const StyledNavLink = styled(Link)`
  ${commonCssLink}

  > svg {
    transform: translateX(-2px);
  }
`
export function AnalyticsMenu() {
  const node = useRef<HTMLDivElement>()
  const open = useModalOpen(ApplicationModal.ANALYTICS_MENU)
  const toggle = useToggleModal(ApplicationModal.ANALYTICS_MENU)
  const darkMode = useIsDarkMode()

  const iconColor = darkMode ? BRAND_COLOR : DARK_GREEN
  const iconHoverColor = darkMode ? BRAND_COLOR : LIGHT_GREEN
  const themeQuery = `?dm=${darkMode ? '1' : '0'}`

  useOnClickOutside(node, open ? toggle : undefined)
  return (
    <StyledMenu ref={node as any}>
      <StyledMenuButton onClick={toggle}>Analytics</StyledMenuButton>

      {open && (
        <MenuFlyout>
          <MenuItem id="link" href={`${ENV_VARS.infoWebsiteUrl}/home${themeQuery}`} target="_self">
            <OverviewIcon color={iconColor} />
            <OverviewIcon color={iconHoverColor} />
            Overview
          </MenuItem>
          <MenuItem id="link" href={`${ENV_VARS.infoWebsiteUrl}/tokens${themeQuery}`} target="_self">
            <TokensIcon color={iconColor} />
            <TokensIcon color={iconHoverColor} />
            Token
          </MenuItem>
          <MenuItem id="link" href={`${ENV_VARS.infoWebsiteUrl}/pairs${themeQuery}`} target="_self">
            <PairsIcon color={iconColor} />
            <PairsIcon color={iconHoverColor} />
            Pairs
          </MenuItem>
          <MenuItem id="link" href={`${ENV_VARS.infoWebsiteUrl}/accounts${themeQuery}`} target="_self">
            <AccountIcon color={iconColor} />
            <AccountIcon color={iconHoverColor} />
            Account
          </MenuItem>
          <StyledNavLink to={'/history'} onClick={toggle}>
            <HistoryIcon color={iconColor} />
            <HistoryIcon color={iconHoverColor} />
            Transaction History
          </StyledNavLink>
        </MenuFlyout>
      )}
    </StyledMenu>
  )
}
