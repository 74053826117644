import { createSlice } from '@reduxjs/toolkit'
import { SerializedFarm, SerializedFarmsState } from 'models'
import { resetUserState } from 'state/global/actions'

import { fetchFarmList, fetchFarmMore } from './actions'

const resetUserData = (farm: SerializedFarm) => ({
  ...farm,
  userData: {
    allowance: '0',
    tokenBalance: '0',
    stakedBalance: '0',
    earnings: '0'
  }
})

const initialState: SerializedFarmsState = {
  data: [],

  isFetching: false,
  userDataLoaded: false,
  isFetchingMore: false,

  poolLength: 0,
  totalFetched: 0,
  lockTimeLength: 0,

  aswPerSecond: 0,
  totalAllocPoint: 0
}

export const farmSlice = createSlice({
  name: 'farms',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(resetUserState, state => {
      state.userDataLoaded = false
      state.data = state.data.map(resetUserData)
    })

    // fetch farm list
    builder.addCase(fetchFarmList.pending, state => {
      state.isFetching = true
    })

    builder.addCase(fetchFarmList.rejected, state => {
      state.isFetching = false
      state.data = state.data.map(resetUserData)
    })

    builder.addCase(fetchFarmList.fulfilled, (state, action) => {
      const { farmList, poolLength, totalFetched, adaswapPerSecond, totalAllocPoint, lockTimeLength } = action.payload

      state.data = farmList
      state.isFetching = false
      state.userDataLoaded = true

      state.poolLength = poolLength
      state.totalFetched = totalFetched
      state.lockTimeLength = lockTimeLength

      state.aswPerSecond = adaswapPerSecond
      state.totalAllocPoint = totalAllocPoint
    })

    // fetch farm more
    builder.addCase(fetchFarmMore.pending, state => {
      state.isFetchingMore = true
    })

    builder.addCase(fetchFarmMore.fulfilled, (state, action) => {
      const { farmList, totalFetched } = action.payload
      const oldData = [...state.data]
      state.data = [...oldData, ...farmList]
      state.totalFetched = totalFetched
      state.isFetchingMore = false
    })

    builder.addCase(fetchFarmMore.rejected, state => {
      state.isFetchingMore = false
    })
  }
})

export default farmSlice.reducer
