import { TransactionResponse } from '@ethersproject/providers'
import { useActiveWeb3React } from 'hooks'
import { FarmWithStakedValue } from 'models'
import { toast } from 'react-toastify'
import { useTransactionAdder } from 'state/transactions/hooks'
import { calculateGasMargin } from 'utils'
import lockTimeIdToDay from 'utils/lockTimeIdToDay'

import { useMasterChefContract } from './useContract'

export function useHarvestFarm(
  farm: FarmWithStakedValue | null,
  setHash: (hash: string) => void,
  setAttemptingTxn: (attemptingTxn: boolean) => void
) {
  const masterChefContract = useMasterChefContract()
  const addTransaction = useTransactionAdder()
  const { account } = useActiveWeb3React()

  return async () => {
    if (!masterChefContract || !farm || !account) return

    const estimate = masterChefContract.estimateGas.harvest
    const method: (...args: any) => Promise<TransactionResponse> = masterChefContract.harvest
    const args: Array<string | number> = [farm.pid, farm.lockTimeId, account]
    const summary = `Harvest ASW Token for farm ${farm.token.symbol}/${farm.quoteToken.symbol} ${
      lockTimeIdToDay(farm.lockTimeId).label
    }`

    setAttemptingTxn(true)

    await estimate(...args)
      .then(estimatedGasLimit =>
        method(...args, {
          gasLimit: calculateGasMargin(estimatedGasLimit)
        }).then(response => {
          setAttemptingTxn(false)
          addTransaction(response, { summary })
          setHash(response.hash)
          toast.info(summary)
        })
      )

      .catch(error => {
        setAttemptingTxn(false)
        if (error?.code === 4001) {
          toast.error('Transaction rejected')
          return
        }

        toast.error(`Failed to havest ASW token: ${error?.message}`)
        console.error(error)
      })
  }
}
