import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded'
import { FormControl, MenuItem, Select, SelectChangeEvent, Stack } from '@mui/material'
import { DARK_BLUE, LIGHT_GREEN } from 'constants/index'
import { useIsDarkMode } from 'state/user/hooks'

export interface SortFilerProps {
  value: string
  onChange: (event: SelectChangeEvent) => void
}

export function SortFilter({ value, onChange }: SortFilerProps) {
  const darkMode = useIsDarkMode()

  return (
    <Stack direction="row" alignItems="center" spacing={3} flexShrink={0}>
      {/* <Typography component="span" fontSize="1rem">
        Sort By
      </Typography> */}

      <FormControl sx={{ minWidth: 186 }}>
        <Select
          value={value}
          onChange={onChange}
          IconComponent={KeyboardArrowDownRoundedIcon}
          sx={{
            borderRadius: 2,
            '.MuiSelect-select': { py: '12.5px' },
            '.MuiSelect-icon': { color: 'primary.main' },
            '.MuiOutlinedInput-notchedOutline': {
              borderWidth: '0.5px !important'
            }
          }}
          MenuProps={{
            sx: {
              '.MuiMenu-paper': {
                mt: 1.5,
                bgcolor: darkMode ? DARK_BLUE : LIGHT_GREEN,
                border: 0.5,
                borderColor: 'primary.main',
                borderRadius: 2,
                color: 'primary.light'
              },

              '.MuiMenu-list': {
                px: 2,
                pb: 2
              },

              '.MuiMenuItem-root': {
                mt: 1,
                borderRadius: 1,
                transition: 'all 200ms ease-in-out',
                color: 'primary.main'
              },

              '.MuiMenuItem-root:hover': {
                backgroundColor: 'primary.main',
                color: darkMode ? DARK_BLUE : LIGHT_GREEN
              },

              '.MuiMenuItem-root.Mui-selected:hover': {
                color: 'primary.main'
              }
            }
          }}
        >
          {sortList.map(option => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Stack>
  )
}

const sortList = [
  {
    value: 'all',
    label: 'All pools'
  },
  {
    value: 'standard',
    label: 'Standard pools'
  }
  // {
  //   value: 'multiplier',
  //   label: 'Multiplier'
  // },
  // {
  //   value: 'earned',
  //   label: 'Earned'
  // },
  // {
  //   value: 'liquidity',
  //   label: 'Liquidity'
  // },
  // {
  //   value: 'latest',
  //   label: 'Latest'
  // }
]
