import { TokenAmount } from '@adaswap/sdk'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Chip,
  Divider,
  Stack,
  Typography
} from '@mui/material'
import { useWeb3React } from '@web3-react/core'
import LinkWithIcon from 'components/pool/LinkWithIcon'
import TokenPair from 'components/pool/TokenPair'
import {
  ASW_TESTNET,
  BRAND_COLOR,
  BRAND_COLOR_LIGHT,
  DARK_BG_GRADIENT,
  DARK_BLUE,
  DARK_GREEN,
  LIGHT_BG_GRADIENT
} from 'constants/index'
import { useState } from 'react'
import { useWalletModalToggle } from 'state/application/hooks'
import { useIsDarkMode } from 'state/user/hooks'
import { ArrowDown, ArrowUp, CalculatorIcon } from 'svgComponents'
import { getDisplayAprPercent } from 'utils/apr'
import { getFullDisplayBalance } from 'utils/formatBalance'
import { getTokenIcon } from 'utils/index'

import { FarmItemProps, FarmItemTooltip } from '.'

const TEXT_SIZE = { xs: '0.875rem', lg: '1rem' }

export function FarmItemThumbnailView({
  farm,
  actionLink,
  totalStacked,
  onOpenStake,
  onOpenCalculator,
  onOpenHarvest
}: FarmItemProps) {
  const { active } = useWeb3React()
  const toggleWalletModal = useWalletModalToggle()
  const darkMode = useIsDarkMode()
  const { token, quoteToken, maxApr, maxLpApr, maxMultiplier, totalStakedBalance, totalPendingAsw } = farm
  const farmSymbol = `${token.symbol}-${quoteToken.symbol}`

  const [expanded, setExpanded] = useState<boolean>(false)

  const textWhite = {
    fontSize: TEXT_SIZE,
    color: darkMode ? 'white' : DARK_GREEN
  }

  const [hasStackedBalance, hasPendingAsw] = [
    totalStakedBalance ? totalStakedBalance.gt(0) : false,
    totalPendingAsw ? totalPendingAsw.gt(0) : false
  ]

  const [formattedTotalStakedBalance, formattedTotalPendingAsw] = [
    totalStakedBalance ? getFullDisplayBalance(totalStakedBalance, 18, 18) : '',
    // totalPendingAsw ?  getFullDisplayBalance(totalPendingAsw, 18, 9) : ''
    new TokenAmount(ASW_TESTNET, totalPendingAsw ? totalPendingAsw.toString() : '')
  ]

  return (
    <Accordion
      disableGutters
      sx={{
        px: 3,
        background: darkMode ? DARK_BG_GRADIENT : LIGHT_BG_GRADIENT,
        borderRadius: '24px !important',
        border: darkMode ? `1px solid ${BRAND_COLOR}` : 'none',
        boxShadow: 'none',

        '&.Mui-expanded': {
          boxShadow: darkMode ? `0 0 10px -5px ${BRAND_COLOR}` : 'none'
        },

        '.MuiAccordionSummary-root': {
          cursor: 'auto !important'
        },

        '.MuiAccordionSummary-content': {
          display: 'block',
          my: 4
        }
      }}
      expanded={expanded}
    >
      <AccordionSummary sx={{ px: 0 }}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <TokenPair icon1={getTokenIcon(token.symbol)} icon2={getTokenIcon(quoteToken.symbol)} iconSize={40} />

          <Stack spacing={1} alignItems="flex-end">
            <Typography component="h1" sx={{ fontSize: '1.25rem', color: darkMode ? BRAND_COLOR_LIGHT : DARK_BLUE }}>
              {farmSymbol}
            </Typography>

            <Stack direction="row" spacing={1} alignItems="center">
              <Chip
                label={`${maxMultiplier ?? 0}X`}
                variant="filled"
                color="primary"
                sx={{
                  fontSize: TEXT_SIZE,
                  fontWeight: 500,
                  '.MuiChip-label': { px: 2.5 }
                }}
              />

              <FarmItemTooltip
                contentList={[
                  'The Multiplier represents the proportion of $ASW reward each farm receives, as a proportion of the $ASW produced each block.',
                  'For example, if a 1x farm received 1$ASW per second, 1 40x farm would receive 40$ASW per second.',
                  'This amount is already included in all APR calculations from the farm.'
                ]}
              />
            </Stack>
          </Stack>
        </Stack>

        <Stack pt={7} pb={4} spacing="10px">
          <Stack direction="row" justifyContent="space-between">
            <Typography sx={textWhite}>APR</Typography>

            <Stack
              direction="row"
              spacing={1.5}
              alignItems="center"
              onClick={onOpenCalculator}
              sx={{
                transition: 'all 200ms ease-in-out',
                ':hover': { cursor: 'pointer', opacity: 0.5 }
              }}
            >
              <Typography sx={textWhite}>{getDisplayAprPercent(maxApr, maxLpApr)}</Typography>
              <CalculatorIcon color={textWhite.color} />
            </Stack>
          </Stack>

          <Stack direction="row" justifyContent="space-between">
            <Typography sx={textWhite}>Earn</Typography>
            <Typography sx={textWhite}>ASW + Fees</Typography>
          </Stack>
        </Stack>

        <Divider sx={{ borderColor: darkMode ? 'rgba(124, 229, 207, 0.3)' : 'rgba(28, 65, 79, 0.3)' }} />

        <Stack spacing={4} py={4}>
          <Stack direction="row" alignItems="center">
            <Stack spacing={1} flex={1}>
              <Typography>
                ASW&nbsp;
                <Typography component="span" sx={textWhite}>
                  Earned
                </Typography>
              </Typography>

              <Typography
                color={darkMode ? BRAND_COLOR_LIGHT : DARK_GREEN}
                fontWeight={600}
                fontSize={{ xs: '1rem', md: '1.125rem' }}
              >
                {hasPendingAsw ? formattedTotalPendingAsw.toSignificant(6) : '0.0'}
              </Typography>
            </Stack>

            <Button
              variant="outlined"
              onClick={onOpenHarvest}
              disabled={!hasPendingAsw}
              sx={{
                fontSize: TEXT_SIZE,
                bgcolor: darkMode ? 'transparent' : DARK_GREEN,
                minWidth: 135,
                py: 1.25
              }}
            >
              Harvest
            </Button>
          </Stack>

          <Stack spacing={1}>
            <Typography color="primary.main">
              {farmSymbol} LP&nbsp;
              <Typography component="span" sx={textWhite}>
                Staked
              </Typography>
            </Typography>

            <Typography
              color={darkMode ? BRAND_COLOR_LIGHT : DARK_GREEN}
              fontWeight={600}
              fontSize={{ xs: '1rem', md: '1.125rem' }}
            >
              {hasStackedBalance ? formattedTotalStakedBalance : '0.0'}
            </Typography>
          </Stack>

          <Button
            fullWidth
            disableElevation
            variant={active ? 'outlined' : 'contained'}
            sx={{ py: 1.75, fontSize: TEXT_SIZE }}
            onClick={active ? onOpenStake : toggleWalletModal}
          >
            {active ? 'Stake LP' : 'Connect Wallet'}
          </Button>
        </Stack>

        <Box textAlign="center">
          <Button
            endIcon={
              expanded ? (
                <ArrowUp color={darkMode ? BRAND_COLOR : DARK_GREEN} style={{ height: '8px' }} />
              ) : (
                <ArrowDown color={darkMode ? BRAND_COLOR : DARK_GREEN} style={{ height: '8px' }} />
              )
            }
            onClick={() => setExpanded(x => !x)}
            sx={{ px: 1.5, fontSize: TEXT_SIZE, fontWeight: 'normal' }}
          >
            Details
          </Button>
        </Box>
      </AccordionSummary>

      <AccordionDetails sx={{ px: 0, pb: 4 }}>
        <Stack spacing={2}>
          <Stack direction="row" justifyContent="space-between" alignItems="flex-start">
            <Typography sx={{ ...textWhite, fontSize: '1rem' }}>Total Staked</Typography>
            <Typography sx={{ ...textWhite, fontSize: '1rem' }}>{totalStacked}</Typography>
          </Stack>

          <LinkWithIcon text={`Get ${farmSymbol} LP`} to={actionLink.addLiquidity} />

          <Stack direction="row" justifyContent="space-between">
            <LinkWithIcon text="View Contract" href={actionLink.viewContract} />
            <LinkWithIcon text="Pair Info" href={actionLink.seePairInfo} />
          </Stack>
        </Stack>
      </AccordionDetails>
    </Accordion>
  )
}
