import { ChainId, Token } from '@adaswap/sdk'
import { ASW, mADA, NATIVE_TOKEN_ADDRESS, TOKENS, WADA_TOKEN, WRAPPED_ADA } from 'constants/index'
import { default as dayjs } from 'dayjs'
import ENV_VARS from 'env'
import { EnvironmentDeploy, TransactionType } from 'models'
import Numeral from 'numeral'
interface ListAsset {
  [key: string]: string
}

export const mapToCDN = (path: string) => {
  if (!path) return
  return `${ENV_VARS.cdnURL}/${path}`
}

export const mapListToCDN = (list: ListAsset, folderName: string): ListAsset => {
  const listCDN = Object.entries(list).reduce((prev, [key, value]) => {
    return { ...prev, [key]: mapToCDN(`${folderName}/${value}`) }
  }, {})

  return listCDN
}

export const equalsIgnoreCase = (a: string, b: string) => {
  return a.localeCompare(b, undefined, { sensitivity: 'accent' }) === 0
}

export function getTokenIcon(symbol?: string, address?: string) {
  const asw: Token = isTestnet ? ASW[ChainId.TESTNET] : ASW[ChainId.MAINNET]
  if (!symbol || (address && symbol === 'ASW' && !equalsIgnoreCase(address, asw.address))) return 'default'
  return TOKENS[`${Object.keys(TOKENS).find(x => symbol.toUpperCase().includes(x))}`] || 'default'
}

export function getTransactionType(event: TransactionType, symbol0: string, symbol1: string) {
  const formattedS0 = symbol0?.length > 8 ? symbol0.slice(0, 7) + '...' : symbol0
  const formattedS1 = symbol1?.length > 8 ? symbol1.slice(0, 7) + '...' : symbol1
  switch (event) {
    case TransactionType.ADD:
      return 'Add ' + formattedS0 + ' and ' + formattedS1
    case TransactionType.REMOVE:
      return 'Remove ' + formattedS0 + ' and ' + formattedS1
    case TransactionType.SWAP:
      return 'Swap ' + formattedS0 + ' for ' + formattedS1
    default:
      return ''
  }
}
const toK = (num: string) => {
  return Numeral(num).format('0.[00]a')
}
const formatDollarAmount = (num: number, digits: number) => {
  const formatter = new Intl.NumberFormat([], {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: digits,
    maximumFractionDigits: digits
  })
  return formatter.format(num)
}

export const formattedNum = (number: string, usd = false, acceptNegatives = false): string => {
  if (number === '' || number === undefined) {
    return usd ? '$0' : '0'
  }
  const num = parseFloat(number)

  if (num > 500000000) {
    return (usd ? '$' : '') + toK(num.toFixed(0))
  }

  if (num === 0) {
    if (usd) {
      return '$0'
    }
    return '0'
  }

  if (num < 0.0001 && num > 0) {
    return usd ? '< $0.0001' : '< 0.0001'
  }

  if (num > 1000) {
    return usd ? formatDollarAmount(num, 0) : Number(num.toFixed(0)).toLocaleString()
  }

  if (usd) {
    if (num < 0.1) {
      return formatDollarAmount(num, 4)
    } else {
      return formatDollarAmount(num, 2)
    }
  }

  return Number(num.toFixed(4)).toString()
}

export const formatTime = (unix: number) => {
  const now = dayjs()
  const timestamp = dayjs.unix(unix)

  const inSeconds = now.diff(timestamp, 'second')
  const inMinutes = now.diff(timestamp, 'minute')
  const inHours = now.diff(timestamp, 'hour')
  const inDays = now.diff(timestamp, 'day')

  if (inHours >= 24) {
    return `${inDays} ${inDays === 1 ? 'day' : 'days'} ago`
  } else if (inMinutes >= 60) {
    return `${inHours} ${inHours === 1 ? 'hour' : 'hours'} ago`
  } else if (inSeconds >= 60) {
    return `${inMinutes} ${inMinutes === 1 ? 'minute' : 'minutes'} ago`
  } else {
    return `${inSeconds} ${inSeconds === 1 ? 'second' : 'seconds'} ago`
  }
}

export const nativeWrappedAdaToken = (token?: Token): Token => {
  if (!token) return WRAPPED_ADA

  const wadaToken = WADA_TOKEN[token.chainId]

  if (token.address.toLowerCase() !== wadaToken.address.toLowerCase()) return token

  return {
    ...token,
    address: NATIVE_TOKEN_ADDRESS,
    symbol: mADA
  } as Token
}

export const isTestnet =
  ENV_VARS.environmentDeploy === EnvironmentDeploy.Testnet || ENV_VARS.environmentDeploy === EnvironmentDeploy.Stage
export const isStage = ENV_VARS.environmentDeploy === EnvironmentDeploy.Stage
export const isMainnet = ENV_VARS.environmentDeploy === EnvironmentDeploy.Mainnet
