import { FormControlLabel, styled, Switch, SwitchProps } from '@mui/material'
import { BG_DARK_BLUE, LIGHT_GREEN } from 'constants/index'
import React from 'react'
import { useIsDarkMode } from 'state/user/hooks'

const StyledSwitch = styled(Switch)(({ theme }) => ({
  padding: 6,

  '.MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.primary.main
  },

  '.MuiSwitch-thumb': {
    width: 16,
    height: 16,
    margin: 2,
    boxShadow: 'none'
  },

  '.Mui-checked': {
    color: `${theme.isDarkTheme ? BG_DARK_BLUE : LIGHT_GREEN} !important`
  },

  '.Mui-checked + .MuiSwitch-track': {
    opacity: '1 !important'
  }
}))

const getSx = (isDarkTheme: boolean) => ({
  '.MuiFormControlLabel-label': {
    color: isDarkTheme ? 'primary.light' : 'primary.main',
    lineHeight: 'normal',
    fontWeight: 'normal',
    fontSize: { xs: '0.875rem', sm: '1rem' }
  }
})

export interface SwitchFieldProps extends SwitchProps {
  label?: string
  isChecked?: boolean
  onToggle?: () => void
}

export function SwitchField({ label, checked, onChange, isChecked, onToggle }: SwitchFieldProps) {
  const darkMode = useIsDarkMode()
  if (!label) return <StyledSwitch checked={checked} onChange={onChange} />
  return (
    <FormControlLabel
      control={<StyledSwitch checked={isChecked} />}
      label={label}
      sx={getSx(darkMode)}
      onChange={onToggle}
    />
  )
}
