import * as React from 'react'

interface Props {
  color?: string
}

export const TwitterIcon = (props: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
    <path
      d="M21.533 7.112c.015.213.015.426.015.64 0 6.502-4.949 13.994-13.995 13.994A13.9 13.9 0 0 1 0 19.538c.396.046.777.061 1.188.061 2.3 0 4.416-.777 6.106-2.101a4.928 4.928 0 0 1-4.599-3.412c.305.046.61.076.93.076a5.2 5.2 0 0 0 1.294-.167A4.92 4.92 0 0 1 .975 9.168v-.061a4.954 4.954 0 0 0 2.223.624 4.915 4.915 0 0 1-2.193-4.096c0-.914.244-1.752.67-2.483A13.981 13.981 0 0 0 11.817 8.3a5.554 5.554 0 0 1-.122-1.127 4.917 4.917 0 0 1 4.92-4.92c1.415 0 2.695.595 3.593 1.554A9.684 9.684 0 0 0 23.33 2.62a4.906 4.906 0 0 1-2.163 2.711A9.86 9.86 0 0 0 24 4.569a10.572 10.572 0 0 1-2.467 2.543z"
      fill={props.color || '#7CE5CF'}
    />
  </svg>
)
