import SearchRoundedIcon from '@mui/icons-material/SearchRounded'
import { TextField } from '@mui/material'
import { DARKER_GREEN, MIDDLE_GREEN } from 'constants/index'
import useDebounce from 'hooks/useDebounce'
import { useEffect, useState } from 'react'
import React from 'react'
import { useIsDarkMode } from 'state/user/hooks'

export interface SearchFilterProps {
  placeholderText: string
  hasBorder?: boolean
  fullWidth?: boolean
  onSearch?: (value: string) => void
}

export function SearchFilter({ onSearch, placeholderText, hasBorder = false, fullWidth = true }: SearchFilterProps) {
  const [value, setValue] = useState('')
  const debounceValue = useDebounce(value, 300)

  useEffect(() => {
    if (onSearch) {
      onSearch(debounceValue)
    }
  }, [debounceValue])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value)
  }

  const darkMode = useIsDarkMode()

  return (
    <TextField
      value={value}
      onChange={handleChange}
      autoComplete="off"
      variant="filled"
      size="small"
      placeholder={placeholderText}
      hiddenLabel
      InputProps={{
        disableUnderline: true,
        endAdornment: <SearchRoundedIcon color="primary" />,
        sx: {
          height: '100%',
          bgcolor: darkMode ? 'rgba(0, 0, 0, 0.06)' : `${DARKER_GREEN} !important`
        }
      }}
      sx={{
        border: !darkMode && hasBorder ? `1px solid ${MIDDLE_GREEN}` : 'none',
        ...(!darkMode && { color: MIDDLE_GREEN }),
        borderRadius: '8px',
        minWidth: { xs: 0, md: 250 },
        flex: fullWidth ? 1 : 0,
        input: { py: '4px' }
      }}
    />
  )
}
